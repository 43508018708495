export class Brands {
  public static FIAT = 'fiat';
  public static FIAT_PROFESSIONAL = 'fiat-professional';
  public static JEEP = 'jeep';
  public static ALFA_ROMEO = 'alfa-romeo';
  public static ABARTH = 'abarth';

  currentBrand: Brand;
  brands: Brand[];
}

export class Brand {
  name: string;
  slug: string;
  code: string;
  stockUrl: string;
  demoUrl: string;
  brandHomeSite: string;
  offerCount: number;
  config: any;
}
