/// <reference types="@types/googlemaps" /> // Don't remove this line!!! It isn't normal comment!

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
declare var google: any;
declare type Autocomplete = google.maps.places.Autocomplete;
declare type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;


@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
})
export class LocationSelectComponent implements OnInit {
  static selectedAfterChange: EventEmitter<boolean> = new EventEmitter();
  @Input() value: string;
  @Input() maxDistance;
  @Input() brandSlug;

  @Output() update: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;
  private autocomplete: Autocomplete;

  constructor(
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.element = $(this.elRef.nativeElement).find('input')[0] as HTMLInputElement;

    $(window).on('keyup', this.element, (event) => {
      if (this.element.value.length <= 2) {
        $('body').addClass('pac-container-hidden');
      } else {
        $('body').removeClass('pac-container-hidden');
      }
    });

    this.checkExistsGoogleObject();
  }

  checkExistsGoogleObject() {
    if (typeof google === 'object' && typeof google.maps === 'object') {
      this.autocomplete = new google.maps.places.Autocomplete(this.element);
      this.autocomplete.setComponentRestrictions({'country': ['pl']});
      this.autocomplete.setTypes(['(cities)']);
      google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
        const placeResult = this.autocomplete.getPlace();

        this.value = placeResult.place_id;
        this.changeDetectorRef.detectChanges();
        if (placeResult.address_components) {
          this.setElementValueByAddressComponnetsArray(placeResult.address_components);
          this.emitUpdateEvent();
        }
      });

      if (this.value !== undefined && this.value !== null) {
        this.setValue(this.value);
      }
    } else {
      setTimeout(() => {
        this.checkExistsGoogleObject();
      }, 500);
    }
  }

  findMe() {
    navigator.geolocation.getCurrentPosition((val) => {
      this.setValueByLatLng(val.coords.latitude, val.coords.longitude);
    });
  }

  setValue(value: any): void {
    const placeRequest = {placeId: value};
    const placeService = new google.maps.places.PlacesService(this.element);
    placeService.getDetails(placeRequest, (placeResult) => {
      $(this.element).val(placeResult.formatted_address);
      this.value = placeResult.place_id;
      this.changeDetectorRef.detectChanges();
      this.setElementValueByAddressComponnetsArray(placeResult.address_components);
      this.emitUpdateEvent();
    });
  }

  setValueByLatLng(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location':  {lat: lat, lng: lng}}, (results) => {
      for (const res of results) {
        if (res.types.indexOf('administrative_area_level_3') !== -1) {
          this.value = res.place_id;
          this.changeDetectorRef.detectChanges();
          this.setElementValueByAddressComponnetsArray(res.address_components);
          this.emitUpdateEvent();
          break;
        }
      }
    });
  }

  setElementValueByAddressComponnetsArray(addressComponents: GeocoderAddressComponent[]) {
    let country;
    let locality;
    let localityAreaLevel3;

    if (addressComponents && addressComponents[0]) {
      for (const component of addressComponents) {
        if (component.types.indexOf('locality') !== -1) {
          locality = component.long_name;
        }
        if (component.types.indexOf('administrative_area_level_3') !== -1) {
          localityAreaLevel3 = component.long_name;
        }
        if (component.types.indexOf('country') !== -1) {
          country = component.long_name;
        }
      }

      if (locality === undefined) {
        locality = localityAreaLevel3;
      }

      $(this.element).val(locality + ', ' + country);
    }
  }

  private emitUpdateEvent() {
    LocationSelectComponent.selectedAfterChange.emit(true);
    const updateObject = {
      name: 'place_id',
      value: this.value
    };
    this.update.emit(updateObject);
    this.setMaxDistance(this.maxDistance);
  }

  setMaxDistance(val) {
    const updateObject = {
      name: 'max_distance',
      value: val
    };
    this.update.emit(updateObject);
  }

  clear() {
    this.value = null;
    this.maxDistance = null;
    $(this.element).val('');
    this.emitUpdateEvent();
    LocationSelectComponent.selectedAfterChange.emit(false);
  }
}
