<div class="stock-select dealer-select no-icon"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null, 'disabled': !isValid}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <div class="select-selection-label">
      {{getLabelByValue(value)}}
    </div>
    <div class="select-selection-icons">
      <b *ngIf="value !== null" (click)="onUpdate(null)" class="remove"><i class="icon icon-x"></i></b>
      <b *ngIf="value === null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="value === null && !isOptionListOpened" class="arrow arrow--up"></b>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened && showAll === true && !loadingDealerList">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.value)" [ngClass]="{'selected': value === item.value}">
        <div class="option__item">
          {{item.label}}<br />
          <small>{{item.address}}</small>
        </div>
      </div>
    </div>
    <div class="select-option-list" *ngIf="isOptionListOpened && showAll === false && !loadingDealerList">
      <div *ngFor="let item of firstAvailableValues" class="option" (click)="onUpdate(item.value)" [ngClass]="{'selected': value === item.value}">
        <div class="option__item">
          {{item.label}}<br />
          <small>{{item.address}}</small>
        </div>
      </div>
      <div class="option" *ngIf="availableValues.length > 5" (click)="setShowAll()">
        <div class="option__item text-center show_all">
          Pokaż wszystkich dealerów
        </div>
      </div>
    </div>
    <div class="select-option-list select-option-info" *ngIf="isOptionListOpened && loadingDealerList && isValid">
      <loader [visible]="true"></loader>
    </div>
    <div class="select-option-list select-option-info" *ngIf="isOptionListOpened && !isValid">
      <div class="option__item text-center error-info">
        Podano niepoprawny kod pocztowy
      </div>
    </div>
  </div>
</div>
