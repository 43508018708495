import { Component, OnInit, OnDestroy } from '@angular/core';
import {StockApiService} from '../../services/stock-api.service';
import {AppComponent} from '../../app.component';
import {LocalStorageService} from '../../services/local-storage.service';
import {AdobeService} from '../../services/adobe.service';
import {BlueKaiService} from '../../services/blue-kai.service';
import {LabelProviderService} from '../../services/labelProvider.service';
import {BrandService} from '../../services/brand.service';
import {FinancialCalculatorPluginService} from
    '../../modules/offer/components/financial-api-calculator/financial-calculations-js-plugin/financial-calculator-plugin.service';
import {FullOffer} from '../../models/offer/full-offer';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormatMoneyPipe} from '../../tools/format-money.pipe';
import {environment} from '../../../environments/environment';
import {TrackingService} from '../../services/tracking.service';
import {ErrorHandler} from '../../tools/error-handler';
import {SessionStorageService} from '../../services/session-storage.service';

declare let ga: Function;
declare let gtag: Function;
declare let window: any;
declare var google: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
})
export class CartComponent implements OnInit, OnDestroy {
  private static STORAGE_SOL_KEY = 'sol-offer';
  static freespeeMasterkeyData = null;
  loading = true;
  AppComponent = AppComponent;
  brandSlug = '';
  offer: FullOffer = null;
  index = 0;
  loaded = false;
  galleryImages = [];
  galleryVidImg = [];
  siteData = window.sitedata;
  adobeThankyouPageData = null;
  offerNotExist = false;
  hiddenText = {
    description: true,
    equipmentStandard: true,
    equipmentOptional: true,
    technicalData: false
  };
  solData = {};
  calculationData = null;
  firstCalculate = true;
  offerDetails = false;
  orderFields = [];
  cartIsEmpty = false;
  solLoader = false;
  errorText = '';
  correctInstallment = true;
  openBox = {
    equipmentStandard: false,
    equipmentOptional: false,
    dealerDescription: true,
  };
  checkboxAkcept = false;
  isCalculatorTest = false;
  selectedPriceType = '';
  orderForCash = {
    cta: 'RP',
    modalFormIsOpen: false,
  };

  private idParamSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private stockApiService: StockApiService,
    private adobeService: AdobeService,
    private blueKaiService: BlueKaiService,
    private labelProviderService: LabelProviderService,
    private LSS: LocalStorageService,
    private SSS: SessionStorageService
  ) {
    // this.loading = false;

    this.solData =  this.LSS.get(CartComponent.STORAGE_SOL_KEY, {});

    if (this.solData['offerId'] && this.solData['calculation'].calculatorId) {
      this.offer = new FullOffer();
      this.stockApiService.getOfferData(+this.solData['offerId'], null).then(value => { // (+) converts string 'id' to a number
        FinancialCalculatorPluginService.init();
        value = this.labelProviderService.fillOfferLabels(value);
        this.offer = value;
        if (this.SSS.get('calculator-test') !== null) {
          this.isCalculatorTest = true;
        }

        FinancialCalculatorPluginService.addOffer('calculate_' + this.offer.id, this.offer, this.isCalculatorTest);
        this.loading = false;
        this.calculationData = this.solData['calculation'];
        this.calculationData['installment'] = Math.ceil(this.calculationData['installment']);
        this.orderFields = this.calculationData['orderFields'].length > 0 ?
          this.calculationData['orderFields'] : ['contribution', 'period', 'limitKm', 'repurchase'];

        if (this.solData['selectedPriceType'] &&
          (this.solData['selectedPriceType'] === 'NET' || this.solData['selectedPriceType'] === 'GROSS')) {
          this.selectedPriceType = this.solData['selectedPriceType'];
        }
      });
      this.cartIsEmpty = false;
    } else {
      this.loading = false;
      this.cartIsEmpty = true;
    }
  }

  ngOnInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
      AppComponent.bodyClass = 'page sol-cart';
    });
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  ngOnDestroy() {
    const modalCalculator = $('.modal--calculator');

    if (modalCalculator.is(':visible')) {
      modalCalculator.modal('hide');
    }
  }

  setDefaultImg800x600(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_800x600.png" class="box-model__photo-img" />';
    }
  }

  setValue(data: any) {
    if (this.firstCalculate) {
      this.calculationData = this.solData['calculation'];
      this.calculationData['installment'] = Math.ceil(this.calculationData['installment']);
    } else {
      this.calculationData = data['data']['calculation'];
      this.calculationData['installment'] = Math.ceil(this.calculationData['installment']);

      this.solData['calculation'] = this.calculationData;
      this.LSS.set(CartComponent.STORAGE_SOL_KEY, this.solData);
    }

    this.correctInstallment = !( // FCAJFIN-24 Ograniczenie na kwotę (dotyczy tylko Abonamentów)
      (this.calculationData['clientType'] === 'b2c' || this.calculationData['clientType']  === 'b2b')
      && this.calculationData['installment'] <= 150);
  }

  openCalculatorBox() {
    if (this.firstCalculate) {
      const currentFields = {};
      for (const key in this.solData['calculation']['currentFields']) {
        if (this.solData['calculation']['currentFields'][key]) {
          currentFields[key] = this.solData['calculation']['currentFields'][key].value;
        }
      }

      let currentExtraServices = [];
      if (this.solData['calculation']['currentExtraServices']) {
        currentExtraServices = this.solData['calculation']['currentExtraServices'];
      }

      FinancialCalculatorPluginService.calculate(this.solData['calculatorId'], currentFields, currentExtraServices,
        this.solData['calculation']['clientType']);
      this.firstCalculate = false;
    }

    $('.modal--calculator').show(300, () => {
      $('.modal--calculator').modal('show');
    });
  }
  showOfferDetails() {
    this.offerDetails = !this.offerDetails;
  }

  setParameterKey(key) {
    switch (key) {
      case 'contribution':
        return 'Wpłata własna';
      case 'period':
        return 'Okres finansowania';
      case 'repurchase':
        return 'Wykup';
      case 'limitKm':
        return 'Limit KM';
    }
  }

  setParameterValue(key, value) {
    if (key === 'period') {
      return value['label']
        .replace('m-ce', '<small>m-ce</small>')
        .replace('m-cy', '<small>m-cy</small>');
    } else if (key === 'limitKm') {
      return FormatMoneyPipe.format(value['value']) + ' <small>KM</small>';
    } else if (key === 'contribution') {
      return value['description']
        .replace(' 0 zł netto', ' 1 zł netto')
        .replace(' 0 zł brutto', ' 1,23 zł brutto')
        .replace('Wkład własny ', '')
        .replace('zł brutto', '<small>zł brutto</small>')
        .replace('zł netto', '<small>zł netto</small>');
    } else {
      return value['description']
        .replace('Wykup ', '')
        .replace('zł brutto', '<small>zł brutto</small>')
        .replace('zł netto', '<small>zł netto</small>');
    }
  }

  sendSolData() {
    const tmpSolData = {
      offerId: this.solData['offerId'],
      email: 'test@test.com',
      financingCalculatorId: this.solData['calculation']['calculatorId'],
      financingCalculatorFields: this.solData['calculation']['currentFields'],
      financingCalculatorExtraServices: this.solData['calculation']['currentExtraServices'],
      financingCalculatorInstallment: this.solData['calculation']['installment']
    };

    this.solLoader = true;
    this.stockApiService.sendSolData(tmpSolData, this.brandSlug).then(value => {
      this.errorText = '';
      if (value.message !== undefined) {
        if (value.message === 'Form successfully saved' && value.PSAFormUrl !== undefined) {
          window.location = value.PSAFormUrl;
        }
      } else {
        this.solLoader = false;
      }
    }).catch(reason => {
      this.solLoader = false;
      this.errorText = 'Wystąpił błąd podczas przesyłania danych, proszę spróbować ponownie';
      ErrorHandler.handle(reason);
    });
  }

  showComunicators(){
    if (['fiat', 'fiat-professional', 'abarth'].indexOf(this.brandSlug) > -1) {
      let show = false;
      for (const item in this.offer.dealer.communicators) {
        if (this.offer.dealer.communicators[item].name === 'Messenger' || this.offer.dealer.communicators[item].name === 'WhatsApp' ) {
          show = true;
        }
      }
      return show;
    } else {
      return true;
    }
  }

  sendGA(event: string, eventCategory: string, eventAction: string, eventLabel: string, eventValue: string) {
    if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS)) {
      if (typeof ga !== 'undefined') {
        ga(event, eventCategory, eventAction, eventLabel, eventValue);
      }
      if (typeof gtag !== 'undefined') {
        gtag(eventCategory, eventLabel, {
          'event_category': eventAction,
          'event_label': eventValue
        });
      }
    }
  }

  setSelectedPriceType(data: any) {
    this.selectedPriceType = data.priceType;
  }

  openOrCloseForm(open) {
    if (open) {
      $('body').addClass('form-modal-open');
    } else {
      $('body').removeClass('form-modal-open');
    }
    this.orderForCash.modalFormIsOpen = open;
  }
}
