<div class="stock-select stock-select--model-version model-version-with-color-list"
     *ngIf="availableValues !== undefined"
     [ngClass]="{'selected': model !== null}">
    <div class="select-selection" (click)="openOrCloseOptionList($event)">
        <div class="select-selection-label" [innerHTML]="getDisplayValue()"></div>
        <div class="select-selection-icons">
            <span *ngIf="model !== null" (click)="onUpdate(null, null)" class="select-close-icon">
              <svg fill="none" height="15" viewBox="0 0 20 20" width="15" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 18L2 2M18 2L2 18" stroke="#1B1C1B" stroke-linecap="round" stroke-width="2"></path>
              </svg>
            </span>
            <span *ngIf="model === null" class="select-arrow" [ngClass]="{'select-arrow-down':!isOptionListOpened}">
              <svg width="17" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.81 12.149l-7.852 7.66A.633.633 0 018.5 20a.647.647 0 01-.458-.192L.19 12.148a.624.624 0 010-.893.656.656 0 01.916 0l6.74 6.564V.64a.63.63 0 01.191-.452.663.663 0 01.926 0 .63.63 0 01.191.451V17.82l6.74-6.564a.656.656 0 01.916 0 .624.624 0 010 .894z" fill="#1B1C1B"/>
              </svg>
            </span>
        </div>
    </div>
    <div class="select-options">
        <div class="select-option-list" *ngIf="isOptionListOpened">
            <div *ngFor="let item of availableValues" class="option"
                 (mouseover)="onFocus(item)"
                 (mouseleave)="mouseLeaveOnlyDesktop()"
                 [ngClass]="{'selected': isSelectedModel(item.key)}">

                <div class="option__item" (click)="onUpdateDesktop(item)" (touchstart)="onTouchDown(item)" (touchend)="onTouchUp()">
                    <div class="option__label" [ngStyle]="{'background-color': setBackgroundColor(item.key)}">
                          <span class="option__model-name">
                              {{item.value}}
                          </span>
                      <span class="option__model">
                              <img class="option__img" [src]="'/assets/img/brands/' + brandSlug + '/models/' + setModelThumbnail(item.key)"/>
                          </span>
                    </div>
                </div>
                <div class="option__select-list pb-3 pb-lg-0"
                     *ngIf="openedSubSelect === item.key"
                     [ngStyle]="{'background-color': setBackgroundColor(item.key)}">
                    <div class="option__select-list__item">
                        Wersje wyposażenia
                    </div>
                    <div *ngFor="let v of item.versions" class="option__select-list__item option" (click)="onUpdateVersion(item.key,v.key)" [ngClass]="{'selected': isSelectedModel(item.key) && isSelectedVersion(v.key)}">
                        <div class="option__item">
                            <input class="option__checkbox" type="checkbox" [checked]="isSelectedModel(item.key) && isSelectedVersion(v.key)">
                            <div class="option__label">
                                <span class="option__check">
                                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM7.6 12.775C7.73333 12.775 7.85833 12.7543 7.975 12.713C8.09167 12.671 8.2 12.6 8.3 12.5L13.975 6.825C14.1583 6.64167 14.25 6.41667 14.25 6.15C14.25 5.88333 14.15 5.65 13.95 5.45C13.7667 5.26667 13.5333 5.175 13.25 5.175C12.9667 5.175 12.7333 5.26667 12.55 5.45L7.6 10.4L5.425 8.225C5.24167 8.04167 5.01667 7.95 4.75 7.95C4.48333 7.95 4.25 8.05 4.05 8.25C3.86667 8.43333 3.775 8.66667 3.775 8.95C3.775 9.23333 3.86667 9.46667 4.05 9.65L6.9 12.5C7 12.6 7.10833 12.671 7.225 12.713C7.34167 12.7543 7.46667 12.775 7.6 12.775Z" fill="#FEFFFF"/>
                                  </svg>
                                </span>
                                {{v.value}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
