import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NewsletterService {

  public showNL: EventEmitter<any> = new EventEmitter();
  constructor() {}

  clickShowNL() {
    this.showNL.emit();
  }
}
