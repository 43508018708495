<div class="stock-select"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <div class="select-selection-label">
      +{{+value}} km
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item)" [ngClass]="{'selected': value === item}">
        <div class="option__item">
          +{{+item}} km
        </div>
      </div>
    </div>
  </div>
</div>
