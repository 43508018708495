<ng-container *ngIf="brand.slug === 'fiat' || brand.slug === 'fiat-professional'">
  <ng-container *ngFor="let label of offer.labels">
    <div class="offer-box__lowest-price-icon" *ngIf="labelClassName.lowestPrice === true && labelClassName.highestDiscount === false"><span>NAJNIŻSZA CENA</span></div>
    <div class="offer-box__highest-discount-icon" *ngIf="labelClassName.lowestPrice === false && labelClassName.highestDiscount === true"><span>NAJWIĘKSZY RABAT</span></div>
    <div class="offer-box__highest-discount-lowest-price-icon" *ngIf="labelClassName.lowestPrice === true && labelClassName.highestDiscount === true"><span>NAJLEPSZY  RABAT<br />I CENA</span></div>
  </ng-container>
</ng-container>
<ng-container *ngIf="brand.slug !== 'jeep' && brand.slug !== 'alfa-romeo' && brand.slug !== 'fiat' && brand.slug !== 'fiat-professional'">
    <ng-container *ngFor="let label of offer.labels">
        <span class="label label--lowest-price" *ngIf="label === 'Najniższa cena'">NAJNIŻSZA CENA</span>
        <span class="label label--highest-discount d-none" *ngIf="label === 'Największy rabat' && brand.slug !== 'alfa-romeo'">% NAJWIĘKSZY RABAT %</span>
    </ng-container>
</ng-container>
