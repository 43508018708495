import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';
import {FormatMoneyPipe} from '../../tools/format-money.pipe';
import {MatSliderChange} from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-price-field',
  templateUrl: './price-field.component.html',
})
export class PriceFieldComponent implements OnInit, OnDestroy, ISearchFormElement {

  @Input() protected name: string;
  @Input() icon: string;
  @Input() valueFrom: number = null;
  @Input() valueTo: number = null;
  @Input() formattedValueFrom: string;
  @Input() formattedValueTo: string;
  @Input() priceFrom: any;
  @Input() priceTo: any;
  @Input() nullValueFrom: string;
  @Input() nullValueTo: string;
  isOptionListOpened = false;
  blurExecFrom: number;
  blurExecTo: number;
  // price: number;
  fromSlider: any;
  toSlider: any;
  fromSliderValue: any;
  toSliderValue: any;
  priceMin: any = null;
  priceMax: any = null;
  showPriceField = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  manualRefresh: EventEmitter<MatSliderChange> = new EventEmitter<MatSliderChange>();

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    this.loadedRangeValues();
  }

  ngOnDestroy() {
  }

  loadedRangeValues() {
    if (this.priceFrom !== undefined && this.priceTo !== undefined) {

      this.priceMin = this.priceFrom;
      this.priceMax = this.priceTo;

      setTimeout(() => {
        this.showPriceField = true;

        if (!this.valueFrom) {
          this.valueFrom = this.priceMin;
        }

        if (!this.valueTo) {
          this.valueTo = this.priceMax;
        }

        this.setValueFormater(this.valueFrom, 'from');
        this.setValueFormater(this.valueTo, 'to');

        this.fromSlider = document.querySelector('#fromSlider');
        this.toSlider = document.querySelector('#toSlider');
        this.fromSlider.value = this.valueFrom;
        this.toSlider.value = this.valueTo;

        this.fillSlider('#CBCBCB', '#A80C42');
        this.setToggleAccessible(this.toSlider);

        this.fromSlider.oninput = () => this.controlFromSlider();
        this.toSlider.oninput = () => this.controlToSlider();

        this.setFromSlider(this.valueFrom);
        this.setToSlider(this.valueTo);

      }, 200);

    } else {
      setTimeout(() => {
        this.loadedRangeValues();
      }, 200);
    }
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public setValueFormater(value: number, rangeName): void {
    if (rangeName === 'from') {
      this.valueFrom = value;
    } else {
      this.valueTo = value;
    }
    this.setFormattedValue(rangeName);
  }

  public setValue(value: number, rangeName): void {
    if (rangeName === 'from') {
      this.valueFrom = value;
    } else {
      this.valueTo = value;
    }
    // this.closeOptionList();
    this.setFormattedValue(rangeName);
    this.emitUpdateEvent(rangeName);
  }

  public getValue(rangeName): any {
    if (rangeName === 'from') {
      return this.valueFrom;
    } else {
      return this.valueTo;
    }
  }

  public setNullValue(nullValue: string, rangeName): void {
    if (rangeName === 'from') {
      this.nullValueFrom = nullValue;
    } else {
      this.nullValueTo = nullValue;
    }
  }

  public setAvailableValues(availableValues: Array<any>, rangeName): void {
    // if (rangeName === 'from') {
    //   this.availableValuesFrom = availableValues;
    // } else {
    //   this.availableValuesTo = availableValues;
    // }
  }

  public onUpdate(value: number, rangeName): void {
    this.setValue(value, rangeName);
    // this.closeOptionList();
    if (rangeName === 'from') {
      clearTimeout(this.blurExecFrom);
    } else {
      clearTimeout(this.blurExecTo);
    }
  }

  private emitUpdateEvent(rangeName) {
    const updateObject = {
      name: rangeName === 'from' ? 'price_from' : 'price_to',
      value: rangeName === 'from' ? this.valueFrom : this.valueTo,
    };
    this.update.emit(updateObject);
  }

  private setFormattedValue(rangeName) {
    if (rangeName === 'from') {
      this.formattedValueFrom = FormatMoneyPipe.format(this.valueFrom);
    } else {
      this.formattedValueTo = FormatMoneyPipe.format(this.valueTo);
    }
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }
  closeOptionList() {
      if (this.isOptionListOpened) {
        this.isOptionListOpened = false;
      }
  }

  onClickInput($event: Event, rangeName) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;

    if (rangeName === 'from') {
      if (this.valueFrom !== null) {
        target.value = this.valueFrom.toString();
      } else {
        target.value = '';
      }
    } else {
      if (this.valueTo !== null) {
        target.value = this.valueTo.toString();
      } else {
        target.value = '';
      }
    }
  }

  setInputValue(value, rangeName) {
    if (rangeName === 'from') {
      this.blurExecFrom = setTimeout(() => {
        if (Number(value) > 0) {
          this.onUpdate(Number(value), rangeName);
          this.setFormattedValue(rangeName);
        } else {
          this.valueFrom = null;
          this.onUpdate(null, rangeName);
        }
      }, 200);
    } else {
      this.blurExecTo = setTimeout(() => {
        if (Number(value) > 0) {
          this.onUpdate(Number(value), rangeName);
          this.setFormattedValue(rangeName);
        } else {
          this.valueTo = null;
          this.onUpdate(null, rangeName);
        }
      }, 200);
    }
  }


  onBlurInput($event: Event, rangeName) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;

    if (rangeName === 'from') {
      this.blurExecFrom = setTimeout(() => {
        const value = target.value.replace(/[^0-9]/g, '');
        if (target.value.length > 0 && Number(value) > 0) {
          this.onUpdate(Number(value), rangeName);
          this.setFormattedValue(rangeName);
          target.value = this.nullValueFrom + ' ' + this.formattedValueFrom + ' zł';
          this.setFromSlider(Number(value));
        } else {
          this.valueFrom = null;
          target.value = this.nullValueFrom + ' 30 000 zł';
          this.onUpdate(null, rangeName);
          this.setFromSlider(30000);
        }
      }, 200);
    } else {
      this.blurExecTo = setTimeout(() => {
        const value = target.value.replace(/[^0-9]/g, '');
        if (target.value.length > 0 && Number(value) > 0) {
          this.onUpdate(Number(value), rangeName);
          this.setFormattedValue(rangeName);
          target.value = this.nullValueTo + ' ' + this.formattedValueTo + ' zł';
          this.setToSlider(Number(value));
        } else {
          this.valueTo = null;
          target.value = this.nullValueTo + ' 200 000 zł';
          this.onUpdate(null, rangeName);
          this.setToSlider(200000);
        }
      }, 200);
    }
  }

  setFromSlider(value) {
    if (this.fromSlider !== null && this.fromSlider !== undefined) {
      if (value === 'min') {
        if (this.priceMin !== null) {
          this.fromSlider.value = this.priceMin;
          this.controlFromSlider(false);
          return value;
        }
      } else {
        if (value > this.priceMax) {
          value = this.priceMax;
        } else if (value < this.priceMin) {
          value = this.priceMin;
        }

        this.fromSlider.value = value;
        this.controlFromSlider(false);
        return value;
      }
    }
  }

  setToSlider(value) {
    if (this.toSlider !== null && this.toSlider !== undefined) {
      if (value === 'max') {
        if (this.priceMax !== null) {
          this.toSlider.value = this.priceMax ;
          this.controlToSlider(false);
          return value;
        }
      } else {
        if (value > this.priceMax) {
          value = this.priceMax;
        } else if (value < this.priceMin) {
          value = this.priceMin;
        }

        this.toSlider.value = value;
        this.controlToSlider(false);
        return value;
      }
    }
  }

  controlFromSlider(setInput = true) {
    const [from, to] = this.getParsed();
    this.fillSlider('#CBCBCB', '#A80C42');

    if (from > to) {
      this.fromSlider.value = to;
      if (setInput) {
        this.setInputValue(to, 'from');
      }
    } else {
      if (setInput) {
        this.setInputValue(from, 'from');
      }
    }
  }

  controlToSlider(setInput = true) {
    const [from, to] = this.getParsed();
    this.fillSlider( '#CBCBCB', '#A80C42');
    this.setToggleAccessible(this.toSlider);
    if (from <= to) {
      this.toSlider.value = to;
      if (setInput) {
        this.setInputValue(to, 'to');
      }
    } else {
      this.toSlider.value = from;
      if (setInput) {
        this.setInputValue(from, 'to');
      }
    }
  }

  getParsed() {
    const from = parseInt(this.fromSlider.value, 10);
    const to = parseInt(this.toSlider.value, 10);
    return [from, to];
  }

  fillSlider(sliderColor, rangeColor) {
    const rangeDistance = this.toSlider.max - this.toSlider.min;
    const fromPosition = this.fromSlider.value - this.toSlider.min;
    const toPosition = this.toSlider.value - this.toSlider.min;
    this.toSlider.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
      ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
      ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%,
      ${sliderColor} 100%)`;
  }

  setToggleAccessible(currentTarget) {
    this.toSlider = document.querySelector('#toSlider');
    if (Number(currentTarget.value) <= 0 ) {
      this.toSlider.style.zIndex = 2;
    } else {
      this.toSlider.style.zIndex = 0;
    }
  }

  setInputMinValue() {
    return FormatMoneyPipe.format(this.priceMin);
  }

  setInputMaxValue() {
    return FormatMoneyPipe.format(this.priceMax);
  }

  getFormSliderValue() {
    if (this.fromSlider !== null && this.fromSlider !== undefined) {
      return this.fromSlider.value;
    }
  }

  getToSliderValue() {
    if (this.toSlider !== null && this.toSlider !== undefined) {
      return this.toSlider.value;
    }
  }
}
