import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

@Component({
  selector: 'app-color-select',
  templateUrl: './color-select.component.html'
})
export class ColorSelectComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;

  @Output() update = new EventEmitter<any>();

  constructor() {
    this.value = null;
  }

  ngOnInit() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
      this.icon = icon;
  }

  public getIcon(): string {
      return this.icon;
  }

  public setValue(value: string): void {
    let newVal: string = null;
    if (value !== null) {
      for (const item of value.split(',')) {
        for (const av of this.availableValues) {
          if (av.key === item) {
            if (newVal !== null) {
              newVal = item;
            } else {
              newVal += ':;' + item;
            }
          }
        }
      }
    }
    this.value = newVal;
  }

  public getValue(): string {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = null;
      this.emitUpdateEvent();
    } else {
      this.addNewValueOrDeleteWhenIsSelected(value);
      this.emitUpdateEvent();
    }
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };
    this.update.emit(updateObject);
  }

  private addNewValueOrDeleteWhenIsSelected(value: string): void {
    if (this.isSelected(value)) {
      const array: Array<string> = this.value.split(':;');
      array.splice(array.indexOf(value, 0), 1);
      if (array.length === 0) {
        this.value = null;
      } else {
        this.value = array.join(':;');
      }
    } else {
      if (this.value === null) {
        this.value = value;
      } else {
        this.value += ':;' + value;
      }
    }
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    for (const val of this.value.split(':;')) {
      if (val === value) {
        return true;
      }
    }

    return false;
  }

  getColorByValue(value: string): string {
    value = value.toLowerCase();

    value = value.replace('ą', 'a');
    value = value.replace('ć', 'c');
    value = value.replace('ę', 'e');
    value = value.replace('ł', 'l');
    value = value.replace('ń', 'n');
    value = value.replace('ó', 'o');
    value = value.replace('ź', 'z');
    value = value.replace('ż', 'z');

    value = value.replace('pomaranczowy', 'orange');
    value = value.replace('amarantowy', 'amaranth');
    value = value.replace('bezowy', 'beige');
    value = value.replace('bialy', 'white');
    value = value.replace('jasnoniebieski', 'light-blue');
    value = value.replace('niebieski', 'blue');
    value = value.replace('zielony', 'green');
    value = value.replace('srebrny', 'silver');
    value = value.replace('purpurowy', 'purple');
    value = value.replace('brazowy', 'brown');
    value = value.replace('zolty', 'yellow');
    value = value.replace('szary', 'gray');
    value = value.replace('czarny', 'black');
    value = value.replace('zloty', 'gold');
    value = value.replace('czerwony', 'red');
    value = value.replace('dwukolorowy', 'bicolour');
    value = value.replace('inny', 'other');

    value = value.replace('bronze', 'brown');


    return value;
  }

  getColorClassValue(key: string): string {
    key = this.getColorByValue(key);

    switch (key) {
      case 'orange': return '#FFA500';
      case 'amaranth': return '#E61C66';
      case 'beige': return '#C2B280';
      case 'white': return '#FFFFFF';
      case 'blue': return '#0000FF';
      case 'light-blue': return '#0066FF';
      case 'green': return '#008000';
      case 'silver': return '#C0C0C0';
      case 'purple': return '#800080';
      case 'brown': return '#964B00';
      case 'yellow': return '#FFFF00';
      case 'gray': return '#808080';
      case 'black': return '#000000';
      case 'gold': return '#FFD700';
      case 'red': return '#FF0000';
      case 'bicolour': return '';
    }
  }
}
