import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ObservedOffersComponent} from './subpages/observed-offers/observed-offers.component';
import {DealerListComponent} from './subpages/dealer-list/dealer-list.component';
import {ComparedOffersComponent} from './subpages/compared-offers/compared-offers.component';
import {CartComponent} from './subpages/cart/cart.component';
import {TermsOfServiceComponent} from './subpages/terms-of-service/terms-of-service.component';
import {TermsOfPlatformComponent} from './subpages/terms-of-platform/terms-of-platform.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: './modules/list/list.module#ListModule',
  },
  {
    path: 'szukaj',
    pathMatch: 'full',
    loadChildren: './modules/list/list.module#ListModule',
  },
  {
    path: 'dealerzy',
    pathMatch: 'full',
    component: DealerListComponent,
  },
  {
    path: 'obserwowane-ogloszenia',
    pathMatch: 'full',
    component: ObservedOffersComponent,
  },
  {
    path: 'porownaj-oferty',
    pathMatch: 'full',
    component: ComparedOffersComponent,
  },
  {
    path: 'koszyk',
    pathMatch: 'full',
    component: CartComponent,
  },
  {
    path: 'regulamin-uslugi',
    pathMatch: 'full',
    component: TermsOfServiceComponent,
  },
  {
    path: 'regulamin-platformy',
    pathMatch: 'full',
    component: TermsOfPlatformComponent,
  },
  {
    path: 'dealer',
    pathMatch: 'prefix',
    loadChildren: './modules/list/list.module#ListModule',
  },
  {
    path: 'dealer-site/:code/obserwowane-ogloszenia',
    pathMatch: 'full',
    component: ObservedOffersComponent,
  },
  {
    path: 'dealer-site/:code/oferta',
    pathMatch: 'prefix',
    loadChildren: './modules/offer/offer.module#OfferModule',
  },
  {
    path: 'dealer-site/:code/koszyk',
    pathMatch: 'full',
    component: CartComponent,
  },
  {
    path: 'dealer-site',
    pathMatch: 'prefix',
    loadChildren: './modules/list/list.module#ListModule',
  },
  {
    path: 'oferta',
    pathMatch: 'prefix',
    loadChildren: './modules/offer/offer.module#OfferModule',
  },
  {path: '**', component: PageNotFoundComponent, data: { routeType: 'error' }}
];


@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
