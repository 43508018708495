import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FinancialProducts} from '../../../../models/offer/financing/financial-products';
import {Brand} from '../../../../models/Brands';

@Component({
  selector: 'app-financial-products',
  templateUrl: './financial-products.component.html'
})
export class FinancialProductsComponent implements OnInit {

  @Input() data: FinancialProducts;
  @Input() brandSlug: string;
  @Input() hiddenForm: boolean;
  creditor: string;
  priceType: string;
  loaded = false;

  @Output() showFormModal = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.loaded = true;
    if (this.data !== null) {
      this.creditor = this.data.id[0] + this.data.id[1] + this.data.id[2];
    }
  }

  getPriceType(): string {
    for (const val in this.data.parameters) {
      if (this.data.parameters[val].netto) {
        this.priceType = 'netto';
      } else {
        this.priceType = 'brutto';
      }
    }
    return this.priceType;
  }

  scrollToForm = () => {
    if (this.hiddenForm) {
      this.showFormModal.emit();
    } else {
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.form--offert').offset().top - 130
      }, 1000);
    }
  }
}
