import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'bootstrap';
import {BrandService} from '../../services/brand.service';
import {Price} from '../../models/offer/price/price';

declare var $: any;
@Component({
  selector: 'app-flex-modal',
  templateUrl: './flex-modal.component.html',
})
export class FlexModalComponent implements OnInit {

  brandSlug: string;
  brandName: string;
  flexPercent = {
    standard: 0.04, // %
    premium: 0.1, // %
  };

  flexInstallment = {
    standard: 0,
    premium: 0,
  };

  @Input() showModal = false;
  @Input() price: Price;
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) {
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  ngOnInit() {
    this.flexInstallment.standard = Math.ceil(this.price.final.brutto * this.flexPercent.standard / 100);
    this.flexInstallment.premium = Math.ceil(this.price.final.brutto * this.flexPercent.premium / 100);
  }

  closeModal(e) {
    const target = $(e.target);
    if (target.hasClass('modal-flex') || target.hasClass('icon-x')) {
      $('body').removeClass('form-modal-open');
      this.showModal = false;
      this.showModalChange.emit(this.showModal);
    }
  }
}
