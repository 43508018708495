import {Component, Input, OnInit} from '@angular/core';
import {BasicOffer} from '../../models/offer/basic-offer';
import {DefaultImagePipe} from '../../tools/default-image.pipe';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html'
})

export class PictureComponent implements OnInit {

  @Input() offer: BasicOffer;
  @Input() toAlt: string;
  @Input() brandSlug: any = '';

  defaultImage = '/assets/img/default_lazy_img_290x158.png';
  offerImage = null;
  imageLg: string;
  imageMd: string;
  imageXs: string;
  imageJpeg: string;
  alt: string;

  constructor() { }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    if (this.offer.image === null) {
      this.offerImage = DefaultImagePipe.getDefaultImage(this.offer.id.toString());
    } else {
      this.offerImage = this.offer.image;
    }

    this.imageLg = this.offerImage + '?fm=webp&w=263&h=143&fit=crop&bg=white';
    this.imageMd = this.offerImage + '?fm=webp&w=290&h=158&fit=crop&bg=white';
    this.imageJpeg = this.offerImage + '?fm=pjpg&w=290&h=158&fit=crop&bg=white';
    this.alt = this.toAlt;

    if (this.brandSlug === 'fiat' || this.brandSlug === 'fiat-professional') {
      this.imageXs = this.offerImage + '?fm=webp&w=290&h=158&fit=crop&bg=white';
    } else {
      this.imageXs = this.offerImage + '?fm=webp&w=233&h=127&fit=crop&bg=white';
    }
  }

}
