import { Component, OnInit } from '@angular/core';
import {ObservedOfferService} from '../../services/observed-offer.service';
import {StockApiService} from '../../services/stock-api.service';
import {BasicOffer} from '../../models/offer/basic-offer';
import {AppComponent} from '../../app.component';
import {AdobeService} from '../../services/adobe.service';
import {ErrorHandler} from '../../tools/error-handler';
import {BlueKaiService} from '../../services/blue-kai.service';
import {TitleService} from '../../services/title.service';
import {LabelProviderService} from '../../services/labelProvider.service';
import {BrandService} from '../../services/brand.service';

@Component({
  selector: 'app-observed-offers',
  templateUrl: './observed-offers.component.html',
})
export class ObservedOffersComponent implements OnInit {
  offers: Array<BasicOffer> = [];
  loading = true;
  AppComponent = AppComponent;
  brandSlug = '';

  constructor(public observedOfferService: ObservedOfferService,
              private stockApiService: StockApiService,
              private adobeService: AdobeService,
              private blueKaiService: BlueKaiService,
              private titleService: TitleService,
              private labelProviderService: LabelProviderService,
              ) {
    this.titleService.setTitleObserved();

    if (observedOfferService.getAllOffers().length > 0) {
      const observedOffers = observedOfferService.getAllOffers(AppComponent.dealerCode);
      this.stockApiService.getSimpleOffersData(observedOffers).then(value => {
        const tmpOffers = [];
        value = this.labelProviderService.fillOfferLabelsByArray(value);
        for (const offer of value) {
          if (observedOffers.find(el => el === offer.uid)) {
            tmpOffers.push(offer);
          }
        }
        this.offers = tmpOffers;
        this.loading = false;
      }).catch(reason => {
        ErrorHandler.log(reason);
      });
    } else {
      this.loading = false;
    }
  }


  isActive(id: any) {
    for (const offer of this.offers) {
      if (offer.uid === id) {
        return true;
      }
    }

    return false;
  }

  removeObservedOffers() {
    for (const id of this.observedOfferService.getAllOffers(AppComponent.dealerCode)) {
      this.observedOfferService.removeOffer(id);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page';
      this.adobeService.pageLoadObservedOffers();
      this.blueKaiService.changeRoute();
    });
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }
}
