import { Injectable } from '@angular/core';
import {StockApiService} from './stock-api.service';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  private model: string = null;
  private modelList: Array<string> = [];
  private isOfferView: boolean;

  private bannersData: Array<any> = null;
  private selectedBanner: Object;

  constructor(
    private stockApiService: StockApiService
  ) {}

  public async selectBanner(model: string = '0', isOfferView: boolean = false) {
    if (!this.bannersData) {
      this.bannersData = await this.stockApiService.getBanners();
    }
    // Jeśli nie ma pobranych banerów, pomijamy ten moduł
    if (!Array.isArray(this.bannersData) || this.bannersData.length === 0) {
      this.selectedBanner = undefined;
      return new Promise((resolve, reject) => {
        resolve(this.selectedBanner);
      });
    }

    if (model !== this.model || isOfferView !== this.isOfferView) {
      this.model = model;
      this.isOfferView = isOfferView;
      this.modelList = [];
      this.selectedBanner = undefined;
    }

    // Jeśli banner został wcześniej wybrany, po prostu jest już częścią obiektu i może zostać użyty w widokach
    if (this.selectedBanner !== undefined) {
      return new Promise((resolve) => {
        resolve(this.selectedBanner);
      });
    }

    // Obsługa wybranych wiece niż jeden model w wyszukiwarce.
    if (typeof model === 'string' && model.length > 0) {
      this.modelList = model.split(':;');
    }

    // Rozpatrujemy dwa scenariusze, widok oferty i listing. Wybór, z którym scenariusz obsługujemy, odbywa się przez zmienną bodyClass.
    // Dla widoku oferty próbujemy wybrać tylko te banery, które mają w ustawieniach is_in_offer_show na true.
    // Banery z API są wstępnie przefiltrowane o markę oraz daty obowiązywania.
    let banners_for_offer;
    let banners_for_listing;
    if (isOfferView) {
      // Filtrowanie bannerów z opcją pokazywania na widoku oferty + filtr na model oferty (info o modelu pod zmienną `currentModel`)
      banners_for_offer = this.bannersData.filter(
        banner => (
          Array.isArray(banner['models']) &&
          Array.from(banner['models']).length > 0 &&
          Array.from(banner['models']).map(function (el) {
            return el['code'];
          }).some(r => this.modelList.indexOf(r) >= 0) &&
          banner['is_in_offer_show'] === true
        )
      );
      // Jeśli coś znajdziemy, losowo pokazujemy któryś z banerów.
      if (banners_for_offer.length > 0) {
        this.selectedBanner = banners_for_offer[this.generateRandomIndex(Object.keys(banners_for_offer).length)];
      } else {
        // Jeśli nic nie znaleźliśmy dla wyświetlanego modelu pokazujemy któryś z banerów z ustawieniem wyświetlania w widoku oferty
        banners_for_offer = this.bannersData.filter(banner => (banner['is_in_offer_show'] === true));

        if (banners_for_offer.length > 0) {
          this.selectedBanner = banners_for_offer[this.generateRandomIndex(Object.keys(banners_for_offer).length)];
        } else {
          this.selectedBanner = undefined;
        }
      }
    } else {
      // Jeśli w wyszukiwarce jest wybrany model, próbujemy wyszukać baner pod ten model.
      if (this.modelList.length > 0) {
        banners_for_listing = this.bannersData.filter(
          banner => (
            Array.isArray(banner['models']) &&
            Array.from(banner['models']).length > 0 &&
            Array.from(banner['models']).map(function (el) {
              return el['code'];
            }).some(r => this.modelList.indexOf(r) >= 0)
          )
        );
      }
      // Znaleźliśmy baner pod model, to go używamy.
      if (Array.isArray(banners_for_listing) && banners_for_listing.length > 0) {
        this.selectedBanner = banners_for_listing[this.generateRandomIndex(Object.keys(banners_for_listing).length)];
      } else {
        // Jednak nie znaleźliśmy baner pod model, wybieramy inny ze wszystkich dostępnych
        this.selectedBanner = this.bannersData[this.generateRandomIndex(Object.keys(this.bannersData).length)];
      }
    }
    return new Promise((resolve) => {
      resolve(this.selectedBanner);
    });
  }

  generateRandomIndex(numberOfBanners) {
    return Math.floor(Math.random() * numberOfBanners);
  }

  getSelectedBanner(): Object {
    return this.selectedBanner;
  }
}
