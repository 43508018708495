import {Component, Input, OnInit} from '@angular/core';
import {EquipmentItem} from '../../../../../models/offer/equipment/equipment';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
})
export class EquipmentListComponent implements OnInit {

  @Input() showPrices = false;
  @Input() data: EquipmentItem[] = [];
  @Input() showDetailsList = false;
  @Input() showGrossPrice = false;
  openedItems = [];

  constructor() { }

  ngOnInit() {
  }

  openItem(item: string) {
    this.openedItems.push(item);
  }

  closeItem(item: string) {
    this.openedItems.splice(this.openedItems.indexOf(item), 1);
  }

  isOpenItem(item: string) {
    return (this.openedItems.indexOf(item) >= 0);
  }

  // use to sum item.price
  getSumNetPrice(priceType: string) {
    let sum = 0;
    for (const item of this.data) {
      sum += item.price;
    }
    if (priceType === 'netto') {
      return Math.round(sum);
    } else {
      return Math.round(sum * 1.23 );
    }
  }

  // use to sum item.gross_price
  getSumGrossPrice(priceType) {
    let sum = 0;
    for (const item of this.data) {
      sum += item.gross_price;
    }
    if (priceType === 'netto') {
      return Math.round(sum / 1.23 );
    } else {
      return Math.round(sum);
    }
  }
}
