export class Price {
  base: PriceDetail;
  final: PriceDetail;
  building: number;
  discount: any;
  leasing: Installment;
  shipping_fee: number; // Holds netto value for LCV and brutto for other brands
  financing_info: any;
}

export class PriceDetail {
  netto: number;
  brutto: number;
}

export class Installment {
  titleDescription: string;
  priceType: string;
  installment: number;
  note: string;
}
