import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StockApiService} from '../../services/stock-api.service';
import {BrandService} from '../../services/brand.service';
import {EmptyModalComponent} from '../../modals/empty-modal/empty-modal.component';
import {AdobeService} from '../../services/adobe.service';
import {ErrorHandler} from '../../tools/error-handler';
import {BlueKaiService} from '../../services/blue-kai.service';
import {environment} from '../../../environments/environment';
import {TrackingService} from '../../services/tracking.service';
import {ConfigModel} from '../../config/config';

declare let window: any;

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {

  @Input() cta: string;
  @Input() ctaRpTd = 'RP';
  @Input() brand_slug: string = null;
  @Input() dealer: string = null;
  @Input() model_id: string = null;
  @Input() vin: string = null;
  @Input() is_km0: boolean = null;
  @Input() offer_id: number = null;
  @Input() adobeThankyouPageData: any = null;
  @Input() showAgreementDescription: boolean;
  @Output() error: EventEmitter<any> = new EventEmitter();

  sendEvent: EventEmitter<any> = new EventEmitter();
  changeZipCode: EventEmitter<any> = new EventEmitter();
  form = null;
  fields = [];
  loading = true;
  status = 0; // -1 when error & 1 when success
  cannotLoadForm = false;
  errorForm = false;
  private fillingOutStarted = false;
  clickonometrics = false;
  leadId = '';

  formValues = {};
  errors = {};
  brandConfig: ConfigModel = null;

  constructor(
      private stockApiService: StockApiService,
      private adobeService: AdobeService,
      private blueKaiService: BlueKaiService,
      private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  isHiddenField(field: any): boolean {
    if (field.type === 'hidden') {
      return true;
    }

    if (field.name === 'dealer_sincom' && this.dealer !== null) {
      return true;
    }

    if (field.name === 'model_id') {
      return true;
    }

    if (field.name === 'brand_id') {
      return true;
    }

    if (field.name === 'g-recaptcha-response') {
      return true;
    }

    if (field.name === 'communication_channel' && ['fiat', 'fiat-professional', 'abarth'].indexOf(this.brand_slug) !== -1) {
      return true;
    }

    return false;
  }

  ngOnInit() {
    this.stockApiService.getLeadForm(this.cta).then(value => {
      this.fields = Object.keys(value.fields);
      for (const key of this.fields) {
        this.errors[key] = [];
        this.formValues[key] = value.fields[key].value;
      }

      for (const key of value.agreements) {
        this.errors[key.inputs[0].name] = [];
        this.formValues[key.inputs[0].name] = null;

      }

      this.form = value;
      this.loading = false;

      if (this.dealer !== null) {
        this.formValues['dealer_sincom'] = this.dealer;
      }

      if (this.model_id !== null) {
        this.formValues['model_id'] = this.model_id;
      }

      if (this.vin !== null && this.vin.length > 0) {
        this.formValues['vin'] = this.vin;
      }

      if (this.is_km0 !== null) {
        this.formValues['is_km0'] = this.is_km0;
      }

      if (this.offer_id !== null) {
        this.formValues['offer_id'] = this.offer_id;
      }

      this.formValues['brand_id'] = BrandService.getCurrentBrand().code;
      this.brandConfig = BrandService.getConfig();
    }).catch(reason => {
      this.cannotLoadForm = true;
      this.error.emit('cannot load form');
      ErrorHandler.handle(reason);
    });
  }

  send() {
    if (this.isFormValid()) {
      this.loading = true;
      this.stockApiService.sendLead(this.cta, this.formValues).then(value => {
        this.loading = false;

        if (value.status === 1) {
          this.status = 1;
          let type = 'not defined';

          if (this.form.cta && this.form.cta[0]) {
            if (this.form.cta[0] === 'RP') {
              type = 'request-form';
            } else if (this.form.cta[0] === 'NL') {
              type = 'newsletter';
            } else {
              type = this.form.cta[0];
            }
          }

          if (value['0'] && value['0'].lead_id) {
            if (value['0'].cta && value['0'].cta === 'TD') {
              type = 'test-drive-form';
              this.adobeService.thankYouPage(this.adobeThankyouPageData, value['0'].lead_id, 'TD');
            } else {
              this.adobeService.thankYouPage(this.adobeThankyouPageData, value['0'].lead_id);
            }
            this.blueKaiService.thankYouPage(type);
            this.leadId = value['0'].lead_id;
          } else {
            this.adobeService.thankYouPage(this.adobeThankyouPageData, '');
            this.blueKaiService.thankYouPage(type);
          }

          if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.CLICKONOMETRICS)) {
            if (BrandService.getCurrentBrand().slug === 'fiat-professional') {
              this.clickonometrics = true;

              const newIframe = document.createElement('iframe');
              newIframe.height = '1';
              newIframe.width = '1';
              newIframe.style.display = 'none';
              newIframe.src = 'https://bedigital.go2cloud.org/aff_l?offer_id=1412&adv_sub=' + this.leadId;
              document.body.appendChild(newIframe);
            } else if (BrandService.getCurrentBrand().slug === 'jeep') {
              const newIframe = document.createElement('iframe');
              newIframe.height = '1';
              newIframe.width = '1';
              newIframe.style.display = 'none';
              newIframe.src = 'https://bedigital.go2cloud.org/aff_l?offer_id=1412&adv_sub=' + this.leadId;
              document.body.appendChild(newIframe);

              const OA_r = Math.floor(Math.random() * 999999);
              window.actionId = this.leadId;
              const newScript = document.createElement('script');
              newScript.type = 'text/javascript';
              newScript.src = 'https://system360.inistrack.net/d/intjsis.php?trackerid=945&model=cpl&append=0&r=' + OA_r;
              document.body.appendChild(newScript);
            }
          }

        } else {
          let validErrors = false;
          if (value.errors && Object.keys(value.errors).length > 0) {
            this.updateCsrfProtectField();
            this.sendEvent.emit((new Date()).getTime());

            for (const key of Object.keys(value.errors)) {
              this.errors[key] = value.errors[key];
            }
            validErrors = true;
          }

          if (value.absent_fields && value.absent_fields.length > 0) {
            for (const field of value.absent_fields) {
              if (typeof this.errors[field] === typeof []) {
                this.errors[field].unshift('Pole wymagane');
              } else {
                this.errors[field] = ['Pole wymagane'];
              }
            }
            validErrors = true;
          }

          if (!validErrors) {
            this.status = -1;
          }

          if (value.message !== undefined) {
            this.errorForm = true;
          }

          this.changeDetectorRef.detectChanges();
        }
      }).catch(reason => {
        this.loading = false;
        ErrorHandler.handle(reason);
      });
    } else {
      this.loading = false;
      this.sendEvent.emit((new Date()).getTime());
    }
  }

  isFormValid(): boolean {
    this.validateFields();
    this.validateAgreements();

    for (const key of Object.keys(this.errors)) {
      if (this.errors[key].length > 0) {
        return false;
      }
    }

    return true;
  }

  validateFields() {

    for (const field of Object.keys(this.form.fields)) {
      this.errors[field] = [];
      if (!this.isHiddenField(this.form.fields[field])) {
        const name = this.form.fields[field].name;

        if (name === 'phone' && this.formValues['communication_channel'] === 'M') {
          return;
        }

        for (const rule of this.form.fields[name].rules) {
          if (rule.regex !== null) {
            const regEx = new RegExp(rule.regex.substr(1, rule.regex.length - 2));

            if (this.formValues[name] === null || !regEx.test(this.formValues[name])) {
              this.errors[name].push(rule.message);
              break;
            }
          }
        }
      }
    }

    if (this.errors['repeat_email'].length === 0 && this.formValues['email'] !== this.formValues['repeat_email']) {
      this.errors['repeat_email'].push('Adresy e-mail nie są takie same');
    }
  }

  validateAgreements() {
    for (const agreement of this.form.agreements) {
      if (agreement.required === true) {
        let isEmpty = true;
        for (const input of agreement.inputs) {
          if (this.formValues[input.name] === input.value) {
            isEmpty = false;
          }
        }

        if (isEmpty) {
           this.errors[agreement.inputs[0].name] = ['Pole wymagane'];
        } else {
          this.errors[agreement.inputs[0].name] = [];
        }
      }
    }
  }

  change($event) {
    if ($event.value === 'undefined' || $event.value === 'null') {
      this.formValues[$event.name] = null;
    } else {
      this.formValues[$event.name] = $event.value;
    }

    if ($event.name === 'zipcode') {
      this.changeZipCode.emit(this.formValues[$event.name]);
    } else if ($event.name === 'communication_channel' && $event.value === 'M') {
      this.errors['phone'] = [];
    }

    this.errors[$event.name] = [];
  }

  openPolicyNotes() {
    EmptyModalComponent.openModal(this.form.disclaimer.information.content['privacy-notes'], true);
  }

  clickFormElement() {
    if (!this.fillingOutStarted) {
      this.fillingOutStarted = true;
      let type = 'not defined';

      if (this.form.cta && this.form.cta[0]) {
        if (this.form.cta[0] === 'RP') {
          type = 'request-form';
        } else if (this.form.cta[0] === 'NL') {
          type = 'newsletter';
        } else {
          type = this.form.cta[0];
        }
      }
      this.blueKaiService.formFillingOutStart(type);
    }
  }

  updateCsrfProtectField () {
    let fieldName = '';
    if (this.form.cta[0] === 'RP') {
      fieldName = 'csrf_protect_field_d3d332d22ds32d2d2d092m_rp';
    } else if (this.form.cta[0] === 'NL') {
      fieldName = 'csrf_protect_field_d3d332d22ds32d2d2d092m_nl';
    }

    this.stockApiService.getLeadForm(this.cta).then(value => {
      if (value.fields[fieldName] !== undefined) {
        this.formValues[fieldName] = value.fields[fieldName].value;
      }
      this.loading = false;
    }).catch(reason => {
      this.cannotLoadForm = true;
      this.error.emit('cannot load form');
      ErrorHandler.handle(reason);
    });
  }
}
