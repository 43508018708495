<section *ngIf="brandSlug !== 'abarth'"
         class="section section--sol-terms section--page-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2>REGULAMIN PLATFORMY INTERNETOWEJ „WIRTUALNY SALON”</h2>
        <p>Niniejszy regulamin określa zasady świadczenia i korzystania z Usług platformy internetowej Wirtualny Salon („<strong>Regulamin Wirtualnego Salonu</strong>”).</p>

        <h3>I. DEFINICJE</h3>
        <p>Użyte w Regulaminie pojęcia oznaczają:</p>
        <table class="sol-terms-table">
          <tr>
            <td>
              Adres pocztyelektronicznej
            </td>
            <td>
              Adres poczty elektronicznej Użytkownika lub Osoby Uprawnionej do Kontaktu wskazany na etapie tworzenia Konta jako Login.
            </td>
          </tr>
          <tr>
            <td>
              Dane osobowe
            </td>
            <td>
              Wszelkie informacje dotyczące zidentyfikowanej lub możliwej do
              <br />
              <br />
              zidentyfikowania osoby fizycznej, przetwarzane w celu prawidłowego świadczenia Usług określonych w niniejszym Regulaminie, a także zawarcia Umowy Rezerwacyjnej oraz złożenia Wniosku o finansowanie i zawarcia Umowy Finansowania, jak również we wszelkich innych celach określonych w niniejszym Regulaminie lub Polityce prywatności
            </td>
          </tr>
          <tr>
            <td>
              Dealer
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              Autoryzowany Dealer Jeep, który, działając na podstawie porozumienia z Usługodawcą prezentuje za pomocą Wirtualnego Salonu posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              Autoryzowany Dealer Alfa Romeo, który, działając na podstawie porozumienia z Usługodawcą prezentuje za pomocą Wirtualnego Salonu posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              Autoryzowany Dealer Fiat, który, działając na podstawie porozumienia z Usługodawcą prezentuje za pomocą Wirtualnego Salonu posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              Autoryzowany Dealer Fiat Professional, który, działając na podstawie porozumienia z Usługodawcą prezentuje za pomocą Wirtualnego Salonu posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
          </tr>
          <tr>
            <td>
              Finansujący
            </td>
            <td>
              <strong>Stellantis Consumer Financial Services Polska sp. z o.o.</strong> z siedzibą w Warszawie ul. Domaniewska 44a, 02-672 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000618642, NIP 5213735679, REGON 364482333, kapitał zakładowy: 5.000.000 zł – w przypadku gdy Umowa Finansowania będzie zawierana w postaci pożyczki konsumenckiej, tj. pożyczki stanowiącej kredyt konsumencki w rozumieniu ustawy z dn. 12.05.2011 r. o kredycie konsumenckim.
              <br />
              <br />
              <strong>Stellantis Financial Services Polska sp. z o.o.</strong> z siedzibą w Warszawie ul. Domaniewska 44a, 02-672 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000021718, NIP 5252211130, REGON 017291458, kapitał zakładowy: 120.050.000 zł – w przypadku gdy Umowa Finansowania będzie zawierana w postaci innej niż pożyczka konsumencka.
            </td>
          </tr>
          <tr>
            <td>
              Osoba Uprawniona Do Kontaktu
            </td>
            <td>
              Osoba fizyczna, która zakłada Konto na polecenie i w imieniu Usługobiorcy będącego osobą prawną lub jednostką organizacyjną, której przepisy prawa przyznają zdolność prawną, oraz jest uprawniona do składania oświadczeń woli w imieniu osoby prawnej lub jednostki organizacyjnej za pośrednictwem Salonu, w tym Zamówień oraz Umów Rezerwacyjnych.
            </td>
          </tr>
          <tr>
            <td>
              Przepisy z zakresu ochrony danych osobowych
            </td>
            <td>
              Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Ogólne Rozporządzenie o Ochronie Danych) (Dz.Urz.UE.L Nr 119) – zwane inaczej „RODO” oraz ustawa z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz.U.2018 poz. 1000).
            </td>
          </tr>
          <tr>
            <td>
              Pojazd
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              Pojazd marki Jeep, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              Pojazd marki Alfa Romeo, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              Pojazd marki Fiat, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              Pojazd marki Fiat Professional, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
          </tr>
          <tr>
            <td>
              Regulamin
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              niniejszy Regulamin platformy internetowej Wirtualny Salon. Regulamin jest dostępny
              pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.jeep.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              niniejszy Regulamin platformy internetowej Wirtualny Salon. Regulamin jest dostępny
              pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              niniejszy Regulamin platformy internetowej Wirtualny Salon. Regulamin jest dostępny
              pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiat.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              niniejszy Regulamin platformy internetowej Wirtualny Salon. Regulamin jest dostępny
              pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-platformy</a>
            </td>
          </tr>
          <tr>
            <td>
              Środki komunikacji elektronicznej
            </td>
            <td>
              techniczne środki porozumiewania się na odległość z wykorzystaniem transmisji danych, w szczególności: poczta elektroniczna lub czat
            </td>
          </tr>
          <tr>
            <td>
              Umowa
            </td>
            <td>
              umowa o świadczenie Usług zawierana pomiędzy Usługodawcą a Usługobiorcą, w przypadku usługi prowadzenia i korzystania z Konta z chwilą potwierdzenia przez Użytkownika procesu rejestracji (Umowa o prowadzenie Konta), bądź w przypadku pozostałych Usług zawierana z chwilą rozpoczęcia korzystania z tych Usług.
            </td>
          </tr>
          <tr>
            <td>
              Umowa Finansowania
            </td>
            <td>
              umowa finansowania zawierana z Finansującym na podstawie Wniosku o finansowanie.
            </td>
          </tr>
          <tr>
            <td>
              Umowa Rezerwacyjna
            </td>
            <td>
              umowa zawierana na odległość pomiędzy Dealerem a Usługobiorcą dotycząca Pojazdu, zawierana z chwilą otrzymania przez Usługobiorcę potwierdzenia Zamówienia. W wyniku zawarcia Umowy Rezerwacyjnej, Dealer zobowiązuje się w stosunku do Klienta, że w razie wykonania przez niego określonych w niniejszym Regulaminie czynności w sposób w nim przewidziany oraz uzyskaniu pozytywnej oceny zdolności kredytowej przez Finansującego sprzeda na rzecz Finansującego lub Klienta (w przypadku umowy pożyczki) wybrany przez Klienta za pośrednictwem Wirtualnego Salonu Pojazd, w celu zawarcia następnie między Finansującym a Klientem Umowy Finansowania dotyczącej tego Pojazdu. Warunkiem zawarcia umowy rezerwacyjnej jest potwierdzenie warunków Zamówienia (w tym m.in. dostępności, ceny i  i wyposażenia pojazdu) przez Dealera. Brak przesłania wiadomości e-mail z potwierdzeniem Zamówienia oznacza, że umowa rezerwacyjna nie została zawarta.
            </td>
          </tr>
          <tr>
            <td>
              Usługi
            </td>
            <td>
              usługi świadczone drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem Platformy Internetowej Wirtualny Salon; w tym: obsługa złożenia i przekazania Zamówienia do realizacji przez Dealera, dostarczanie informacji związanych z Pojazdami lub finansowaniem udzielanym przez Finansującego, w tym kalkulator opłat związanych z finansowaniem i porównywarka modeli Pojazdów). Warunki i zasady świadczenia usługi zawiera Regulamin Platformy Internetowej „Wirtualny Salon”.
            </td>
          </tr>
          <tr>
            <td>
              Usługi Finansującego
            </td>
            <td>
              usługi świadczone drogą elektroniczną przez Finansującego na rzecz Usługobiorcy za pośrednictwem strony wniosek.psafinance.pl w zakresie umożliwienia złożenia Wniosku o finansowanie.
            </td>
          </tr>
          <tr>
            <td>
              Usługobiorca
            </td>
            <td>
              Pełnoletnia osoba fizyczna, osoba prawna lub jednostka organizacyjna niebędąca osobą prawną, której przepisy prawa przyznają zdolność prawną, będąca usługobiorcą w rozumieniu art. 2 pkt 7 UŚUDE, korzystająca z Usług na podstawie Umowy lub Usług Finansującego.
              <br />
              <br />
              Usługobiorcą jest w szczególności osoba fizyczna prowadzącą jednoosobową działalność gospodarczą, której kodeks cywilny przyznaje niektóre prawa konsumenckie.
            </td>
          </tr>
          <tr>
            <td>
              Usługodawca
            </td>
            <td>
              Stellantis Polska Spółką z ograniczoną odpowiedzialnością, z kapitałem zakładowym w wysokości 58 492 000 zł, z siedzibą w Warszawie, przy Al. Krakowskiej 206, (02-219), wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy XIV Wydział Gospodarczy KRS, pod nr KRS 0000019125. Nr NIP 5260151365.
              <br />
              <br />
              W zakresie Usług Finansującego Usługodawcami są Finansujący.
              <br />
              <br />
              ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (t.j. Dz.U. z 2017 r., poz. 1219 ze zm.).
            </td>
          </tr>
          <tr>
            <td>
              Użytkownik
            </td>
            <td>
              Usługobiorca, który korzysta z Platformy internetowej Wirtualny Salon.
            </td>
          </tr>
          <tr>
            <td>
              Wniosek o finansowanie
            </td>
            <td>
              wniosek o zawarcie Umowy Finansowania składany przez Użytkownika za pośrednictwem Wirtualnego Salonu.
            </td>
          </tr>
          <tr>
            <td>
              Wirtualny Salon lub Platforma
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              platforma internetowa dostępna pod adresem <a href="https://salon.jeep.pl">https://salon.jeep.pl</a>, za pomocą którego Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              platforma internetowa dostępna pod adresem <a href="https://salon.alfaromeo.pl">https://salon.alfaromeo.pl</a>, za pomocą którego Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              platforma internetowa dostępna pod adresem <a href="https://salon.fiat.pl">https://salon.fiat.pl</a>, za pomocą którego Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              platforma internetowa dostępna pod adresem <a href="https://salon.fiatprofessional.pl">https://salon.fiatprofessional.pl</a>, za pomocą którego Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
          </tr>
          <tr>
            <td>
              Zamówienie
            </td>
            <td>
              wybór za pośrednictwem Wirtualnego Salonu przez Klienta: Pojazdu, Dodatkowych Benefitów dla Klientów (jeśli są dostępne w Wirtualnym Salonie); warunkiem ważności Zamówienia jest złożenie Wniosku o finansowanie i jego pozytywne rozpatrzenie przez Finansującego.
            </td>
          </tr>
        </table>
        <br />
        <h3>II. WARUNKI OGÓLNE</h3>
        <ul>
          <li>
            Do współpracy z Systemem teleinformatycznym, niezbędne jest spełnienie następujących wymagań technicznych:
            <ul>
              <li>stabilne połączenie z siecią Internet o parametrach min. 0,5 Mbit/s,</li>
              <li>przeglądarka internetowa umożliwiająca wyświetlanie na ekranie komputera dokumentów HTML oraz z włączoną obsługą JavaScript; kompatybilne przeglądarki: IE11+, Edge14+, Firefox40+, Safari 9+, Chrome 32+, Opera 32+,</li>
              <li>przeglądarka akceptująca konto poczty elektronicznej umożliwiająca wyświetlanie na ekranie komputera dokumentów HTML oraz z włączoną obsługą JavaScript; kompatybilne przeglądarki: IE11+, Edge14+, Firefox40+, Safari 9+, Chrome 32+, Opera 32+.</li>
            </ul>
          </li>
          <li>Korzystanie z Usług platformy internetowej Wirtualny Salon może wiązać się ze standardowym ryzykiem związanym z wykorzystywaniem sieci Internet. Zaleca się Usługobiorcy przedsięwzięcie odpowiednich czynności w celu jego zminimalizowania.</li>
          <li>Zakazane jest wykorzystywanie Wirtualnego Salonu przez Usługobiorców w sposób sprzeczny z prawem, dobrymi obyczajami lub naruszający dobra osobiste Usługodawcy, Dealera, Finansującego lub osób trzecich.</li>
          <li>Usługodawca zobowiązuje się względem Usługobiorcy do rozpoczęcia świadczenia Usług niezwłocznie, z tym że w przypadku świadczenia Usług dostępnych dla Użytkownika tj. już po założeniu Konta nie później niż 24 godziny od chwili zakończenia procesu zakładania Konta.</li>
          <li>Korzystanie z Wirtualnego Salonu może odbywać się wyłącznie na zasadach określonych w treści niniejszego Regulaminu. Korzystając z platformy Usługobiorca wyraża zgodę na wszystkie warunki zawarte w Regulaminie i zobowiązuje się do ich przestrzegania.</li>
        </ul>

        <h3>III. REKLAMACJE DOTYCZĄCE USŁUG ŚWIADCZONYCH PRZEZ USŁUGODAWCĘ</h3>
        <ul>
          <li>Użytkownicy są uprawnieni do złożenia reklamacji w przypadku, gdy Usługodawca nie wywiązuje się ze swoich obowiązków opisanych w niniejszym Regulaminie lub wywiązuje się z nich w sposób niezgodny z postanowieniami</li>
          <li>
            Reklamację, o której mowa w pkt. 3.1., można złożyć:
            <ul>
              <li *ngIf="brandSlug === 'jeep'">za pomocą poczty elektronicznej na adres sklep@jeep.pl</li>
              <li *ngIf="brandSlug === 'alfa-romeo'">za pomocą poczty elektronicznej na adres sklep@alfaromeo.pl</li>
              <li *ngIf="brandSlug === 'fiat'">za pomocą poczty elektronicznej na adres sklep@fiat.pl</li>
              <li *ngIf="brandSlug === 'fiat-professional'">za pomocą poczty elektronicznej na adres sklep@fiatprofessional.pl</li>
            </ul>
          </li>
          <li>Reklamacja powinna zawierać w szczególności opis problemu będącego podstawą reklamacji oraz dane kontaktowe Użytkownika zgłaszającego reklamację (reklamującego): imię i nazwisko lub nazwa, adres zamieszkania bądź siedziby podmiotu, Adres poczty elektronicznej, numer telefonu.</li>
          <li>Usługodawca rozpatruje reklamację w terminie 14 dni od daty jej otrzymania. Jeżeli reklamacja nie może zostać rozpatrzona w danym terminie, Usługodawca powiadomi o tym reklamującego na piśmie bądź w formie elektronicznej, wskazując przyczyny opóźnienia i przewidywany termin rozpatrzenia reklamacji.</li>
          <li>Odpowiedź na reklamację oraz informacja o niemożliwości rozpatrzenia reklamacji w terminie, o której mowa w pkt 3.4, wysyłana jest na Adres poczty elektronicznej wskazany przez Użytkownika. W szczególnych okolicznościach odpowiedź może zostać wysłana z wykorzystaniem innych środków komunikacji (np. tradycyjną pocztą).</li>
        </ul>

        <h3>IV. POZASĄDOWE SPOSOBY ROZSTRZYGANIA SPORÓW</h3>
        <ul>
          <li>Użytkownicy będący konsumentami mają prawo skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, w szczególności mogą 1) na wniosek Usługobiorcy skorzystać z pośrednictwa Rzecznika Praw Konsumenta przy Urzędzie Ochrony Konkurencji i Konsumentów lub Wojewódzkich Inspektoratów Inspekcji Handlowych, lub 2) skorzystać z pośrednictwa prywatnych instytucji zajmujących się mediacją z inicjatywy Usługobiorcy na zasadach określonych indywidualnie przez te instytucje. Lista w/w podmiotów dostępna na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów. Usługodawca informuje, że pod adresem https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=PL dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR).</li>
        </ul>

        <h3>V. OCHRONA DANYCH OSOBOWYCH</h3>
        <ul>
          <li>W zakresie świadczenia Usług, administratorem Danych Osobowych Usługobiorców (“Administrator”) jest Usługodawca.</li>
          <li>W przypadku złożenia Zamówienia oraz Wniosku o finansowanie, administratorami danych osobowych Użytkowników będą również wybrany przez Użytkownika Dealer oraz Finansujący.</li>
          <li>Usługodawca, na podstawie odrębnej umowy, powierzył przetwarzanie danych osobowych Idealia Digital Hub Sp. z o.o. z siedzibą w Warszawie oraz UNICRON Szymon Kwiatkowski z siedzibą w Pabianicach w celu zapewnienia obsługi technicznej Wirtualnego Salonu.</li>
          <li>Finansujący, na podstawie odrębnej umowy powierzył przetwarzanie danych osobowych AIQLABS Sp. z o.o. z siedzibą w Warszawie w celu zapewnienia usługi weryfikacji tożsamości Użytkownika w ramach obsługi wniosku o finansowanie.</li>
          <li>Administrator zapewnia bezpieczeństwo Danych osobowych Usługobiorcy lub Użytkownika, stosując odpowiednie środki techniczne i organizacyjne niezbędne do zapewnienia bezpieczeństwa przetwarzanych Danych osobowych w ramach Platformy. Administrator spełnia wszelkie wymogi wynikające z Przepisów z zakresu ochrony danych osobowych. Administrator zapewnia środki zapobiegające przypadkowemu lub niezgodnemu z prawem zniszczeniu, utraceniu, zmodyfikowaniu, nieuprawnionemu ujawnieniu lub uzyskaniu nieuprawnionego dostępu do Danych osobowych przesyłanych drogą elektroniczną przez osoby nieuprawnione. Administrator zastrzega, że w określonych sytuacjach ujawnienie danych może nastąpić z przyczyn wynikających z przepisów prawnych.</li>
          <li>W związku z przetwarzaniem Danych osobowych, Usługodawca wypełnia obowiązki informacyjne wobec Usługobiorcy i Użytkownika dotyczące przetwarzania Danych osobowych poprzez zamieszczone na platformie internetowej Wirtualny Salon stosowne klauzule informacyjne.</li>
          <li>Szczegółowe informacje dotyczące przetwarzania przez Usługodawcę oraz Finansującego Danych osobowych określa  <a class="nav-link" (click)="openPrivacyNotes()">Polityka prywatności</a> oraz <a target="_self" class="nav-link cl-policy-link" href="javascript:getCookieOpenModal()" rel="nofollow" data-adobe="bottom-nav:editorial:cookies">Polityka plików cookie</a> Usługodawcy, a także Zasady przetwarzania danych osobowych oraz <a href="https://www.stellantis-financial-services.pl/polityka-cookies.html" target="_blank">Polityka cookies</a> Finansującego, jak również szczegółowe informacje przedstawione Usługobiorcy w ramach realizacji obowiązków informacyjnych wymaganych Przepisami z zakresu ochrony danych osobowych.</li>
        </ul>
        <h3>VI. INNE POSTANOWIENIA</h3>
        <ul>
          <li>Regulamin wchodzi w życie w dniu 03.11.2023 r.</li>
          <li *ngIf="brandSlug === 'jeep'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.jeep.pl/regulamin-platformy</a></li>
          <li *ngIf="brandSlug === 'alfa-romeo'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-platformy</a></li>
          <li *ngIf="brandSlug === 'fiat'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiat.pl/regulamin-platformy</a></li>
          <li *ngIf="brandSlug === 'fiat-professional'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-platformy</a></li>

          <li>Usługodawca na żądanie Usługobiorcy udostępni treść niniejszego Regulaminu w sposób umożliwiający jego pozyskanie, odtwarzanie i utrwalanie za pomocą Systemu teleinformatycznego, którym posługuje się Usługobiorca.</li>
          <li>Usługodawca zastrzega sobie prawo do jednostronnej zmiany postanowień Regulaminu, bez konieczności uzasadniania przyczyn, z zastrzeżeniem punktów 6.5.-6.8. poniżej.</li>

          <li *ngIf="brandSlug === 'jeep'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Salonu
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.jeep.pl/regulamin-platformy</a> oraz bezpośrednie
            poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'alfa-romeo'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Salonu
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-platformy</a> oraz bezpośrednie
            poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'fiat'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Salonu
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiat.pl/regulamin-platformy</a> oraz bezpośrednie
            poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'fiat-professional'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Salonu
            pod adresem <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-platformy</a> oraz bezpośrednie
            poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>

          <li>Zmiany dokonane w Regulaminie wchodzą w życie po upływie 7 dni od momentu udostępnienia jego treści na stronie internetowej Salonu i bezpośredniego poinformowania zgodnie z pkt. 6.5 powyżej.</li>
          <li>Zamówienia złożone przed wejściem w życie zmian Regulaminu, zgodnie z pkt 6.6. są realizowane na podstawie zapisów treści Regulaminu obowiązującego w dniu złożenia Zamówienia.</li>
          <li>W przypadku określonym w punkcie 6.4. Usługobiorca ma prawo rozwiązać Umowę natychmiastowo lub z zachowaniem 7-dniowego okresu wypowiedzenia według wyboru Usługobiorcy, w terminie 14 dni po uzyskaniu informacji o zmianie postanowień Regulaminu w wyniku bezpośredniego poinformowania Usługobiorcy, zgodnie z pkt 6.5 powyżej, poprzez poinformowanie drogą elektroniczną lub pisemnie Usługodawcy. Brak złożenia oświadczenia o rozwiązaniu umowy wobec Usługodawcy w ww. terminie 14 dni oznacza, że Usługobiorca zaakceptował zmieniony Regulamin.</li>
          <li>W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają przepisy Kodeksu cywilnego, Przepisy z zakresu ochrony danych osobowych, UŚUDE, Ustawy o prawach konsumenta oraz inne przepisy prawa.</li>
        </ul>
      </div>
    </div>
  </div>

  <loader [visible]="loading"></loader>
</section>
