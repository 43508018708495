import {Inject, Injectable} from '@angular/core';
import {SESSION_STORAGE, StorageService} from 'angular-webstorage-service';
import {BrandService} from './brand.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {
  }

  get = (key: string, defaultValue = null) => {
    const k = key + '_' + BrandService.getCurrentBrand().slug;
    return this.storage.get(k) === null ? defaultValue : this.storage.get(k);
  }

  getMultiBrand = (key: string, defaultValue = null) => {
    const k = key + '_multi-brand';
    return this.storage.get(k) === null ? defaultValue : this.storage.get(k);
  }

  setMultiBrand = (key: string, value: any) => {
    const k = key + '_multi-brand';
    this.storage.set(k, value);
  }

  set = (key: string, value: any) => {
    this.storage.set(key + '_' + BrandService.getCurrentBrand().slug, value);
  }

  remove = (key: string) => {
    this.storage.remove(key + '_' + BrandService.getCurrentBrand().slug);
  }

  setGlobal(key: string, value: string) {
    this.storage.set(key, value);
  }
}

