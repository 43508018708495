import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;

@Component({
  selector: 'app-equipment-select',
  templateUrl: './equipment-select.component.html',
})
export class EquipmentSelectComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  searchString: string = null;
  isOptionListOpened = false;
  initialDisplayValue: string;
  inputFocusDefaultValue = 'Wpisz nazwę wyposażenia…';
  isFocusedInput = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
    this.value = null;
  }

  ngOnInit() {
    this.initialDisplayValue = this.getDisplayValue();
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setValue(value: string): void {
    let newVal: string = null;
    if (value !== null) {
      for (const item of value.split(':;')) {
        for (const av of this.availableValues) {
          if (av.key === item) {
            if (newVal !== null) {
              newVal = item;
            } else {
              newVal += ':;' + item;
            }
          }
        }
      }
    }
    this.value = newVal;
  }

  public getValue(): string {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate($event: Event, value: string): void {
    if (value === null) {
      this.value = null;
      this.emitUpdateEvent();
    } else {
      this.addNewValueOrDeleteWhenIsSelected(value);
      this.emitUpdateEvent();
    }

    const target = $event.target as HTMLElement;
    const input = $($(target).closest('.stock-select').find('.select-selection-label-input')[0]);
    if (this.searchString !== null) {
      input.trigger('focus');
    } else {
      input.val(this.getDisplayValue());
      input.trigger('focus');
    }
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };
    this.update.emit(updateObject);
  }

  private addNewValueOrDeleteWhenIsSelected(value: string): void {
    if (this.isSelected(value)) {
      const array: Array<string> = this.value.split(':;');
      array.splice(array.indexOf(value, 0), 1);
      if (array.length === 0) {
        this.value = null;
      } else {
        this.value = array.join(':;');
      }
    } else {
      if (this.value === null) {
        this.value = value;
      } else {
        this.value += ':;' + value;
      }
    }
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      if (this.isOptionListOpened) {
        this.closeOptionList();
      } else {
        this.isOptionListOpened = true;
        const input = $($(this.eRef.nativeElement).find('.select-selection-label-input')[0]);
        input.trigger('focus');
      }
    }
  }

  closeOptionList() {
    this.searchString = null;
    this.isOptionListOpened = false;
    const input = $($(this.eRef.nativeElement).find('.select-selection-label-input')[0]);
    input.val(this.getDisplayValue());
    input.trigger('blur');
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    for (const val of this.value.split(':;')) {
      if (val === value) {
        return true;
      }
    }

    return false;
  }

  public getDisplayValue() {
    if (this.value !== null && this.value.length > 0 && this.availableValues !== undefined) {
      let s = '';
      for (const val of this.availableValues) {
        if (this.isSelected(val.key)) {
          s = s + val.value + ', ';
        }
      }
      return s.substr(0, s.length - 2);
    } else {
      return this.nullValue;
    }
  }

  onBlurInput($event: Event) {
    this.isFocusedInput = false;
  }

  onKeyDownInput ($event: KeyboardEvent) {
    if (!this.isOptionListOpened) {
      this.openOrCloseOptionList();
    }
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (target.value === this.getDisplayValue() || target.value === this.inputFocusDefaultValue) {
      target.value = '';
    }

    if ($event.key === 'Tab' || $event.key === 'Enter') {
        this.closeOptionList();
        $($event.target).trigger('blur');
    }
  }

  onKeyUpInput($event: KeyboardEvent) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    this.searchString = target.value;
    if (target.value.length === 0) {
      this.searchString = null;
      target.value = this.inputFocusDefaultValue;
    }
  }

  onFocusInput() {
    this.isFocusedInput = true;
    const input = $($(this.eRef.nativeElement).find('.select-selection-label-input')[0]);
    if (input.val() === this.getDisplayValue()) {
      input.val(this.inputFocusDefaultValue);
    }
  }

  isHiddenValue(value: string) {
    if (value === 'Klimatyzacja manualna' && this.isSelected('Klimatyzacja automatyczna')) {
      return true;
    }
    if (value === 'Klimatyzacja automatyczna' && this.isSelected('Klimatyzacja manualna')) {
      return true;
    }

    if (this.searchString !== null) {
      const regex = new RegExp(this.searchString, 'gi');
      return !regex.test(value);
    }
    return false;
  }
}
