import {Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit} from '@angular/core';
import 'bootstrap';
import {ComparedOfferService} from '../../services/compared-offer.service';
import {AppComponent} from '../../app.component';
import {LabelProviderService} from '../../services/labelProvider.service';
import {StockApiService} from '../../services/stock-api.service';
import {BrandService} from '../../services/brand.service';
import {Brand} from '../../models/Brands';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../../services/local-storage.service';
import { Subject } from 'rxjs';
import {ErrorHandler} from '../../tools/error-handler';
import {BasicOffer} from '../../models/offer/basic-offer';

declare var $: any;

@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareModalComponent implements OnInit {

  offers: Array<BasicOffer> = [];
  // offers: Array<any> = [];
  AppComponent = AppComponent;
  loading = true;
  brandList: Brand[];
  loading$ = new Subject();
  compareOnlyTwoOffers = false;
  addNewOffer = false;
  brandSlug: string;
  brandName: string;

  constructor(
      public comparedOfferService: ComparedOfferService,
      private LSS: LocalStorageService,
      private stockApiService: StockApiService,
      private labelProviderService: LabelProviderService,
      private changeDetection: ChangeDetectorRef
  ) {
    this.brandList = BrandService.getBrandList();
    this.brandName = BrandService.getCurrentBrand().name;
    this.brandSlug = BrandService.getCurrentBrand().slug;

    const storageOffers = comparedOfferService.getAllOffers();
    if (storageOffers.length > 0) {
      this.stockApiService.getSimpleOffersData(comparedOfferService.getAllOffers()).then(value => {
        value = this.labelProviderService.fillOfferLabelsByArray(value);
        this.offers = this.uniqueArray(value);
        this.loading = false;

        let offerToRemove = '0';
        for (const storageOffer of storageOffers) {
          offerToRemove = storageOffer;
          for (const offer of this.offers) {
            if (offer.id.toString() === offerToRemove) {
              offerToRemove = '0';
            }
          }
          if (offerToRemove !== '0') {
            comparedOfferService.removeOffer(offerToRemove);
            offerToRemove = '0';
          }
        }

        this.changeDetection.detectChanges();
      }).catch(reason => {
        ErrorHandler.log(reason);
      });
    } else {
      this.loading = false;
    }
  }

  uniqueArray(arr) {
    return arr.filter((value, index) => {
      return index === arr.findIndex(obj => {
        return obj.id === value.id;
      });
    });
  }

  ngOnInit() {

    this.comparedOfferService.compareInfo.subscribe((info) => {
      this.compareOnlyTwoOffers = info;
      this.addNewOffer = false;
      this.changeDetection.detectChanges();
      this.hideInfo();
    });

    this.comparedOfferService.changeCompare.subscribe((offers) => {

      if (this.comparedOfferService.getAllOffers().length > 0) {
        this.stockApiService.getSimpleOffersData(this.comparedOfferService.getAllOffers()).then(value => {
          value = this.labelProviderService.fillOfferLabelsByArray(value);

          if (this.offers.length < value.length) {
            this.addNewOffer = true;
            this.hideInfo();
          } else {
            this.addNewOffer = false;
          }
          this.offers = this.uniqueArray(value);
          this.loading = false;
          this.changeDetection.detectChanges();
        }).catch(reason => {
          ErrorHandler.log(reason);
        });
      } else {
        this.offers = [];
        this.loading = false;
        this.changeDetection.detectChanges();
      }
    });

  }

  hideInfo() {
    setTimeout(() => {
      this.compareOnlyTwoOffers = false;
      this.addNewOffer = false;
      this.changeDetection.detectChanges();
    }, 3000);
  }

  changeBrand(slug, $event) {
    if (environment.version === 'dev') {
      $event.preventDefault();
      this.LSS.setGlobal('brand', slug);
      document.location.href = '/';
    }

  }
}
