import {
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-select-dropdown',
  templateUrl: './select-dropdown.component.html',
})
export class SelectDropdownComponent implements OnInit {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  isOptionListOpened = false;

  constructor(private eRef: ElementRef) {
    this.value = null;
  }

  ngOnInit() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(): string {
    return this.icon;
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClass = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClass || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }

  closeOptionList() {
    this.isOptionListOpened = false;
  }
}
