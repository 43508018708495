import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormatMoneyPipe} from './format-money.pipe';
import {SeoPipe} from './seo.pipe';
import {DefaultImagePipe} from './default-image.pipe';
import {UrlPrefixPipe} from './url-prefix.pipe';
import {OfferUrlPipe} from './offer-url.pipe';
import {OfferDescriptionPipePipe} from './offer-description.pipe';
import {DemoOfferUrlPipe} from './demo-offer-url.pipe';
import {OfferWordPipe} from './offer-word.pipe';
import {DealerCodePipe} from './dealer-code.pipe';
import {ShippingFeeGrossPipe} from './shipping-fee-gross.pipe';
import {ShippingFeeNetPipe} from './shipping-fee-net.pipe';
import {KeysPipe} from './keys.pipe';

@NgModule({
  declarations: [
    FormatMoneyPipe,
    SeoPipe,
    DefaultImagePipe,
    UrlPrefixPipe,
    OfferUrlPipe,
    OfferDescriptionPipePipe,
    DemoOfferUrlPipe,
    OfferWordPipe,
    DealerCodePipe,
    ShippingFeeGrossPipe,
    ShippingFeeNetPipe,
    KeysPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FormatMoneyPipe,
    SeoPipe,
    DefaultImagePipe,
    UrlPrefixPipe,
    OfferWordPipe,
    OfferUrlPipe,
    OfferDescriptionPipePipe,
    DemoOfferUrlPipe,
    DealerCodePipe,
    ShippingFeeGrossPipe,
    ShippingFeeNetPipe,
    KeysPipe
  ]
})
export class ToolsModule { }
