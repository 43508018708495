import {Component, Input, OnInit} from '@angular/core';
import {DefaultImagePipe} from '../../tools/default-image.pipe';
import {FullOffer} from '../../models/offer/full-offer';

@Component({
  selector: 'app-box-compare-picture',
  templateUrl: './box-compare-picture.component.html'
})

export class BoxComparePictureComponent implements OnInit {

  @Input() offer: FullOffer;
  @Input() toAlt: string;
  @Input() brandSlug: any = '';

  defaultImage = '/assets/img/default_lazy_img_290x158.png';
  offerImage = null;
  imageLg: string;
  imageMd: string;
  imageXs: string;
  imageJpeg: string;
  alt: string;

  constructor() { }

  setDefaultImg(event) {
    if (event.currentTarget && event.currentTarget.parentElement) {
      event.currentTarget.parentElement.innerHTML = '<img src="/assets/img/default_img_290x158.png" class="box-model__photo-img" />';
    }
  }

  ngOnInit() {
    if (this.offer.images.length === 0) {
      this.offerImage = DefaultImagePipe.getDefaultImage(this.offer.id.toString());
    } else {
      this.offerImage = this.offer.images[0];
    }

    this.imageXs = this.offerImage + '?fm=webp&w=290&h=158&fit=crop&bg=white';
    this.imageLg = this.offerImage + '?fm=webp&w=263&h=143&fit=crop&bg=white';
    this.imageMd = this.offerImage + '?fm=webp&w=290&h=158&fit=crop&bg=white';
    this.imageJpeg = this.offerImage + '?fm=pjpg&w=290&h=158&fit=crop&bg=white';
    this.alt = this.toAlt;

  }

}
