import { Component, OnInit } from '@angular/core';
import {BlueKaiService} from '../services/blue-kai.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {

  constructor(
              private blueKaiService: BlueKaiService,
  ) {
  }

  ngOnInit(): void {
    this.blueKaiService.changeRoute();
  }

}
