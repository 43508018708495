import { Pipe, PipeTransform } from '@angular/core';
import {SeoPipe} from './seo.pipe';
import {UrlPrefixPipe} from './url-prefix.pipe';

@Pipe({
  name: 'offerUrl'
})
export class OfferUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return UrlPrefixPipe.getUrlPrefix() + '/oferta/' + value.uid + '/' + SeoPipe.slugify(value.model + ' ' + value.engineType);
  }
}
