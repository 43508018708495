import {EventEmitter, Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {BrandService} from './brand.service';
import {StockApiService} from './stock-api.service';
import {BasicOffer} from '../models/offer/basic-offer';
import {AppComponent} from '../app.component';
import {ErrorHandler} from '../tools/error-handler';

@Injectable({
  providedIn: 'root'
})
export class ComparedOfferService {

  private static STORAGE_KEY = 'compared-offers';
  private static STORAGE_DATA_KEY = 'compared-offers-data';
  public changeCompare: EventEmitter<any> = new EventEmitter();
  public compareInfo: EventEmitter<any> = new EventEmitter();

  private offers: Array<string> = [];
  update: EventEmitter<Array<string>> = new EventEmitter();

  constructor(private storage: LocalStorageService,
              private stockApiService: StockApiService) {
    if (BrandService.getCurrentBrand().slug) {
      this.getOffers();
    } else {
      const subscription = BrandService.changeBrand.subscribe(value => {
        this.getOffers();
      });
    }
  }

  public getOffers() {
    this.offers = this.storage.get(ComparedOfferService.STORAGE_KEY, []);
  }

  addOffer(id: any): void {
    this.getOffers();

    id = id.toString();
    if (this.offers.length < 2) {
      if (!this.isCompared(id)) {
        this.stockApiService.getSimpleOffersData(id).then(value => {
          if (value && value.length > 0) {
            this.offers.push(id);
            const data = this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
            data['offer-' + id] = value[0];
            this.storage.set(ComparedOfferService.STORAGE_DATA_KEY, data);
            this.updateStorage();
          }
        }).catch(reason => {
          ErrorHandler.log(reason);
        });
      }
    } else {
      this.compareInfo.emit(true);
    }

  }

  removeOffer(id: any): void {
    id = id.toString();
    if (this.isCompared(id)) {
      const data = this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
      delete data['offer-' + id];
      this.storage.set(ComparedOfferService.STORAGE_DATA_KEY, data);
      this.offers = this.offers.filter(el => el !== id);
      this.updateStorage();
    }
  }

  changeComparedStatus(id: any) {
    this.compareInfo.emit(false);
    id = id.toString();
    if (this.isCompared(id)) {
      this.removeOffer(id);
    } else {
      this.addOffer(id);
    }
  }

  getAllOffers(): Array<string> {
    return this.offers;
  }

  isCompared(id: any): boolean {
    return this.offers.indexOf(id.toString()) > -1;
  }

  private updateStorage() {
    if (this.offers.length > 0) {
      this.storage.set(ComparedOfferService.STORAGE_KEY, this.offers);
    } else {
      this.storage.remove(ComparedOfferService.STORAGE_KEY);
    }
    this.update.emit(this.offers);
    this.changeCompare.emit(this.offers);
  }

  getOfferData(id: any): BasicOffer {
    const data = this.storage.get(ComparedOfferService.STORAGE_DATA_KEY, {});
    if (data['offer-' + id]) {
      return data['offer-' + id];
    }

    return null;
  }
}
