<div class="stock-select" [attr.data-open]="isOptionListOpened" [ngClass]="{'selected': value !== null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <i class="icon {{icon}}" [ngClass]="{icon: icon !== null}" *ngIf="icon !== null"></i>
    <div class="select-selection-label">
      <input type="text" (focus)="onClickInput($event)" (blur)="onBlurInput($event)"
             class="select-selection-label-input" value="{{nullValue}} {{ (value !== null ? formattedValue + ' zł': '')}}">
    </div>
    <div class="select-selection-icons">
      <b *ngIf="value !== null" (click)="onUpdate(null)" class="remove"><i class="icon icon-x"></i></b>
      <b *ngIf="value === null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="value === null && !isOptionListOpened" class="arrow arrow--up"></b>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.key)" [ngClass]="{'selected': value !== null && value.toString() === item.key.toString()}">
        <div class="option__item">
          {{item.value}}
        </div>
      </div>
    </div>
  </div>
</div>