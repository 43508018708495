import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'offerWord'
})
export class OfferWordPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    if (value === 1) {
      return  'OFERTA';
    } else if (value % 10 > 1 && value % 10 < 5 && (value % 100 < 10 || value % 100 > 20)) {
      return 'OFERTY';
    } else {
      return 'OFERT';
    }
  }

}
