<ng-container *ngIf="bannerPosition === 'header' && isBannerSelected() === true">
    <a [href]="bannerLink"
       [target]="bannerTarget"
       *ngIf="bannerLink !== null"
        class="header__banner d-none d-lg-block">
      <img class="d-none d-xl-block"
           [lazyLoad]="getCdnBannerUrlForSize('620x100')"/>
      <img class="d-none d-lg-block d-xl-none"
           [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
    </a>
    <span *ngIf="bannerLink === null" class="header__banner d-none d-lg-block">
      <img class="d-none d-xl-block"
           [lazyLoad]="getCdnBannerUrlForSize('620x100')"/>
      <img class="d-none d-lg-block d-xl-none"
           [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
    </span>
</ng-container>
<ng-container *ngIf="brand && bannerPosition === 'section' && isBannerSelected() === true">
  <section class="section section-banner mt-5 mt-sm-3 mb-3 d-lg-none" [ngClass]="{'mt-md-5': brand.slug === 'jeep' || brand.slug === 'alfa-romeo' }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <a [href]="bannerLink"
             [target]="bannerTarget"
             *ngIf="bannerLink !== null"
             class="header__banner d-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="getCdnBannerUrlForSize('320x50')"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="getCdnBannerUrlForSize('690x100')"/>
          </a>
          <span *ngIf="bannerLink === null" class="header__banner d-lg-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="getCdnBannerUrlForSize('320x50')"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="getCdnBannerUrlForSize('690x100')"/>
          </span>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf="brand && bannerPosition === 'underHeader' && isBannerSelected() === true">
  <section class="section section-banner my-0">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8 offset-lg-3 mt-5 mt-md-3 mt-lg-0">
          <a [href]="bannerLink"
             [target]="bannerTarget"
             *ngIf="bannerLink !== null"
             class="header__banner d-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="getCdnBannerUrlForSize('320x50')"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="getCdnBannerUrlForSize('690x100')"/>
          </a>
          <span *ngIf="bannerLink === null" class="header__banner d-lg-block">
            <img class="mx-auto mw-100" data-version="mobile-320"
                 [lazyLoad]="getCdnBannerUrlForSize('320x50')"/>
            <img class="mx-auto mw-100" data-version="mobile"
                 [lazyLoad]="getCdnBannerUrlForSize('470x85')"/>
            <img class="mx-auto mw-100" data-version="tablet"
                 [lazyLoad]="getCdnBannerUrlForSize('690x100')"/>
          </span>
        </div>
      </div>
    </div>
  </section>
</ng-container>
