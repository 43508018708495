<div class="form-accordion-agreement fieldset-{{data.inputs[0].name}}"
     [ngClass]="{'accordion-agreement--is-open': showAgreementDescription}" *ngIf="data.type === 'radio'">
  <div class="accordion-agreement--title" (click)="openAgreementDescription('fieldset-' + data.inputs[0].name)">
    {{data.label}}
    <div class="accordion-agreement-arrow">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
      </svg>
    </div>
  </div>
  <div class="accordion-agreement--description" (click)="agreementPrivacyModal($event)" [innerHTML]="data.content_after"></div>
  <div class="agreements__box">
    <label class="agreements__radio agreements__radio--{{ input.value }} stock-radio" [ngClass]="{'is-error': errors[0]}" *ngFor="let input of data.inputs">
      <div class="stock-radio__option" [ngClass]="{selected: value === input.value}" (click)="selectValue(input.name, input.value)">
        <span class="stock-radio__check"></span>
        <span class="stock-radio__label">{{input.label}}</span>
      </div>
    </label>
  </div>
</div>
