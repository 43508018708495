import {Component, OnInit, Input, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-banner-note',
  templateUrl: './banner-note.component.html'
})

export class BannerNoteComponent implements OnInit {

  static onSelectBanner: EventEmitter<any> = new EventEmitter();

  selectedBanner: Object;
  bannerNote: string = null;

  ngOnInit(): void {
    BannerNoteComponent.onSelectBanner.subscribe((banner) => {
      this.selectedBanner = banner;
      if (typeof this.selectedBanner === 'object') {
        this.bannerNote = this.selectedBanner['note'];
      } else {
        this.bannerNote = null;
      }
    });
  }

  isBannerSelected() {
    return this.selectedBanner !== undefined;
  }
}
