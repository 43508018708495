import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {LocalStorageService} from '../../../../services/local-storage.service';

declare var $: any;

@Component({
  selector: 'app-group-select',
  templateUrl: './group-select.component.html',
})
export class GroupSelectComponent implements OnInit {
  @Input() group: any = null;
  @Input() errors: any = [];
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  @Input() brandSlug: string = null;

  isValid = false;
  loadingGroupList = true;
  value = null;
  isOptionListOpened = false;
  currentGroupValue = null;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !$(event.target).hasClass('show_all')) {
      this.closeOptionList();
    }
  }

  constructor(
    private eRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private LSS: LocalStorageService
  ) {
  }

  ngOnInit() {
    this.isValid = true;
    this.loadingGroupList = false;
    this.currentGroupValue = this.LSS.get('current-group-value');

    for (const item of this.availableValues) {
      if (this.currentGroupValue && item.value.toString().toLowerCase() === this.currentGroupValue.toLowerCase()) {
        this.value = item.value;
        this.update.emit(this.value);
      }
    }
  }

  getLabelByValue(value) {
    for (const item of this.availableValues) {
      if (item.value === value) {
        return item.label;
      }
    }
    return 'Wybierz model*';
  }

  setGroupList(group) {
    this.changeDetectorRef.detectChanges();
  }

  public onUpdate(value: any): void {
    this.value = value;
    this.update.emit(value);
    this.closeOptionList();
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }

  closeOptionList() {
    this.isOptionListOpened = false;
  }
}
