import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferRoutingModule } from './offer-routing.module';
import { OfferComponent } from './components/offer/offer.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FinancialApiCalculatorComponent } from './components/financial-api-calculator/financial-api-calculator.component';
import {MatSliderModule} from '@angular/material';
import {ToolsModule} from '../../tools/tools.module';
import {AppComponentsModule} from '../../app-components/app-components.module';
import {CrystalGalleryModule} from "ngx-crystal-gallery";
import {AngularStickyThingsModule} from '@w11k/angular-sticky-things';
import { EquipmentListComponent } from './components/offer/equipment-list/equipment-list.component';
import { FinancialProductsComponent } from './components/financial-products/financial-products.component';

@NgModule({
    declarations: [OfferComponent, FinancialApiCalculatorComponent, EquipmentListComponent, FinancialProductsComponent],
    exports: [
        FinancialApiCalculatorComponent,
        EquipmentListComponent
    ],
    imports: [
        CommonModule,
        OfferRoutingModule,
        SwiperModule,
        MatSliderModule,
        ToolsModule,
        AppComponentsModule,
        CrystalGalleryModule,
        AngularStickyThingsModule,
    ]
})
export class OfferModule { }
