<div class="field-location" [ngClass]="brandSlug === 'fiat' || brandSlug === 'fiat-professional' ? 'field-without-icon': 'field--icon'">
  <i class="icon icon-address"></i>
  <input class="form-control" type="text" name="location"/>
  <span class="field__btn" (click)="findMe($event)" *ngIf="value === null">
    <i *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'" class="icon-marker"></i>
    <svg *ngIf="brandSlug === 'fiat'|| brandSlug === 'fiat-professional'" width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.66667 7.08333C6.05625 7.08333 6.38987 6.9445 6.66754 6.66683C6.94474 6.38964 7.08333 6.05625 7.08333 5.66667C7.08333 5.27708 6.94474 4.94346 6.66754 4.66579C6.38987 4.3886 6.05625 4.25 5.66667 4.25C5.27708 4.25 4.94369 4.3886 4.6665 4.66579C4.38883 4.94346 4.25 5.27708 4.25 5.66667C4.25 6.05625 4.38883 6.38964 4.6665 6.66683C4.94369 6.9445 5.27708 7.08333 5.66667 7.08333ZM5.66667 14.1667C3.76597 12.5493 2.34647 11.0469 1.40817 9.65954C0.469389 8.27262 0 6.98889 0 5.80833C0 4.0375 0.569736 2.62674 1.70921 1.57604C2.84821 0.525347 4.16736 0 5.66667 0C7.16597 0 8.48512 0.525347 9.62412 1.57604C10.7636 2.62674 11.3333 4.0375 11.3333 5.80833C11.3333 6.98889 10.8642 8.27262 9.92587 9.65954C8.9871 11.0469 7.56736 12.5493 5.66667 14.1667Z" fill="#1B1C1B"/>
    </svg>
    <span class="find-me"> Znajdź mnie</span>
  </span>
  <app-distance-select *ngIf="value !== null" [value]="maxDistance" (update)="setMaxDistance($event)"></app-distance-select>
  <span class="field__btn" (click)="clear()" *ngIf="value !== null"><i class="icon-x"></i></span>
</div>

