<div class="stock-select"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
      <div class="select-selection-label" *ngIf="value !== null">
        {{getValueToDisplay(value)}}
      </div>
      <div class="select-selection-label" *ngIf="value === null">
        {{nullValue}}
      </div>
    <div class="select-selection-icons">
      <b *ngIf="value !== null" (click)="onUpdate(null)" class="remove"><i class="icon icon-x"></i></b>
      <b *ngIf="value === null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="value === null && !isOptionListOpened" class="arrow arrow--up"></b>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.key)" [ngClass]="{'selected': value !== null && value.toString() === item.key.toString()}">
        <div class="option__item">
          {{item.value}}
        </div>
      </div>
    </div>
  </div>
</div>
