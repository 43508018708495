import {EventEmitter, Injectable} from '@angular/core';
import {DigitalDataAdobeTM} from '../models/digitalDataAdobeTM';
import {BrandService} from './brand.service';
import {environment} from '../../environments/environment';
import {TrackingService} from './tracking.service';
import {TrackingDebuggerComponent} from '../app-components/tracking-debugger/tracking-debugger.component';

declare var window: any;
declare var _satellite: any;

@Injectable({
  providedIn: 'root'
})
export class AdobeService {

  sateliteAttempsNumber = 0;
  lastPageName = '';

  constructor() { }

  pageLoadHomepage(models = null) {
    const data = new DigitalDataAdobeTM(true, models);

    TrackingService.debug({type: TrackingDebuggerComponent.TYPE_FIRED, action: 'pageLoadHomepage', service: 'AdobeService', data});
    this.executeAction(data, 'pageload', 'pageLoadHomepage');
  }

  pageLoadDealerList() {
    const data = new DigitalDataAdobeTM(true);
    data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:dealer-list';
    data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:dealer-list';

    TrackingService.debug({
      type: TrackingDebuggerComponent.TYPE_FIRED,
      action: 'pageLoadDealerList',
      service: 'AdobeService',
      data
    });
    this.executeAction(data, 'pageload', 'pageLoadDealerList');
  }

  pageLoadObservedOffers() {
    const data = new DigitalDataAdobeTM(true);
    data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:favourites';
    data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:favourites';

    TrackingService.debug({
      type: TrackingDebuggerComponent.TYPE_FIRED,
      action: 'pageLoadObservedOffers',
      service: 'AdobeService',
      data
    });
    this.executeAction(data, 'pageload', 'pageLoadObservedOffers');
  }

  pageLoadOffer(vehicleTrim: string, vehicleModel: string, vehicleID: string) {
    const data = new DigitalDataAdobeTM(true);
    data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:offer';
    data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:offer';

    data.page.pageInfo.vehicleTrim = vehicleTrim;
    data.page.pageInfo.vehicleModel = vehicleModel;
    data.page.pageInfo.vehicleModelName = vehicleModel;
    data.page.pageInfo.vehicleID = vehicleID;

    data.page.component.form.stepName = 'form';
    data.page.component.form.nameType = 'lead:quote-request';
    data.page.component.form.leadType = 'quote-request';

    TrackingService.debug({type: TrackingDebuggerComponent.TYPE_FIRED, action: 'pageLoadOffer', service: 'AdobeService', data});
    this.executeAction(data, 'pageload', 'pageLoadOffer');
  }

  getThankyouPageOfferData(vehicleTrim: string, vehicleModel: string, vehicleID: string) {
    const data = new DigitalDataAdobeTM(true);
    data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:offer';
    data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:offer';

    data.page.pageInfo.vehicleTrim = vehicleTrim;
    data.page.pageInfo.vehicleModel = vehicleModel;
    data.page.pageInfo.vehicleModelName = vehicleModel;
    data.page.pageInfo.vehicleID = vehicleID;

    data.page.component.form.stepName = 'thankyou';
    data.page.component.form.nameType = 'lead:quote-request';
    data.page.component.form.leadType = 'quote-request';

    return data;
  }

  newsletterLoaded() {
    const data = new DigitalDataAdobeTM();

    data.page.pageInfo.pageName = this.lastPageName;
    data.page.component.form.stepName = 'form';
    data.page.component.form.nameType = 'permission:sign-up-for-updates';

    TrackingService.debug({type: TrackingDebuggerComponent.TYPE_FIRED, action: 'newsletterLoaded', service: 'AdobeService', data});
    this.executeAction(data, 'pageload', 'newsletterLoaded');
  }

  thankYouPage(data: DigitalDataAdobeTM, leadId, leadType = null) {
    data.page.component.form.transactionID = 'CCPOL' + leadId;

    if (leadType === 'TD') {
      data.page.component.form.nameType = 'lead:test-drive-request';
      data.page.component.form.leadType = 'test-drive-request';
    }

    data.page.pageInfo.pageName = this.lastPageName;
    TrackingService.debug({type: TrackingDebuggerComponent.TYPE_FIRED, action: 'thankYouPage', service: 'AdobeService', data});
    this.executeAction(data, 'thankyou', 'thankYouPage');
  }

  getNewsletterThankYouData() {
    const data = new DigitalDataAdobeTM();

    data.page.pageInfo.pageName = this.lastPageName;
    data.page.component.form.stepName = 'thankyou';
    data.page.component.form.nameType = 'permission:sign-up-for-updates';

    return data;
  }


  offerFinancingRecalculation(term: number, downPayment: number, purchaseType: string, tradeIn: number) {
    const data = new DigitalDataAdobeTM(true);
    data.page.component.paymentCalc.term = term;
    data.page.component.paymentCalc.downPayment = downPayment;
    data.page.component.paymentCalc.purchaseType = purchaseType;
    data.page.component.paymentCalc.tradeIn = tradeIn;
    data.page.pageInfo.pageName = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:results';
    data.page.category.primaryCategory = BrandService.getCurrentBrand().slug + ':pl:shopping-tools:stocklocator:results';

    TrackingService.debug({
      type: TrackingDebuggerComponent.TYPE_FIRED,
      action: 'offerFinancingRecalculation',
      service: 'AdobeService',
      data
    });
    this.executeAction(data, 'recalculation', 'offerFinancingRecalculation');
  }

  loadedSearchResults(filters, resultCount: number) {
    const data = new DigitalDataAdobeTM(true);
    data.page.component.inventorySearch.filterList = filters;
    data.page.component.inventorySearch.numberOfVehicles = resultCount;

    TrackingService.debug({
      type: TrackingDebuggerComponent.TYPE_FIRED,
      action: 'loadedSearchResults',
      service: 'AdobeService',
      data
    });
    this.executeAction(data, 'stocklocator', 'loadedSearchResults');
  }

  private executeAction(data: DigitalDataAdobeTM, action: string, methodName: string) {
    this.lastPageName = data.page.pageInfo.pageName;
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.ADOBE_DTM)) {
      // wait with first track until _satellite is loaded
      window.digitalData = data;
      if (typeof _satellite !== 'undefined' && _satellite.track) {
        _satellite.track(action);
        TrackingService.debug({
          type: TrackingDebuggerComponent.TYPE_SUCCESS,
          action: methodName,
          service: 'AdobeService',
          data
        });
      } else {
        // when _satelite is not loaded, try after 100ms
        this.sateliteAttempsNumber++;
        if (this.sateliteAttempsNumber < 10) {
          setTimeout(() => {
            TrackingService.debug(
              {
                type: TrackingDebuggerComponent.TYPE_WARNING,
                action: methodName,
                service: 'AdobeService',
                data: {message: 'Tracker not loaded, next attempt after 500ms'}
              });
            this.executeAction(data, action, methodName);
          }, 500);
        } else {
          TrackingService.debug(
            {
              type: TrackingDebuggerComponent.TYPE_ERROR,
              action: methodName,
              service: 'AdobeService',
              data: {message: 'Tracker not loaded, number of attempts exceeded'}
            });
        }
      }
    }
  }
}
