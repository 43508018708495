<div class="modal--offers-compare" *ngIf="(brandSlug === 'fiat' || brandSlug === 'fiat-professional') && offers.length > 0">

  <div class="modal--offers-compare-info" *ngIf="addNewOffer">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 18C1.45 18 0.979 17.8043 0.587 17.413C0.195667 17.021 0 16.55 0 16V2C0 1.45 0.195667 0.979 0.587 0.587C0.979 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V16C18 16.55 17.8043 17.021 17.413 17.413C17.021 17.8043 16.55 18 16 18H2ZM7.6 12.775C7.73333 12.775 7.85833 12.7543 7.975 12.713C8.09167 12.671 8.2 12.6 8.3 12.5L13.975 6.825C14.1583 6.64167 14.25 6.41667 14.25 6.15C14.25 5.88333 14.15 5.65 13.95 5.45C13.7667 5.26667 13.5333 5.175 13.25 5.175C12.9667 5.175 12.7333 5.26667 12.55 5.45L7.6 10.4L5.425 8.225C5.24167 8.04167 5.01667 7.95 4.75 7.95C4.48333 7.95 4.25 8.05 4.05 8.25C3.86667 8.43333 3.775 8.66667 3.775 8.95C3.775 9.23333 3.86667 9.46667 4.05 9.65L6.9 12.5C7 12.6 7.10833 12.671 7.225 12.713C7.34167 12.7543 7.46667 12.775 7.6 12.775Z" fill="#01BDAE"/>
    </svg>
    <span>Oferta została dodana do porównywarki.</span>
  </div>

  <div class="modal--offers-compare-info info-warning" *ngIf="compareOnlyTwoOffers">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5Z" fill="#A80C42"/>
      <path d="M15.4238 7.27043C15.597 7.09728 15.8318 7 16.0767 7C16.3216 7 16.5564 7.09728 16.7296 7.27043C16.9027 7.44359 17 7.67844 17 7.92332C17 8.1682 16.9027 8.40305 16.7296 8.5762L13.3045 12L16.729 15.4238C16.9021 15.597 16.9994 15.8318 16.9994 16.0767C16.9994 16.3216 16.9021 16.5564 16.729 16.7296C16.5558 16.9027 16.3209 17 16.0761 17C15.8312 17 15.5963 16.9027 15.4232 16.7296L12 13.3045L8.5762 16.729C8.40305 16.9021 8.1682 16.9994 7.92332 16.9994C7.67844 16.9994 7.44359 16.9021 7.27043 16.729C7.09728 16.5558 7 16.3209 7 16.0761C7 15.8312 7.09728 15.5963 7.27043 15.4232L10.6955 12L7.27105 8.5762C7.09789 8.40305 7.00062 8.1682 7.00062 7.92332C7.00062 7.67844 7.09789 7.44359 7.27105 7.27043C7.4442 7.09728 7.67905 7 7.92393 7C8.16881 7 8.40366 7.09728 8.57682 7.27043L12 10.6955L15.4238 7.27105V7.27043Z" fill="#F4F4F4"/>
    </svg>
    <span>Możesz porównać tylko dwie oferty.</span>
  </div>

<!--  <h1 *ngIf="compareOnlyTwoOffers">Możesz dodać tylko 2 oferty</h1>-->
<!--  <h1 *ngIf="addNewOffer">Oferta została dodana do porównywarki</h1>-->

<!--  <h4 *ngFor="let item of offers">- {{item.id}} - {{item.image}}?fm=webp&w=263&h=143&fit=crop&bg=white - {{item.model}} - {{item.price.final.brutto}} - {{item.version}} - {{item.engineType}}</h4>-->

  <div class="container d-md-block" [ngClass]="{'d-none': offers.length < 2}">
    <div class="row">
      <div class="col-12">
        <div class="compare-content">
          <div class="compare-model-box" *ngFor="let item of offers">
            <a [href]="item | offerUrl" class="compare-model-link" *ngIf="item.version.toLowerCase() !== 'electric vehicle'" title="{{ brandName }} {{ item.model }} {{ item.version }} {{ item.engineType }}"></a>
            <a [href]="item | offerUrl" class="compare-model-link" *ngIf="item.version.toLowerCase() === 'electric vehicle'" title="{{ brandName }} {{ item.model }} {{ item.engineType }}"></a>
            <div class="compare-model-box--thumbnail">
              <img [src]="item.image + '?fm=webp&w=263&h=143&fit=crop&bg=white'" alt="" />
            </div>
            <div class="compare-model-box--info">
              <div class="compare-model-box--price" *ngIf="brandSlug === 'fiat'">{{ item.price.final.brutto | formatMoney }} ZŁ</div>
              <div class="compare-model-box--price" *ngIf="brandSlug === 'fiat-professional'">{{ item.price.final.netto | formatMoney }} zł netto</div>
              <div class="compare-model-box--name">{{ item.model }}</div>
              <div class="compare-model-box--version" *ngIf="item.version.toLowerCase() !== 'electric vehicle'">{{ item.version }} - {{ item.engineType }}</div>
              <div class="compare-model-box--version" *ngIf="item.version.toLowerCase() === 'electric vehicle'">{{ item.engineType }}</div>
              <div class="compare-model--remove" (click)="comparedOfferService.removeOffer(item.id)">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5 11.5L1.5 1.5M11.5 1.5L1.5 11.5" stroke="#858585" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
            </div>
          </div>
          <a type="button" class="btn-compare" *ngIf="offers.length === 2" [href]="'/porownaj-oferty' | addUrlPrefix">
            Porównaj oferty
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475975 11.1368 0.31121 11.2553 0.189729C11.3738 0.0682483 11.5345 -5.04191e-07 11.7021 -5.11516e-07C11.8697 -5.18842e-07 12.0304 0.0682482 12.1489 0.189729Z" fill="#1B1C1B"/>
            </svg>
          </a>

        </div>
      </div>
    </div>
  </div>



</div>
