<div class="modal modal--empty fade" tabindex="-1" role="dialog" aria-labelledby="newsletterLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document" [ngClass]="{'w-auto': classReference.autoWidth}">
    <div class="modal-content">
      <div class="modal-header border-0">
        <span class="close" data-dismiss="modal" aria-label="Zamknij">
            <span class="icon-x"></span>
        </span>
      </div>
      <div class="modal-body" [innerHTML]="classReference.content">
      </div>
    </div>
  </div>
</div>