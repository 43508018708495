<section *ngIf="brandSlug !== 'abarth'"
         class="section section--sol-terms section--page-content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 *ngIf="brandSlug === 'jeep'">Regulamin ŚWIADCZENIA USŁUGI „SALON JEEP”</h2>
        <h2 *ngIf="brandSlug === 'alfa-romeo'">Regulamin ŚWIADCZENIA USŁUGI „SALON ALFA ROMEO”</h2>
        <h2 *ngIf="brandSlug === 'fiat'">Regulamin ŚWIADCZENIA USŁUGI „SALON FIAT”</h2>
        <h2 *ngIf="brandSlug === 'fiat-professional'">Regulamin ŚWIADCZENIA USŁUGI „SALON FIAT PROFESSIONAL”</h2>

        <p *ngIf="brandSlug === 'jeep'">Regulamin określa zasady działania usługi Zamówień Online – „Wirtualny Salon”, dostępnej pod adresem salon.jeep.pl „<strong>Regulamin Usługi Wirtualny Salon</strong>”).</p>
        <p *ngIf="brandSlug === 'alfa-romeo'">Regulamin określa zasady działania usługi Zamówień Online – „Wirtualny Salon”, dostępnej pod adresem salon.alfaromeo.pl „<strong>Regulamin Usługi Wirtualny Salon</strong>”).</p>
        <p *ngIf="brandSlug === 'fiat'">Regulamin określa zasady działania usługi Zamówień Online – „Wirtualny Salon”, dostępnej pod adresem salon.fiat.pl „<strong>Regulamin Usługi Wirtualny Salon</strong>”).</p>
        <p *ngIf="brandSlug === 'fiat-professional'">Regulamin określa zasady działania usługi Zamówień Online – „Wirtualny Salon”, dostępnej pod adresem salon.fiatprofessional.pl „<strong>Regulamin Usługi Wirtualny Salon</strong>”).</p>

        <ng-container *ngIf="brandSlug === 'jeep'">
          <p>Regulamin określa prawa i obowiązki Usługobiorców, a także prawa, obowiązki i zakres odpowiedzialności Stellantis Polska sp. z o.o. jako podmiotu zarządzającego i prowadzącego  ”Wirtualny Salon” oraz Stellantis Financial Services Polska Sp. z o.o. i Stellantis Consumer Financial Services Polska Sp. z o.o. jako Finansujących, jak również poszczególnych Dealerów marki Jeep jako obsługujących zamówienie samochodu oraz na kolejnych etapach operacyjnie wspierających zawarcie Umowy Finansowania.</p>
          <p>Dealerzy marek Jeep współpracujących w grupie Stellantis Financial Services oferują pojazdy powyższej marki, przy czym ”Wirtualny Salon” nie służy do zawierania umów sprzedaży pojazdów za gotówkę, bowiem pojazdy będą dostępne jedynie w finansowaniu udostępnianym przez Finansujących w postaci leasingu lub pożyczki.</p>
          <p>Klienci za pomocą Wirtualnego Salonu mogą wybrać i zarezerwować samochód u danego Dealera, a następnie za pośrednictwem strony wniosek.psafinance.pl, do którego Klient zostanie przekierowany, aby złożyć Wniosek o finansowanie na ten wybrany samochód. Umowa Finansowania zawierana jest u Dealera marki Jeep pomiędzy Klientem a Finansującym.</p>
        </ng-container>
        <ng-container *ngIf="brandSlug === 'alfa-romeo'">
          <p>Regulamin określa prawa i obowiązki Usługobiorców, a także prawa, obowiązki i zakres odpowiedzialności Stellantis Polska sp. z o.o. jako podmiotu zarządzającego i prowadzącego  ”Wirtualny Salon” oraz Stellantis Financial Services Polska Sp. z o.o. i Stellantis Consumer Financial Services Polska Sp. z o.o. jako Finansujących, jak również poszczególnych Dealerów marki Alfa Romeo jako obsługujących zamówienie samochodu oraz na kolejnych etapach operacyjnie wspierających zawarcie Umowy Finansowania.</p>
          <p>Dealerzy marek Alfa Romeo współpracujących w grupie Stellantis Financial Services oferują pojazdy powyższej marki, przy czym ”Wirtualny Salon” nie służy do zawierania umów sprzedaży pojazdów za gotówkę, bowiem pojazdy będą dostępne jedynie w finansowaniu udostępnianym przez Finansujących w postaci leasingu lub pożyczki.</p>
          <p>Klienci za pomocą Wirtualnego Salonu mogą wybrać i zarezerwować samochód u danego Dealera, a następnie za pośrednictwem strony wniosek.psafinance.pl, do którego Klient zostanie przekierowany, aby złożyć Wniosek o finansowanie na ten wybrany samochód. Umowa Finansowania zawierana jest u Dealera marki Alfa Romeo pomiędzy Klientem a Finansującym.</p>
        </ng-container>
        <ng-container *ngIf="brandSlug === 'fiat'">
          <p>Regulamin określa prawa i obowiązki Usługobiorców, a także prawa, obowiązki i zakres odpowiedzialności Stellantis Polska sp. z o.o. jako podmiotu zarządzającego i prowadzącego  ”Wirtualny Salon” oraz Stellantis Financial Services Polska Sp. z o.o. i Stellantis Consumer Financial Services Polska Sp. z o.o. jako Finansujących, jak również poszczególnych Dealerów marki Fiat jako obsługujących zamówienie samochodu oraz na kolejnych etapach operacyjnie wspierających zawarcie Umowy Finansowania.</p>
          <p>Dealerzy marek Fiat współpracujących w grupie Stellantis Financial Services oferują pojazdy powyższej marki, przy czym ”Wirtualny Salon” nie służy do zawierania umów sprzedaży pojazdów za gotówkę, bowiem pojazdy będą dostępne jedynie w finansowaniu udostępnianym przez Finansujących w postaci leasingu lub pożyczki.</p>
          <p>Klienci za pomocą Wirtualnego Salonu mogą wybrać i zarezerwować samochód u danego Dealera, a następnie za pośrednictwem strony wniosek.psafinance.pl, do którego Klient zostanie przekierowany, aby złożyć Wniosek o finansowanie na ten wybrany samochód. Umowa Finansowania zawierana jest u Dealera marki Fiat pomiędzy Klientem a Finansującym.</p>
        </ng-container>
        <ng-container *ngIf="brandSlug === 'fiat-professional'">
          <p>Regulamin określa prawa i obowiązki Usługobiorców, a także prawa, obowiązki i zakres odpowiedzialności Stellantis Polska sp. z o.o. jako podmiotu zarządzającego i prowadzącego  ”Wirtualny Salon” oraz Stellantis Financial Services Polska Sp. z o.o. i Stellantis Consumer Financial Services Polska Sp. z o.o. jako Finansujących, jak również poszczególnych Dealerów marki Fiat Professional jako obsługujących zamówienie samochodu oraz na kolejnych etapach operacyjnie wspierających zawarcie Umowy Finansowania.</p>
          <p>Dealerzy marek Fiat Professional współpracujących w grupie Stellantis Financial Services oferują pojazdy powyższej marki, przy czym ”Wirtualny Salon” nie służy do zawierania umów sprzedaży pojazdów za gotówkę, bowiem pojazdy będą dostępne jedynie w finansowaniu udostępnianym przez Finansujących w postaci leasingu lub pożyczki.</p>
          <p>Klienci za pomocą Wirtualnego Salonu mogą wybrać i zarezerwować samochód u danego Dealera, a następnie za pośrednictwem strony wniosek.psafinance.pl, do którego Klient zostanie przekierowany, aby złożyć Wniosek o finansowanie na ten wybrany samochód. Umowa Finansowania zawierana jest u Dealera marki Fiat Professional pomiędzy Klientem a Finansującym.</p>
        </ng-container>

        <p>Informacje o poszczególnych krokach niezbędnych do zamówienia samochodu oraz złożenia Wniosku o finansowanie na wybrany samochód dostępne są dostępna na stronie w sekcji FAQ</p>
        <p>Warunkiem korzystania z “Wirtualnego Salonu” jest zapoznanie się oraz zaakceptowanie treści Regulaminu.</p>

        <h3>I. DEFINICJE</h3>
        <p>Użyte w Regulaminie pojęcia oznaczają:</p>
        <table class="sol-terms-table">
          <tr>
            <td>
              Dealer
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              autoryzowany Dealer marki Jeep, który, działając na podstawie porozumienia z Usługodawcą, prezentuje za pomocą Wirtualnego Salonu  posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              autoryzowany Dealer marki Alfa Romeo, który, działając na podstawie porozumienia z Usługodawcą, prezentuje za pomocą Wirtualnego Salonu  posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              autoryzowany Dealer marki Fiat, który, działając na podstawie porozumienia z Usługodawcą, prezentuje za pomocą Wirtualnego Salonu  posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              autoryzowany Dealer marki Fiat Professional, który, działając na podstawie porozumienia z Usługodawcą, prezentuje za pomocą Wirtualnego Salonu  posiadane przez niego Pojazdy, które dostępne są wyłącznie w finansowaniu w postaci Umowy Finansowania; wybór Pojazdu na etapie składania Zamówienia jest równoznaczny z wyborem Dealera.
            </td>
          </tr>
          <tr>
            <td>
              Dodatkowe benefity dla Klientów
            </td>
            <td>
              oferowane przez Dealera dodatkowe świadczenia na rzecz Klientów.
            </td>
          </tr>
          <tr>
            <td>
              Finansujący
            </td>
            <td>
              <strong>Stellantis Consumer Financial Services Polska sp. z o.o.</strong> z siedzibą w Warszawie ul. Domaniewska 44a, 02-672 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000618642, NIP 5213735679, REGON 364482333, kapitał zakładowy: 5.000.000 zł – w przypadku gdy Umowa Finansowania będzie zawierana w postaci pożyczki konsumenckiej, tj. pożyczki stanowiącej kredyt konsumencki w rozumieniu ustawy z dn. 12.05.2011 r. o kredycie konsumenckim.
              <br />
              <br />
              <strong>Stellantis Financial Services Polska sp. z o.o.</strong> z siedzibą w Warszawie ul. Domaniewska 44a, 02-672 Warszawa, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000021718, NIP 5252211130, REGON 017291458, kapitał zakładowy: 120.050.000 zł – w przypadku gdy Umowa Finansowania będzie zawierana w postaci innej niż pożyczka konsumencka.
            </td>
          </tr>
          <tr>
            <td>
              Klient
            </td>
            <td>
              Usługobiorca, który zawiera z Dealerem Umowę Rezerwacyjną, a z Finansującym Umowę Finansowania.
            </td>
          </tr>
          <tr>
            <td>
              Marka
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              Jeep
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              Alfa Romeo
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              Fiat
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              Fiat Professional
            </td>
          </tr>
          <tr>
            <td>
              Opłata Rezerwacyjna
            </td>
            <td>
              opłata uiszczana przez Użytkownika na rzecz Dealera na podstawie Umowy Rezerwacyjnej.
            </td>
          </tr>
          <tr>
            <td>
              Opłata Wstępna
            </td>
            <td>
              wpłata początkowa określona w umowie leasingu.
            </td>
          </tr>
          <tr>
            <td>
              Wpłata Własna
            </td>
            <td>
              wpłata początkowa określona w umowie pożyczki.
            </td>
          </tr>
          <tr>
            <td>
              Pojazd
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              pojazd marki Jeep z oferty magazynowej Dealera, będący własnością Dealera, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              pojazd marki Alfa Romeo z oferty magazynowej Dealera, będący własnością Dealera, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              pojazd marki Fiat z oferty magazynowej Dealera, będący własnością Dealera, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              pojazd marki Fiat Professional z oferty magazynowej Dealera, będący własnością Dealera, dostępny na platformie Wirtualnego Salonu wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.
            </td>
          </tr>
          <tr>
            <td>
              Regulamin
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              niniejszy Regulamin usługi sklepu internetowego „Wirtualny Salon” oraz strony https://wniosek.psafinance.pl .
              Regulamin jest dostępny pod adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.jeep.pl/regulamin-uslugi</a>
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              niniejszy Regulamin usługi sklepu internetowego „Wirtualny Salon” oraz strony https://wniosek.psafinance.pl .
              Regulamin jest dostępny pod adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-uslugi</a>
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              niniejszy Regulamin usługi sklepu internetowego „Wirtualny Salon” oraz strony https://wniosek.psafinance.pl .
              Regulamin jest dostępny pod adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiat.pl/regulamin-uslugi</a>
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              niniejszy Regulamin usługi sklepu internetowego „Wirtualny Salon” oraz strony https://wniosek.psafinance.pl .
              Regulamin jest dostępny pod adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-uslugi</a>
            </td>
          </tr>
          <tr>
            <td>
              Wirtualny Salon lub Sklep
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              platforma internetowa oferująca usługi zgodnie z  Regulaminem Platformy Internetowej „Wirtualny Salon”
              dostępna pod adresem www.salon.jeep.pl  za pomocą której Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              platforma internetowa oferująca usługi zgodnie z  Regulaminem Platformy Internetowej „Wirtualny Salon”
              dostępna pod adresem www.salon.alfaromeo.pl  za pomocą której Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              platforma internetowa oferująca usługi zgodnie z  Regulaminem Platformy Internetowej „Wirtualny Salon”
              dostępna pod adresem www.salon.fiat.pl  za pomocą której Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              platforma internetowa oferująca usługi zgodnie z  Regulaminem Platformy Internetowej „Wirtualny Salon”
              dostępna pod adresem www.salon.fiatprofessional.pl  za pomocą której Dealerzy oferują posiadane przez nich nowe Pojazdy z oferty magazynowej wraz z finansowaniem dostarczonym przez Finansującego w formie Umowy Finansowania, przy czym w Wirtualnym Salonie nie będzie dochodziło do zawarcia umowy sprzedaży Pojazdu ani też Umowy Finansowania, tylko do zawarcia Umowy Rezerwacyjnej.
            </td>
          </tr>
          <tr>
            <td>
              Umowa
            </td>
            <td *ngIf="brandSlug === 'jeep'">
              umowa o świadczenie Usług zawierana pomiędzy Usługodawcą a Usługobiorcą, w przypadku usługi prowadzenia
              i korzystania z Konta z chwilą potwierdzenia przez Użytkownika procesu rejestracji (Umowa o prowadzenie Konta),
              bądź w przypadku pozostałych Usług zawierana z chwilą rozpoczęcia korzystania z tych Usług, zgodnie z Regulaminem
              Platformy Internetowej „Wirtualny Salon”. <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.jeep.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'alfa-romeo'">
              umowa o świadczenie Usług zawierana pomiędzy Usługodawcą a Usługobiorcą, w przypadku usługi prowadzenia
              i korzystania z Konta z chwilą potwierdzenia przez Użytkownika procesu rejestracji (Umowa o prowadzenie Konta),
              bądź w przypadku pozostałych Usług zawierana z chwilą rozpoczęcia korzystania z tych Usług, zgodnie z Regulaminem
              Platformy Internetowej „Wirtualny Salon”. <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'fiat'">
              umowa o świadczenie Usług zawierana pomiędzy Usługodawcą a Usługobiorcą, w przypadku usługi prowadzenia
              i korzystania z Konta z chwilą potwierdzenia przez Użytkownika procesu rejestracji (Umowa o prowadzenie Konta),
              bądź w przypadku pozostałych Usług zawierana z chwilą rozpoczęcia korzystania z tych Usług, zgodnie z Regulaminem
              Platformy Internetowej „Wirtualny Salon”. <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiat.pl/regulamin-platformy</a>
            </td>
            <td *ngIf="brandSlug === 'fiat-professional'">
              umowa o świadczenie Usług zawierana pomiędzy Usługodawcą a Usługobiorcą, w przypadku usługi prowadzenia
              i korzystania z Konta z chwilą potwierdzenia przez Użytkownika procesu rejestracji (Umowa o prowadzenie Konta),
              bądź w przypadku pozostałych Usług zawierana z chwilą rozpoczęcia korzystania z tych Usług, zgodnie z Regulaminem
              Platformy Internetowej „Wirtualny Salon”. <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-platformy</a>
            </td>
          </tr>
          <tr>
            <td>
              Umowa Finansowania
            </td>
            <td>
              umowa finansowania zawierana z Finansującym na podstawie Wniosku o finansowanie.
            </td>
          </tr>
          <tr>
            <td>
              Umowa Rezerwacyjna
            </td>
            <td>
              umowa zawierana na odległość pomiędzy Dealerem a Usługobiorcą dotycząca Pojazdu, zawierana z chwilą otrzymania przez Usługobiorcę potwierdzenia Zamówienia. W wyniku zawarcia Umowy Rezerwacyjnej, Dealer zobowiązuje się w stosunku do Klienta, że w razie wykonania przez niego określonych w niniejszym Regulaminie czynności w sposób w nim przewidziany oraz uzyskaniu pozytywnej oceny zdolności kredytowej przez Finansującego sprzeda na rzecz Finansującego (w przypadku umowy leasingu) lub Klienta (w przypadku umowy pożyczki) wybrany przez Klienta za pośrednictwem Wirtualnego Salonu Pojazd, w celu zawarcia następnie między Finansującym a Klientem Umowy Finansowania dotyczącej tego Pojazdu. Warunkiem zawarcia umowy rezerwacyjnej jest potwierdzenie warunków Zamówienia (w tym m.in. dostępności, ceny i  i wyposażenia pojazdu) przez Dealera. Brak przesłania wiadomości e-mail z potwierdzeniem Zamówienia oznacza, że umowa rezerwacyjna nie została zawarta.
            </td>
          </tr>
          <tr>
            <td>
              Usługi
            </td>
            <td>
              usługi świadczone drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem Platformy Internetowej Wirtualny Salon; w tym: obsługa złożenia i przekazania Zamówienia do realizacji przez Dealera, dostarczanie informacji związanych z Pojazdami lub finansowaniem udzielanym przez Finansującego, w tym kalkulator opłat związanych z Umową Finansowania, porównywarka modeli Pojazdów. Warunki i zasady świadczenia usługi zawiera <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">Regulamin Platformy Internetowej „Wirtualny Salon”</a>.
            </td>
          </tr>
          <tr>
            <td>
              Usługi Finansującego
            </td>
            <td>
              usługi świadczone drogą elektroniczną przez Finansującego na rzecz Usługobiorcy za pośrednictwem strony <a href="https://wniosek.psafinance.pl" target="_blank">https://wniosek.psafinance.pl</a> w zakresie umożliwienia złożenia Wniosku o finansowanie.
            </td>
          </tr>
          <tr>
            <td>
              Usługobiorca
            </td>
            <td>
              Pełnoletnia osoba fizyczna, osoba prawna lub jednostka organizacyjna niebędąca osobą prawną, której przepisy prawa przyznają zdolność prawną, będąca usługobiorcą w rozumieniu art. 2 pkt 7 UŚUDE, korzystająca z Usług na podstawie Umowy lub Usług Finansującego.
              <br />
              <br />
              Usługobiorcą jest w szczególności osoba fizyczna prowadzącą jednoosobową działalność gospodarczą, której kodeks cywilny przyznaje niektóre prawa konsumenckie.
            </td>
          </tr>
          <tr>
            <td>
              Usługodawca
            </td>
            <td>
              Stellantis Polska Spółką z ograniczoną odpowiedzialnością, z kapitałem
              zakładowym w wysokości 58 492 000 zł, z siedzibą w Warszawie, przy Al.
              Krakowskiej 206, (02-219), wpisaną do rejestru przedsiębiorców Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m.st. Warszawy
              XIV Wydział Gospodarczy KRS, pod nr KRS 0000019125. Nr NIP 5260151365.
              W zakresie Usług Finansującego Usługodawcami są Finansujący.
            </td>
          </tr>
          <tr>
            <td>
              Użytkownik
            </td>
            <td>
              Usługobiorca, który korzysta z Platformy Internetowej Wirtualny Salon.
            </td>
          </tr>
          <tr>
            <td>
              Wniosek o finansowanie
            </td>
            <td>
              wniosek o zawarcie Umowy Finansowania składany przez Użytkownika za pośrednictwem wniosek.psafinance.pl
            </td>
          </tr>
          <tr>
            <td>
              Zamówienie
            </td>
            <td>
              wybór za pośrednictwem Platformy Internetowej Wirtualny Salon przez Klienta: Pojazdu, Dodatkowych Benefitów dla Klientów (jeśli są dostępne w Wirtualnym Salonie); warunkiem ważności Zamówienia jest złożenie Wniosku o finansowanie i jego pozytywne rozpatrzenie przez Finansującego.
            </td>
          </tr>
        </table>
        <br />
        <h3>I. POSTANOWIENIA OGÓLNE</h3>
        <ul>
          <li>
            Niniejszy Regulamin określa:
            <ul>
              <li>prawa i obowiązki Klienta, Usługodawcy, Dealera i Finansującego związane ze świadczeniem Usług lub Usług Finansującego,</li>
              <li>prawa i obowiązki Klienta, Usługodawcy, Dealera i Finansującego związane z zawieraniem na odległość i wykonaniem Umów Rezerwacyjnych,</li>
              <li>zasady dokonywania Zamówień za pośrednictwem Wirtualnego Salonu,</li>
              <li>zasady składania Wniosków o finansowanie i zawierania Umów Finansowania,</li>
              <li>ogólne zasady ochrony Danych osobowych; szczegółowe informacje dotyczące m.in. podstaw prawnych oraz celów przetwarzania danych, a także praw wynikających z RODO zawierają: <a class="nav-link" (click)="openPrivacyNotes()">Polityka prywatności</a> i <a target="_self" class="nav-link cl-policy-link" href="javascript:getCookieOpenModal()" rel="nofollow" data-adobe="bottom-nav:editorial:cookies">polityka plików cookie</a> Usługodawcy, Polityka Prywatności i <a href="https://www.stellantis-financial-services.pl/polityka-cookies.html" target="_blank">Polityka cookies</a> Finansującego oraz poszczególne klauzule informacyjne zamieszczone na platformie internetowej Wirtualny Salon.</li>
            </ul>
          </li>
          <li>Usługodawca świadczy Usługi, a Finansujący Usługi Finansującego, zgodnie z niniejszym Regulaminem jak również <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">Regulaminem Platformy Internetowej Wirtualny Salon.</a></li>
          <li>Dealerzy zawierają Umowę Rezerwacyjną z Klientem za pośrednictwem Wirtualnego Salonu zgodnie z</li>
          <li>Wniosek o finansowanie składany jest przez Klienta, po przekierowaniu ze strony Wirtualnego Salonu, na stronę <a href="https://wniosek.psafinance.pl" target="_blank">https://wniosek.psafinance.pl</a>.</li>
          <li>Po wydaniu decyzji o udzieleniu finansowania, Umowa Finansowania dotycząca wybranego Pojazdu jest zawierana poza Platformą Internetową Wirtualny Salon, tj. u wybranego Dealera (a w przypadku pożyczki także drogą elektroniczną), pomiędzy Finansującym a Użytkownikiem.</li>
        </ul>

        <h3>II. PRZYJMOWANIE ZAMÓWIEŃ I ZAWIERANIE UMÓW REZERWACYJNYCH</h3>
        <p *ngIf="brandSlug === 'jeep'">2.1. Aktualne informacje na temat dostępnych Pojazdów, w tym dane techniczne, dotyczące wyposażenia standardowego,
          wyposażenia dodatkowego, dodatkowych benefitów dla Klientów (jeśli dostępne), oraz warunków lub zasad finansowania
          zamieszczane są na Platformie Internetowej Wirtualnego Salonu znajdującej się pod adresem <a href="https://salon.jeep.pl">https://salon.jeep.pl</a></p>
        <p *ngIf="brandSlug === 'alfa-romeo'">2.1. Aktualne informacje na temat dostępnych Pojazdów, w tym dane techniczne, dotyczące wyposażenia standardowego,
          wyposażenia dodatkowego, dodatkowych benefitów dla Klientów (jeśli dostępne), oraz warunków lub zasad finansowania
          zamieszczane są na Platformie Internetowej Wirtualnego Salonu znajdującej się pod adresem <a href="https://salon.alfaromeo.pl">https://salon.alfaromeo.pl</a></p>
        <p *ngIf="brandSlug === 'fiat'">2.1. Aktualne informacje na temat dostępnych Pojazdów, w tym dane techniczne, dotyczące wyposażenia standardowego,
          wyposażenia dodatkowego, dodatkowych benefitów dla Klientów (jeśli dostępne), oraz warunków lub zasad finansowania
          zamieszczane są na Platformie Internetowej Wirtualnego Salonu znajdującej się pod adresem <a href="https://salon.fiat.pl">https://salon.fiat.pl</a></p>
        <p *ngIf="brandSlug === 'fiat-professional'">2.1. Aktualne informacje na temat dostępnych Pojazdów, w tym dane techniczne, dotyczące wyposażenia standardowego,
          wyposażenia dodatkowego, dodatkowych benefitów dla Klientów (jeśli dostępne), oraz warunków lub zasad finansowania
          zamieszczane są na Platformie Internetowej Wirtualnego Salonu znajdującej się pod adresem <a href="https://salon.fiatprofessional.pl">https://salon.fiatprofessional.pl</a></p>

        <p>2.2. Na stronie Wirtualnego Salonu przy danym Pojeździe prezentowane są informacje o wysokości raty finansowania dla wskazanych przez Usługobiorcę parametrów finansowania, w kwocie netto. Usługobiorca korzystając z kalkulatora finansowania może dokonać własnego wyliczenia raty. Cena brutto oraz netto Pojazdu widoczna jest na karcie produktu w kalkulatorze finansowym oraz w potwierdzeniu Zamówienia. Ceny brutto/netto Pojazdów prezentowanych w Sklepie skalkulowane są wyłącznie na potrzeby kalkulacji rat i innych opłat wynikających z Umowy Finansowania na podstawie ceny rekomendowanej, z uwzględnieniem ceny cennikowej. W ramach finansowania danego Pojazdu prezentowanego w Wirtualnym Salonie Dealerzy mogą oferować wg własnego uznania Dodatkowe Benefity dla Klientów.</p>
        <p>2.3. Klient, na każdym etapie procesu zawierania Umowy Finansowania, a także przed rozpoczęciem tego procesu, może negocjować z Dealerem dodatkowe korzyści, które Dealer może mu przyznać w celu zachęcenia go do zawarcia Umowy Finansowania na Pojazd w ramach Wirtualnego Salonu. Korzyściami mogą być w szczególności korzyści pieniężne (przykładowo w formie wypłat lub ponoszenia przez Dealera części niektórych rat lub opłat z tytułu Umowy Finansowania), korzyści w postaci darmowych lub tańszych usług, koła, akcesoria itd. Dealer przyznaje lub odmawia przyznania takich korzyści wedle własnego uznania.</p>
        <p>2.4. Klient, na każdym etapie procesu zawierania Umowy Finansowania w ramach Sklepu może zrezygnować z kontynuowania tego procesu i zakupić pojazd samemu lub za pośrednictwem podmiotu finansującego, w tym także Finansującego, na warunkach, które swobodnie wynegocjuje z Dealerem i podmiotem finansującym. Oświadczenie o rezygnacji Klient może złożyć Dealerowi w terminie 14 dni od dnia otrzymania potwierdzenia Zamówienia, ale nie później niż do dnia zawarcia Umowy Finansowania.</p>

        <ul>
          <li>Informacje dotyczące Pojazdów oraz warunków ich finansowania podane na stronie internetowej Wirtualnego Salonu nie stanowią oferty w rozumieniu Kodeksu Cywilnego, lecz zaproszenie do zawarcia Umowy</li>
          <li>Każdy Pojazd przypisany jest do konkretnego Dealera. Wybór Pojazdu przez Klienta jest równoznaczny z wyborem Dealera, z którym, na warunkach określonych w niniejszym Regulaminie, zostanie zawarta Umowa Rezerwacyjna, w wykonaniu której, po spełnieniu postanowień zawartych w niniejszym Regulaminie oraz pozytywnej ocenie Klienta przez Finansującego, wybrany Dealer dokona sprzedaży zarezerwowanego Pojazdu na rzecz Finansującego lub Klienta w celu umożliwienia Klientowi zawarcia Umowy</li>
          <li>
            Składając Zamówienie Klient:
            <ul>
              <li>uzupełnia w formularzu Zamówienia dane wymagane przez Usługodawcę (w tym Finansującego), natomiast w przypadku gdy Klientem jest osoba prawna lub jednostka organizacyjna, wówczas Osoba Uprawniona Do Kontaktów podaje także nazwę, adres siedziby, numer NIP oraz REGON podmiotu, który reprezentuje;</li>
              <li>potwierdza wybór zamawianego Pojazdu, którego właścicielem jest określony Dealer,</li>
              <li>potwierdza wybór Dodatkowych benefitów dla Klientów (jeśli są dostępne w Sklepie) oraz sposobu finansowania ubezpieczenia Pojazdu (w przypadku przedsiębiorców).</li>
            </ul>
          </li>
          <li>Niezwłocznie po złożeniu Zamówienia Klientowi na podany przez niego adres poczty elektronicznej zostanie przesłana wiadomość e-mail stanowiąca potwierdzenie Zamówienia i tym samym zawarcia Umowy Rezerwacyjnej. W potwierdzeniu Zamówienia Klient otrzyma m.in. następujące informacje: dane dotyczące Pojazdu, cena brutto, dane Dealera, wysokość Opłaty Rezerwacyjnej, warunki Umowy Finansowania (np. wysokość raty, Opłata Wstępna/Wpłata Własna, opłata za rejestrację, limit kilometrów, czas trwania umowy).</li>
          <li>Jeśli Zamówienie zostało złożone przez Osobę Upoważnioną Do Kontaktu, Umowa Rezerwacyjna zawierana jest pomiędzy Dealerem a Użytkownikiem reprezentowanym przez Osobę Upoważnioną Do Kontaktu. W takiej sytuacji momentem zawarcia Umowy Rezerwacyjnej jest również moment otrzymania potwierdzenia Zamówienia przez Osobę Upoważnioną do kontaktu</li>
          <li>Umowa Rezerwacyjna zawarta jest na czas określony do momentu zawarcia Umowy Finansowania, z zastrzeżeniem postanowień zawartych w punkcie 2.6. oraz w rozdziałach IV i V</li>
          <li>W ramach jednego konta Użytkownika od momentu złożenia Zamówienia do momentu zawarcia przez Klienta Umowy Finansowania możliwe jest złożenie i realizacja tylko tego jednego Zamówienia.</li>
        </ul>
        <h3>III. CZYNNOŚCI UŻYTKOWNIKÓW Z WYKORZYSTANIEM WIRTUALNEGO SALONU ZMIERZAJĄCE DO ZAWARCIA UMOWY FINANSOWANIA</h3>
        <p>3.1. Po zawarciu Umowy Rezerwacyjnej celem skutecznego utrzymania rezerwacji Pojazdu do czasu zawarcia Umowy Finansowania, Klient dokonuje zapłaty na rzecz Dealera kwoty 3.000 zł tytułem Opłaty Rezerwacyjnej w terminie 48 godzin od otrzymania Potwierdzenia Zamówienia. Brak uiszczenia w terminie Opłaty Rezerwacyjnej będzie skutkował wygaśnięciem Umowy Rezerwacyjnej. Opłata Rezerwacyjna zostanie zwrócona w przypadku rozwiązania Umowy Rezerwacyjnej. W przypadku zawarcia przez Klienta i Finansującego Umowy Finansowania, Opłata Rezerwacyjna zostanie zaliczona na poczet Opłaty Wstępnej lub przyszłych opłat leasingowych (w przypadku leasingu) albo Wpłaty Własnej lub przyszłych opłat pożyczkowych (w przypadku pożyczki) – albo na poczet innych wspólnie uzgodnionych kosztów, na co Użytkownik i Dealer wyrażają zgodę.</p>
        <p>3.2   Celem uzyskania decyzji kredytowej z wykorzystaniem Wirtualnego salonu, Klient może złożyć Wniosek o finansowanie wraz z wymaganymi przez Finansującego dokumentami, koniecznymi do oceny zdolności kredytowej Użytkownika, w terminie 48 godzin od otrzymania wysłania wniosku</p>
        <ul>
          <li>Po złożeniu Wniosku o finansowanie, Użytkownik otrzymuje wiadomość e-mail z informacją o statusie wniosku.</li>
          <li>W przypadku decyzji pozytywnej Klient wybiera datę odbioru Pojazdu, którą następnie potwierdza Dealer. W razie warunkowej decyzji Finansującego, Dealer skontaktuje się z Klientem telefonicznie lub za pomocą poczty elektronicznej, ustalając zmienione parametry finansowania niezbędne do ponownego rozpatrzenia Wniosku o finansowanie. W przypadku pozytywnej decyzji kredytowej Klient otrzyma potwierdzenie zmienionych parametrów finansowania w formie wiadomości e- mail.</li>
          <li>W wyznaczonym terminie odbioru Pojazdu, w siedzibie Dealera, dochodzi do zawarcia przez Klienta Umowy Finansowania z Finansującym.</li>
          <li>
            W wyniku zawarcia Umowy Rezerwacyjnej Dealer zobowiązany jest do
            <ul>
              <li>nieoferowania Pojazdu osobom trzecim przez okres w niej wskazany,</li>
              <li>współpracy z Klientem w celu pomocy Klientowi w spełnieniu wymogów Finansującego koniecznych do oceny zdolności kredytowej Użytkownika,</li>
              <li>sprzedania Pojazdu Finansującemu lub Klientowi za cenę będącą podstawą wydania pozytywnej decyzji kredytowej).</li>
            </ul>
          </li>
          <li>Klient otrzymuje od Usługodawcy informacje przypominające: o zapłacie Opłaty Rezerwacyjnej, dacie odbioru Pojazdu, a także informację dot. anulowania Zamówienia w przypadkach wskazanych w punkcie IV poniżej. W każdej chwili Klient może sprawdzić status realizacji Zamówienia logując się do swojego Konta na stronie</li>
        </ul>

        <h3>IV. ROZWIĄZANIE UMOWY REZERWACYJNEJ</h3>
        <ul>
          <li>
            Umowa Rezerwacyjna ulega natychmiastowemu rozwiązaniu w przypadku:
            <ul>
              <li>nieuiszczenia w terminie Opłaty Rezerwacyjnej zgodnie z punktem 3.1;</li>
              <li>anulowania Wniosku o finansowanie;</li>
              <li>nie zawarcia Umowy Finansowania najpóźniej na 7 dni przed ustaloną datą odbioru Pojazdu;</li>
              <li>rezygnacji przez Użytkownika, w przypadkach określonych w punktach 2.4. – 2.6.</li>
            </ul>
          </li>
          <li>W przypadku rozwiązania Umowy Rezerwacyjnej z powyższych przyczyn, Opłata Rezerwacyjna zostanie zwrócona w terminie 7 dni roboczych od dnia rozwiązania Umowy Rezerwacyjnej.</li>
        </ul>

        <h3>V. ODSTĄPIENIE OD UMOWY REZERWACYJNEJ</h3>
        <ul>
          <li>Niezależnie od postanowień punktu 2.4. – 2.6. Regulaminu, Klient będący konsumentem (również w rozumieniu ustawy z dnia 31 lipca 2019 r. o zmianie niektórych ustaw w celu ograniczenia obciążeń regulacyjnych Dz. U. 2019. 1495) może odstąpić od Umowy Rezerwacyjnej bez podania przyczyny w ciągu 14 dni od daty zawarcia Umowy Rezerwacyjnej, zgodnie z Ustawą o prawach konsumenta, chyba że zgodnie z postanowieniami Regulaminu Umowa Rezerwacyjna uległa wcześniej rozwiązaniu.</li>
          <li>W przypadku odstąpienia od Umowy Rezerwacyjnej Dealer dokonuje zwrotu Opłaty Rezerwacyjnej niezwłocznie, nie później niż w ciągu 7 dni roboczych od dnia otrzymania oświadczenia Klienta o odstąpieniu od Umowy Jeżeli Klient będący konsumentem (również w rozumieniu ustawy z dnia 31 lipca 2019 r. o zmianie niektórych ustaw w celu ograniczenia obciążeń regulacyjnych Dz. U. 2019. 1495) odstąpił w terminie wskazanym w punkcie 5.1 Regulaminu od Umowy Rezerwacyjnej i w tym czasie doszło do zawarcia Umowy Finansowania, wówczas za wyraźną zgodą Klienta, Dealer przekazuje Opłatę Rezerwacyjną na poczet Opłaty Wstępnej/Wpłaty Własnej lub innych kosztów dodatkowych wynikających z Umowy Finansowania.</li>
          <li>Zwrot należności następuje przy użyciu takiego samego sposobu zapłaty, jakiego użył Użytkownik.</li>
          <li>Szczegółowe pouczenie o zasadach odstąpienia od Umowy Rezerwacyjnej zawiera Załącznik nr 1 do Regulaminu. Wzór formularza o odstąpieniu od Umowy Rezerwacyjnej, z którego może skorzystać Użytkownik stanowi Załącznik nr 2 do Regulaminu.</li>
        </ul>

        <h3>VI. SPOSOBY FINANSOWANIA POJAZDÓW</h3>
        <ul>
          <li>Pojazdy oferowane na platformie Wirtualnego Salonu dostępne są wyłącznie w finansowaniu w formie Umowy Finansowania zawieranej z Finansującym.</li>
        </ul>

        <h3>VII. WNIOSEK O FINANSOWANIE</h3>
        <ul>
          <li>Finansujący udostępnia formularz Wniosku o finansowanie oraz informacje dotyczące warunków Umowy</li>
          <li>Finansujący umożliwia Użytkownikowi złożenie Wniosku o finansowanie za pośrednictwem systemu udostępnianego przez Finansującego. Złożenie Wniosku o finansowanie nie stanowi zobowiązania Użytkownika do zawarcia Umowy</li>
          <li>
            Użytkownik zobowiązany jest do podania wymaganych przez Finansującego informacji wskazanych we Wniosku o finansowanie, niezbędnych do dokonania oceny zdolności kredytowej, w szczególności:
            <ul class="terms-list-number">
              <li>danych identyfikujących Użytkownika oraz jego danych teleadresowych;</li>
              <li>danych dotyczących sytuacji zawodowej oraz ekonomiczno-finansowej Użytkownika.</li>
            </ul>
          </li>
          <li>Użytkownik zobowiązany jest do przesłania skanów lub fotografii wymaganych przez Finansującego dokumentów wymaganych do rozpatrzenia Wniosku o finansowanie, w tym potwierdzających tożsamość Użytkownika oraz niezbędnych do dokonania oceny jego zdolności kredytowej, jak również dokumentów stwierdzających prawo do zawarcia Umowy Finansowania, w tym reprezentowania Użytkownika oraz dokumentów określających jego małżeński ustrój majątkowy (w przypadku Użytkownika będącego osobą fizyczną).</li>
          <li>Finansujący może wystąpić do Użytkownika o udzielenie dodatkowych informacji lub przesłanie skanów lub fotografii dodatkowych dokumentów.</li>
          <li>W zakresie, w jakim umożliwienie Użytkownikowi przez Finansującego złożenia Wniosku o finansowanie za pośrednictwem systemu Finansującego stanowi świadczenie usług drogą elektroniczną, do usług tych stosuje się odpowiednio postanowienia niniejszego Regulaminu dotyczące Usług.</li>
        </ul>

        <h3>VIII. ZAWARCIE UMOWY FINANSOWANIA</h3>
        <p>8.1.  W razie pozytywnej decyzji kredytowej, Użytkownik podpisuje Umowę Finansowania w lokalu Dealera działającego w charakterze pośrednika Finansującego. Pozytywna decyzja kredytowa nie stanowi zobowiązania Finansującego do zawarcia Umowy Finansowania.</p>
        <p>8.2. Przed zawarciem Umowy Finansowania Użytkownik zobowiązany jest do przedłożenia w lokalu Dealera oryginałów dokumentów przesłanych uprzednio Finansującemu w formie skanów lub fotografii. Osoba działająca w lokalu Dealera jako przedstawiciel Finansującego jest uprawniona i zobowiązania do wykonania kopii ww. dokumentów i potwierdzenia ich za zgodność z oryginałem.</p>
        <ul>
          <li>
            1. PŁATNOŚCI I NADPŁATY
            <ul>
              <li>Klient dokonuje zapłaty za Opłatę Rezerwacyjną zgodnie z art. 2.10. powyżej, przelewem na rachunek bankowy Dealera, co nie wyklucza możliwości dokonania zapłaty w inny sposób, w szczególności gotówką w kasie Dealera. Za dzień płatności przyjmuje się datę zaksięgowania środków na rachunku Dealera.</li>
              <li>Realizacja Zamówienia rozpoczęta zostanie po przesłaniu Użytkownikowi potwierdzenia przyjęcia Zamówienia, o którym mowa w art.2.10. oraz płatności przez Dealera.</li>
              <li>Użytkownik może dokonać płatności zaliczki w sposób opisany powyżej na poczet Opłaty Wstępnej/Wpłaty Własnej oraz zaliczki na poczet składki ubezpieczenia Pojazdu.</li>
              <li>Brak otrzymania potwierdzenia dokonania zapłaty  powoduje anulowanie Zamówienia i rozwiązanie Umowy Rezerwacyjnej, zgodnie z postanowieniami punktu 4.1. Regulaminu.</li>
              <li>W przypadku powstania nadpłat jak również konieczności zwrotu Opłaty Rezerwacyjnej, zaliczki na poczet Opłaty Wstępnej/Wpłaty Własnej lub zaliczki na poczet składki ubezpieczenia Pojazdu, zwrot następuje przy użyciu takiego samego sposobu zapłaty, jakiego użył Użytkownik w terminie 7 dni roboczych.</li>
            </ul>
          </li>
        </ul>

        <h3>X. ZMIANY POJAZDÓW, CEN I WARUNKÓW FINANSOWANIA</h3>
        <p>Usługodawca, Dealer oraz Finansujący zastrzegają sobie prawo do zmiany Pojazdów i ich cen, a także warunków finansowania znajdujących się w ofercie Sklepu, wprowadzania nowych Pojazdów oraz nowych rodzajów finansowania do oferty, przeprowadzania i odwoływania akcji promocyjnych w Sklepie lub wprowadzania w nich zmian, z zastrzeżeniem zdania drugiego. Powyższe uprawnienia nie mają wpływu na ceny Pojazdów, w zakresie których zostały złożone Zamówienia przed wejściem w życie wskazanych powyżej zmian.</p>

        <h3>XI. REKLAMACJE DOTYCZĄCE USŁUG ŚWIADCZONYCH PRZEZ DEALERA</h3>
        <ul>
          <li>Użytkownicy są uprawnieni do złożenia reklamacji w przypadku, gdy Dealer nie wywiązuje się ze swoich obowiązków opisanych w niniejszym Regulaminie lub wywiązuje się z nich w sposób niezgodny z postanowieniami</li>
          <li>Reklamację, o której mowa w pkt. 11.1., można złożyć w formie papierowej na adres siedziby Dealera lub w formie elektronicznej na adres poczty elektronicznej podany przez Reklamacja powinna zawierać w szczególności opis problemu będącego podstawą reklamacji oraz dane kontaktowe Użytkownika zgłaszającego reklamację (reklamującego): imię i nazwisko lub nazwa, adres zamieszkania bądź siedziby podmiotu, Adres poczty elektronicznej, numer telefonu.</li>
          <li>Dealer rozpatruje reklamację w terminie 14 dni od daty jej Jeżeli reklamacja nie może zostać rozpatrzona w danym terminie, Dealer powiadomi o tym reklamującego na piśmie bądź w formie elektronicznej, wskazując przyczyny opóźnienia i przewidywany termin rozpatrzenia reklamacji.</li>
          <li>Odpowiedź na reklamację oraz informacja, o której mowa w pkt 11.3. zdanie drugie, wysyłana jest na Adres poczty elektronicznej wskazany przez Użytkownika. W szczególnych okolicznościach odpowiedź może zostać wysłana z wykorzystaniem innych środków komunikacji (np. tradycyjną pocztą).</li>
        </ul>

        <h3>XI. REKLAMACJE DOTYCZĄCE USŁUG ŚWIADCZONYCH PRZEZ DEALERA</h3>
        <ul>
          <li>
            Użytkownik ma prawo do składania reklamacji dotyczących Usług Finansującego:
            <ul class="terms-list-number">
              <li>w formie pisemnej: pocztą lub kurierem na adres Finansującego: 02-672 Warszawa, ul. Domaniewska 44a, faxem na numer 22 331 96 72,</li>
              <li>w formie elektronicznej na adres: bok@psa-finance.com</li>
              <li>ustnie: telefonicznie w Centrum Obsługi Klienta nr tel. 801 51 21 31 lub 22 457 21 31 albo osobiście w siedzibie Finansującego.</li>
            </ul>
          </li>
          <li>Złożona reklamacja powinna zawierać co najmniej następujące dane kontaktowe: numer Wniosku o finansowanie oraz imię i nazwisko Użytkownika.</li>
          <li>Reklamacja zostanie rozpatrzona w ciągu 30 dni. W szczególnie skomplikowanych przypadkach uniemożliwiających rozpatrzenie reklamacji i udzielenie odpowiedzi w w/w terminie okres ten może ulec wydłużeniu do maksymalnie 60 dni, o czym Finansujący poinformuje Użytkownika z odpowiednim wyprzedzeniem, wyjaśniając przyczynę opóźnienia, wskazując okoliczności wymagające dalszego ustalenia oraz określając przewidywany termin udzielenia odpowiedzi na reklamację.</li>
          <li>Odpowiedź na reklamację zostanie udzielona w formie pisemnej albo – za zgodą Użytkownika – pocztą elektroniczną.</li>
          <li>Złożenie reklamacji niezwłocznie po powzięciu zastrzeżeń przez Użytkownika może ułatwić i przyspieszyć rzetelne rozpatrzenie reklamacji.</li>
          <li>Użytkownik będący osobą fizyczną może złożyć wniosek do Rzecznika Finansowego o rozstrzygnięcie sporu w drodze postępowania pozasądowego.</li>
          <li>Użytkownik będący konsumentem może również złożyć wniosek o rozstrzygnięcie sporu do Rzecznika Finansowego, jako podmiotu uprawnionego do prowadzenia postępowania w sprawie pozasądowego rozwiązywania sporów konsumenckich w rozumieniu ustawy z dnia 23 września 2016 r. o pozasądowym rozwiązywaniu sporów konsumenckich (Dz. U. poz. 1823 z późn. zm.). Informacje o trybie tego postępowania znajdują się na stronie internetowej Rzecznika Finansowego: <a href="https://rf.gov.pl/" target="_blank">rf.gov.pl</a>.</li>
          <li>Organem właściwym w sprawach ochrony konsumentów jest Prezes Urzędu Ochrony Konkurencji i Konsumentów.</li>
          <li><strong>OCHRONA DANYCH OSOBOWYCH</strong></li>
        </ul>
        <p>
          W zakresie świadczenia Usług, administratorem danych osobowych Usługobiorców jest Usługodawca. Zasady dotyczące przetwarzania danych osobowych znajdują się w Zastrzeżeniach Prawnych na Platformie Internetowej „Wirtualny Salon”.
        </p>
        <ul>
          <li><strong>INNE POSTANOWIENIA</strong></li>
          <li>Regulamin wchodzi w życie w dniu 03.11.2023r.</li>
          <li *ngIf="brandSlug === 'jeep'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.jeep.pl/regulamin-uslugi</a></li>
          <li *ngIf="brandSlug === 'alfa-romeo'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-uslugi</a></li>
          <li *ngIf="brandSlug === 'fiat'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiat.pl/regulamin-uslugi</a></li>
          <li *ngIf="brandSlug === 'fiat-professional'">Obowiązujący Regulamin jest dostępny dla Usługobiorcy pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-uslugi</a></li>

          <li>Usługodawca na żądanie Usługobiorcy udostępni treść niniejszego Regulaminu w sposób umożliwiający jego pozyskanie, odtwarzanie i utrwalanie za pomocą Systemu teleinformatycznego, którym posługuje się Usługobiorca.</li>
          <li>Usługodawca zastrzega sobie prawo do jednostronnej zmiany postanowień Regulaminu, bez konieczności uzasadniania przyczyn Usługobiorcy, z zastrzeżeniem punktu 14.5.-14.8. poniżej.</li>

          <li *ngIf="brandSlug === 'jeep'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez
            umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Wirtualnego Salonu pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.jeep.pl/regulamin-uslugi</a> oraz
            bezpośrednie poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'alfa-romeo'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez
            umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Wirtualnego Salonu pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.alfaromeo.pl/regulamin-uslugi</a> oraz
            bezpośrednie poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'fiat'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez
            umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Wirtualnego Salonu pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiat.pl/regulamin-uslugi</a> oraz
            bezpośrednie poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>
          <li *ngIf="brandSlug === 'fiat-professional'">Usługodawca zobowiązuje się do poinformowania Usługobiorcy o zmianach dokonanych w Regulaminie poprzez
            umieszczenie jednolitego tekstu Regulaminu na stronie internetowej Wirtualnego Salonu pod
            adresem <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">https://salon.fiatprofessional.pl/regulamin-uslugi</a> oraz
            bezpośrednie poinformowanie Usługobiorcy drogą elektroniczną na podany przez niego Adres poczty elektronicznej.</li>

          <li>Zmiany dokonane w Regulaminie wchodzą w życie po upływie 7 dni od momentu udostępnienia jego treści na stronie internetowej Wirtualnego Salonu i bezpośredniego poinformowania zgodnie z pkt. 14.5 powyżej.</li>
          <li>Zamówienia złożone przed wejściem w życie zmian Regulaminu, zgodnie z pkt 14.6. są realizowane na podstawie zapisów treści Regulaminu obowiązującego w dniu złożenia Zamówienia.</li>
          <li>W przypadku określonym w punkcie 14.4. Usługobiorca ma prawo rozwiązać Umowę natychmiastowo lub z zachowaniem 7-dniowego okresu wypowiedzenia według wyboru Usługobiorcy, w terminie 14 dni po uzyskaniu informacji o zmianie postanowień Regulaminu w wyniku bezpośredniego poinformowania Usługobiorcy, zgodnie z pkt 14.5 powyżej, poprzez poinformowanie drogą elektroniczną lub pisemnie Usługodawcy. Zaniechanie poinformowania Usługodawcy w ww. terminie 14 dni oznacza, że Usługobiorca zaakceptował zmieniony Regulamin.</li>
          <li>W sprawach nieuregulowanych w niniejszym Regulaminie zastosowanie mają przepisy Kodeksu cywilnego, Przepisy z zakresu ochrony danych osobowych, UŚUDE, Ustawy o prawach konsumenta oraz innych przepisów prawa.</li>
        </ul>

        <h3>ZAŁĄCZNIKI DO REGULAMINU:</h3>
        <ul>
          <li>ZAŁĄCZNIK Nr 1 Pouczenie o zasadach odstąpienia od Umowy Rezerwacyjnej</li>
          <li>ZAŁĄCZNIK Nr 2 Wzór formularza o odstąpieniu od Umowy Rezerwacyjnej</li>
        </ul>
        <br />

        <h3>ZAŁĄCZNIK NR 1</h3>
        <br />
        <h3>ZASADY DOTYCZĄCE REALIZACJI PRAWA ODSTĄPIENIA OD UMOWY REZERWACYJNEJ</h3>
        <br />
        <p><strong>Prawo odstąpienia od umowy.</strong></p>
        <p>Przysługuje Państwu prawo odstąpienia od Umowy Rezerwacyjnej w terminie 14 dni od dnia jej zawarcia  – tj. od dnia otrzymania potwierdzenia Zamówienia na podany adres poczty elektronicznej, bez podania jakiejkolwiek przyczyny.</p>
        <p>Zgodnie z punktem XI Regulaminu, prawo odstąpienia nie przysługuje jeśli Umowa Rezerwacyjna uległa przed upływem w/w terminu rozwiązaniu albo w tym czasie doszło do zawarcia Umowy o finansowanie, na co wyraziliście Państwo zgodę.</p>
        <p>Aby skorzystać z prawa odstąpienia, muszą Państwo poinformować Dealera, z którym zawarliście Umowę Rezerwacyjną (dane Dealera znajdują się w potwierdzeniu Zamówienia) – o swojej decyzji o odstąpieniu od umowy Rezerwacyjnej, w drodze oświadczenia (na przykład pisma wysłanego pocztą, faksem lub pocztą elektroniczną). Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe. Mogą Państwo również wypełnić i przesłać formularz odstąpienia od umowy lub jakiekolwiek inne jednoznaczne oświadczenie drogą elektroniczną na adres e-mail Dealera, z którym zawarliście Państwo Umowę Rezerwacyjną.</p>
        <p>Jeżeli skorzystają Państwo z tej możliwości, prześlemy Państwu niezwłocznie potwierdzenie otrzymania informacji o odstąpieniu od umowy na trwałym nośniku (na przykład pocztą elektroniczną) Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.</p>
        <br />
        <p><strong>Skutki odstąpienia od umowy:</strong></p>
        <p>W przypadku odstąpienia od niniejszej umowy otrzymają Państwo zwrot wszystkich otrzymanych od Państwa płatności, niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym Dealer został poinformowany o Państwa decyzji o wykonaniu prawa odstąpienia od Umowy Rezerwacyjnej. Zwrotu płatności dokonuje Dealer przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie. Nie poniosą Państwo żadnych opłat w związku z tym zwrotem.</p>
        <br />
        <br />
        <p><strong>ZAŁĄCZNIK NR 2</strong></p>
        <br />
        <p><strong>WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY REZERWACYJNEJ</strong></p>
        <p>(formularz ten należy wypełnić i odesłać tylko w przypadku chęci odstąpienia od Umowy Rezerwacyjnej)</p>
        <p>Nazwa, adres Dealera, u którego dokonaliście Państwo Zamówienia (dane Dealera znajdują się w potwierdzeniu Zamówienia):</p>
        <p>.............................................................................................................................................................................................</p>
        <p>Ja/My(*) niniejszym informuję/informujemy(*) o moim/naszym odstąpieniu od Umowy Rezerwacyjnej.</p>
        <p>- Data Zamówienia (data otrzymania potwierdzającego maila):</p>
        <p>...............................................................</p>
        <p>- Imię i nazwisko konsumenta(-ów) albo firma oraz NIP osoby fizycznej prowadzącej działalność gospodarczą korzystającej z ochrony konsumenckiej:</p>
        <p>.............................................................................................................................................................................................</p>
        <p>- Adres konsumenta(-ów) albo firmy osoby fizycznej prowadzącej działalność gospodarczą korzystającej z ochrony konsumenckiej:</p>
        <p>.............................................................................................................................................................................................</p>
        <p>Podpis konsumenta(-ów) albo osoby fizycznej prowadzącej działalność gospodarczą korzystającej z ochrony konsumenckiej (tylko jeżeli formularz jest przesyłany w wersji papierowej)</p>
        <p>...............................................................</p>
        <p>- Data: ...................................................</p>
        <p>(*) Niepotrzebne skreślić. </p>
      </div>
    </div>
  </div>

  <loader [visible]="loading"></loader>
</section>
