import {EventEmitter, Injectable, Output} from '@angular/core';
import {Brand, Brands} from '../models/Brands';
import {Config, ConfigModel} from '../config/config';
import {environment} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BrandService {
  private static brand: Brand = new Brand();
  private static brandList: Brand[] = null;
  public static changeBrand: EventEmitter<any> = new EventEmitter();

  public static getCurrentBrand(): Brand {
    return BrandService.brand;
  }

  public static getBrandList() {
    return BrandService.brandList;
  }

  public static setData(data: Brands) {
    BrandService.brand = data.currentBrand;
    BrandService.brandList = data.brands;
    this.changeBrand.emit(data.currentBrand);
  }

  public static setBrand(brand: Brand) {
    if (!environment.production) {
      this.brand = brand;
      this.changeBrand.emit(brand);
    }
  }

  public static getConfig(): ConfigModel {
    return Config[BrandService.getCurrentBrand().slug];
  }

  public setNewDataWithoutBrandChanging(data: Brands) {
    BrandService.brand = data.currentBrand;
    BrandService.brandList = data.brands;
  }
}
