<ul class="list list--equipment text-uppercase">
  <li class="list__item" *ngFor="let item of data">
    <span class="d-flex justify-content-between">
      <span class="list__item-name">{{item.name}}</span>
      <span class="list__item-price text-right" *ngIf="showPrices && !showGrossPrice">{{item.price | formatMoney}} zł</span>
      <span class="list__item-price text-right" *ngIf="showPrices && showGrossPrice">{{item.gross_price | formatMoney}} zł</span>
      <span class="list__item-wrap" *ngIf="!showDetailsList">
        <span class="list__item-btn icon-plus" *ngIf="item.contents && item.contents.length > 0 && !isOpenItem(item.code)"
           (click)="openItem(item.code)"></span>
        <span class="list__item-btn icon-minus" *ngIf="item.contents && item.contents.length > 0 && isOpenItem(item.code)"
           (click)="closeItem(item.code)"></span>
      </span>
    </span>

    <ng-container *ngIf="!showDetailsList">
      <ul class="sublist w-100" *ngIf="isOpenItem(item.code)">
        <li class="sublist__item" *ngFor="let eq of item.contents">
          <span class="sublist__item-name">{{eq.name}}</span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="showDetailsList">
      <ul class="sublist w-100">
        <li class="sublist__item" *ngFor="let eq of item.contents">
          <span class="sublist__item-name">{{eq.name}}</span>
        </li>
      </ul>
    </ng-container>
  </li>
  <li class="list__item" *ngIf="showPrices">
    <h3 class="h3_sum">WARTOŚĆ OPCJI DODATKOWYCH</h3>
    <span class="d-flex justify-content-between">
      <span class="list__item-name">Kwota NETTO</span>
      <span *ngIf="!showGrossPrice" class="list__item-price text-right">{{getSumNetPrice('netto') | formatMoney}} zł</span>
      <span *ngIf="showGrossPrice" class="list__item-price text-right">{{getSumGrossPrice('netto') | formatMoney}} zł</span>
      <span class="list__item-wrap"></span>
    </span>
  </li>
  <li class="list__item" *ngIf="showPrices">
    <span class="d-flex justify-content-between">
      <span class="list__item-name">Kwota BRUTTO</span>
      <span *ngIf="!showGrossPrice" class="list__item-price text-right">{{getSumNetPrice('BRUTTO') | formatMoney}} zł</span>
      <span *ngIf="showGrossPrice" class="list__item-price text-right">{{getSumGrossPrice('BRUTTO') | formatMoney}} zł</span>
      <span class="list__item-wrap"></span>
    </span>
  </li>
</ul>
