import { Pipe, PipeTransform } from '@angular/core';
import {SeoPipe} from './seo.pipe';
import {UrlPrefixPipe} from './url-prefix.pipe';
import {DomSanitizer} from '@angular/platform-browser';
import {OfferComponent} from '../modules/offer/components/offer/offer.component';

@Pipe({
  name: 'offerDescription'
})
export class OfferDescriptionPipePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    // value = this.freespeeReplaceNumbers(value);
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  /**
   * RegExp should work for:
   (61) 87-32-147
   691 355-644
   887-748-697
   +48 63 249 76 00
   15 842 52 36 wew 36 lub 40
   52 516 1000/ 52&nbsp;349 41 24
   692&nbsp;95 65 25
   795506784
   516 - 133 - 222
   - 694 401 128
   +48 667-88-1837
   +48 667-881-828
   +48 669 900 939
   669 441 140
   41 24 80 100
   22 864 27 07
   (+48) 12 378 10 00
   59-848-99-92
   42 689 72 30
   506 95 46 47
   + 48 510 990 091
   +48&nbsp;510&nbsp;994 043
   *
   * @param description
   * @private
   */
  private freespeeReplaceNumbers(description: string): string {
    const regex = RegExp('(\\+?\\(?\\+?\\d{2,}\\)?(\\s|-|\\s-\\s|&nbsp;|)\\d{2,}(\\s|-|\\s-\\s|&nbsp;|)\\d{2,}' +
      '((\\s|-|\\s-\\s|&nbsp;|)\\d+)?((\\s|-|\\s-\\s|&nbsp;|)\\d+)?)', 'g');

    const matches = description.match(regex);

    if (matches) {
      matches.forEach((value) => {
        const digits = value.replace(/\D/g, '');
        if (digits.length >= 9) {
          const phone = digits.substr(digits.length - 9);
          const shard = phone.substr(phone.length - 4).split('').reduce((a, b) => +a + +b, 0);
          const mk = '&' + jQuery.param(OfferComponent.freespeeMasterkeyData);

          // [FreeSpeeReplacedNumberSec] - hack na kilka tych samych numerów w opisie ;)
          const replacedPhone = phone.substr(0, 4) + '[FreeSpeeReplacedNumberSec]' + phone.substr(4);
          const a = document.createElement('a');
          a.setAttribute('href', 'javascript:void(0)');
          a.setAttribute('onclick', 'replaceNumber(this);');
          a.setAttribute('data-phone', replacedPhone);
          a.setAttribute('data-shard', shard.toFixed(0));
          a.setAttribute('data-mk', mk);
          a.text = '+48... (pokaż numer)';

          while (description.indexOf(value) >= 0) {
            description = description.replace(
              value,
              a.outerHTML
            );
          }

        }
      });
    }

    description = description.replace(
      /\[FreeSpeeReplacedNumberSec\]/g,
      ''
    );

    return description;
  }
}
