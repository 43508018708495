import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, EventEmitter, Output} from '@angular/core';
import {Calculation} from '../../../../models/offer/financing/financing';
import {AdobeService} from '../../../../services/adobe.service';
import {FullOffer} from '../../../../models/offer/full-offer';
import {FinancialCalculatorPluginService} from './financial-calculations-js-plugin/financial-calculator-plugin.service';
import {Subscription} from 'rxjs';
import {SessionStorageService} from '../../../../services/session-storage.service';

@Component({
  selector: 'app-financial-api-calculator',
  templateUrl: './financial-api-calculator.component.html',
})
export class FinancialApiCalculatorComponent implements OnInit, OnDestroy {
  @Input() name: string;  // Nazwa kalkulatora
  @Input() calculation: Calculation; // Kalkulacja z backendu
  @Input() offer: FullOffer; // Obiekt oferty
  @Input() addToCartBtn: boolean;
  @Input() customerType: string;
  @Input() selectedPriceType: string;

  sliders: any; // lista suwaków

  sliderValues: any; // Lista wartości przypisane do suwaków

  showNote: boolean;

  onUpdateSubscription: Subscription;
  onErrorSubscription: Subscription;

  error: boolean = false;
  timeoutCheck: number = 0;
  correctInstallment = true;
  isCalculatorTest = false;

  @Output() calculationData = new EventEmitter<any>();
  @Output() addToCart = new EventEmitter<any>();
  @Output() setSelectedPriceType = new EventEmitter<any>();

  currentExtraServices: any; // 'flex' | 'flex_premium'
  availableExtraServices: any;
  showModalFlex = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private adobeService: AdobeService,
    private SSS: SessionStorageService
  ) {
    this.sliders = [];
    this.sliderValues = {};
    this.currentExtraServices = [];
    this.availableExtraServices = [];
  }

  ngOnInit() {
    this.updateSliders();
    this.updateExtraServices();
    if (this.SSS.get('calculator-test') !== null) {
      this.isCalculatorTest = true;
    }
    // FinancialCalculatorPluginService.addOffer('calculate_' + this.offer.id, this.offer, this.isCalculatorTest);
    this.onUpdateSubscription = FinancialCalculatorPluginService.onUpdate.subscribe((data) => {
      if (data.calculation.clientType === this.name) {
        clearTimeout(this.timeoutCheck);
        this.error = false;
        this.calculation = data.calculation;
        this.updateSliders();
        this.updateExtraServices();
        this.calculationData.emit({
          data: data,
          key: this.customerType,
          name: this.name,
        });
        this.changeDetectorRef.detectChanges();
      }
    });

    this.onErrorSubscription = FinancialCalculatorPluginService.onError.subscribe((data) => {
      if (data.offerId === this.name + '_' + this.offer.id) {
        this.error = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.onUpdateSubscription.unsubscribe();
    this.onErrorSubscription.unsubscribe();
  }

  updateSliders() {
    for (let item in this.calculation.currentFields) {
      if (this.sliders.indexOf(item) === -1) {
        this.sliders.push(item);
      }

      this.sliderValues[item] = this.calculation.availableFields[item]
        .findIndex(x => x.value === this.calculation.currentFields[item].value);
    }

    this.correctInstallment = !( // FCAJFIN-24 Ograniczenie na kwotę (dotyczy tylko Abonamentów)
      (this.calculation.clientType === 'b2c' || this.calculation.clientType === 'b2b') && this.calculation.installment <= 150);
  }
  updateExtraServices() {
    if (this.calculation.clientType === 'b2c' && this.calculation.availableExtraServices && this.calculation.currentExtraServices) {
      this.availableExtraServices = this.calculation.availableExtraServices;
      this.currentExtraServices = this.calculation.currentExtraServices;
    }
  }

  getSliderLabel = function () {
    return this.label;
  };

  getSliderValuesForCalculation() {
    let result = {};
    for (let item of this.sliders) {
      result[item] = this.calculation.availableFields[item][this.sliderValues[item]].value;
    }

    return result;
  }
  getExtraServicesForCalculation() {
    let result = [];
    result = this.currentExtraServices;
    return result;
  }

  sliderChange(value, slider) {
    this.sliderValues[slider] = Number(value.value);
    this.changeDetectorRef.detectChanges();
    FinancialCalculatorPluginService.calculate(
      'calculate_' + this.offer.id,
      this.getSliderValuesForCalculation(),
      this.getExtraServicesForCalculation(),
      this.name);
    clearTimeout(this.timeoutCheck);
    this.timeoutCheck = setTimeout(() => {
      this.error = true;
    }, 3000);
  }

  triggerAdobeDTMEvent() {
    this.adobeService.offerFinancingRecalculation(
      this.sliders.period ? this.calculation.availableFields.period[this.sliderValues.period].value : 0,
      this.sliders.contribution ? this.calculation.availableFields.contribution[this.sliderValues.contribution].value : 0,
      this.calculation.priceType,
      this.sliders.repurchase ? this.calculation.availableFields.repurchase[this.sliderValues.repurchase].value : 0,
    );
  }

  currentValue () {
    this.addToCart.emit();
  }

  setExtraServices (id) {
    if (id === 'brak') {
      this.currentExtraServices = [];
    } else {
      this.currentExtraServices = [id];
    }

    this.changeDetectorRef.detectChanges();
    FinancialCalculatorPluginService.calculate(
      'calculate_' + this.offer.id,
      this.getSliderValuesForCalculation(),
      this.getExtraServicesForCalculation(),
      this.name);
    clearTimeout(this.timeoutCheck);
    this.timeoutCheck = setTimeout(() => {
      this.error = true;
    }, 3000);
  }

  showExtraServicesInfo (e) {
    e.stopPropagation();
    this.showModalFlex = true;
    setTimeout(() => {
      $('body').addClass('form-modal-open');
      $('.modal-flex').fadeIn( 'fast');
    }, 100);
  }

  changeSelectedPriceType(priceType) {
    this.sendSelectedPriceType(priceType);
  }

  switchSelectedPriceType() {
    let priceType;
    if (this.selectedPriceType === 'GROSS') {
      priceType = 'NET';
    } else {
      priceType = 'GROSS';
    }

    this.sendSelectedPriceType(priceType);
  }

  sendSelectedPriceType(priceType) {
    this.setSelectedPriceType.emit({priceType: priceType});
  }

  protected readonly Math = Math;
}
