import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;

@Component({
  selector: 'app-select-checkbox-list',
  templateUrl: './select-checkbox-list.component.html',
})
export class SelectCheckboxListComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  isOptionListOpened = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
    this.value = null;
  }

  ngOnInit() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(): string {
    return this.icon;
  }

  public setValue(value: string): void {
    let newVal: string = null;
    if (value !== null) {
      for (const item of value.split(':;')) {
        for (const av of this.availableValues) {
          if (av.key === item) {
            if (newVal !== null) {
              newVal = item;
            } else {
              newVal += ':;' + item;
            }
          }
        }
      }
    }
    this.value = newVal;
  }

  public getValue(): string {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = null;
      this.emitUpdateEvent();
    } else {
      this.addNewValueOrDeleteWhenIsSelected(value);
      this.emitUpdateEvent();
    }
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };
    this.update.emit(updateObject);
  }

  private addNewValueOrDeleteWhenIsSelected(value: string): void {
    if (this.isSelected(value)) {
      const array: Array<string> = this.value.split(':;');
      array.splice(array.indexOf(value, 0), 1);
      if (array.length === 0) {
        this.value = null;
      } else {
        this.value = array.join(':;');
      }
    } else {
      if (this.value === null) {
        this.value = value;
      } else {
        this.value += ':;' + value;
      }
    }
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
    this.isOptionListOpened = false;
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    for (const val of this.value.split(':;')) {
      if (val === value) {
        return true;
      }
    }

    return false;
  }

  public implodeValues() {
    let s = '';
    for (const val of this.availableValues) {
      if (this.isSelected(val.key)) {
        s = s + val.value + ', ';
      }
    }
    return s.substr(0, s.length - 2);
  }
}
