import {Price} from './price/price';
import {Dealer} from './dealer/dealer';
import {Equipment} from './equipment/equipment';
import {TechnicalData} from './technical-data/technical-data';
import {Color} from './color/color';
import {Interior} from './interior/interior';
import {Model} from './model/model';
import {Version} from './version/version';
import {Series} from './series/series';
import {Brand} from './brand/brand';
import {Financing} from './financing/financing';
import {AdobeConfiguration} from './adobe-configuration';
import {FinancialProducts} from './financing/financial-products';

export class FullOffer {
  uid: string;
  id: number;
  price: Price;
  images: string[];
  equipment: Equipment;
  description: string;
  dealer: Dealer;
  status: string;
  sincom: string;
  technicalData: TechnicalData;
  countOfVisits: number;
  // financing: Array<Financing>;
  financing: Array<any> | any;
  financial_products: Array<FinancialProducts>;
  labels: any[];
  serialNo: string;
  productionYear: number;
  color: Color;
  interior: Interior;
  model: Model;
  version: Version;
  series: Series;
  brand: Brand;
  visitCount: number;
  adobeConfiguration: AdobeConfiguration;
  leasing_installment: any;
  videos: any[];
  is_km0: boolean;
  vin: string;
  registrationDate: string;

  public constructor() {
    this.price = new Price();
    this.equipment = new Equipment();
    this.dealer = new Dealer();
    this.technicalData = new TechnicalData();
    this.color = new Color();
    this.interior = new Interior();
    this.model = new Model();
    this.version = new Version();
    this.series = new Series();
    this.brand = new Brand();
  }
}
