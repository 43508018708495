import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SelectComponent} from './select/select.component';
import {PriceSelectComponent} from './price-select/price-select.component';
import {PriceFieldComponent} from './price-field/price-field.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {EquipmentSelectComponent} from './equipment-select/equipment-select.component';
import {ModelVersionSelectComponent} from './model-version-select/model-version-select.component';
import {ModelVersionSelectWithColorComponent} from './model-version-select-with-color/model-version-select-with-color.component';
import {MultipleSelectComponent} from './multiple-select/multiple-select.component';
import {SelectCheckboxListComponent} from './select-checkbox-list/select-checkbox-list.component';
import {SelectDropdownComponent} from './select-dropdown/select-dropdown.component';
import {ColorSelectComponent} from './color-select/color-select.component';
import {InputComponent} from './input/input.component';
import { LocationSelectComponent } from './location-select/location-select.component';
import {DistanceSelectComponent} from './location-select/distance-select/distance-select.component';
import { ModelLengthSelectComponent } from './model-length-select/model-length-select.component';
import { ModelHeightSelectComponent } from './model-height-select/model-height-select.component';
import {MatSliderModule} from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        MatSliderModule,
    ],
  declarations: [
    CheckboxComponent,
    ColorSelectComponent,
    EquipmentSelectComponent,
    ModelVersionSelectComponent,
    ModelVersionSelectWithColorComponent,
    MultipleSelectComponent,
    SelectCheckboxListComponent,
    SelectDropdownComponent,
    PriceSelectComponent,
    PriceFieldComponent,
    SelectComponent,
    InputComponent,
    LocationSelectComponent,
    DistanceSelectComponent,
    ModelLengthSelectComponent,
    ModelHeightSelectComponent
  ],
  exports: [
    CheckboxComponent,
    ColorSelectComponent,
    EquipmentSelectComponent,
    ModelVersionSelectComponent,
    ModelVersionSelectWithColorComponent,
    MultipleSelectComponent,
    SelectCheckboxListComponent,
    SelectDropdownComponent,
    PriceSelectComponent,
    PriceFieldComponent,
    SelectComponent,
    InputComponent,
    LocationSelectComponent,
    ModelLengthSelectComponent,
    ModelHeightSelectComponent,
  ],
  entryComponents: [
    CheckboxComponent,
    ColorSelectComponent,
    EquipmentSelectComponent,
    ModelVersionSelectComponent,
    ModelVersionSelectWithColorComponent,
    MultipleSelectComponent,
    SelectCheckboxListComponent,
    SelectDropdownComponent,
    PriceSelectComponent,
    PriceFieldComponent,
    SelectComponent,
    InputComponent,
    LocationSelectComponent,
    ModelLengthSelectComponent,
    ModelHeightSelectComponent,
  ]
})
export class FormModule { }
