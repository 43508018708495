import { Pipe, PipeTransform } from '@angular/core';
import {BrandService} from '../services/brand.service';
import {AppComponent} from '../app.component';

declare let window: any;

@Pipe({
  name: 'defaultImage'
})
export class DefaultImagePipe implements PipeTransform {

  public static getDefaultImage(offerId: string): string {
    return AppComponent.getCDNBaseUrl() + '/uploads/' + BrandService.getCurrentBrand().code + '/' + offerId + '/default.jpg';
  }

  transform(value: any, args?: any): any {
    if (value === null) {
      return DefaultImagePipe.getDefaultImage(args.toString());
    } else {
      return value;
    }
  }

}
