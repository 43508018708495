import {EventEmitter, Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';
import {BrandService} from './brand.service';
import {StockApiService} from './stock-api.service';
import {BasicOffer} from '../models/offer/basic-offer';
import {AppComponent} from '../app.component';
import {ErrorHandler} from '../tools/error-handler';

@Injectable({
  providedIn: 'root'
})
export class ObservedOfferService {
  private static STORAGE_KEY = 'observed-offers';
  private static STORAGE_DATA_KEY = 'observed-offers-data';

  private offers: Array<string> = [];
  update: EventEmitter<Array<string>> = new EventEmitter();

  constructor(private storage: LocalStorageService,
              private stockApiService: StockApiService) {
    if (BrandService.getCurrentBrand().slug) {
      this.getOffers();
    } else {
      const subscription = BrandService.changeBrand.subscribe(value => {
        this.getOffers();
      });
    }
  }

  public getOffers() {
    this.offers = this.storage.get(ObservedOfferService.STORAGE_KEY, []);
  }

  addOffer(id: any): void {
    this.getOffers();
    id = id.toString();
    if (!this.isObserved(id)) {
      this.stockApiService.getSimpleOffersData(id).then(value => {
        if (value && value.length > 0) {
          this.offers.push(id);
          const data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
          data['offer-' + id] = value[0];
          this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, data);
          this.updateStorage();
        }
      }).catch(reason => {
        ErrorHandler.log(reason);
      });
    }
  }

  removeOffer(id: any): void {
    id = id.toString();
    if (this.isObserved(id)) {
      const data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
      delete data['offer-' + id];
      this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, data);
      this.offers = this.offers.filter(el => el !== id);
      this.updateStorage();
    }
  }

  changeObservedStatus(id: any) {
    id = id.toString();
    if (this.isObserved(id)) {
      this.removeOffer(id);
    } else {
      this.addOffer(id);
    }
  }

  getAllOffers(dealerCode: string = null): Array<string> {
    if (dealerCode !== null && AppComponent.isDealerStock) {

      const dealerOffers = [];
      const data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});

      for (const item of this.offers) {
        if (data['offer-' + item] && (data['offer-' + item] as BasicOffer).dealer_code === dealerCode) {
          dealerOffers.push(item);
        }
      }
      return dealerOffers;
    } else {
      return this.offers;
    }
  }

  isObserved(id: any): boolean {
    if (id) {
      return this.offers.indexOf(id.toString()) > -1;
    } else {
      // Usunięcie starych ofert (bez uid) z obserwowanych
      this.storage.set(ObservedOfferService.STORAGE_KEY, []);
      this.storage.set(ObservedOfferService.STORAGE_DATA_KEY, {});
    }
  }

  private updateStorage() {
    if (this.offers.length > 0) {
      this.storage.set(ObservedOfferService.STORAGE_KEY, this.offers);
    } else {
      this.storage.remove(ObservedOfferService.STORAGE_KEY);
    }
    this.update.emit(this.offers);
  }

  getOfferData(id: any): BasicOffer {
    const data = this.storage.get(ObservedOfferService.STORAGE_DATA_KEY, {});
    if (data['offer-' + id]) {
      return data['offer-' + id];
    }

    return null;
  }
}
