import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {ReCaptcha2Component} from 'ngx-captcha';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
})
export class CaptchaComponent implements OnInit {

  @Input() siteKey: string;
  @Input() sendEvent: Observable<any>;
  public formGroup: FormGroup;
  @Output() changeResponse: EventEmitter<object> = new EventEmitter();
  @ViewChild('captchaFormElem') captchaFormElem: ReCaptcha2Component;


  constructor(
      private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    this.sendEvent.subscribe((val) => {
      this.captchaFormElem.reloadCaptcha();
      this.changeResponse.emit(null);
    });
  }


  setCaptchaResponse(captchaResponse: any) {
    this.changeResponse.emit(captchaResponse);
  }

  handleExpire() {
    this.captchaFormElem.resetCaptcha();
    this.changeResponse.emit(null);
  }
}
