import { Pipe, PipeTransform } from '@angular/core';
import {SeoPipe} from './seo.pipe';
import {UrlPrefixPipe} from './url-prefix.pipe';
import {BrandService} from '../services/brand.service';

@Pipe({
  name: 'shippingFeeGross'
})
export class ShippingFeeGrossPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (BrandService.getCurrentBrand().code === '77') {
      if (value === 406 || value === 407) {
        return 500;
      }
      return Math.round(value * 1.23);
    }
    return value;
  }
}
