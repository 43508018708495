/// <reference types="@types/googlemaps" /> // Don't remove this line!!! It isn't normal comment!

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
declare var google: any;
declare type Autocomplete = google.maps.places.Autocomplete;
declare type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;


@Component({
  selector: 'app-location-select',
  templateUrl: './location-select.component.html',
})
export class LocationSelectComponent implements OnInit {
  static selectedAfterChange: EventEmitter<boolean> = new EventEmitter();
  @Input() value: string;
  @Input() errors: any = [];

  @Output() update: EventEmitter<any> = new EventEmitter();

  private element: HTMLInputElement;
  private autocomplete: Autocomplete;

  constructor(
    private elRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.element = $(this.elRef.nativeElement).find('input')[0] as HTMLInputElement;

    $(window).on('keyup', this.element, (event) => {
      if (this.element.value.length <= 2) {
        $('body').addClass('pac-container-hidden');
      } else {
        $('body').removeClass('pac-container-hidden');
      }
    });

    this.autocomplete = new google.maps.places.Autocomplete(this.element);
    this.autocomplete.setComponentRestrictions({'country': ['pl']});
    this.autocomplete.setTypes(['(cities)']);
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const placeResult = this.autocomplete.getPlace();
      this.value = JSON.stringify({
        place_id: placeResult.place_id,
        lat: placeResult.geometry.location.lat(),
        lng: placeResult.geometry.location.lng()
      });
      this.changeDetectorRef.detectChanges();
      if (placeResult.address_components) {
        this.emitUpdateEvent();
      }
    });

    if (this.value !== undefined && this.value !== null) {
      this.setValue(this.value);
    }
  }

  findMe($event) {
    navigator.geolocation.getCurrentPosition((val) => {
      this.setValueByLatLng(val.coords.latitude, val.coords.longitude);
    });
  }

  setValue(value: any): void {
    value = JSON.parse(value);
    const placeRequest = {placeId: value.place_id};
    const placeService = new google.maps.places.PlacesService(this.element);
    placeService.getDetails(placeRequest, (placeResult) => {
      this.value = JSON.stringify({
        place_id: placeResult.place_id,
        lat: placeResult.geometry.location.lat(),
        lng: placeResult.geometry.location.lng()
      });
      $(this.element).val(this.value);
      this.changeDetectorRef.detectChanges();
      this.emitUpdateEvent();
    });
  }

  setValueByLatLng(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({'location':  {lat: lat, lng: lng}}, (results) => {
      for (const res of results) {
        if (res.types.indexOf('administrative_area_level_3') !== -1) {
          this.value = JSON.stringify({
            place_id: res.place_id,
            lat: lat,
            lng: lng
          });
          this.changeDetectorRef.detectChanges();
          this.emitUpdateEvent();
          break;
        }
      }
    });
  }

  private emitUpdateEvent() {
    this.update.emit(this.value);
  }

  clear() {
    this.value = null;
    $(this.element).val('');
    this.emitUpdateEvent();
  }
}
