<div class="stock-select stock-select--preferred-contact no-icon"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <div class="select-selection-placeholder">
      {{label}}
    </div>
    <div class="select-selection-label" *ngIf="value !== null">
      {{getValueToDisplay(value)}}
    </div>
    <div class="select-selection-label" *ngIf="value === null">
      {{nullValue}}
    </div>
    <div class="select-selection-icons">
      <b *ngIf="isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="!isOptionListOpened" class="arrow arrow--up"></b>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.value)" [ngClass]="{'selected': value !== null && value.toString() === item.value.toString()}">
        <div class="option__item">
          {{item.label}}
        </div>
      </div>
    </div>
  </div>
</div>
