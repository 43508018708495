<div class="stock-select {{class}}"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null && !isFocusedInput, 'no-icon': icon == null}">
  <div class="select-selection" (click)="openOrCloseOptionList($event)">
    <i class="icon {{icon}}" [ngClass]="{icon: icon !== null}" *ngIf="icon !== null"></i>
    <div class="select-selection-label">
      <input type="text" (keydown)="onKeyDownInput($event)" (keyup)="onKeyUpInput($event)" (blur)="onBlurInput($event)"
             (focus)="onFocusInput()" class="select-selection-label-input" value="{{initialDisplayValue}}">
    </div>
    <div class="select-selection-icons">
      <b *ngIf="value !== null" (click)="onUpdate($event, null)" class="remove"><i class="icon icon-x"></i></b>
      <b *ngIf="value == null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
      <b *ngIf="value == null && !isOptionListOpened" class="arrow arrow--up"></b>
    </div>
  </div>
  <div class="select-options">
    <div class="select-option-list" *ngIf="isOptionListOpened">
      <div *ngFor="let item of availableValues" class="option" (click)="onUpdate($event, item.key)"
           [hidden]="isHiddenValue(item.value)"
           [ngClass]="{'selected': isSelected(item.key)}">
        <div class="option__item">
          <input class="option__checkbox" type="checkbox" [checked]="isSelected(item.key)">
          <div class="option__label">
                        <span class="option__check">
                            <i class="icon icon-check"></i>
                        </span>
            <span class="option__text">{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
