export class TechnicalData {
  vehicleType: string;
  fuelType: string;
  door: string;
  seats: string;
  engineCapacity: number;
  engineType: string;
  enginePower: number;
  averageFuelConsumption: string;
  bodyType: string;
  driveType: string;
  gearboxType: string;
  gears: number;
  equipmentLevel: string;
  emissionStandard: string;
  co2Emission: string;
}
