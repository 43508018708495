import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import 'bootstrap';
import {BrandService} from '../../services/brand.service';
import {Brand} from '../../models/Brands';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../../services/local-storage.service';
import { Subject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-brand-select',
  templateUrl: './brand-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrandSelectComponent implements OnInit {

  brandList: Brand[];
  loading$ = new Subject();

  constructor(
      private LSS: LocalStorageService
  ) {
    this.brandList = BrandService.getBrandList();
  }

  ngOnInit() {
      $('#selectBrand').on('shown.bs.modal', (e) => {
          const element = document.getElementById('selectBrandBtn'),
              leftPos = element.getBoundingClientRect().left + window.scrollX;

          $('.modal--select-brand .modal-content').css('padding-right', $(window).width() - leftPos - 50);
          $('.modal--select-brand .modal-dialog').css('width', ($(window).width() - leftPos - 50) + 377);
          setTimeout(() => {
            this.loading$.next({ isIntersecting: true });
          }, 500);
      });
  }

  changeBrand(slug, $event) {
    if (environment.version === 'dev') {
      $event.preventDefault();
      this.LSS.setGlobal('brand', slug);
      document.location.href = '/';
    }
  }
}
