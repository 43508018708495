<ng-container *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'">
  <section class="section section--cart-offer mt-4 mt-md-5 mt-lg-2 ">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="headline-wrap">
            <div class="headline">
              <h3 class="headline__title text-uppercase">Twój Koszyk</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-center mt-5" *ngIf="cartIsEmpty">
          <h1>Koszyk jest pusty</h1>
        </div>
        <div class="col-lg-6 col-data px-0" *ngIf="offer && offer.id">
          <div class="container container-half container-half-lg-left h-100 d-flex flex-column">
            <div class="row justify-content-center h-100">
              <div class="col-sm-8 col-md-6 col-lg-12">
                <div class="row h-100">
                  <div class="col-12">
                    <div class="headline headline--model-name headline--transparent w-100">
                      <h1 class="headline__title">{{ offer.model.name }}</h1>
                      <h2 class="headline__subtitle">{{ offer.version.name }}</h2>
                      <div class="financial-parameters">
                        <div class="financial-parameter-row">
                          <div class="financial-parameter-key">OFERTA NR:</div>
                          <div class="financial-parameter-value">{{ offer.id }}</div>
                        </div>
                        <div class="financial-parameter-row">
                          <div class="financial-parameter-key">Cena brutto:</div>
                          <div class="financial-parameter-value"><span>{{ offer.price.final.brutto | formatMoney }} <small>zł</small></span></div>
                        </div>
                      </div>
                      <h1 class="headline__title mt-3">Parametry finansowania</h1>
                      <ng-container *ngIf="calculationData && calculationData['currentFields']">
                        <h2 class="headline__subtitle">{{ calculationData['title'] }}</h2>
                        <div class="financial-parameters">
                          <div class="financial-parameter-row" *ngIf="!correctInstallment">
                            <div class="installment-alert my-1">
                              Parametry kalkulacji tego produktu nie obejmują poniższych ustawień
                            </div>
                          </div>
                          <ng-container *ngFor="let field of orderFields">
                            <ng-container *ngFor="let item of calculationData['currentFields'] | keyvalue">
                              <ng-container *ngIf="field === item.key">
                                <div class="financial-parameter-row">
                                  <div class="financial-parameter-key" [innerHTML]="setParameterKey(item.key)"></div>
                                  <div class="financial-parameter-value" [innerHTML]="setParameterValue(item.key, item.value)"></div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <div class="financial-parameter-row" *ngIf="correctInstallment">
                            <div class="financial-parameter-key">Miesięczna rata: </div>
                            <div class="financial-parameter-value"><span>{{ calculationData['installment'] | formatMoney}} <small>zł/mies</small></span></div>
                          </div>
                          <div class="financial-parameter-link" (click)="openCalculatorBox()">Zmień parametry finansowania</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-gallery">
          <div class="gallery">
            <div class="s-wrapper swiper swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
              <div class="swiper-wrapper">
                <div class="swiper-slide ng-star-inserted swiper-slide-active">
                  <picture *ngIf="offer && offer.images && offer.images[0]">
                    <source media="(min-width: 1600px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white">
                    <source media="(min-width: 1440px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=844&h=564&fit=crop&bg=white">
                    <source media="(min-width: 1200px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=759&h=507&fit=crop&bg=white">
                    <source media="(min-width: 992px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=631&h=422&fit=crop&bg=white">
                    <source media="(min-width: 768px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=769&h=514&fit=crop&bg=white">
                    <source media="(min-width: 576px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=591&h=395&fit=crop&bg=white">
                    <source media="(min-width: 480px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=437&h=292&fit=crop&bg=white">
                    <source media="(min-width: 360px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=446&h=298&fit=crop&bg=white">
                    <img src="{{ offer.images[0] }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white" class="box-model__photo-img"
                         (error)="setDefaultImg800x600($event)" alt="{{ offer.model.name }} {{ offer.version.name }}"/>
                  </picture>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <section class="section section--more-info" *ngIf="offer">
    <div class="container">
      <div class="row my-5">
        <div class="col-md-11 col-lg-3">
          <div class="headline headline--dealer text-uppercase">
            <h2 class="headline__title">Zamów on-line</h2>
          </div>
        </div>
        <div class="col-md-11 col-lg-9 pl-lg-0">
          <div class="form form-sol">
            <div class="px-lg-2 offset-lg-1">
              <div class="form__field--sol offset-md-0">
                <p *ngIf="errorText.length > 0">{{errorText}}<br /><br /></p>
                <div class="sol-cart-checkbox">
                  <div class="sol-cart-checkbox__option selected" (click)="checkboxAkcept = !checkboxAkcept"  [ngClass]="{'selected': checkboxAkcept}">
                    <span class="sol-cart-checkbox__check">
                        <i class="icon icon-check"></i>
                    </span>
                    <span class="sol-cart-checkbox__label">
                      Akceptuję
                    </span>
                  </div>
                  <span>
                  <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix" target="_blank">regulamin usługi</a> i&nbsp;<a [routerLink]="'/regulamin-platformy' | addUrlPrefix" target="_blank">regulamin platformy</a>
                  </span>
                </div>
                <button *ngIf="!solLoader && correctInstallment && checkboxAkcept" (click)="sendSolData()" class="cta-sol">PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</button>
                <button *ngIf="!solLoader && !correctInstallment" class="cta-sol cta-sol-disabled">PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</button>
                <button *ngIf="!solLoader && correctInstallment && !checkboxAkcept" class="cta-sol cta-sol-disabled">PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</button>
                <div *ngIf="brandSlug === 'alfa-romeo'" class="cta-sol-order-for-cash">
                  <button *ngIf="!solLoader && correctInstallment && checkboxAkcept" (click)="openOrCloseForm(true)" class="cta-sol">ZAMAWIAM ZA GOTÓWKĘ</button>
                  <button *ngIf="!solLoader && !checkboxAkcept" class="cta-sol cta-sol-disabled">ZAMAWIAM ZA GOTÓWKĘ</button>
                </div>
                <p *ngIf="solLoader">Trwa wysyłanie danych, proszę czekać</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal--calculator fade" tabindex="-1" role="dialog" aria-labelledby="newsletterLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered w-auto" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
          <span class="close" data-dismiss="modal" aria-label="Zamknij">
              <span class="icon-x"></span>
          </span>
          </div>
          <div class="modal-body">
            <div class="container">

              <div class="financing row" *ngIf="!loading && solData['calculation']">
                <div class="financing__box col-12">
                  <div class="financing__box-wrap">
                    <app-financial-api-calculator
                      [name]="solData['calculatorSlug']"
                      [calculation]="calculationData"
                      [offer]="offer"
                      [customerType]="'b2b'"
                      [addToCartBtn]="false"
                      (calculationData)="setValue($event)"
                      (setSelectedPriceType)="setSelectedPriceType($event)"
                      [selectedPriceType]="selectedPriceType"
                    ></app-financial-api-calculator>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section section--more-info-cart" *ngIf="offer">
    <div class="container">
      <div class="row my-5">
        <div class="col-md-11 col-lg-3">
          <div class="headline headline--details text-uppercase">
            <h2 class="headline__title" [ngClass]="{'offer-details': offerDetails}" (click)="showOfferDetails()">
              <span *ngIf="!offerDetails">Szczegóły zamówienia</span>
              <span *ngIf="offerDetails">Lokalizacja samochodu</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </h2>
            <div class="headline__info" *ngIf="offer.dealer === null">
              <small>Samochód dostępny na placu centralnym FCA Poland i&nbsp;dostępny u&nbsp;każdego dealera w&nbsp;Polsce</small>
            </div>
            <div class="headline__info" *ngIf="offer.dealer.subdealer !== true && offerDetails">
              <p class="headline__info--dealer-name">
                {{offer.dealer.name}}
              </p>
              <p><em>{{offer.dealer.street}}<br />
                {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em>
              </p>
            </div>
            <div class="headline__info d-block d-lg-none" *ngIf="offer.dealer.subdealer === true && offerDetails">
              <p class="headline__info--dealer-name">
                {{offer.dealer.name}}
              </p>
              <p><em>{{offer.dealer.street}}<br />
                {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-11 col-lg-9">
          <div class="cart-details-info" *ngIf="offerDetails">
            <div class="cart-details-item">
              <div>
                <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
                  <h3 class="headline__title">Informacje</h3>
                </div>
                <div class="hidden-box hidden-box--white" [attr.data-hidden]="hiddenText.technicalData" data-opt="technicalData">
                  <ul class="list text-uppercase">
                    <li class="list__item" *ngIf="offer.dealer !== null">
                      Lokalizacja
                      <span class="list__item-value">{{offer.dealer.city}}, {{offer.dealer.region}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.dealer === null">
                      Lokalizacja
                      <span class="list__item-value">U KAŻDEGO DEALERA W POLSCE</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.engineCapacity && offer.technicalData.engineCapacity != '0.0' && offer.technicalData.engineCapacity != 0.0">
                      POJEMNOŚĆ SKOKOWA
                      <span class="list__item-value">{{offer.technicalData.engineCapacity}} CM<sup>3</sup></span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.enginePower && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                      MOC SILNIKA
                      <span class="list__item-value">{{offer.technicalData.enginePower}} KM</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.averageFuelConsumption && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                      ŚREDNIE SPALANIE
                      <span class="list__item-value">{{offer.technicalData.averageFuelConsumption}} L/100KM</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.co2Emission && offer.technicalData.co2Emission != 'N/A' && offer.technicalData.co2Emission != 'NA'">
                      EMISJA CO<sub>2</sub>
                      <span class="list__item-value">{{offer.technicalData.co2Emission}} G/KM</span>
                    </li>
                    <li class="list__item" *ngIf="offer.productionYear">
                      ROK PRODUKCJi
                      <span class="list__item-value">{{offer.productionYear}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.color.name && offer.color.name != '-'">
                      KOLOR NADWOZIA
                      <span class="list__item-value">{{offer.color.name}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.interior.name && offer.interior.name != '******************************'">
                      RODZAJ TAPICERKI
                      <span class="list__item-value">{{offer.interior.name}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.fuelType && offer.technicalData.fuelType != 'nieokreślony'">
                      RODZAJ PALIWA
                      <span *ngIf="brandSlug !== 'alfa-romeo'" class="list__item-value">{{offer.technicalData.fuelType}}</span>
                      <span *ngIf="brandSlug === 'alfa-romeo'" class="list__item-value">{{offer.technicalData.fuelType.replace('Miękka hybryda benzynowa', 'Hybryda')}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.gearboxType">
                      SKRZYNIA BIEGÓW
                      <span class="list__item-value">{{offer.technicalData.gearboxType}}</span>
                    </li>
                    <li class="list__item" *ngIf="offer.technicalData.driveType">
                      NAPĘD
                      <span class="list__item-value">{{offer.technicalData.driveType}}</span>
                    </li>
                  </ul>
  <!--                <span class="link link&#45;&#45;more" (click)="hiddenText.technicalData=false">POKAŻ WSZYSTKO</span>-->
  <!--                <span class="link link&#45;&#45;less" (click)="hiddenText.technicalData=true">ZWIŃ</span>-->
                </div>
              </div>
            </div>
            <div class="cart-details-item">
              <div *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0">
                <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
                  <h3 class="headline__title">Wyposażenie dodatkowe<br /><small>(wliczone w cenę NETTO/BRUTTO)</small></h3>
                </div>
                <div class="hidden-box" [attr.data-hidden]="hiddenText.equipmentOptional" data-opt="equipmentOptional">
                  <app-equipment-list [data]="offer.equipment.optional" [showPrices]="true"></app-equipment-list>
                  <span class="link link--more" (click)="hiddenText.equipmentOptional=false">POKAŻ WSZYSTKO</span>
                  <span class="link link--less" (click)="hiddenText.equipmentOptional=true">ZWIŃ</span>
                </div>
              </div>
              <div *ngIf="offer.description && offer.description.length > 0">
                <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
                  <h3 class="headline__title">Opis</h3>
                </div>
                <div class="hidden-box" [attr.data-hidden]="hiddenText.description" data-opt="description">
                  <div class="description" [innerHTML]="offer.description"></div>
                  <span class="link link--more" (click)="hiddenText.description=false">POKAŻ WSZYSTKO</span>
                  <span class="link link--less" (click)="hiddenText.description=true">ZWIŃ</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<!--  NEW LAYOUT (FIAT)-->
<ng-container *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'">
  <section class="section section--cart-offer">
    <ng-container *ngIf="offer && offer.id">
      <section class="section section--offer-section-1">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-lg-6 col-data">
              <div class="headline row headline-main">
                <h3 class="headline__title text-uppercase">Twój Koszyk</h3>
              </div>
              <div class="container d-flex flex-column px-0">
                <div class="row justify-content-center h-100">
                  <div class="col-sm-8 col-lg-12">
                    <div class="row h-100">
                      <div class="col-12">
                        <div class="headline headline--model-name">
                          <h1 class="headline__title">{{ offer.model.name }}</h1>
                          <h2 class="headline__subtitle">{{ offer.version.name }}</h2>
                        </div>
                        <div class="financial-parameters">
                          <div class="financial-parameter-row">
                            <small class="financial-parameter-key">OFERTA NR:</small>
                            <span class="financial-parameter-value">{{ offer.id }}</span>
                          </div>
                          <div class="financial-parameter-row">
                            <small class="financial-parameter-key">CENA BRUTTO:</small>
                            <span class="financial-parameter-value"><span>{{ offer.price.final.brutto | formatMoney }} <small>ZŁ</small></span></span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="headline headline--model-name">
                          <h1 class="headline__title">Parametry finansowania</h1>
                          <h2 class="headline__subtitle"  *ngIf="calculationData && calculationData['title']">{{ calculationData['title'] }}</h2>
                        </div>
                        <ng-container *ngIf="calculationData && calculationData['currentFields']">
                          <div class="financial-parameters">
                            <div class="financial-parameter-row" *ngIf="!correctInstallment">
                              <div class="installment-alert my-1">
                                Parametry kalkulacji tego produktu nie obejmują poniższych ustawień
                              </div>
                            </div>
                            <ng-container *ngFor="let field of orderFields">
                              <ng-container *ngFor="let item of calculationData['currentFields'] | keyvalue">
                                <ng-container *ngIf="field === item.key">
                                  <div class="financial-parameter-row">
                                    <div class="financial-parameter-key" [innerHTML]="setParameterKey(item.key)"></div>
                                    <div class="financial-parameter-value" [innerHTML]="setParameterValue(item.key, item.value)"></div>
                                  </div>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <div class="financial-parameter-row" *ngIf="correctInstallment">
                              <div class="financial-parameter-key">Miesięczna rata: </div>

                              <ng-container *ngIf="brandSlug === 'fiat'">
                                <ng-container *ngIf="calculationData['priceType'] === selectedPriceType">
                                  <div class="financial-parameter-value"><span>{{ calculationData['installment'] | formatMoney}} <small>zł/mies</small></span></div>
                                </ng-container>
                                <ng-container *ngIf="calculationData['priceType'] !== selectedPriceType">
                                  <ng-container *ngIf="selectedPriceType === 'NET'">
                                    <div class="financial-parameter-value"><span>{{ calculationData['installment'] / 1.23 | formatMoney}} <small>zł/mies</small></span></div>
                                  </ng-container>
                                  <ng-container *ngIf="selectedPriceType === 'GROSS'">
                                    <div class="financial-parameter-value"><span>{{ calculationData['installment'] * 1.23 | formatMoney}} <small>zł/mies</small></span></div>
                                  </ng-container>
                                </ng-container>



                              </ng-container>
                              <ng-container *ngIf="brandSlug !== 'fiat'">
                                <div class="financial-parameter-value"><span>{{ calculationData['installment'] | formatMoney}} <small>zł/mies</small></span></div>
                              </ng-container>

                            </div>
                            <div class="financial-parameter-link" (click)="openCalculatorBox()">Zmień parametry finansowania</div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-gallery">
              <div class="gallery">
                <div class="s-wrapper swiper swiper-container swiper-container-fade swiper-container-initialized swiper-container-horizontal">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide ng-star-inserted swiper-slide-active">
                      <picture *ngIf="offer && offer.images && offer.images[0]">
                        <source media="(min-width: 1600px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white">
                        <source media="(min-width: 1440px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=844&h=564&fit=crop&bg=white">
                        <source media="(min-width: 1200px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=759&h=507&fit=crop&bg=white">
                        <source media="(min-width: 992px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=631&h=422&fit=crop&bg=white">
                        <source media="(min-width: 768px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=769&h=514&fit=crop&bg=white">
                        <source media="(min-width: 576px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=591&h=395&fit=crop&bg=white">
                        <source media="(min-width: 480px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=437&h=292&fit=crop&bg=white">
                        <source media="(min-width: 360px)" srcset="{{ offer.images[0] }}?fm=pjpg&w=446&h=298&fit=crop&bg=white">
                        <img src="{{ offer.images[0] }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white" class="box-model__photo-img"
                             (error)="setDefaultImg800x600($event)" alt="{{ offer.model.name }} {{ offer.version.name }}"/>
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section--offer-section-2">
        <div class="container container-form-sol">
          <div class="headline">
            <h2 class="headline__title">Zamów on-line</h2>
          </div>
          <div class="form form-sol">
            <div class="form__field--sol offset-md-0">
              <p *ngIf="errorText.length > 0">{{errorText}}<br /><br /></p>
              <div class="sol-cart-checkbox">
                <div class="sol-cart-checkbox__option selected" (click)="checkboxAkcept = !checkboxAkcept"  [ngClass]="{'selected': checkboxAkcept}">
                    <span class="sol-cart-checkbox__check">
                        <i class="icon icon-check"></i>
                    </span>
                  <span class="sol-cart-checkbox__label">
                      Akceptuję
                    </span>
                </div>
                <span>
                  <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix" target="_blank">regulamin usługi</a> i&nbsp;<a [routerLink]="'/regulamin-platformy' | addUrlPrefix" target="_blank">regulamin platformy</a>
                  </span>
              </div>
              <button *ngIf="!solLoader && correctInstallment && checkboxAkcept" (click)="sendSolData()" class="cta-sol"><span>PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</span></button>
              <button *ngIf="!solLoader && !correctInstallment" class="cta-sol cta-sol-disabled"><span>PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</span></button>
              <button *ngIf="!solLoader && correctInstallment && !checkboxAkcept" class="cta-sol cta-sol-disabled"><span>PRZECHODZĘ DO WNIOSKU O FINANSOWANIE</span></button>
              <p *ngIf="solLoader">Trwa wysyłanie danych, proszę czekać</p>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="container-grid">
            <div class="container-grid--informations">
              <div class="headline">
                <h2 class="headline__title offer-details-btn" [ngClass]="{'offer-details': offerDetails}" (click)="showOfferDetails()">Informacje
                  <svg class="bi-sol bi-chevron-double-down" fill="currentColor" height="35" viewBox="0 0 16 16" width="35" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill-rule="evenodd"></path>
                    <path d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" fill-rule="evenodd"></path>
                  </svg>
                </h2>
              </div>
              <div *ngIf="offerDetails" class="list">
                <div class="list__item" *ngIf="offer.dealer !== null">
                  <div class="list__item-key">Lokalizacja</div>
                  <div class="list__item-value">{{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, {{offer.dealer.region}}</ng-container></div>
                </div>
                <div class="list__item" *ngIf="offer.dealer === null">
                  <div class="list__item-key">Lokalizacja</div>
                  <div class="list__item-value">U KAŻDEGO DEALERA W POLSCE</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.engineCapacity && offer.technicalData.engineCapacity != '0.0' && offer.technicalData.engineCapacity != 0.0">
                  <div class="list__item-key">POJEMNOŚĆ SKOKOWA</div>
                  <div class="list__item-value">{{offer.technicalData.engineCapacity}} CM<sup>3</sup></div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.enginePower && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                  <div class="list__item-key">MOC SILNIKA</div>
                  <div class="list__item-value">{{offer.technicalData.enginePower}} KM</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.averageFuelConsumption && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                  <div class="list__item-key">ŚREDNIE SPALANIE</div>
                  <div class="list__item-value">{{offer.technicalData.averageFuelConsumption}} L/100KM</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.co2Emission && offer.technicalData.co2Emission != 'N/A' && offer.technicalData.co2Emission != 'NA'">
                  <div class="list__item-key">EMISJA CO<sub>2</sub></div>
                  <div class="list__item-value">{{offer.technicalData.co2Emission}} G/KM</div>
                </div>
                <div class="list__item" *ngIf="offer.productionYear">
                  <div class="list__item-key">ROK PRODUKCJi</div>
                  <div class="list__item-value">{{offer.productionYear}}</div>
                </div>
                <div class="list__item" *ngIf="offer.color.name && offer.color.name != '-'">
                  <div class="list__item-key">KOLOR NADWOZIA</div>
                  <div class="list__item-value">{{offer.color.name}}</div>
                </div>
                <div class="list__item" *ngIf="offer.interior.name && offer.interior.name != '******************************'">
                  <div class="list__item-key">RODZAJ TAPICERKI</div>
                  <div class="list__item-value">{{offer.interior.name}}</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.fuelType && offer.technicalData.fuelType != 'nieokreślony'">
                  <div class="list__item-key">RODZAJ PALIWA</div>
                  <div class="list__item-value">{{offer.technicalData.fuelType}}</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.gearboxType">
                  <div class="list__item-key">SKRZYNIA BIEGÓW</div>
                  <div class="list__item-value">{{offer.technicalData.gearboxType}}</div>
                </div>
                <div class="list__item" *ngIf="offer.technicalData.driveType">
                  <div class="list__item-key">NAPĘD</div>
                  <div class="list__item-value">{{offer.technicalData.driveType}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="offerDetails" class="container-grid--offer-details">
              <div class="offer-details--equipment-optional" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0">
                <div class="offer-details-box" [ngClass]="{'open-box': openBox.equipmentStandard}">
                  <div class="offer-details-box-button" (click)="openBox.equipmentStandard = !openBox.equipmentStandard"><span>Wyposażenie standardowe</span></div>
                  <div class="offer-details-box-content">
                    <div class="offer-details-box-headline">
                    </div>
                    <app-equipment-list
                      [data]="offer.equipment.standard"
                      [showPrices]="false"
                      [showDetailsList]="true"></app-equipment-list>
                  </div>
                </div>
              </div>
              <div class="offer-details--equipment-optional" *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0">
                <div class="offer-details-box" [ngClass]="{'open-box': openBox.equipmentOptional}">
                  <div class="offer-details-box-button" (click)="openBox.equipmentOptional = !openBox.equipmentOptional"><span>Wyposażenie dodatkowe</span></div>
                  <div class="offer-details-box-content">
                    <div class="offer-details-box-headline">
                      <strong>Wyposażenie dodatkowe</strong><br />
                      (wliczone w cenę netto/brutto)
                    </div>
                    <app-equipment-list
                      [data]="offer.equipment.optional"
                      [showPrices]="true"
                      [showGrossPrice]="brandSlug === 'fiat'"
                      [showDetailsList]="true"></app-equipment-list>
                  </div>
                </div>
              </div>
              <div class="offer-details--desc" *ngIf="offer.description && offer.description.length > 0">
                <div class="offer-details-box" [ngClass]="{'open-box': openBox.dealerDescription}">
                  <div class="offer-details-box-button-no-arrow"><span>Opis oferty</span></div>
                  <div class="offer-details-box-content open-box">
                    <div class="offer-details-box-content--desc"
                         [innerHTML]="offer.description"
                         [ngClass]="{'less-desc': hiddenText.description}"></div>
                    <div class="link link--more" *ngIf="hiddenText.description"><span (click)="hiddenText.description=false">POKAŻ WIĘCEJ</span></div>
                  </div>
                </div>
              </div>
              <div class="info-km0 mx-0" *ngIf="offer.is_km0">
                Samochód nowy, zarejestrowany, bez przebiegu.
              </div>
            </div>
          </div>
        </div>
        <div class="modal modal--calculator fade" tabindex="-1" role="dialog" aria-labelledby="newsletterLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg modal-dialog-centered w-auto" role="document">
            <div class="modal-content">
              <div class="modal-header border-0">
          <span class="close" data-dismiss="modal" aria-label="Zamknij">
              <span class="icon-x"></span>
          </span>
              </div>
              <div class="modal-body">
                <div class="container">

                  <div class="financing row" *ngIf="!loading && solData['calculation']">
                    <div class="financing__box col-12">
                      <div class="financing__box-wrap">
                        <app-financial-api-calculator
                          [name]="solData['calculatorSlug']"
                          [calculation]="calculationData"
                          [offer]="offer"
                          [customerType]="'b2b'"
                          [addToCartBtn]="false"
                          (calculationData)="setValue($event)"
                          (setSelectedPriceType)="setSelectedPriceType($event)"
                          [selectedPriceType]="selectedPriceType"
                        ></app-financial-api-calculator>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section section--offer-section-3">
        <div class="container">
          <div class="row">
            <div class="col-md-11 col-lg-4" *ngIf="offer.dealer === null">
              <div class="headline headline--dealer">
                <h2 class="headline__title">Lokalizacja</h2>
              </div>
              <div class="headline__info w-100 mt-lg-0">
                <small>Samochód dostępny na placu centralnym FCA Poland i&nbsp;dostępny u&nbsp;każdego dealera w&nbsp;Polsce</small>
              </div>
            </div>

            <div class="col-md-11 col-lg-4" *ngIf="offer.dealer !== null">
              <div class="headline headline--dealer">
                <h2 class="headline__title">Sprzedawca</h2>
              </div>
            </div>
            <div class="col-md-11 col-lg-8 px-0 px-sm-4">
              <div class="headline__info w-100 mt-lg-0" *ngIf="offer.dealer.subdealer !== true">
                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon--show-map">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                    </svg>
                    Pokaż na mapie
                  </a>
                </p>

                <!--                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">-->
                <!--                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>-->
                <!--                  </svg>-->
                <!--                  +48 ... <small>POKAŻ NUMER</small>-->
                <!--                </div>-->
                <!--                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">-->
                <!--                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">-->
                <!--                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>-->
                <!--                    </svg>-->
                <!--                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>-->
                <!--                </div>-->
              </div>
              <div class="headline__info d-block d-lg-none" *ngIf="offer.dealer.subdealer === true">
                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon--show-map">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                    </svg>
                    Pokaż na mapie
                  </a>
                </p>

                <!--                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">-->
                <!--                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>-->
                <!--                  </svg>-->
                <!--                  +48 ... <small>POKAŻ NUMER</small>-->
                <!--                </div>-->
                <!--                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">-->
                <!--                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">-->
                <!--                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                <!--                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>-->
                <!--                    </svg>-->
                <!--                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>-->
                <!--                </div>-->
                <div class="headline__communicators"
                     *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                  <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                  <div *ngFor="let item of offer.dealer.communicators">
                    <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                    <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                  </div>
                </div>
              </div>
              <div class="dealer-all-offer w-100">
                <a [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="AppComponent.isDealerStock === false">
                  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
                  </svg>
                  <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
  </section>
</ng-container>

<section class="section section--more-info">
  <div class="modal-form" *ngIf="brandSlug === 'alfa-romeo' && orderForCash.modalFormIsOpen && offer.dealer !== null">
    <div class="modal-form-dialog">
      <div class="modal-header border-0">
                  <span aria-label="Zamknij" (click)="openOrCloseForm(false)">
                    <span class="icon-x"></span>
                  </span>
      </div>
      <div class="section-offer--form">
        <app-form [cta]="'sol-cash-sell'"
                  [dealer]="offer.dealer.code + '.' + offer.dealer.sitecode"
                  [model_id]="offer.model.commercialCode"
                  [vin]="offer.vin"
                  [adobeThankyouPageData]="adobeThankyouPageData"
                  [brand_slug]="offer.brand.slug"
                  [offer_id]="offer.id"
                  [is_km0]="offer.is_km0"
                  [showAgreementDescription]="false"
        ></app-form>
      </div>
    </div>
  </div>
</section>
<loader [visible]="loading"></loader>
