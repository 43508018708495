let version = '0';

// get build hash from styles filename - add this to assets
if ($('link').length > 0) {
  const name = $($('link')[0]).attr('href');
  const start = name.indexOf('.') + 1;
  const end = name.indexOf('.', start + 1);

  if (start !== -1 && end !== -1) {
    version = name.substr(start, end - start);
  }
}

export const environment = {
  production: false,
  version: version.toString(),
  APIBaseURL: '/api/',
  CDNBaseURL: 'https://cdn.fcapoland.pl',
  multiBrand: false,
  financialCalculations: {
    apiUrl: 'https://sfs.fcapoland.pl/',
    wsProtocol: 'wss://',
    wsHost: 'sfs.fcapoland.pl',
    wsPort: 443,
    wsPath: '/ws',
    wsSupport: true,
    isProduction: false,
    isDebugMode: true,
  },
  isSol: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
