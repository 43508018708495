import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EmptyModalComponent} from '../../../modals/empty-modal/empty-modal.component';

@Component({
  selector: 'app-form-agreement',
  templateUrl: './agreement.component.html',
})
export class AgreementComponent implements OnInit {

  @Input() data;
  @Input() errors: string[] = [];
  @Input() brandSlug: string = null;
  @Input() form;
  @Input() showAgreementDescription: boolean;
  value = '-1';
  @Output() valueChange: EventEmitter<object> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.valueChange.emit({name: this.data.inputs[0].name, value: this.value});
  }

  selectValue(name, value) {
    this.errors = [];
    this.value = value;
    this.valueChange.emit({name, value});
  }

  openInfo() {
    EmptyModalComponent.openModal(this.data.content_after);
  }

  openAgreementDescription(id) {
    const elem = $('.' + id);
    if (elem.hasClass('accordion-agreement--is-open')) {
      elem.removeClass('accordion-agreement--is-open');
    } else {
      elem.addClass('accordion-agreement--is-open');
    }
  }

  agreementPrivacyModal(e) {
    if (e.target.href) {
      if (e.target.href.includes('#privacy-notes')) {
        e.preventDefault();
        EmptyModalComponent.openModal(this.form.disclaimer.information.content['privacy-notes'], true);
      }
    }
  }
}
