import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-distance-select',
  templateUrl: './distance-select.component.html',
})
export class DistanceSelectComponent implements OnInit {
  @Input() value: any = 50;
  @Input() availableValues: Array<any> = [50, 100, 150, 200, 250, 300];
  isOptionListOpened = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
  }

  ngOnInit() {
    if (this.availableValues.indexOf(+this.value) === -1) {
      this.value = 50;
      setTimeout(() => {
        this.emitUpdateEvent();
      }, 150);
    }
  }

  public setValue(value: any): void {
    this.value = value;
    this.closeOptionList();
    this.emitUpdateEvent();
  }

  public onUpdate(value: any): void {
    this.setValue(value);
    this.closeOptionList();
  }

  private emitUpdateEvent() {
    this.update.emit(this.value);
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }

  closeOptionList() {
    this.isOptionListOpened = false;
  }
}
