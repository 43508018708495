import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StockApiService} from '../../services/stock-api.service';
import {AppComponent} from '../../app.component';
import {AdobeService} from '../../services/adobe.service';
import {BlueKaiService} from '../../services/blue-kai.service';
import {TitleService} from '../../services/title.service';
import {BrandService} from '../../services/brand.service';
import {EmptyModalComponent} from '../../modals/empty-modal/empty-modal.component';
import {ErrorHandler} from '../../tools/error-handler';

declare let window: any;

@Component({
  selector: 'app-terms-of-services',
  templateUrl: './terms-of-service.component.html',
})
export class TermsOfServiceComponent implements OnInit {
  loading = true;
  AppComponent = AppComponent;
  brandSlug = '';
  siteData = window.sitedata;

  constructor(private stockApiService: StockApiService,
              private adobeService: AdobeService,
              private blueKaiService: BlueKaiService,
              private titleService: TitleService,
              private changeDetectorRef: ChangeDetectorRef,
              ) {
    this.titleService.setTitleObserved();
    window.scrollTo(0, 0);
    this.loading = false;
  }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page page-sol-terms';
      this.adobeService.pageLoadObservedOffers();
      this.blueKaiService.changeRoute();
    });
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  openPrivacyNotes() {
    $('body').addClass('cursor-progress');
    this.stockApiService.getPrivacyNotes().then(value => {
      EmptyModalComponent.openModal(value, true);
      $('body').removeClass('cursor-progress');
    }, reason => {
      $('body').removeClass('cursor-progress');
      ErrorHandler.handle(reason);
    });
  }
}
