import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalsComponent} from './modals.component';
import {BrandSelectComponent} from './brand-select/brand-select.component';
import {CompareModalComponent} from './compare-modal/compare-modal.component';
import {NewsletterFormComponent} from './newsletter-form/newsletter-form.component';
import {FormModule} from '../form/form.module';
import {AppComponentsModule} from '../app-components/app-components.module';
import { EmptyModalComponent } from './empty-modal/empty-modal.component';
import { ShareComponent } from './share/share.component';
import {ShareButtonModule} from '@ngx-share/button';
import {ToolsModule} from '../tools/tools.module';
import {LazyLoadImageModule, scrollPreset} from 'ng-lazyload-image';

@NgModule({
    imports: [
        CommonModule,
        FormModule,
        AppComponentsModule,
        ShareButtonModule,
        ToolsModule,
        LazyLoadImageModule.forRoot({
          preset: scrollPreset
        })
    ],
  declarations: [
    ModalsComponent,
    BrandSelectComponent,
    CompareModalComponent,
    NewsletterFormComponent,
    EmptyModalComponent,
    ShareComponent,
  ],
  exports: [
    ModalsComponent,
    BrandSelectComponent,
    CompareModalComponent,
    NewsletterFormComponent,
  ],
  entryComponents: [
    ModalsComponent,
    BrandSelectComponent,
    CompareModalComponent,
    NewsletterFormComponent,
  ]
})
export class ModalsModule { }
