import {environment} from '../../environments/environment';
import * as Raven from 'raven-js';

export class ErrorHandler {
  static handle(error: any) {
    if (!error.stack || !(/exactag.js/.test(error.stack))) {
      if (environment.production) {
        Raven.captureException(error);
      } else {
        console.log(error);
      }
    }
  }

  static log(error: any) {
    if (!environment.production) {
      console.log(error);
    }
  }
}
