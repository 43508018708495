import {ApplicationRef, Component, ComponentFactoryResolver, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CdkPortal, DomPortalHost} from '@angular/cdk/portal';

@Component({
  selector: 'app-tracking-debugger',
  templateUrl: './tracking-debugger.component.html',
  styleUrls: ['./tracking-debugger.component.scss']
})
export class TrackingDebuggerComponent implements OnInit {
  public static data = [];
  public static actions = {};
  public static emitEvent: EventEmitter<any>;
  public static TYPE_FIRED = 'Fired';
  public static TYPE_SUCCESS = 'Success';
  public static TYPE_WARNING = 'Warning';
  public static TYPE_ERROR = 'Error';

  @Input() open: EventEmitter<any>;
  @ViewChild(CdkPortal) portal: CdkPortal;

  public classReference = TrackingDebuggerComponent;
  private externalWindow = null;
  private filters = {
    'services': []
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector) {
    TrackingDebuggerComponent.emitEvent = new EventEmitter<any>();
    TrackingDebuggerComponent.emitEvent.subscribe((data) => {
      if (TrackingDebuggerComponent.actions[data.service]) {
        TrackingDebuggerComponent.actions[data.service].push(data.action);
      } else {
        TrackingDebuggerComponent.actions[data.service] = [data.action];
      }
      TrackingDebuggerComponent.data.push(data);
    });
  }

  openWindow() {
    if (this.externalWindow) {
      this.externalWindow.close();
    }
    this.externalWindow = window.open('', '', 'width=1200,height=400');
    document.querySelectorAll('link, style').forEach(htmlElement => {
      this.externalWindow.document.head.appendChild(htmlElement.cloneNode(true));
    });

    const title = this.externalWindow.document.createElement('title');
    title.innerText = 'Tracking Debugger';
    this.externalWindow.document.head.appendChild(title);
    this.externalWindow.document.body.style = 'margin: 0;';

    const style = this.externalWindow.document.createElement('style');
    style.innerHTML = `
      .ngx-json-viewer {
        height: auto !important;
      }
    `;
    this.externalWindow.document.head.appendChild(style);

    const host = new DomPortalHost(
      this.externalWindow.document.body,
      this.componentFactoryResolver,
      this.applicationRef,
      this.injector
    );

    host.attach(this.portal);
  }

  ngOnInit() {
    this.open.subscribe(() => {
      this.openWindow();
    });

    window.onbeforeunload = () => this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.externalWindow.close();
  }

  isVisible(data: any) {
    if (this.filters.services.length > 0) {
      return (this.filters.services.indexOf(data.service) > -1);
    }

    return true;
  }

  isServiceSelected(service: string) {
    return (this.filters.services.indexOf(service) > -1);
  }

  changeService(service: string) {
    if (this.isServiceSelected(service)) {
      this.filters.services.splice(this.filters.services.indexOf(service), 1);
    } else {
      this.filters.services.push(service);
    }
  }

  clearFilters() {
    this.filters.services = [];
  }

  clearHistory() {
    this.filters.services = [];
    TrackingDebuggerComponent.data = [];
    TrackingDebuggerComponent.actions = {};
  }
}
