import {EventEmitter, Injectable} from '@angular/core';
import * as FCP from './financialCalculatorPlugin.min';
import {environment} from '../../../../../../environments/environment';
import {FullOffer} from '../../../../../models/offer/full-offer';
import {BrandService} from '../../../../../services/brand.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialCalculatorPluginService {

  private static fcp: any;
  public static onUpdate: EventEmitter<any> = new EventEmitter();
  public static onError: EventEmitter<any> = new EventEmitter();

  private static timeoutHandle: number = 0;
  private static clientType = 'b2b';

  public static init() {
    // Zabezpieczenie przed podwójną inicjalizacją połączeń WS
    // (zmiana oferty = usunięcie obiektów wcześniej procesowanych ofert z kalkulacji)
    if (this.fcp && this.fcp.wsConnection && this.fcp.wsConnection.readyState === WebSocket.OPEN) {
      this.fcp.wsConnection.close();
      this.fcp = null;
    }

    // Inicjalizacja biblioteki
    this.fcp = new FCP.FinancialCalculatorPlugin({
      calculatorApi: environment.financialCalculations.apiUrl,
      wsProtocol: environment.financialCalculations.wsProtocol,
      wsHost: environment.financialCalculations.wsHost,
      wsPort: environment.financialCalculations.wsPort,
      wsPath: environment.financialCalculations.wsPath,
      wsSupport: environment.financialCalculations.wsSupport,
      wsAutoConnect: true,
      wsAutoReconnect: true,
      wsAutoReconnectOnRequest: true,
      reconnectInterval: 5000,
      brandSlug: BrandService.getCurrentBrand().slug,
      websiteId: 'salon',
      isProduction: environment.financialCalculations.isProduction,
      isDebugMode: environment.financialCalculations.isDebugMode,
    });

    // Event — detekcja danych
    this.fcp.onUpdateOfferDetection = (callback, offerObject) => {
      this.onUpdate.emit({offerId: offerObject.getId(), calculation: offerObject.getCalculations()});
    };

    // Event — aktualizacja danych
    this.fcp.onUpdateOfferCalculation = (callback, offerObject, clientType) => {
      this.onUpdate.emit({offerId: offerObject.getId(), calculation: offerObject.getCalculationByType(clientType)});
    };

    // Event — błąd
    this.fcp.onErrorOfferDetection = (callback, offerObject) => {
      this.onError.emit({offerId: offerObject.getId()});
    };

    // Event — błąd
    this.fcp.onErrorOfferCalculation = (callback, offerObject) => {
      this.onError.emit({offerId: offerObject.getId()});
    };
  }

  public static addOffer(offerId: string, offer: FullOffer, withCalculationParams)
  {
    // dodanie oferty do procesowania

    if (this.fcp.wsConnection.readyState === 0) {
      setTimeout(() => {
        this.fcp.attachOffer(
          offerId,
          {
            'modelName': offer.model.name,
            'modelCode': offer.model.commercialCode,
            'versionName': offer.version.name,
            'productionYear': offer.productionYear,
            'grossPrice': offer.price.final.brutto,
            'netPrice': offer.price.final.netto,
            'baseNetPrice': offer.price.base.netto,
            'baseGrossPrice': offer.price.base.brutto,
            'versionId': offer.sincom,
            'fuelType': offer.technicalData.fuelType,
            'status': offer.is_km0 ? 'km0' : 'new'
          },
          null, // init fields
        );

        if (withCalculationParams) {
          this.fcp.setWithCalculationParams(true);
        }

        this.fcp.detectNow();
      }, 500);
    } else {
      this.fcp.attachOffer(
        offerId,
        {
          'modelName': offer.model.name,
          'modelCode': offer.model.commercialCode,
          'versionName': offer.version.name,
          'productionYear': offer.productionYear,
          'grossPrice': offer.price.final.brutto,
          'netPrice': offer.price.final.netto,
          'baseNetPrice': offer.price.base.netto,
          'baseGrossPrice': offer.price.base.brutto,
          'versionId': offer.sincom,
          'fuelType': offer.technicalData.fuelType,
          'status': offer.is_km0 ? 'km0' : 'new'
        },
        null, // init fields
      );

      if (withCalculationParams) {
        this.fcp.setWithCalculationParams(true);
      }

      this.fcp.detectNow();
    }
  }

  public static calculate(offerId: string, fields: object, extraServices: object, clientType: string)
  {
    // wysłanie żądania kalkulacji dla oferty
    this.fcp.updateOfferCalculationById(offerId, clientType, fields, extraServices);

    // Auto zamykanie połączenia WS po 15s nieaktywności użytkownika w zakresie kalkulacji raty
    clearTimeout(this.timeoutHandle);
    this.timeoutHandle = setTimeout(() => {
      this.fcp.wsConnection.close();
    }, 15000);
  }
}
