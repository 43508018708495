import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ComparedOfferService} from '../../services/compared-offer.service';
import {StockApiService} from '../../services/stock-api.service';
import {BasicOffer} from '../../models/offer/basic-offer';
import {AppComponent} from '../../app.component';
import {AdobeService} from '../../services/adobe.service';
import {ErrorHandler} from '../../tools/error-handler';
import {BlueKaiService} from '../../services/blue-kai.service';
import {TitleService} from '../../services/title.service';
import {LabelProviderService} from '../../services/labelProvider.service';
import {BrandService} from '../../services/brand.service';
import {FullOffer} from '../../models/offer/full-offer';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TrackingService} from '../../services/tracking.service';
import {NewsletterService} from '../../services/newsletter.service';
import * as Raven from 'raven-js';

declare let window: any;

@Component({
  selector: 'app-compared-offers',
  templateUrl: './compared-offers.component.html',
})
export class ComparedOffersComponent implements OnInit {
  offersBasic: Array<BasicOffer> = [];
  offers: Array<FullOffer> = [];
  loading = true;
  AppComponent = AppComponent;
  brandSlug = '';
  showDifferentClass = false;
  freespeeDealerPhoneNumber = [null, null];
  siteData = window.sitedata;

  constructor(public comparedOfferService: ComparedOfferService,
              public newsletterService: NewsletterService,
              private stockApiService: StockApiService,
              private adobeService: AdobeService,
              private blueKaiService: BlueKaiService,
              private titleService: TitleService,
              private labelProviderService: LabelProviderService,
              private changeDetectorRef: ChangeDetectorRef,
              ) {
    this.titleService.setTitleObserved();

    if (comparedOfferService.getAllOffers().length === 2) {

      for (const item of comparedOfferService.getAllOffers()) {
        this.stockApiService.getOfferData(parseInt(item, 10)).then(value => {
          value = this.labelProviderService.fillOfferLabels(value);
          this.offers.push(value);
        }).catch(reason => {
          comparedOfferService.removeOffer(parseInt(item, 10));
          ErrorHandler.log(reason);
          this.loading = false;
        });

        this.isFinally();
      }
    } else {
      this.loading = false;
    }
  }

  isFinally() {
    if (this.offers.length === 2) {
      this.loading = false;
    } else {
      setTimeout(() => {
        this.isFinally();
      }, 200);
    }
  }

  isActive(id: any) {
    id = +id;

    for (const offer of this.offers) {
      if (offer.id === id) {
        return true;
      }
    }

    return false;
  }

  removeObservedOffers() {
    for (const id of this.comparedOfferService.getAllOffers()) {
      this.comparedOfferService.removeOffer(id);
    }
  }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page compare-page';

      // this.adobeService.pageLoadObservedOffers();
      // this.blueKaiService.changeRoute();
    });
    this.brandSlug = BrandService.getCurrentBrand().slug;
  }

  showDifferent() {
    this.showDifferentClass = !this.showDifferentClass;
  }

  showPhone(offer, key) {
    const phone = offer.dealer.phones[0];

    if (phone) {
      this.freespeeDealerPhoneNumber[key] = phone;
      this.changeDetectorRef.detectChanges();
    //   window.__fs_dncs_instance.getNumber(
    //     (ref, res) => {
    //       this.freespeeDealerPhoneNumber[key] = '+48' + res.e164.substr(res.e164.length - 9);
    //       this.changeDetectorRef.detectChanges();
    //       // tracking();
    //     },
    //     null,
    //     phone.substr(phone.length - 9),
    //     'fcausedportalpl',
    //     phone.substr(phone.length - 4).split('').reduce((a, b) => +a + +b, 0),
    //     '&' + jQuery.param(this.getFreeSpeeData(offer)),
    //     null
    //   );
    // } else {
    //   throwError('Dealer phone not found!');
    }

  }

  getFreeSpeeData(offer): object {
    const getDeviceType = () => {
      const ua = navigator.userAgent;
      if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
      }
      if (
        /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
      ) {
        return 'mobile';
      }
      return 'desktop';
    };

    let MASTERKEYType_Codbrand = offer.brand.name.toUpperCase();

    if (offer.brand.code === '83') {
      MASTERKEYType_Codbrand = 'ALFAROMEO';
    } else if (offer.brand.code === '77') {
      MASTERKEYType_Codbrand = 'FIATPRO';
    }


    if (!this.siteData.freespee_data.campaign_name
      || !this.siteData.freespee_data.campaign_id
      || !this.siteData.freespee_data.offer_id
      || !this.siteData.freespee_data.level1
      || !this.siteData.freespee_data.level2
      || !this.siteData.freespee_data.level3
      || !this.siteData.freespee_data.level4
      || !this.siteData.freespee_data.start_date
      || !this.siteData.freespee_data.end_date
      || !this.siteData.freespee_data.source
    ) {
      Raven.setUserContext({
        'freespee_data': this.siteData.freespee_data,
        'page_url': location.href
      });
      Raven.captureException(new Error('Phone MK data not provided or incomplete'));
      console.error('Phone MK data not provided or incomplete');
    }

    return {
      custnr: offer.dealer.code + '.' + offer.dealer.sitecode,
      custname: offer.dealer.name.substr(0, 60),
      MandateCode: offer.dealer.code,
      OutletCode: offer.dealer.sitecode,
      MasterKey: this.siteData.freespee_data.campaign_name + ' - SALES',
      MASTERKEYType_Codbrand,
      MASTERKEYType_Codmarket: '3123',
      MASTERKEYType_Language: 'D',
      MASTERKEYType_Offer: this.siteData.freespee_data.offer_id || '209', // Domyślny OID '209', wartość wymagana przez Link [FCASTOCK3-410]
      MASTERKEYType: 'PH',
      MASTERKEYType_Campaign: this.siteData.freespee_data.campaign_id,
      MASTERKEYType_Level1: this.siteData.freespee_data.level1,
      MASTERKEYType_Level2: this.siteData.freespee_data.level2,
      MASTERKEYType_Level3: this.siteData.freespee_data.level3,
      MASTERKEYType_Level4: this.siteData.freespee_data.level4,
      MASTERKEYType_MBA: '1',
      MASTERKEYType_Campaign_Desc: this.siteData.freespee_data.campaign_name,
      MASTERKEYType_CampaignStart: this.siteData.freespee_data.start_date,
      MASTERKEYType_CampaignEnd: this.siteData.freespee_data.end_date,
      CAMPAIGNType_Source: offer.brand.code === '77' ? 'PP' : 'PS',
      CAMPAIGNType: 'LX',
      CAMPAIGNDETAILType_codmarket: '3123',
      CAMPAIGNDETAILType_codbrand: offer.brand.code,
      CAMPAIGNDETAILType_coddealer: offer.dealer.code,
      CAMPAIGNDETAILType_Coddealerlocation: offer.dealer.sitecode,
      CAMPAIGNDETAILType_Language: 'D',
      MK_CampaignSource: this.siteData.freespee_data.source,
      PRODUCTType_CODMODEL: offer.model.code,
      PRODUCTType_DESCRIPTION: offer.brand.name + ' ' + offer.model.name,
      PRODUCTType_BRAND: offer.brand.name,
      PRODUCTType_VIN: offer.vin,
      PRODUCTType_CONDITION: 'new',
      PROMOType_LASTREFERRER: (TrackingService.isOpenclPerformanceCookieEnabled() ? this.siteData.freespee_data.referer : ''),
      PROMOType_DEVICEUSED: getDeviceType(),
      PROMOType_PAGEURL: btoa(location.href),
    };
  }


}
