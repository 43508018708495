export class Equipment {
  standard: Array<EquipmentItem>;
  optional: Array<EquipmentItem>;
}

export class EquipmentItem {
  code: string;
  name: string;
  price: number;
  gross_price: number;
  contents: Array<EquipmentContentItem>;
}

export class EquipmentContentItem {
  code: string;
  name: string;
}
