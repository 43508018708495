<section class="section section--info-error">
  <div class="container">
    <div class="row">
      <div class="headline-wrap col-6 col-lg-4 offset-lg-0 col-xl-3">
        <div class="headline">
          <h3 class="headline__title">BŁĄD 404</h3>
        </div>
      </div>
      <div class="col-12 col-lg-8 col-xl-9 py-5 my-3">
        <h1>Nie znaleziono takiej strony</h1>
        <p class="intro">Przepraszamy. Strona, której szukasz nie została odnaleziona. Prawdopodobnie została skasowana, zmieniono jej nazwę albo została czasowo usunięta. Sprawdź poprawność adresu lub zajrzyj na <a [routerLink]="'' | addUrlPrefix">stronę główną</a>.</p>
      </div>
    </div>
  </div>
</section>
