import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMoney'
})
export class FormatMoneyPipe implements PipeTransform {

  static format(value) {
    return Math.ceil(Number(value)).toLocaleString('fr-FR');
  }

  transform(value: any, args?: any): any {
    return FormatMoneyPipe.format(value);
  }
}
