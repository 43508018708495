<script src="../color-select/color-select.component.spec.ts"></script>
<div class="stock-select {{class}}"
     *ngIf="availableValues !== undefined"
     [attr.data-open]="isOptionListOpened"
     [ngClass]="{'selected': value !== null, 'no-icon': icon == null}">
    <div class="select-selection" (click)="openOrCloseOptionList($event)">
        <i class="icon {{icon}}" [ngClass]="{icon: icon !== null}" *ngIf="icon !== null"></i>
        <div class="select-selection-label" *ngIf="value !== null">
            {{implodeValues()}}
        </div>
        <div class="select-selection-label" *ngIf="value === null">
            {{nullValue}}
        </div>
        <div class="select-selection-icons">
            <b *ngIf="value !== null" (click)="onUpdate(null)" class="remove"><i class="icon icon-x"></i></b>
            <b *ngIf="value == null && isOptionListOpened"> <i class="arrow arrow--down"></i></b>
            <b *ngIf="value == null && !isOptionListOpened" class="arrow arrow--up"></b>
        </div>
    </div>
    <div class="select-options">
        <div class="select-option-list" *ngIf="isOptionListOpened">
            <div *ngFor="let item of availableValues" class="option" (click)="onUpdate(item.key)"  [ngClass]="{'selected': isSelected(item.key)}">
                <div class="option__item">
                    <input class="option__checkbox" type="checkbox" [checked]="isSelected(item.key)">
                    <div class="option__label">
                        <span class="option__check">
                            <i class="icon icon-check"></i>
                        </span>
                        {{item.value}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
