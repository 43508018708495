import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-cta-select',
  templateUrl: './cta-select-component.html'
})
export class CtaSelectComponent implements OnInit {

  @Input() name: string;
  @Input() value: string;
  @Input() data;

  @Output() update = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    this. emitUpdateEvent(this.name, this.value);
  }

  public onUpdate(name: string, value: string): void {
    if (value === null) {
      this.value = value;
      this.emitUpdateEvent(name, value);
    } else {
      this.value = value;
      this.emitUpdateEvent(name, value);
    }
  }

  private emitUpdateEvent(name, value) {
    this.update.emit({name, value});
  }
}
