export class Dealer {
  name: string;
  email: string;
  street: string;
  post_code: string;
  phones: string[] = [];
  code: string;
  sitecode: string;
  rrdi: string;
  city: string;
  region: string;
  coordinates: Coordinates;
  website: string;
  openingHours = [];
  subdealer: boolean;
  communicators: any[];
}

export class Coordinates {
  longitude: number;
  latitude: number;
}
