import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-empty-modal',
  templateUrl: './empty-modal.component.html',
})
export class EmptyModalComponent implements OnInit {
  static content: string;
  static autoWidth = false;

  classReference = EmptyModalComponent;

  constructor() {
  }

  public static openModal(content: string, autoWidth: boolean = false) {
    this.content = content;
    this.autoWidth = autoWidth;

    $('.modal--empty').show(300, () => {
      $('.modal--empty').modal('show');
    });
  }

  ngOnInit() {
  }
}
