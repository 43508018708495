<div class="pre-reservation-checkbox">
  <div class="pre-reservation-checkbox__option" (click)="onUpdate(value)"  [ngClass]="{'selected': isSelected(value)}">
    <span class="pre-reservation-checkbox__check">
        <i class="icon icon-check"></i>
    </span>
    <span class="pre-reservation-checkbox__label">{{ label }}</span>
  </div>
  <div class="pre-reservation-checkbox__info">
    <p>Wstępna rezerwacja nie jest tożsama z&nbsp;zamówieniem samochodu.</p>
  </div>
</div>
