<ng-container *cdkPortal>
  <div>
    <div class="row m-0 w-100 navigator">
      <button class="filter-btn" *ngFor="let item of classReference.actions | keyvalue" [ngClass]="{active: isServiceSelected(item.key)}" (click)="changeService(item.key)">{{item.key}}</button>
      <button class="clear-btn" (click)="clearFilters()">Clear filters</button>
      <button class="clear-btn" (click)="clearHistory()">Clear history</button>
    </div>
    <div class="row m-0 empty"></div>
    <ng-container *ngFor="let item of classReference.data">
      <div class="row m-0" *ngIf="isVisible(item)"
           [ngClass]="{
         'bg-fired': item.type === classReference.TYPE_FIRED,
         'bg-success': item.type === classReference.TYPE_SUCCESS,
         'bg-warning': item.type === classReference.TYPE_WARNING,
         'bg-error': item.type === classReference.TYPE_ERROR
         }">
        <div class="col-2 p-0">
          <code><b>TYPE:</b> {{ item.type }}</code>
        </div>
        <div class="col-3 p-0">
          <code><b>SERVICE:</b> {{ item.service }}</code>
        </div>
        <div class="col-4 p-0">
          <code><b>ACTION:</b> {{ item.action }}</code>
        </div>
        <div class="col-12 p-0">
          <ngx-json-viewer [json]="{data: item.data}" [expanded]="false"></ngx-json-viewer>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
