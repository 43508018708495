import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
})
export class ShareComponent implements OnInit {
  static offerPhoto = '/';
  public ShareComponent = ShareComponent;
  constructor() {
  }

  public static openModal() {
    $('.modal--share').show(300, () => {
      $('.modal--share').modal('show');
      if ($('.swiper-wrapper .box-model__photo-img')[0]) {
        this.offerPhoto = $('.swiper-wrapper .box-model__photo-img')[0].src;
      }
    });
  }

  ngOnInit() {
  }
}
