<div class="box-model align-items-end d-flex" [routerLink]="!isActive && isOffersLoaded ? null : item | offerUrl" *ngIf="observedOffers.isObserved(item.uid)" [ngClass]="{'is-disabled': !isActive && isOffersLoaded}">
  <div *ngIf="brandSlug === 'alfa-romeo' && additionalProtection" class="one-year-protected one-year-protected one-year-protected_offer">
    <img src="/assets/img/brands/alfa-romeo/3rd-year-protection.png" alt="Dodatkowy rok gwarancji"/>
  </div>
  <div class="box-model__photo">
    <div class="label-wrap" [ngClass]="{'fiat-label-five-year-warranty': brandSlug === 'fiat' && ['3562'].indexOf(item.modelCode) > -1}">
      <app-offer-labels [offer]="item"></app-offer-labels>
    </div>

    <app-picture [offer]="item" *ngIf="item.version !== null" [toAlt]="brandName + ' ' + item.model + ' ' + item.version + ' ' + item.engineType"></app-picture>
    <app-picture [offer]="item" *ngIf="item.version === null" [toAlt]="brandName + ' ' + item.model + ' ' + item.engineType"></app-picture>
    <div class="label label--reservation text-uppercase" *ngIf="item.status === 'reserved'">Rezerwacja</div>
  </div>
  <div class="box-model__info d-flex">
    <div class="box-model__information text-uppercase w-100 text-center" *ngIf="!isActive && isOffersLoaded">Oferta nieaktualna</div>
    <ul class="box-model__nav d-flex">
      <li class="box-model__nav-item my-2">
        <span class="box-model__nav-icon icon-camera"></span><span class="label-quantity">{{ item.countOfImage }}</span>
      </li>
      <li class="box-model__nav-item my-2" *ngIf="item.countOfVideos > 0">
        <span class="box-model__nav-icon icon-video"><span class="label-quantity">{{ item.countOfVideos }}</span></span>
      </li>
      <li class="box-model__nav-item box-model__nav-item--clipboard">
        <span class="btn-icon btn-icon--clipboard"
              [ngClass]="{active: observedOffers.isObserved(item.uid)}"
              (click)="observedOffers.changeObservedStatus(item.uid)"
        >
          <span class="box-model__nav-icon icon-star_contour"></span>
          <span class="box-model__nav-icon icon-star"></span>
        </span>
      </li>
    </ul>
    <div class="box-model__col">
      <div class="box-model__wrap d-flex">
        <h3 class="box-model__name text-uppercase w-100" title="{{ item.model }}">{{ item.model }}</h3>
        <div class="box-model__version text-uppercase" *ngIf="item.version !== null" title="{{ item.version }} {{ item.engineType }}">{{ item.version }}<br />{{ item.engineType }}</div>
        <div class="box-model__version text-uppercase" *ngIf="item.version === null" title="{{ item.engineType }}">{{ item.engineType }}</div>
        <div *ngIf="brandSlug !== 'alfa-romeo'" class="price">
          <div class="price__tax d-flex pb-1">
            <small class="price__text">BRUTTO:</small>
            <span class="price__item-1">{{ item.price.final.brutto | formatMoney }}<small>ZŁ</small></span>
            <span class="price__item-2" *ngIf="item.price.discount.amount.netto > 0 && item.price.discount.amount.brutto > 0 && item.dealer_code !== '0075206'  && item.dealer_code !== '0720006'">
              <span>{{ item.price.base.brutto + (item.price.shipping_fee | shippingFeeGross) | formatMoney }}</span>
              <small>ZŁ</small>
              <b>*</b>
            </span>
          </div>
          <div class="price__notax d-flex">
            <small class="price__text">NETTO:</small>
            <span class="price__item-1">{{ item.price.final.netto | formatMoney }}<small>ZŁ</small></span>
            <span class="price__item-2" *ngIf="item.price.discount.amount.netto > 0 && item.price.discount.amount.brutto > 0 && item.dealer_code !== '0075206'  && item.dealer_code !== '0720006'">
              <span>{{ item.price.base.netto + (item.price.shipping_fee | shippingFeeNet) | formatMoney }}</span>
              <small>ZŁ</small>
              <b>*</b>
            </span>
          </div>
          <div *ngIf="brandSlug === 'jeep' && item.price.financing_info !== undefined && item.price.financing_info['b2b'] !== undefined" class="sol-installment">
            <div class="installment-label" *ngIf="item.price.financing_info['b2b']['installment'] > 150">
              <div class="installment__text"><small>RATA OD: </small></div>
              <div class="installment__value">{{ item.price.financing_info['b2b']['installment'] | formatMoney }}<small>ZŁ NETTO/M-C</small></div>
            </div>
          </div>
          <span *ngIf="item.price.discount.amount.netto > 0 && item.price.discount.amount.brutto > 0 && item.dealer_code !== '0075206'  && item.dealer_code !== '0720006'" class="price__importer-price-info">
            * Cena cennikowa importera z&nbsp;wyposażeniem dodatkowym
          </span>
        </div>

        <div *ngIf="brandSlug === 'alfa-romeo' && item.price.financing_info !== undefined && (item.price.financing_info['b2b'] !== undefined || item.price.financing_info['l101'] !== undefined)"
             class="only-installment">
          <div class="installment-label">
            <div class="installment__text"><small>RATA OD: </small></div>
            <div *ngIf="item.price.financing_info['b2b'] !== undefined"
                 class="installment__value">{{ item.price.financing_info['b2b']['installment'] | formatMoney }}<small><strong>ZŁ</strong> NETTO/M-C</small></div>
            <div *ngIf="item.price.financing_info['b2b'] === undefined && item.price.financing_info['l101'] !== undefined"
                 class="installment__value">{{ item.price.financing_info['l101']['installment'] | formatMoney }}<small><strong>ZŁ</strong> NETTO/M-C</small></div>
          </div>
        </div>

        <span class="box-model__local w-100 mt-auto">
            <span class="box-model__local-item d-flex text-uppercase aling-item-center">
              <span class="icon-marker"></span>
              <span class="box-model__local-text ml-1"> {{item.localization.replace('woj. , ', '')}}</span>
            </span>
        </span>
      </div>
    </div>
  </div>
</div>
