<div *ngIf="offer !== null && offer.id > 0">
  <ng-container *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'">
    <section class="section section--general-offer" [ngClass]="{'mt-4 mt-md-5 mt-lg-2': brandSlug === 'alfa-romeo', ' mt-0': brandSlug !== 'alfa-romeo'}" >
      <div class="container">
        <div class="row">
          <div class="headline-sticky-wrap col-11 col-md-9 col-lg-5">
            <div class="headline-sticky" [ngClass]="{'sticky-communicators': offer.dealer !== null && offer.dealer.communicators.length > 0}">
              <div #spacer></div>
              <div stickyThing [spacer]="spacer" [enable]="!isSol" class="headline row justify-content-between align-items-start align-items-sm-end">
                <h3 *ngIf="isSol" class="headline__title ie-headline__title">POPROŚ O KONTAKT</h3>
<!--                <h3 *ngIf="brandSlug === 'jeep'" class="headline__title ie-headline__title">ZAPYTAJ O SZCZEGÓŁY</h3>-->
                <h3 *ngIf="!isSol" class="headline__title ie-headline__title">POPROŚ O OFERTĘ</h3>
                <div class="headline__right text-right">
                  <div class="headline__phone d-block" *ngIf="!freespeeDealerPhoneNumber && offer.dealer !== null && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                    <i class="icon icon-phone"></i> <strong>+48...</strong> POKAŻ NUMER
                  </div>
                  <div class="headline__phone d-block" *ngIf="freespeeDealerPhoneNumber">
                    <a href="tel:{{freespeeDealerPhoneNumber}}" data-adobe="content:stocklocator:call"><i class="icon icon-phone"></i><strong [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></strong></a>
                  </div>
                  <span class="headline__send-message" (click)="openOrCloseForm(true); ctaType = 'RP'" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true && isSol">WYŚLIJ WIADOMOŚĆ</span>
                  <span class="headline__send-message" (click)="scrollToForm()" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true && !isSol">WYŚLIJ WIADOMOŚĆ</span>

                  <div class="headline__communicators-top" *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                    <div><strong class="headline__send-message" (click)="scrollToCommunicators()">Porozmawiaj na czacie:</strong> </div>
                    <div class="communicators" *ngFor="let item of offer.dealer.communicators">
                      <div class="communicators-item pb-0" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" title="Messenger" target="_blank"><img src="assets/img/icon/messenger.png"></a></div>
                      <div class="communicators-item pb-0" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" title="WhatsApp" target="_blank"><img src="assets/img/icon/whatsapp.png"></a></div>
                      <div class="communicators-item pb-0" *ngIf="item.name === 'Skype' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('skype:' + item.id + '?call')" (click)="sendGA('send', 'event', 'link', 'click', 'Skype')" title="Skype" target="_blank"><img src="assets/img/icon/skype.png"></a></div>
                      <div class="communicators-item pb-0" *ngIf="item.name === 'Viber' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('viber://chat?number=48' + item.id)" (click)="sendGA('send', 'event', 'link', 'click', 'Viber')" title="Viber" target="_blank"><img src="assets/img/icon/viber.png"></a></div>
                    </div>
                  </div>
                </div>
                <div class="headline__footer-link" [ngClass]="{'sol__footer-link': isSol && (brandSlug === 'jeep' || brandSlug === 'alfa-romeo') && insuranceSol}">
                  <a [routerLink]="'/' | addUrlPrefix" class="text-uppercase" *ngIf="!hasLastFindProperties()"><span class="icon-arrow_left"></span> wróć do strony głównej</a>
                  <a [routerLink]="'/szukaj/' | addUrlPrefix" [queryParams]="lastFindProperties" class="text-uppercase" *ngIf="hasLastFindProperties()"><span class="icon-arrow_left"></span> wróć do wyników
                    wyszukiwania</a>
                </div>
                <div class="offer-info">
                  <div class="offer-info__wrap d-flex align-items-end w-100">
                    <div class="offer-info__headline">
                      <h3 class="offer-info__title">{{ offer.model.name }}</h3>
                      <h4 class="offer-info__subtitle">{{ offer.version.name }}</h4>
                    </div>
                    <div *ngIf="brandSlug !== 'alfa-romeo'" class="price offer-info__price">
                      <div class="price__tax d-flex pb-1">
                        <small class="price__text">BRUTTO:</small>
                        <span class="price__item-1"><span>{{offer.price.final.brutto | formatMoney }}</span><small>ZŁ</small></span>
                      </div>
                      <div class="price__notax d-flex">
                        <small class="price__text">NETTO:</small>
                        <span class="price__item-1"><span>{{offer.price.final.netto | formatMoney }}</span><small>ZŁ</small></span>
                      </div>
                    </div>
                    <div *ngIf="brandSlug === 'alfa-romeo'" class="w-100">
                      <div class="headline-only-installment" *ngIf="isSol && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]">
                        <small class="headline-only-installment__text">RATA:</small>
                        <span class="headline-only-installment__value"><span>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment | formatMoney }}</span>
                          <small class="text-uppercase">ZŁ {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].priceType }}/M-C</small>
                        </span>
                      </div>
                      <div class="headline-only-installment" *ngIf="!isSol && offer.price.financing_info !== undefined">
                        <small class="headline-only-installment__text">RATA:</small>
                        <span *ngIf="offer.price.financing_info['b2b'] !== undefined"
                              class="headline-only-installment__value">
                          <span>{{ offer.price.financing_info['b2b']['installment'] | formatMoney }}</span>
                          <small class="text-uppercase">ZŁ NETTO/M-C</small>
                        </span>
                        <span *ngIf="offer.price.financing_info['b2b'] === undefined && offer.price.financing_info['l101'] !== undefined"
                              class="headline-only-installment__value">
                          <span>{{ offer.price.financing_info['l101']['installment'] | formatMoney }}</span>
                          <small class="text-uppercase">ZŁ NETTO/M-C</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isSol && (brandSlug === 'jeep' || brandSlug === 'alfa-romeo') && insuranceSol" class="headline headline-sol-beam row justify-content-between align-items-start align-items-sm-end">
                <div class="headline-sol-beam-text">
                  <strong>Oferta specjalna:</strong> ubezpieczenie OC/AC na rok za {{insuranceSolPercent}} wartości auta przy zamówieniu online
                </div>
              </div>

              <div *ngIf="brandSlug === 'alfa-romeo' && additionalProtection" class="headline headline-sol-beam-brighter row justify-content-between align-items-start align-items-sm-end">
                <div class="headline-sol-beam-text">
                  Dodatkowa ochrona pogwarancyjna na trzeci rok z limitem przebiegu do 120 tys. km
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-data px-0">
            <div class="container container-half container-half-lg-left h-100 d-flex flex-column">
              <div class="row justify-content-center h-100">
                <div class="col-sm-8 col-md-6 col-lg-12">
                  <div class="row h-100">
                    <div class="col-12" [ngClass]="{'bg-white bg-white--1': offer.brand.slug == 'fiat'}">
                      <div class="headline headline--model-name headline--transparent w-100">
                        <h1 class="headline__title">{{ offer.model.name }}</h1>
                        <h2 class="headline__subtitle">{{ offer.version.name }}</h2>
                      </div>
                      <div *ngIf="brandSlug !== 'alfa-romeo'" class="price mb-sm-4" [ngClass]="{'price-up': financialProductsLabel !== null && (offer.brand.slug ==='jeep')}">
                        <div class="price__tax d-flex pb-3">
                          <small class="price__text">BRUTTO:</small>
                          <span class="price__item-1">{{ offer.price.final.brutto | formatMoney }}<small>ZŁ</small></span>
                          <span class="price__item-2" *ngIf="offer.price.discount.amount.netto > 0 && offer.price.discount.amount.brutto > 0 && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'">
                          <span>{{ offer.price.base.brutto + (offer.price.shipping_fee | shippingFeeGross) | formatMoney }}</span>
                          <small>ZŁ</small>
                          <sup>*</sup>
                        </span>
                        </div>
                        <div class="price__notax d-flex">
                          <small class="price__text">NETTO:</small>
                          <span class="price__item-1">{{ offer.price.final.netto | formatMoney }}<small>ZŁ</small></span>
                          <span class="price__item-2" *ngIf="offer.price.discount.amount.netto > 0 && offer.price.discount.amount.brutto > 0 && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'">
                          <span>{{ offer.price.base.netto + (offer.price.shipping_fee | shippingFeeNet) | formatMoney }}</span>
                          <small>ZŁ</small>
                          <sup>*</sup>
                        </span>
                        </div>
                        <span *ngIf="offer.price.discount.amount.netto > 0 && offer.price.discount.amount.brutto > 0 && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'" class="price__importer-price-info">
                        * Cena cennikowa importera z&nbsp;wyposażeniem dodatkowym
                      </span>
                      </div>

                      <div *ngIf="brandSlug === 'alfa-romeo'" class="w-100">
                        <div class="offer-only-installment" *ngIf="isSol && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]] && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment > 150">
                          <small class="offer-only-installment__text">RATA:</small>
                          <span class="offer-only-installment__value">{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment | formatMoney }}
                            <small class="text-uppercase">ZŁ {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].priceType }}/M-C</small>
                          </span>
                        </div>
                        <div class="offer-only-installment" *ngIf="!isSol && offer.price.financing_info !== undefined">
                          <small class="offer-only-installment__text">RATA:</small>
                          <span *ngIf="offer.price.financing_info['b2b'] !== undefined"
                                class="offer-only-installment__value">
                            {{ offer.price.financing_info['b2b']['installment'] | formatMoney }}
                            <small class="text-uppercase">ZŁ NETTO/M-C</small>
                          </span>
                          <span *ngIf="offer.price.financing_info['b2b'] === undefined && offer.price.financing_info['l101'] !== undefined"
                                class="offer-only-installment__value">
                            {{ offer.price.financing_info['l101']['installment'] | formatMoney }}
                            <small class="text-uppercase">ZŁ NETTO/M-C</small>
                          </span>
                        </div>

                        <div class="financial-products-button">
                          <span (click)="scrollToLeasingCalculator()">Zmień parametry finansowania <i class="icon icon-right_arrow"></i></span>
                        </div>
                        <div class="offer-small-price">

                          <div class="small-price">
                            <small class="small-price__text">BRUTTO:</small>
                            <span class="small-price__value"><span>{{ offer.price.final.brutto | formatMoney }}</span><small>ZŁ</small></span>
                          </div>
                          <div class="small-price">
                            <small class="small-price__text">NETTO:</small>
                            <span class="small-price__value"><span>{{ offer.price.final.netto | formatMoney }}</span><small>ZŁ</small></span>
                          </div>
                        </div>

                      </div>

                      <div *ngIf="financialProductsLabel !== null && brandSlug !== 'alfa-romeo' && brandSlug !== 'jeep'" class="financial-products-label" (click)="scrollToFinancialProducts()">
                        <span>od <strong>{{financialProductsLabel['min_installment'] | formatMoney}}</strong> ZŁ {{this.financialProductsLabel['price_type']}}/MIESIĄC</span>
                        <br />{{financialProductsLabel['label']}}<br /><div class="financial-link-more">
                        <span class="link-more">szczegóły <i class="icon icon-right_arrow"></i></span></div>
                      </div>

                      <div *ngIf="offer.brand.slug ==='jeep' || offer.brand.slug ==='alfa-romeo'" class="financial-products-link">
                        <a [href]="checkBusinessLink()" target="_blank">Sprawdź ofertę biznesową <i class="icon icon-right_arrow"></i></a>
                      </div>

                      <div class="price__building" *ngIf="offer.price.building > 0">
                        *Cena pojazdu z zabudową o wartości: {{ offer.price.building | formatMoney }}zł netto
                      </div>
                    </div>
                    <div class="col-12 align-self-end" [ngClass]="{'bg-white': offer.brand.slug == 'fiat'}">
                      <div class="gallery-thumb__wrap">
                        <div class="gallery-thumb swiper-container" [swiper]="configThumb" [(index)]="index">
                          <div class="swiper-wrapper">
                            <div class="gallery-thumb__item swiper-slide" *ngFor="let item of galleryVidImg; let i = index"
                                 (click)="selectIndex(i)" [ngClass]="{active: i==index}">
                              <div *ngIf=" item.type == 'video'" class="cg-min-video">
                                <img src="{{ item.path }}">
                                <div class="cg-min-video-play-button">
                                  <div class="cg-min-video-play-button-arrow"></div>
                                </div>
                              </div>
                              <img *ngIf=" item.type == 'image'" src="{{ item.originUrl }}?fm=pjpg&w=140&h=76&fit=crop&bg=white" (error)="setDefaultImg140x76($event)" alt="{{ offer.model.name }} {{ offer.version.name }}"/>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <span class="icon-left_arrow"></span>
                        </div>
                        <div class="swiper-button-next">
                          <span class="icon-right_arrow"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 d-flex align-items-end link-list__wrap"  *ngIf="isSol">
                      <div class="link-list d-flex text-uppercase justify-content-start mt-4 w-100">
                        <div class="link-list__item" style="cursor: auto">
                          <a [href]="'/drukuj-oferte/' +  offer.id" data-adobe="content:stocklocator:print"
                             (click)="sendGA('send', 'event', 'link', 'click', 'print')">Pobierz ofertę</a>
                          <a [href]="getInfoForm()" [ngClass]="{'visibility-hidden': infoFormUrl === null}">Pobierz formularz informacyjny</a>
                        </div>
                        <div class="link-list__item ml-sm-auto mr-0">
                        <span class="btn-icon btn-icon--clipboard"
                              [ngClass]="{active: observedOffers.isObserved(offer.id)}"
                              (click)="observedOffers.changeObservedStatus(offer.id)"
                        >
                          <i class="icon-star_contour"></i>
                          <i class="icon-star"></i>
                        </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-sm-12 d-flex align-items-end link-list__wrap"  *ngIf="!isSol">
                      <div class="link-list d-flex text-uppercase justify-content-end mt-4 w-100">
                        <div class="link-list__item d-none d-sm-inline-block">
                          <a [href]="'/drukuj-oferte/' +  offer.id" (click)="sendGA('send', 'event', 'link', 'click', 'print')">Wydrukuj</a>
                        </div>
                        <div class="link-list__item">
                          <a (click)="share()" data-adobe="content:stocklocator:share"><i class="icon-share mr-2"></i><span class="d-none d-sm-inline-block"> Udostępnij</span></a>
                        </div>
                        <div class="link-list__item ml-sm-auto mr-0">
                        <span class="btn-icon btn-icon--clipboard"
                              [ngClass]="{active: observedOffers.isObserved(offer.uid)}"
                              (click)="observedOffers.changeObservedStatus(offer.uid)"
                        >
                          <i class="icon-star_contour"></i>
                          <i class="icon-star"></i>
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-gallery">
            <div class="gallery">
              <swiper [config]="config" [(index)]="index">
                <div *ngFor="let item of galleryVidImg" (click)="lightBox.open(galleryVidImg, {loop: galleryVidImg.length > 1, index: index})">
                  <picture *ngIf="item.type == 'video'">
                    <div class="cg-video">
                      <img src="{{ item.path }}" class="box-model__photo-img cg-no-black-bar" alt="{{ offer.model.name }} {{ offer.version.name }}" />
                      <div class="cg-video-play-button">
                        <div class="cg-video-play-button-arrow"></div>
                      </div>
                    </div>
                  </picture>
                  <picture *ngIf="item.type == 'image'">
                    <source media="(min-width: 1600px)" srcset="{{ item.originUrl }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white">
                    <source media="(min-width: 1440px)" srcset="{{ item.originUrl }}?fm=pjpg&w=844&h=564&fit=crop&bg=white">
                    <source media="(min-width: 1200px)" srcset="{{ item.originUrl }}?fm=pjpg&w=759&h=507&fit=crop&bg=white">
                    <source media="(min-width: 992px)" srcset="{{ item.originUrl }}?fm=pjpg&w=631&h=422&fit=crop&bg=white">
                    <source media="(min-width: 768px)" srcset="{{ item.originUrl }}?fm=pjpg&w=769&h=514&fit=crop&bg=white">
                    <source media="(min-width: 576px)" srcset="{{ item.originUrl }}?fm=pjpg&w=591&h=395&fit=crop&bg=white">
                    <source media="(min-width: 480px)" srcset="{{ item.originUrl }}?fm=pjpg&w=437&h=292&fit=crop&bg=white">
                    <source media="(min-width: 360px)" srcset="{{ item.originUrl }}?fm=pjpg&w=446&h=298&fit=crop&bg=white">
                    <img src="{{ item.originUrl }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white" class="box-model__photo-img" (error)="setDefaultImg800x600($event)" alt="{{ offer.model.name }} {{ offer.version.name }}" />
                  </picture>
                </div>
              </swiper>
              <div class="label-wrap">
                <div class="label label--reservation text-uppercase" *ngIf="offer.status === 'reserved'">Rezerwacja</div>
                <app-offer-labels [offer]="offer"> </app-offer-labels>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--more-info">
      <div class="container">
        <div class="grid">
          <div class="grid-headline">
            <div class="headline pr-3 text-uppercase ie-headline_pr-3" *ngIf="offerFinancialCalculators['b2b'] !== null">
              <h2 class="headline__title">Finansowanie</h2>
            </div>
          </div>
          <div class="grid-financing" *ngIf="offerFinancialCalculators['b2b'] !== null" [ngClass]="{'grid-financing--no-desc-equipment': offer.equipment.standard.length === 0 && offer.equipment.optional.length === 0}">
            <div class="financing row financing--sol-tab" *ngIf="!customerTab">
<!--              nowa struktura danych-->
              <ng-container *ngIf="offerFinancialCalculators['b2b'] !== undefined">
                <ng-container *ngFor="let customer of offerFinancialCalculators | keyvalue;">
                  <div class="financing__box col-md-6" *ngFor="let item of customer.value">
                    <div class="financing__box-wrap">
                      <app-financial-api-calculator
                        [name]="item.name"
                        [calculation]="item.calculation"
                        [offer]="offer"
                        [addToCartBtn]="false"
                        [customerType]="'all'"
                        (calculationData)="setValue($event)"
                        (setSelectedPriceType)="setSelectedPriceType($event)"
                        [selectedPriceType]="selectedPriceType"
                      ></app-financial-api-calculator>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <!-- SOL, Abonament -->
            <ng-container *ngIf="customerTab">
              <div class="financing row financing--sol-tab">
                <div class="financing__box col-md-12">
                  <div class="headline--transparent customers-tab">
                    <span class="customer-tab" (click)="selectCustomer('b2b')" [ngClass]="{'active': activeCustomer === 'b2b'}">
                      <h3 class="headline__title">Klient biznesowy</h3>
                    </span>
                    <span class="customer-tab" (click)="selectCustomer('b2c')" [ngClass]="{'active': activeCustomer === 'b2c'}">
                      <h3 class="headline__title">Klient indywidualny</h3>
                    </span>
                  </div>
                </div>
              </div>

<!--              Klient biznesowy-->
              <div class="financing row financing--sol" [hidden]="activeCustomer === 'b2c'" *ngIf="offerFinancialCalculators['b2b'] !== []">
                <ng-container *ngFor="let item of offerFinancialCalculators['b2b']; let i = index">
                  <div class="financing__box col-md-6" [ngClass]="{'animate-frozen': activeFinancialCalculatorId.b2b !== item.name && isSol}">
                    <div *ngIf="activeFinancialCalculatorId.b2b !== item.name && isSol" class="financing__box-frozen" (click)="selectFinancialCalculator('b2b', i, item.name)">
                      <span>wybierz</span>
                    </div>
                    <div class="financing__box-wrap">
                      <app-financial-api-calculator
                        [name]="item.name"
                        [calculation]="item.calculation"
                        [offer]="offer"
                        [addToCartBtn]="isSol"
                        [customerType]="'b2b'"
                        (calculationData)="setValue($event)"
                        (addToCart)="goToCart()"
                        (setSelectedPriceType)="setSelectedPriceType($event)"
                        [selectedPriceType]="selectedPriceType"
                      ></app-financial-api-calculator>
                    </div>
                  </div>
                </ng-container>
              </div>

<!--              Klient indywidualny-->
              <div class="financing row financing--sol" [hidden]="activeCustomer === 'b2b'" *ngIf="offerFinancialCalculators['b2c'] !== undefined">
                <ng-container *ngFor="let item of offerFinancialCalculators['b2c']; let i = index">
                  <div class="financing__box col-md-6" [ngClass]="{'animate-frozen': activeFinancialCalculatorId.b2c !== item.name && isSol}">
                    <div *ngIf="activeFinancialCalculatorId.b2c !== item.name && isSol" class="financing__box-frozen" (click)="selectFinancialCalculator('b2c', i, item.name)">
                      <span>wybierz</span>
                    </div>
                    <div class="financing__box-wrap">
                      <app-financial-api-calculator
                        [name]="item.name"
                        [calculation]="item.calculation"
                        [offer]="offer"
                        [addToCartBtn]="isSol"
                        [customerType]="'b2c'"
                        (calculationData)="setValue($event)"
                        (addToCart)="goToCart()"
                        (setSelectedPriceType)="setSelectedPriceType($event)"
                        [selectedPriceType]="selectedPriceType"
                      ></app-financial-api-calculator>
                    </div>
                  </div>
                </ng-container>
              </div>

              <div class="financing row financing--sol-tab" *ngIf="isSol">
                <div class="financing__box col-md-12"
                     *ngIf="serviceFlex && activeCustomer === 'b2c' && activeFinancialCalculatorId.b2c === 'b2c'">
                  <div class="financing--sol-info">
                    <h4>Dodatkowe korzyści</h4>
                    <div class="sol-info-table">
                      <div class="sol-info-item">
                        Ubezpieczenie AC/OC/NNW
                      </div>
                      <div class="sol-info-item">
                        Gwarantowany odkup
                      </div>
                      <div class="sol-info-item">
                        Elastyczny termin zakończenia umowy: FLEX <span class="icon-info cursor-pointer" (click)="showExtraServicesInfo()"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="financing__box col-md-12">
                  <div class="financing--sol-info">
                    <h4>Dodatkowe opłaty brutto w&nbsp;zamówieniu <span>on-line:</span></h4>
                    <div class="sol-info-table">
                      <ng-container *ngIf="brandSlug === 'jeep'">
                        <div class="sol-info-key">
                          Ubezpieczenie AC/OC/NNW:
                          <span class="info-tooltip icon-info" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                          <span class="tooltip-box">
                          Cena za rekomendowane ubezpieczenie pakietowe pojazdu, ze zniesionymi wszelkimi udziałami własnymi po stronie Klienta. Stawka podlega weryfikacji zgodnie z indywidualna historią ubezpieczeniową.
                          </span>
                        </span>
                        </div>
                        <div *ngIf="insuranceSol !== null" class="sol-info-value">{{ insuranceSol | formatMoney }} ZŁ</div>
                        <div *ngIf="insuranceSol === null" class="sol-info-value">Zapytaj dealera</div>
                      </ng-container>
                      <ng-container *ngIf="brandSlug === 'alfa-romeo'">
                        <div class="sol-info-key">
                          Ubezpieczenie AC/OC:
                          <span class="info-tooltip icon-info" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                          <span class="tooltip-box">
                          Cena za rekomendowane ubezpieczenie pakietowe pojazdu, ze zniesionymi wszelkimi udziałami własnymi po stronie Klienta. Stawka podlega weryfikacji zgodnie z indywidualna historią ubezpieczeniową.
                          </span>
                        </span>
                        </div>
                        <div *ngIf="insuranceSol !== null" class="sol-info-value">{{ insuranceSol | formatMoney }} ZŁ</div>
                        <div *ngIf="insuranceSol === null" class="sol-info-value">Zapytaj dealera</div>
                      </ng-container>
                      <div class="sol-info-key">
                        Opłata za rejestrację:
                        <span class="info-tooltip icon-info" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                          <span class="tooltip-box">
                          Opłata jednorazowa doliczana do czynszu inicjalnego, tytułem czynności rejestracyjnych pojazdu
                          </span>
                        </span>
                      </div>
                      <div class="sol-info-value">480 zł</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="financing row financing--sol-tab mt-0" *ngIf="brandConfig.eSellerPhone">
                <div class="financing__box col-md-12">
                  <div class="financing--sol-info">
                    <h4>Masz pytania lub potrzebujesz pomocy w zamówieniu online? Zadzwoń: <span>{{ brandConfig.eSellerPhone }}</span></h4>
                  </div>
                </div>
              </div>
              <div class="financing row financing--sol-tab mt-0" *ngIf="optionalInsuranceForBev">
                <div class="financing__box col-md-12">
                  <div class="financing--sol-info optional-insurance">
                    <div class="optional-insurance-headline">
                      <div class="optional-insurance-logo">
                        <img src="/assets/img/optional-insurance-logo-white.png" />
                      </div>
                      <div class="optional-insurance-title">
                        Ubezpieczenie dopasowane do Twojego elektryka zapewni ochronę&nbsp;m.in.:
                      </div>
                    </div>
                    <ul class="optional-insurance-list">
                      <li>na wypadek pożaru, wybuchu czy samozapłonu,</li>
                      <li>działania wilgoci lub mrozu na baterię trakcyjną,</li>
                      <li>przed zawirusowaniem lub włamaniem do systemów.</li>
                    </ul>
                    <small>O szczegóły zapytaj dealera</small>
                    <div class="pt-3 text-right">
                      <a href="/assets/uploads/ERGO_EV_PAKIET_w_pigulce.pdf" target="_blank" class="link-more-info">
                        <span>Więcej informacji <i class="icon icon-right_arrow"></i></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- / SOL, Abonament -->

            <div class="financing row" *ngIf="(offer.financial_products | keys).length !== 0 && offer.brand.slug !== 'alfa-romeo' && offer.brand.slug !== 'jeep'">
              <div class="financing__box col-md-12" *ngFor="let item of offer.financial_products | keyvalue">
                <div class="financing__box-wrap-top financial-products">
                  <app-financial-products
                    [data]="item.value"
                    [brandSlug]="offer.brand.slug"
                    [hiddenForm]="false"
                    (showFormModal)="openOrCloseForm(true)"
                  ></app-financial-products>
                </div>
              </div>
            </div>

            <div class="financing row financing--sol-tab mt-0" *ngIf="brandSlug === 'abarth' && brandConfig.eSellerPhone">
              <div class="financing__box col-md-12">
                <div class="financing--sol-info">
                  <h4>Masz pytania lub potrzebujesz pomocy w zamówieniu online? Zadzwoń: <span>{{ brandConfig.eSellerPhone }}</span></h4>                  </div>
              </div>
            </div>
          </div>

          <div class="grid-info justify-content-between" [ngClass]="{'grid-info--no-financial mt-0': offerFinancialCalculators['b2b'] === null && offerFinancialCalculators['b2c'] === null}">
            <div>
              <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
                <h3 class="headline__title">Informacje</h3>
              </div>
              <div class="hidden-box hidden-box--white" [attr.data-hidden]="hiddenText.technicalData" data-opt="technicalData">
                <ul class="list text-uppercase">
                  <li class="list__item" *ngIf="offer.dealer !== null">
                    Lokalizacja
                    <span class="list__item-value">{{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, {{offer.dealer.region}}</ng-container></span>
                  </li>
                  <li class="list__item" *ngIf="offer.dealer === null">
                    Lokalizacja
                    <span class="list__item-value">U KAŻDEGO DEALERA W POLSCE</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.engineCapacity && offer.technicalData.engineCapacity != '0.0' && offer.technicalData.engineCapacity != 0.0">
                    POJEMNOŚĆ SKOKOWA
                    <span class="list__item-value">{{offer.technicalData.engineCapacity}} CM<sup>3</sup></span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.enginePower && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                    MOC SILNIKA
                    <span class="list__item-value">{{offer.technicalData.enginePower}} KM</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.averageFuelConsumption && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                    ŚREDNIE SPALANIE
                    <span class="list__item-value">{{offer.technicalData.averageFuelConsumption}} L/100KM</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.co2Emission && offer.technicalData.co2Emission != 'N/A' && offer.technicalData.co2Emission != 'NA'">
                    EMISJA CO<sub>2</sub>
                    <span class="list__item-value">{{offer.technicalData.co2Emission}} G/KM</span>
                  </li>
                  <li class="list__item" *ngIf="offer.productionYear">
                    ROK PRODUKCJi
                    <span class="list__item-value">{{offer.productionYear}}</span>
                  </li>
                  <li class="list__item" *ngIf="offer.color.name && offer.color.name != '-'">
                    KOLOR NADWOZIA
                    <span class="list__item-value">{{offer.color.name}}</span>
                  </li>
                  <li class="list__item" *ngIf="offer.interior.name && offer.interior.name != '******************************'">
                    RODZAJ TAPICERKI
                    <span class="list__item-value">{{offer.interior.name}}</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.fuelType && offer.technicalData.fuelType != 'nieokreślony'">
                    RODZAJ PALIWA
                    <span *ngIf="brandSlug !== 'alfa-romeo'" class="list__item-value">{{offer.technicalData.fuelType}}</span>
                    <span *ngIf="brandSlug === 'alfa-romeo'" class="list__item-value">{{offer.technicalData.fuelType.replace('Miękka hybryda benzynowa', 'Hybryda')}}</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.gearboxType">
                    SKRZYNIA BIEGÓW
                    <span class="list__item-value">{{offer.technicalData.gearboxType}}</span>
                  </li>
                  <li class="list__item" *ngIf="offer.technicalData.driveType">
                    NAPĘD
                    <span class="list__item-value">{{offer.technicalData.driveType}}</span>
                  </li>
                  <li class="list__item" *ngIf="showVin">
                    VIN/NUMER SERYJNY
                    <span class="list__item-value">{{offer.vin ? offer.vin : offer.serialNo}}</span>
                  </li>
                </ul>
                <span class="link link--more" (click)="hiddenText.technicalData=false">POKAŻ WSZYSTKO</span>
                <span class="link link--less" (click)="hiddenText.technicalData=true">ZWIŃ</span>
              </div>
            </div>
            <div *ngIf="offer.dealer !== null && isSol" class="sol-dealer-info">
              <div class="headline headline--dealer text-uppercase py-5">
                <h2 class="headline__title">Sprzedawca</h2>
                <div class="headline__info">
                  <p class="headline__info--dealer-name">
                    {{offer.dealer.name}}
                  </p>
                  <p><em>{{offer.dealer.street}}<br />
                    {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                    <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                       target="_blank"
                       class="btn-icon btn-icon&#45;&#45;show-map">
                      <span class="icon-marker"></span> Pokaż na mapie
                    </a>
                  </p>
                </div>
                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                  <i class="icon icon-phone"></i> <strong>+48...</strong> POKAŻ NUMER
                </div>
                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}"><i class="icon icon-phone"></i><strong [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></strong></a>
                </div>

                <div *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                  <div class="headline__info pt-4 mb-0 communicators-link">
                    <p class="headline__info--communicators-title">Porozmawiaj na czacie:</p>
                  </div>
                  <div class="headline__communicators" *ngFor="let item of offer.dealer.communicators">
                    <div class="pb-3" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger.png"> {{item.name}}</a></div>
                    <div class="pb-3" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp.png"> {{item.name}}</a></div>
                    <div class="pb-3" *ngIf="item.name === 'Skype' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('skype:' + item.id + '?call')" (click)="sendGA('send', 'event', 'link', 'click', 'Skype')" target="_blank"><img src="assets/img/icon/skype.png"> {{item.name}}</a></div>
                    <div class="pb-3" *ngIf="item.name === 'Viber' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('viber://chat?number=48' + item.id)" (click)="sendGA('send', 'event', 'link', 'click', 'Viber')" target="_blank"><img src="assets/img/icon/viber.png"> {{item.name}}</a></div>
                  </div>
                </div>

              </div>
              <a class="link link--under-headline d-block text-right" [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
                ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA
              </a>
            </div>
          </div>

        <div class="grid-option pl-lg-5" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0 || offer.equipment.standard && offer.equipment.standard.length > 0 && offer.equipment.optional && offer.equipment.optional.length > 0">
            <div class="headline headline--transparent text-uppercase ml-lg-5 pt-0 pb-2 my-0" *ngIf="offer.description && offer.description.length > 0">
              <h3 class="headline__title">Wyposażenie</h3>
            </div>
            <div class="hidden-box ml-lg-5" [attr.data-hidden]="hiddenText.equipmentStandard" data-opt="equipmentStandard" *ngIf="offer.description && offer.description.length > 0">
              <ul class="list list--equipment text-uppercase">
                <li class="list__item" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0"><strong>Wyposażenie standardowe</strong></li>
                <li class="list__item" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0">
                  <app-equipment-list [data]="offer.equipment.standard" [showPrices]="false"></app-equipment-list>
                </li>
                <li class="list__item" *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0"><strong>Wyposażenie dodatkowe</strong><br /><h4>(wliczone w cenę NETTO/BRUTTO)</h4></li>
                <li class="list__item" *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0">
                  <app-equipment-list [data]="offer.equipment.optional" [showPrices]="true"></app-equipment-list>
                </li>
              </ul>
              <span class="link link--more" (click)="hiddenText.equipmentStandard=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" (click)="hiddenText.equipmentStandard=true">ZWIŃ</span>
            </div>
            <div class="headline headline--transparent text-uppercase ml-lg-5 pt-0 pb-2 my-0 ie-equipment" *ngIf="!offer.description">
              <h3 *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0"  class="headline__title">Wyposażenie standardowe</h3>
            </div>
            <div class="hidden-box ml-lg-5" [attr.data-hidden]="hiddenText.equipmentStandard" data-opt="equipmentStandard" *ngIf="!offer.description">
              <app-equipment-list *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0" [data]="offer.equipment.standard" [showPrices]="false"></app-equipment-list>
              <span class="link link--more" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0"  (click)="hiddenText.equipmentStandard=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0"  (click)="hiddenText.equipmentStandard=true">ZWIŃ</span>
            </div>
            <div class="headline headline--transparent text-uppercase ml-lg-5 pt-0 pb-2 my-0" *ngIf="!offer.description">
            </div>
          </div>
          <div class="grid-option pl-lg-5" *ngIf="!offer.equipment.standard && !offer.equipment.optional && offer.description && offer.description.length > 0">
            <div class="headline headline--transparent text-uppercase ml-lg-5 pt-0 pb-2 my-0">
              <h3 class="headline__title">Opis</h3>
            </div>
            <div class="hidden-box ml-lg-5" [attr.data-hidden]="hiddenText.description" data-opt="description">
              <div class="description" [innerHTML]="offer.description | offerDescription"></div>
              <span class="link link--more" (click)="hiddenText.description=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" (click)="hiddenText.description=true">ZWIŃ</span>
            </div>
          </div>
          <div class="grid-option ml-lg-0 pl-lg-5 mt-0 mt-lg-0 ie-equipment-optional" *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0 && offer.equipment.standard.length === 0">
            <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
              <h3 class="headline__title">Wyposażenie dodatkowe<br /><small>(wliczone w cenę NETTO/BRUTTO)</small></h3>
            </div>
            <div class="hidden-box" [attr.data-hidden]="hiddenText.equipmentOptional" data-opt="equipmentOptional">
              <app-equipment-list [data]="offer.equipment.optional" [showPrices]="true"></app-equipment-list>
              <span class="link link--more" (click)="hiddenText.equipmentOptional=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" (click)="hiddenText.equipmentOptional=true">ZWIŃ</span>
            </div>
          </div>

          <div class="grid-desc ml-lg-4 pl-lg-5 pt-4 pt-lg-0 ie-equipment-optional" [ngClass]="{'grid-desc-no-equipment': offer.equipment.standard.length === 0 && offer.equipment.optional && offer.equipment.optional.length < 1}" *ngIf="offer.description && offer.description.length > 0">
            <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
              <h3 class="headline__title">Opis</h3>
            </div>
            <div class="hidden-box" [attr.data-hidden]="hiddenText.description" data-opt="description">
              <div class="description" [innerHTML]="offer.description | offerDescription"></div>
              <span class="link link--more" (click)="hiddenText.description=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" (click)="hiddenText.description=true">ZWIŃ</span>
            </div>
          </div>
          <div class="grid-desc ml-lg-4 pl-lg-5 mt-5 mt-lg-0 ie-equipment-optional" *ngIf="!offer.description && offer.equipment.optional && offer.equipment.optional.length > 0 && offer.equipment.standard.length > 0">
            <div class="headline headline--transparent text-uppercase pt-0 pb-2 my-0">
              <h3 class="headline__title">Wyposażenie dodatkowe<br /><small>(wliczone w cenę NETTO/BRUTTO)</small></h3>
            </div>
            <div class="hidden-box" [attr.data-hidden]="hiddenText.equipmentOptional" data-opt="equipmentOptional">
              <app-equipment-list [data]="offer.equipment.optional" [showPrices]="true"></app-equipment-list>
              <span class="link link--more" (click)="hiddenText.equipmentOptional=false">POKAŻ WSZYSTKO</span>
              <span class="link link--less" (click)="hiddenText.equipmentOptional=true">ZWIŃ</span>
            </div>
          </div>
        </div>
        <div class="row my-3 ie-d-both" *ngIf="offer.is_km0">
          <div class="col-md-11 col-lg-9 offset-md-3">
            <small>Samochód nowy, zarejestrowany, bez przebiegu.</small>
          </div>
        </div>
        <div class="row my-5 ie-d-both">
          <div class="col-md-11 col-lg-3" *ngIf="offer.dealer === null">
            <div class="headline headline--dealer text-uppercase py-5">
              <h2 class="headline__title">Lokalizacja</h2>
              <div class="headline__info">
                <!-- TODO - wystylowanie tekstu (lwichrowski) -->
                <small>Samochód dostępny na placu centralnym FCA Poland i dostępny u każdego dealera w Polsce</small>
              </div>
            </div>
          </div>
          <div class="col-md-11 col-lg-3" *ngIf="offer.dealer !== null"
               [ngClass]="{'d-block d-lg-none': isSol}">
            <div class="headline headline--dealer text-uppercase py-5">
              <h2 class="headline__title">Sprzedawca</h2>
              <div class="headline__info">
                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon&#45;&#45;show-map">
                    <span class="icon-marker"></span> Pokaż na mapie
                  </a>
                </p>
              </div>
              <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                <i class="icon icon-phone"></i> <strong>+48...</strong> POKAŻ NUMER
              </div>
              <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}"><i class="icon icon-phone"></i><strong [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></strong></a>
              </div>

              <div *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                <div class="headline__info pt-4 mb-0 communicators-link">
                  <p class="headline__info--communicators-title">Porozmawiaj na czacie:</p>
                </div>
                <div class="headline__communicators" *ngFor="let item of offer.dealer.communicators">
                  <div class="pb-3" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger.png"> {{item.name}}</a></div>
                  <div class="pb-3" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp.png"> {{item.name}}</a></div>
                  <div class="pb-3" *ngIf="item.name === 'Skype' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('skype:' + item.id + '?call')" (click)="sendGA('send', 'event', 'link', 'click', 'Skype')" target="_blank"><img src="assets/img/icon/skype.png"> {{item.name}}</a></div>
                  <div class="pb-3" *ngIf="item.name === 'Viber' && ['fiat', 'fiat-professional', 'abarth'].indexOf(brandSlug) === -1"><a [href]="sanitize('viber://chat?number=48' + item.id)" (click)="sendGA('send', 'event', 'link', 'click', 'Viber')" target="_blank"><img src="assets/img/icon/viber.png"> {{item.name}}</a></div>
                </div>
              </div>

            </div>
            <a class="link link--under-headline d-block text-right" [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
              ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA
            </a>
          </div>
          <div class="col-md-11 col-lg-9">

            <ng-container *ngIf="!isSol">
              <app-form [cta]="'getaquote'" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                        [dealer]="offer.dealer.code + '.' + offer.dealer.sitecode"
                        [model_id]="offer.model.commercialCode"
                        [vin]="offer.vin"
                        [adobeThankyouPageData]="adobeThankyouPageData"
                        [brand_slug]="offer.brand.slug"
                        [showAgreementDescription]="true"
              ></app-form>
              <app-form [cta]="'getaquote_invenduto'" *ngIf="offer.dealer === null && !appComponentReference.isDealerStock"
                        [dealer]="null"
                        [model_id]="offer.model.commercialCode"
                        [vin]="offer.vin"
                        [adobeThankyouPageData]="adobeThankyouPageData"
                        [brand_slug]="offer.brand.slug"
                        [showAgreementDescription]="true"
              ></app-form>
            </ng-container>

            <div class="modal-form" *ngIf="modalFormIsOpen && isSol">
              <div class="modal-form-dialog">
                <div class="modal-header border-0">
                  <span aria-label="Zamknij" (click)="openOrCloseForm(false)">
                    <span class="icon-x"></span>
                  </span>
                </div>
                <app-form [cta]="'getaquote'" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                          [dealer]="offer.dealer.code + '.' + offer.dealer.sitecode"
                          [model_id]="offer.model.commercialCode"
                          [vin]="offer.vin"
                          [adobeThankyouPageData]="adobeThankyouPageData"
                          [brand_slug]="offer.brand.slug"
                          [showAgreementDescription]="false"
                          [ctaRpTd]="ctaType"
                ></app-form>
                <app-form [cta]="'getaquote_invenduto'" *ngIf="offer.dealer === null && !appComponentReference.isDealerStock"
                          [dealer]="null"
                          [model_id]="offer.model.commercialCode"
                          [vin]="offer.vin"
                          [adobeThankyouPageData]="adobeThankyouPageData"
                          [brand_slug]="offer.brand.slug"
                          [showAgreementDescription]="false"
                ></app-form>
              </div>
            </div>

            <app-flex-modal
              *ngIf="serviceFlex && offer && offer.price"
              [(showModal)]="showModalFlex"
              [price]="offer.price"
            ></app-flex-modal>

            <div *ngIf="(offer.dealer !== null && offer.dealer.subdealer === true)">
              <div class="form form--offert pr-4 pr-lg-0 pb-5 form--no-dealer">
                <div class="offset-lg-1 px-lg-2">
                  <div class="headline headline--form-offert headline--transparent text-uppercase my-0 pt-5 pb-2">
                    <h3 class="headline__text_subdealer">Zapraszamy do kontaktu telefonicznego na numer widoczny na tej stronie.</h3>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(offer.dealer === null && appComponentReference.isDealerStock) && siteData && siteData.dealer">
              <div class="form form--offert pr-4 pr-lg-0 pb-5 form--no-dealer">
                <div class="offset-lg-1 px-lg-2">
                  <div class="headline headline--form-offert headline--transparent text-uppercase my-0 pt-5 pb-2">
                    <h3 class="headline__text_subdealer">Zapraszamy do kontaktu</h3>
                    <div class="headline__info">
                      <p class="headline__info--dealer-name">
                        {{siteData.dealer.name}}
                      </p>
                      <p><em>{{siteData.dealer.address.street}}<br />
                        {{siteData.dealer.address.post_code}} {{siteData.dealer.address.city}}<ng-container *ngIf="siteData.dealer.address.region && siteData.dealer.address.region.name">, WOJ. {{siteData.dealer.address.region.name}}</ng-container></em><br />
                        <a href="https://www.google.com/maps/dir/?api=1&destination={{siteData.dealer.coordinates.latitude}},{{siteData.dealer.coordinates.longitude}}"
                           target="_blank"
                           class="btn-icon btn-icon&#45;&#45;show-map">
                          <span class="icon-marker"></span> Pokaż na mapie
                        </a>
                      </p>
                    </div>
                    <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && siteData.dealer.phones && siteData.dealer.phones.length > 0" (click)="showPhone(siteData.dealer.phones[0])">
                      <i class="icon icon-phone"></i> <strong>+48...</strong> POKAŻ NUMER
                    </div>
                    <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber">
                      <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}"><i class="icon icon-phone"></i><strong [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--sol-documents" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sol-accordion-wrap">
              <div class="sol-accordion-box sol-field-faq" *ngIf="brandSlug === 'jeep' || brandSlug === 'alfa-romeo'">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-faq')">
                  <h3 class="text-left px-2 mb-2">FAQ</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description pl-3">
                  <div class="row">
                    <div class="col-12 pl-4">
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-1">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-1')">
                          <h4 class="text-left">JAK ODNALEŹĆ SAMOCHODY DEALERA W MOJEJ OKOLICY?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              W polu wyszukiwarki możesz wybrać swoją lokalizacje, strona internetowa wyświetli auta z koncesji najbliżej Ciebie.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-2">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-2')">
                          <h4 class="text-left">JAK KORZYSTAĆ Z FILTRÓW PRZY WYBORZE SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Jeśli masz sprecyzowane oczekiwania wobec swojego nowego auta, ale nie wiesz jeszcze, który
                              <ng-container *ngIf="brandSlug === 'jeep'">model Jeepa</ng-container>
                              <ng-container *ngIf="brandSlug === 'alfa-romeo'">model Alfy Romeo</ng-container>
                              interesuje Cię najbardziej, skorzystaj z filtrów wyszukiwania.
                              <br />
                              <br />
                              Na liście ofert możesz przeszukiwać bazę aut na podstawie preferowanej ceny, modelu rodzaju, rodzaju paliwa, roku produkcyjnego, lokalizacji lub wielu innych możliwości parametryzowania.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-3">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-3')">
                          <h4 class="text-left">JAK ZMIENIAĆ PARAMETRY FINANSOWE OFERTY?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Parametry finansowe możesz zmienić na karcie produktu w sekcji "FINANSOWANIE". Możesz dostosować wysokość wpłaty własnej, czas trwania umowy oraz roczny limit kilometrów.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-4">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-4')">
                          <h4 class="text-left">O JAKICH KOSZTACH PAMIĘTAĆ PRZY ZAMÓWIENIU SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Na karcie konkretnego pojazdu jest pełna informacja o kosztach związanych z zawarciem umowy finansowej na konkretne auto. Przede wszystkim należy zwrócić uwagę na wysokość raty miesięcznej, czas trwania kontraktu oraz na wysokość opłaty wstępnej.
                              <br /><br />
                              Dodatkowo, zgodnie z polskimi przepisami, aby zapewnić Ci możliwość wyjechania z salonu samochodem auto musi być ubezpieczone. Dlatego w tej samej sekcji strony znajduje się informacja o wysokości kosztu za polisę ubezpieczenia OC/AC/NW.
                              <br /><br />
                              Ostatnią opłatą o jakiej należy pamiętać to koszt rejestracji samochodu – aktualna cena znajduje się na karcie samochodu. Faktura za opłatę rejestracyjną zostanie przesłana do Klienta po uruchomieniu umowy finansowania.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-5">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-5')">
                          <h4 class="text-left">ILE TO WSZYSTKO TRWA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Zakładamy, że od momentu złożenia zamówienia do odbioru samochodu z salonu nie minie więcej niż 14 dni. Na długość procesu ma wpływ przede wszystkim czas potrzebny na wydanie decyzji (od 15 minut do maksymalnie 48h – do końca następnego po złożeniu wniosku dnia roboczego), czas rejestracji samochodu w wydziale komunikacji starostwa powiatowego lub miasta oraz czas jaki będziesz potrzebował na dostarczenie wymaganych dokumentów aby złożyć wniosek o finansowanie.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-6">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-6')">
                          <h4 class="text-left">JAK MOGĘ OPŁACIĆ UBEZPIECZENIE?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Ubezpieczenie opłacić możesz w racie lub jednorazowym przelewem w zależności od wyboru produktu finansowego (pożyczka, leasing). Informacje o wysokości opłaty znajdziesz na karcie produktu, a szczegółowy zakres ubezpieczenia tutaj.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-7">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-7')">
                          <h4 class="text-left">CZY MOGĘ DOKUPIĆ DODATKOWE USŁUGI DO ZAMÓWIENIA SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Tak, możesz dokupić pakiet pogwarancyjny, który zapewni Ci bezproblemowe serwisowanie swojego samochodu przez 4 lata lub do osiągnięcia określonego limitu kilometrów.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-10">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-10')">
                          <h4 class="text-left">CZYM JEST OPŁATA WSTĘPNA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Wysokość opłaty wstępnej na naszej platformie jest wyrażona w kwocie brutto. Opłata wstępna, zwana również wpłatą początkową, lub czynszem inicjalnym jest opłatą ponoszoną przez Klienta na początku kontraktu finansowego. O wysokości opłaty wstępnej decyduje Klient wybierając z dostępnego zakresu.
                              <br /><br />
                              Od wysokości opłaty wstępnej zależy wysokość miesięcznych rat, w taki sposób, że im wyższa opłata wstępna, tym niższe miesięczne raty płacone przez Klienta.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-11">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-11')">
                          <h4 class="text-left">ILE MAM CZASU NA DOKONANIE OPŁATY WSTĘPNEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Na opłacenie opłaty wstępnej masz 7 dni, po tym terminie Twoje zamówienie będzie anulowane.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-12">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-12')">
                          <h4 class="text-left">JAK MOGĘ WYBRAĆ PREFEROWANY DZIEŃ ODBIORU SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Datę i godzinę odbioru ustali z Tobą sprzedawca.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-13">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-13')">
                          <h4 class="text-left">CZY MOGĘ ZREZYGNOWAĆ Z ZAMÓWIENIA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Tak, na każdym etapie procesu przed podpisaniem umowy o finansowanie możesz zrezygnować z zamówienia. Skontaktuj się w tym celu z dealerem u którego zamówiłeś auto.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-14">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-14')">
                          <h4 class="text-left">CO TO JEST LEASING?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              To forma finansowania auta, w której, w przeciwieństwie do pożyczki leasingobiorca użytkując pojazd, nie posiada go w rozumieniu prawnym, przez co jest zwolniony z wielu formalności wynikających z posiadania samochodu.
                              <br /><br />
                              SimplyDrive to szeroki wachlarz korzyści dla Twojej firmy:
                              <ul>
                                <li>Wyjątkowo niska miesięczna rata</li>
                                <li>Elastyczność zakończenia kontraktu – możliwość zwrotu auta:
                                  <ul>
                                    <li>Brak kłopotów z odsprzedażą (gwarantowana wartość odkupu samochodu, określona w momencie podpisania umowy leasingowej)</li>
                                    <li>Łatwość wymiany na nowe auto w ramach kolejnej umowy leasingowej</li>
                                  </ul>
                                </li>
                                <li>Możliwość jeżdżenia ciągle nowym samochodem – okres leasingu już od dwóch lat</li>
                                <li>Kompleksowy pakiet ubezpieczeń komunikacyjnych OC, AC, NNW, Assistance w atrakcyjnej cenie</li>
                                <li>Pakiet przeglądów serwisowych na 4 lata lub 60 000 km bez dodatkowych opłat</li>
                                <li>Możliwość odliczenia VAT przez firmy</li>
                              </ul>
                              <br />
                              Od teraz to wszystko dostępne także w wersji online. W ramach oferty łączymy prostotę i mobilność - podstawowe zalety
                              <br /><br />
                              SimplyDrive z jeszcze większą niż dotychczas wygodą. Cały proces począwszy od wybrania
                              modelu samochodu, a kończąc na uzyskaniu zgody na leasing odbywa się online.
                              Twoja pierwsza wizyta w salonie
                              <ng-container *ngIf="brandSlug === 'jeep'">Jeepa</ng-container>
                              <ng-container *ngIf="brandSlug === 'alfa-romeo'">Alfy Romeo</ng-container>
                              może nastąpić dopiero przy odbiorze samochodu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-15">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-15')">
                          <h4 class="text-left">ILE TRWA AKCEPTACJA WNIOSKU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Wniosek będzie analizowany od 15 minut do 48 godzin, czyli do końca kolejnego dnia roboczego. W przypadku dni wolnych od pracy czas ten może ulec wydłużeniu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-16">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-16')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O BRAKU LUB BŁĘDNYCH DOKUMENTACH LEASINGOWYCH, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Nic się nie stało! Przygotuj brakujące dokumenty, dołącz je do formularza i raz jeszcze wyślij swój wniosek leasingowy.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-17">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-17')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O NEGATYWNEJ DECYZJI, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              W tej sytuacji skontaktuje się z Tobą dealer aby przedstawić Ci alternatywne propozycje.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-18">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-18')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O WARUNKOWEJ DECYZJI, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Skontaktuje się z Tobą Dealer i przedstawi Ci możliwe rozwiązania. Jeżeli je zaakceptujesz, będziesz mógł kontynuować proces zakupowy.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-19">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-19')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O ZAAKCEPTOWANYM WNIOSKU, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Gratulujemy! Teraz możesz dokonać opłaty wstępnej i wybrać datę odbioru Twojego samochodu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-20">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-20')">
                          <h4 class="text-left">PO JAKIM CZASIE OD AKCEPTACJI WNIOSKU BĘDĘ MÓGŁ ODEBRAĆ SAMOCHÓD?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Proces rejestracji i ubezpieczenia auta oraz przygotowania go do wydania będzie trwał ok. 3-4 dni po dokonaniu opłaty wstępnej. Preferowaną datę odbioru możesz ustalić zaraz po dokonaniu opłaty wstępnej. O możliwym terminie odbioru samochodu poinformuje Cię dealer.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sol-accordion-box sol-field-get-terms" *ngIf="brandSlug === 'jeep' || brandSlug === 'alfa-romeo'">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-get-terms')">
                  <h3 class="text-left px-2 mb-2" *ngIf="brandSlug === 'jeep'">REGULAMIN SKLEPU JEEP</h3>
                  <h3 class="text-left px-2 mb-2" *ngIf="brandSlug === 'alfa-romeo'">REGULAMIN SKLEPU ALFA ROMEO</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description">
                  <div class="row">
                    <div class="col-12">
                      <ul>
                        <li>
                          <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">
                            <span>REGULAMIN USŁUGI</span>
                          </a>
                        </li>
                        <li class="mb-3">
                          <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">
                            <span>REGULAMIN PLATFORMY</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sol-accordion-box sol-field-get-documents">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-get-documents')">
                  <h3 class="text-left px-2">DOKUMENTY DO POBRANIA</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ul>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Umowa_leasingu_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa leasingu Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Umowa_leasingu_konsumenckiego_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa leasingu konsumenckiego Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Wniosek_leasingu_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek leasingu Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Wniosek_leasingu_konsumenckiego_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek leasingu konsumenckiego Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Ogolne_Warunki_Umowy_Leasing.pdf" target="_blank">
                            <span class="sol-doc">Ogólne Warunki Umowy - Leasing</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Ogolne_Warunki_Umowy_Leasing_Konsumencki.pdf" target="_blank">
                            <span class="sol-doc">Ogólne Warunki Umowy - Leasing Konsumencki</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <ul>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Umowa_pozyczki_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa pożyczki Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Umowa_pozyczki_dla_osob_fizycznych_Pozyczka_Konsumencka.pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa pożyczki dla osób fizycznych - Pożyczka Konsumencka Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Wniosek_pozyczki_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek pożyczki Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Wniosek_o_udzielenie_Pozyczki_Konsumenckiej_Stellantis_Consumer_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek o udzielenie Pożyczki Konsumenckiej Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Regulamin_udzielania_pozyczek_na_zakup_pojazdow_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Regulamin udzielania pożyczek na zakup pojazdów Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Regulamin_udzielania_pozyczek_na_zakup_pojazdow_Stellantis_Consumer_Financial_Services_Polska_Sp._z_o.o._dla_Pozyczki_Konsumenckiej.pdf" target="_blank">
                            <span class="sol-doc">Regulamin udzielania pożyczek na zakup pojazdów Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o. dla Pożyczki Konsumenckiej</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--sol-banner" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <h3 *ngIf="brandSlug === 'alfa-romeo'">Zamów Alfę Romeo online</h3>
          <h3 *ngIf="brandSlug === 'jeep'">Zamów Jeepa online</h3>

          <div class="sol-banner-items">
            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_1.png?v=2" />
              <span>Szeroki wybór samochodów dostępnych od ręki</span>
            </div>

            <div class="sol-banner-item" *ngIf="brandSlug === 'jeep' && insuranceSolPercent">
              <img src="/assets/img/icon/sol_2.png?v=2" />
              <span>Pakiet ubezpieczenia OC/AC za {{ insuranceSolPercent }} wartości pojazdu dla samochodów osobowych</span>
            </div>
            <div class="sol-banner-item" *ngIf="brandSlug === 'alfa-romeo' && insuranceSolPercent">
              <img src="/assets/img/icon/sol_2.png?v=2" />
              <span>Pakiet ubezpieczenia OC/AC za {{insuranceSolPercent}} wartości pojazdu dla samochodów osobowych</span>
            </div>

            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_3.png?v=2" />
              <span>Bezpieczna transakcja</span>
            </div>

            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_4.png?v=2" />
              <span>Wsparcie sprzedawcy</span>
              <span class="p-0" *ngIf="brandConfig.eSellerPhone">Zadzwoń: <span class="p-0">{{ brandConfig.eSellerPhone }}</span></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--similar-offers" *ngIf="similarOffers && similarOffers.length > 2">
      <div class="container">
        <div class="row">
          <div class="col-md-11 col-lg-3">
            <div class="headline headline--similar-offers text-uppercase pt-5">
              <h2 class="headline__title">Podobne oferty</h2>
            </div>
          </div>
          <div class="col-lg-8 col-xl-9">
            <div class="row models-wrap">
              <ng-container *ngIf="brandSlug === 'alfa-romeo'">
              <app-offer-card  class="col-12 col-lg-6 col-xl-4"
                               *ngFor="let item of similarOffers; let last = last"
                               [item]="item"
                               [ngClass]="(last)?'d-lg-none d-xl-block':null"
                               [customerType]="activeCustomer"></app-offer-card>
              </ng-container>
              <ng-container *ngIf="brandSlug !== 'alfa-romeo'">
              <app-offer-card  class="col-12 col-lg-6 col-xl-4"
                               *ngFor="let item of similarOffers; let last = last"
                               [item]="item"
                               [ngClass]="(last)?'d-lg-none d-xl-block':null"></app-offer-card>
              </ng-container>
            </div>
          </div>
          <div class="section__bg"></div>
        </div>
      </div>
    </section>
    <section class="section section--note">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 ml-lg-auto">
            <p class="mb-4 text-uppercase">Ogłoszenie wyświetlono {{offer.visitCount}} razy</p>
          </div>
        </div>
        <div class="row" *ngIf="isSol && brandSlug === 'jeep' && insuranceSolPercent === '1,7%' && insuranceSol">
          <div class="col-lg-9 ml-lg-auto">
            <p  class="mb-2">
              Pakiet ubezpieczeń OC/AC za 1,7% przy zamówieniu online
              <br />
              Ubezpieczenie OC/AC/NNW/HCA Premium dla pojazdów w pierwszym roku eksploatacji. Stała suma ubezpieczenia pojazdu przez okres 12 miesięcy, brak konsumpcji sumy ubezpieczenia oraz udziału własnego w przypadku wystąpienia szkody, zniesiona zwyżka w przypadku pojazdów będących własnością (lub współwłasnością) osób fizycznych w wieku do 29 lat, naprawa samochodu w Autoryzowanym Serwisie Obsługi Jeep, z zastosowaniem części oryginalnych. Pakiet ubezpieczeń dostępny za 1,7% w przypadku skorzystania przez Klienta z oferty zakupu online. Istnieje możliwość rozszerzenia ochrony o opcje dodatkowe - zwyżki z tytułu użytkowania oraz rozszerzenia ochrony dopłaca Klient zgodnie z tabelą zwyżek i zniżek. Szczegóły dotyczące promocji dostępne w salonach Jeep.
            </p>
          </div>
        </div>
        <div class="row" *ngIf="brandSlug === 'alfa-romeo' && additionalProtection">
          <div class="col-lg-9 ml-lg-auto">
            <p  class="mb-2">
              Pakiet dodatkowej ochrony serwisowej dotyczy wyłącznie samochodów Alfa Romeo Junior, Tonale, Giulia, Stelvio sprzedanych lub zamówionych w listopadzie 2024 i obejmuje 2 lata gwarancji umownej i 1 rok dodatkowej ochrony pogwarancyjnej do całkowitego limitu przebiegu 120.000 km. To świadczenie jest realizowane poprzez umowę Przedłużonej Ochrony - szczegóły tego pakietu dostępne są u autoryzowanych dealerów Alfa Romeo.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="sol-beam" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="headline-beam" *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]">
              <ng-container *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment">
                <span>{{ beamHeadline }}</span><br />
                <strong>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment | formatMoney }} zł</strong>
                <small> {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].priceType }} x {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].periodLabel }}</small>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-md-7 mt-4 mt-md-0 px-md-0 text-center text-md-right">
            <button *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true && brandSlug === 'alfa-romeo'"
                    class="btn btn-sol"
                    (click)="openOrCloseForm(true); ctaType = 'TD'">Zamów jazdę testową</button>
            <button *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                    class="btn btn-sol"
                    (click)="openOrCloseForm(true); ctaType = 'RP'">Poproś o kontakt</button>
            <button *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]
             && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment"
                    class="btn btn-sol"
                    (click)="goToCart()">Dodaj do koszyka</button>
            <button *ngIf="!availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]
             || !availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment"
                    class="btn btn-sol btn-sol-disabled">Dodaj do koszyka</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!--  NEW LAYOUT (FIAT)-->
  <ng-container *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'">
    <section class="section section--offer-section-1" >
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-data">

            <div #spacer></div>
            <div stickyThing [spacer]="spacer" [enable]="!isSol" class="headline headline-spacer row">
              <h3 class="headline__title ie-headline__title">POPROŚ O OFERTĘ</h3>
              <div class="headline__contact-box">
                <div class="contact-box-email" (click)="scrollToForm()" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H3.2L2 15.2V2H18V14ZM15 9H13V7H15M11 9H9V7H11M7 9H5V7H7" fill="#FEFFFF"/>
                  </svg>
                  <span *ngIf="brandSlug === 'fiat' && !isSol">NAPISZ DO NAS</span>
                  <span *ngIf="brandSlug === 'fiat-professional' && !isSol"
                        class="contact-box-no-spacer">NAPISZ DO NAS</span>
                  <span *ngIf="brandSlug === 'fiat-professional' && !isSol"
                        class="contact-box-spacer">POPROŚ O OFERTĘ</span>
                  <span *ngIf="brandSlug === 'fiat' && isSol" (click)="openOrCloseForm(true)">NAPISZ DO NAS</span>
                  <span *ngIf="brandSlug === 'fiat-professional' && isSol" (click)="openOrCloseForm(true)"
                        class="contact-box-no-spacer">NAPISZ DO NAS</span>
                </div>

                <div class="contact-box-phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                  </svg>
                  <span>+ 48 ... <small>POKAŻ NUMER</small></span>
                </div>

                <div class="contact-box-phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#FEFFFF"/>
                    </svg>
                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span>
                  </a>
                </div>
              </div>

              <div class="offer-info">
                <div class="offer-info__wrap d-flex w-100">
                  <div class="offer-info__headline">
                    <h3 class="offer-info__title">{{ offer.model.name }}</h3>
                    <h4 class="offer-info__subtitle">{{ offer.version.name }}</h4>
                  </div>
                  <div *ngIf="brandSlug !== 'alfa-romeo'" class="price offer-info__price">
                    <div class="price__tax d-flex">
                      <small class="price__text">BRUTTO:</small>
                      <span class="price__item-1"><span>{{offer.price.final.brutto | formatMoney }} ZŁ</span></span>
                    </div>
                    <div class="price__notax d-flex">
                      <small class="price__text">NETTO:</small>
                      <span class="price__item-1"><span>{{offer.price.final.netto | formatMoney }} ZŁ</span></span>
                    </div>
                  </div>
                </div>

                <div class="offer-info__favorite">
                  <div class="favorite-offer"
                       [ngClass]="{'favorite-offer--active': observedOffers.isObserved(offer.uid)}"
                       (click)="observedOffers.changeObservedStatus(offer.uid)">
                    <svg fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"></path>
                    </svg>
                  </div>
                </div>

              </div>
            </div>

            <div class="container d-flex flex-column px-0">

              <div class="headline headline-sol-beam" *ngIf="isSol && insuranceSolPercent">
                <div *ngIf="brandSlug === 'fiat'" class="headline-sol-beam-text">
                  <strong>Oferta specjalna:</strong> ubezpieczenie OC/AC za {{ insuranceSolPercent }} wartości auta przy zamówieniu online
                </div>
                <div *ngIf="brandSlug === 'fiat-professional'" class="headline-sol-beam-text">
                  <strong>Oferta specjalna:</strong> ubezpieczenie OC/AC za {{ insuranceSolPercent }} wartości auta
                </div>
              </div>
              <div class="headline headline-sol-beam headline-sol-beam-package" *ngIf="isSol && packageService">
                <div class="headline-sol-beam-text">
                  Pakiet Serwisowy Pełna Obsługa na 3&nbsp;lata&nbsp;/180&nbsp;000km za 1&nbsp;zł&nbsp;netto przy zamówieniu online

                  <span class="info-tooltip icon-info" (click)="showTooltip($event)" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                    <span class="tooltip-box" (click)="hideTooltipClick($event)">
                    Pakiet Serwisowy Pełna Obsługa zawiera ochronę pogwarancyjną, przeglądy i&nbsp;Fiat Assistance.
                      <a href="/assets/uploads/Warunki_pakietu_pelna_obsluga.pdf" target="_blank">Szczegóły Pakietu ></a>
                    </span>
                  </span>
                </div>
              </div>
              <div class="headline headline-sol-beam headline-sol-beam-package box-sol-count" *ngIf="isSol && packageService && solCounter > 0">
                <div class="headline-sol-beam-text">
                  {{ solCounterText }}
                </div>
              </div>

              <div class="row justify-content-center h-100">
                <div class="col-sm-8 col-md-6 col-lg-12">
                  <div class="row h-100">
                    <div class="col-12" [ngClass]="{'bg-white bg-white--1': offer.brand.slug == 'fiat'}">
                      <div class="headline headline--model-name headline--transparent w-100">
                        <div style="position: absolute; display: flex; top: 0; right: 0; width: 60px; height: 30px;">
                          <div style="position: relative;flex: 0 0 50%;">
                            <div class="compare-icon" (click)="comparedOffers.changeComparedStatus(offer.id)">
                              <div class="compare-icon-arrows">
                                <svg width="26" height="12" viewBox="0 0 26 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M19.7217 1.13393L24.8713 6.67667C24.9119 6.71837 24.9443 6.76854 24.9665 6.82412C24.9886 6.87969 25 6.93953 25 7C25 7.06047 24.9886 7.1203 24.9665 7.17588C24.9443 7.23146 24.9119 7.28162 24.8713 7.32333L19.7217 12.8661C19.642 12.9518 19.5339 13 19.4213 13C19.3086 13 19.2005 12.9518 19.1209 12.8661C19.0412 12.7803 18.9964 12.664 18.9964 12.5427C18.9964 12.4215 19.0412 12.3052 19.1209 12.2194L23.5338 7.46189L1.42913 7.4619C1.31532 7.4619 1.20617 7.41323 1.12569 7.32661C1.04521 7.23999 1 7.1225 1 7C1 6.8775 1.04521 6.76001 1.12569 6.67339C1.20617 6.58677 1.31532 6.5381 1.42913 6.5381L23.5338 6.5381L19.1209 1.78058C19.0412 1.69483 18.9964 1.57852 18.9964 1.45725C18.9964 1.33598 19.0412 1.21968 19.1209 1.13393C19.2005 1.04817 19.3086 1 19.4213 1C19.5339 1 19.642 1.04817 19.7217 1.13393Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
                                  <path d="M6.27835 12.8661L1.12874 7.32333C1.08806 7.28163 1.05567 7.23146 1.03354 7.17589C1.01141 7.12031 1 7.06047 1 7C0.999998 6.93953 1.01141 6.8797 1.03354 6.82412C1.05567 6.76854 1.08806 6.71838 1.12874 6.67667L6.27835 1.13393C6.35802 1.04817 6.46607 1 6.57874 1C6.69141 1 6.79947 1.04817 6.87914 1.13393C6.95881 1.21968 7.00356 1.33598 7.00356 1.45725C7.00356 1.57852 6.95881 1.69483 6.87914 1.78058L2.46621 6.5381L24.5709 6.5381C24.6847 6.5381 24.7938 6.58677 24.8743 6.67339C24.9548 6.76001 25 6.8775 25 7C25 7.1225 24.9548 7.23999 24.8743 7.32661C24.7938 7.41323 24.6847 7.4619 24.5709 7.4619L2.46621 7.4619L6.87914 12.2194C6.95881 12.3052 7.00356 12.4215 7.00356 12.5427C7.00356 12.664 6.95881 12.7803 6.87914 12.8661C6.79947 12.9518 6.69141 13 6.57874 13C6.46607 13 6.35802 12.9518 6.27835 12.8661Z" fill="#1B1C1B" stroke="#1B1C1B" stroke-width="0.7"/>
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div style="position: relative;flex: 0 0 50%;margin-top: 5px;">
                            <div class="favorite-offer"
                                 [ngClass]="{'favorite-offer--active': observedOffers.isObserved(offer.uid)}"
                                 (click)="observedOffers.changeObservedStatus(offer.uid)">
                              <svg fill="none" height="19" viewBox="0 0 21 19" width="21" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.47852 17.0751L9.47743 17.0742C6.75877 14.6431 4.56312 12.6755 3.03837 10.836C1.52151 9.00596 0.75 7.39658 0.75 5.69482C0.75 2.92032 2.94439 0.75 5.775 0.75C7.37914 0.75 8.92829 1.49024 9.93427 2.64607L10.5 3.29607L11.0657 2.64607C12.0717 1.49024 13.6209 0.75 15.225 0.75C18.0556 0.75 20.25 2.92032 20.25 5.69482C20.25 7.39658 19.4785 9.00596 17.9616 10.836C16.4369 12.6755 14.2412 14.6431 11.5226 17.0742L11.5215 17.0751L10.5 17.9921L9.47852 17.0751Z" stroke="#A80C42" stroke-width="1.5"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <h1 class="headline__title">{{ offer.model.name }}</h1>
                        <h2 class="headline__subtitle">{{ offer.version.name }}</h2>
                      </div>
                      <div class="price">
                        <div class="price__tax d-flex">
                          <small class="price__text">BRUTTO:</small>
                          <span class="price__item-1">{{ offer.price.final.brutto | formatMoney }} ZŁ</span>
                          <span class="price__item-2" *ngIf="offer.price.discount.amount.brutto > 0 && offer.price.discount.amount.netto > 0  && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'">
                          <span>{{ offer.price.base.brutto + (offer.price.shipping_fee | shippingFeeGross) | formatMoney }} ZŁ*</span>
                        </span>
                        </div>
                        <div class="price__notax d-flex">
                          <small class="price__text">NETTO:</small>
                          <span class="price__item-1">{{ offer.price.final.netto | formatMoney }} ZŁ</span>
                          <span class="price__item-2" *ngIf="offer.price.discount.amount.brutto > 0 && offer.price.discount.amount.netto > 0  && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'">
                          <span>{{ offer.price.base.netto + (offer.price.shipping_fee | shippingFeeGross) / 1.23 | formatMoney }} ZŁ*</span>
                        </span>
                        </div>
                      </div>
                      <div class="price-details-info"
                           *ngIf="offer.price.discount.amount.brutto > 0 && offer.price.discount.amount.netto > 0  && offer.dealer.code !== '0075206'  && offer.dealer.code !== '0720006'">
                        * Cena cennikowa importera z wyposażeniem dodatkowym
                      </div>
                      <div class="link-get-form-info" *ngIf="isSol && infoFormUrl !== null">
                        <a [href]="getInfoForm()">Pobierz formularz informacyjny</a>
                      </div>
                    </div>
                    <div class="col-3 col-sm-12 d-flex align-items-end link-list__wrap" [ngClass]="{'bg-white': offer.brand.slug == 'fiat'}">
                      <div class="link-list d-none d-sm-flex text-uppercase justify-content-end w-100">
                        <div class="link-list__item">
                          <a [href]="'/drukuj-oferte/' +  offer.id" data-adobe="content:stocklocator:print"
                             (click)="sendGA('send', 'event', 'link', 'click', 'print')">
                            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.80037 13.948C4.59338 13.948 4.45388 13.9678 4.38188 13.9876V15.2834C4.46737 15.3032 4.57425 15.3087 4.72162 15.3087C5.2605 15.3087 5.59237 15.0425 5.59237 14.5926C5.59237 14.19 5.30662 13.948 4.80037 13.948ZM8.72325 13.9612C8.49825 13.9612 8.352 13.981 8.26538 14.0008V16.8718C8.352 16.8916 8.4915 16.8916 8.6175 16.8916C9.53662 16.8982 10.1351 16.4032 10.1351 15.356C10.1419 14.443 9.59625 13.9612 8.72325 13.9612Z" fill="#1B1C1B"/>
                              <path d="M11.25 0H2.25C1.65326 0 1.08097 0.231785 0.65901 0.644365C0.237053 1.05695 0 1.61652 0 2.2V19.8C0 20.3835 0.237053 20.9431 0.65901 21.3556C1.08097 21.7682 1.65326 22 2.25 22H15.75C16.3467 22 16.919 21.7682 17.341 21.3556C17.7629 20.9431 18 20.3835 18 19.8V6.6L11.25 0ZM6.18525 15.609C5.83763 15.928 5.32462 16.071 4.72725 16.071C4.61142 16.0722 4.49564 16.0656 4.38075 16.0512V17.6198H3.375V13.2902C3.82889 13.224 4.28751 13.1938 4.74638 13.2C5.373 13.2 5.8185 13.3166 6.11888 13.5509C6.40462 13.7731 6.59812 14.1372 6.59812 14.5662C6.597 14.9974 6.45075 15.3615 6.18525 15.609ZM10.4681 17.0995C9.99563 17.4834 9.27675 17.666 8.39812 17.666C7.87162 17.666 7.49925 17.633 7.24613 17.6V13.2913C7.70018 13.2265 8.15866 13.196 8.6175 13.2C9.46913 13.2 10.0226 13.3496 10.4546 13.6686C10.9215 14.0074 11.214 14.5475 11.214 15.323C11.214 16.1623 10.9001 16.742 10.4681 17.0995ZM14.625 14.047H12.9015V15.0491H14.5125V15.8565H12.9015V17.6209H11.8822V13.233H14.625V14.047ZM10.875 6.6V1.1L16.5 6.6H10.875Z" fill="#1B1C1B"/>
                            </svg>
                          </a>
                        </div>
                        <div class="link-list__item mr-1">
                          <a (click)="share()" data-adobe="content:stocklocator:share">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 20C14.1667 20 13.4583 19.7083 12.875 19.125C12.2917 18.5417 12 17.8333 12 17C12 16.8833 12.0083 16.7623 12.025 16.637C12.0417 16.5123 12.0667 16.4 12.1 16.3L5.05 12.2C4.76667 12.45 4.45 12.6457 4.1 12.787C3.75 12.929 3.38333 13 3 13C2.16667 13 1.45833 12.7083 0.875 12.125C0.291667 11.5417 0 10.8333 0 10C0 9.16667 0.291667 8.45833 0.875 7.875C1.45833 7.29167 2.16667 7 3 7C3.38333 7 3.75 7.07067 4.1 7.212C4.45 7.354 4.76667 7.55 5.05 7.8L12.1 3.7C12.0667 3.6 12.0417 3.48767 12.025 3.363C12.0083 3.23767 12 3.11667 12 3C12 2.16667 12.2917 1.45833 12.875 0.875C13.4583 0.291667 14.1667 0 15 0C15.8333 0 16.5417 0.291667 17.125 0.875C17.7083 1.45833 18 2.16667 18 3C18 3.83333 17.7083 4.54167 17.125 5.125C16.5417 5.70833 15.8333 6 15 6C14.6167 6 14.25 5.929 13.9 5.787C13.55 5.64567 13.2333 5.45 12.95 5.2L5.9 9.3C5.93333 9.4 5.95833 9.51233 5.975 9.637C5.99167 9.76233 6 9.88333 6 10C6 10.1167 5.99167 10.2373 5.975 10.362C5.95833 10.4873 5.93333 10.6 5.9 10.7L12.95 14.8C13.2333 14.55 13.55 14.354 13.9 14.212C14.25 14.0707 14.6167 14 15 14C15.8333 14 16.5417 14.2917 17.125 14.875C17.7083 15.4583 18 16.1667 18 17C18 17.8333 17.7083 18.5417 17.125 19.125C16.5417 19.7083 15.8333 20 15 20Z" fill="#1B1C1B"/>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 align-self-end" [ngClass]="{'bg-white': offer.brand.slug == 'fiat'}">
                      <div class="gallery-thumb__wrap">
                        <div class="gallery-thumb swiper-container" [swiper]="configThumb" [(index)]="index">
                          <div class="swiper-wrapper">
                            <div class="gallery-thumb__item swiper-slide" *ngFor="let item of galleryVidImg; let i = index"
                                 (click)="selectIndex(i)" [ngClass]="{active: i==index}">
                              <div *ngIf=" item.type == 'video'" class="cg-min-video">
                                <img src="{{ item.path }}">
                                <div class="cg-min-video-play-button">
                                  <div class="cg-min-video-play-button-arrow"></div>
                                </div>
                              </div>
                              <img *ngIf=" item.type == 'image'" src="{{ item.originUrl }}?fm=pjpg&w=140&h=76&fit=crop&bg=white" (error)="setDefaultImg140x76($event)" alt="{{ offer.model.name }} {{ offer.version.name }}"/>
                            </div>
                          </div>
                        </div>
                        <div class="swiper-button-prev">
                          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.85106 16.8103L0.19149 8.95805C0.130979 8.89897 0.0828052 8.8279 0.0498882 8.74917C0.0169731 8.67043 -3.75291e-07 8.58566 -3.71547e-07 8.5C-3.67802e-07 8.41434 0.0169731 8.32957 0.0498883 8.25083C0.0828053 8.17209 0.130979 8.10103 0.19149 8.04195L7.85106 0.189728C7.96956 0.068247 8.13029 -5.20122e-07 8.29787 -5.11516e-07C8.46546 -5.02911e-07 8.62618 0.068247 8.74468 0.189728C8.86318 0.31121 8.92976 0.475974 8.92976 0.647775C8.92976 0.819576 8.86318 0.98434 8.74468 1.10582L2.18085 7.84565L19.3617 7.84565C19.531 7.84565 19.6933 7.91459 19.813 8.0373C19.9328 8.16002 20 8.32646 20 8.5C20 8.67354 19.9328 8.83998 19.813 8.9627C19.6933 9.08541 19.531 9.15435 19.3617 9.15435L2.18085 9.15435L8.74468 15.8942C8.86318 16.0157 8.92975 16.1804 8.92975 16.3522C8.92975 16.524 8.86318 16.6888 8.74468 16.8103C8.62618 16.9318 8.46546 17 8.29787 17C8.13029 17 7.96956 16.9318 7.85106 16.8103Z" fill="#1B1C1B"/>
                          </svg>
                        </div>
                        <div class="swiper-button-next">
                          <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475975 11.1368 0.31121 11.2553 0.189729C11.3738 0.0682483 11.5345 -5.04191e-07 11.7021 -5.11516e-07C11.8697 -5.18842e-07 12.0304 0.0682482 12.1489 0.189729Z" fill="#1B1C1B"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-gallery">
            <div class="gallery"
                 [ngClass]="{'lowest-price': (labelClassName.lowestPrice && !labelClassName.highestDiscount),
                 'highest-discount': !labelClassName.lowestPrice && labelClassName.highestDiscount,
                 'highest-discount-lowest-price': labelClassName.lowestPrice && labelClassName.highestDiscount}" >
              <div class="label-wrap">
                <app-offer-labels [offer]="offer"> </app-offer-labels>
              </div> 
              <swiper [config]="config" [(index)]="index">
                <div *ngFor="let item of galleryVidImg" (click)="lightBox.open(galleryVidImg, {loop: galleryVidImg.length > 1, index: index})">
                  <picture *ngIf="item.type == 'video'">
                    <div class="cg-video">
                      <img src="{{ item.path }}" class="box-model__photo-img cg-no-black-bar" alt="{{ offer.model.name }} {{ offer.version.name }}" />
                      <div class="cg-video-play-button">
                        <div class="cg-video-play-button-arrow"></div>
                      </div>
                    </div>
                  </picture>
                  <picture *ngIf="item.type == 'image'">
                    <source media="(min-width: 1600px)" srcset="{{ item.originUrl }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white">
                    <source media="(min-width: 1440px)" srcset="{{ item.originUrl }}?fm=pjpg&w=844&h=564&fit=crop&bg=white">
                    <source media="(min-width: 1200px)" srcset="{{ item.originUrl }}?fm=pjpg&w=759&h=507&fit=crop&bg=white">
                    <source media="(min-width: 992px)" srcset="{{ item.originUrl }}?fm=pjpg&w=631&h=422&fit=crop&bg=white">
                    <source media="(min-width: 768px)" srcset="{{ item.originUrl }}?fm=pjpg&w=769&h=514&fit=crop&bg=white">
                    <source media="(min-width: 576px)" srcset="{{ item.originUrl }}?fm=pjpg&w=591&h=395&fit=crop&bg=white">
                    <source media="(min-width: 480px)" srcset="{{ item.originUrl }}?fm=pjpg&w=437&h=292&fit=crop&bg=white">
                    <source media="(min-width: 360px)" srcset="{{ item.originUrl }}?fm=pjpg&w=446&h=298&fit=crop&bg=white">
                    <img src="{{ item.originUrl }}?fm=pjpg&w=1115&h=745&fit=crop&bg=white" class="box-model__photo-img" (error)="setDefaultImg800x600($event)" alt="{{ offer.model.name }} {{ offer.version.name }}" />
                  </picture>
                </div>
                <div class="label-wrap label-wrap-bottom" *ngIf="offer.status === 'reserved'">
                  <div class="label label--reservation text-uppercase">Rezerwacja</div>
                </div> 
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--offer-section-2">
      <div class="container">
        <div class="container-grid">
          <div class="container-grid--informations">
            <div class="headline">
              <h2 class="headline__title">Informacje</h2>
            </div>
            <div class="list">
              <div class="list__item" *ngIf="offer.dealer !== null">
                <div class="list__item-key">Lokalizacja</div>
                <div class="list__item-value">{{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, {{offer.dealer.region}}</ng-container></div>
              </div>
              <div class="list__item" *ngIf="offer.dealer === null">
                <div class="list__item-key">Lokalizacja</div>
                <div class="list__item-value">U KAŻDEGO DEALERA W POLSCE</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.engineCapacity && offer.technicalData.engineCapacity != '0.0' && offer.technicalData.engineCapacity != 0.0">
                <div class="list__item-key">POJEMNOŚĆ SKOKOWA</div>
                <div class="list__item-value">{{offer.technicalData.engineCapacity}} CM<sup>3</sup></div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.enginePower && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                <div class="list__item-key">MOC SILNIKA</div>
                <div class="list__item-value">{{offer.technicalData.enginePower}} KM</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.averageFuelConsumption && offer.technicalData.averageFuelConsumption != 'N/A' && offer.technicalData.averageFuelConsumption != 'NA'">
                <div class="list__item-key">ŚREDNIE SPALANIE</div>
                <div class="list__item-value">{{offer.technicalData.averageFuelConsumption}} L/100KM</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.co2Emission && offer.technicalData.co2Emission != 'N/A' && offer.technicalData.co2Emission != 'NA'">
                <div class="list__item-key">EMISJA CO<sub>2</sub></div>
                <div class="list__item-value">{{offer.technicalData.co2Emission}} G/KM</div>
              </div>
              <div class="list__item" *ngIf="offer.productionYear">
                <div class="list__item-key">ROK PRODUKCJi</div>
                <div class="list__item-value">{{offer.productionYear}}</div>
              </div>
              <div class="list__item" *ngIf="offer.color.name && offer.color.name != '-'">
                <div class="list__item-key">KOLOR NADWOZIA</div>
                <div class="list__item-value">{{offer.color.name}}</div>
              </div>
              <div class="list__item" *ngIf="offer.interior.name && offer.interior.name != '******************************'">
                <div class="list__item-key">RODZAJ TAPICERKI</div>
                <div class="list__item-value">{{offer.interior.name}}</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.fuelType && offer.technicalData.fuelType != 'nieokreślony'">
                <div class="list__item-key">RODZAJ PALIWA</div>
                <div class="list__item-value">{{offer.technicalData.fuelType}}</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.gearboxType">
                <div class="list__item-key">SKRZYNIA BIEGÓW</div>
                <div class="list__item-value">{{offer.technicalData.gearboxType}}</div>
              </div>
              <div class="list__item" *ngIf="offer.technicalData.driveType">
                <div class="list__item-key">NAPĘD</div>
                <div class="list__item-value">{{offer.technicalData.driveType}}</div>
              </div>
              <div class="list__item" *ngIf="showVin">
                <div class="list__item-key">VIN/NUMER SERYJNY</div>
                <div class="list__item-value">{{offer.vin ? offer.vin : offer.serialNo}}</div>
              </div>
            </div>
          </div>
          <div class="container-grid--offer-details">
            <div class="offer-details--equipment-optional" *ngIf="offer.equipment.standard && offer.equipment.standard.length > 0">
              <div class="offer-details-box" [ngClass]="{'open-box': openBox.equipmentStandard}">
                <div class="offer-details-box-button" (click)="openBox.equipmentStandard = !openBox.equipmentStandard"><span>Wyposażenie standardowe</span></div>
                <div class="offer-details-box-content">
                  <div class="offer-details-box-headline">
                  </div>
                  <app-equipment-list
                    [data]="offer.equipment.standard"
                    [showPrices]="false"
                    [showDetailsList]="true"></app-equipment-list>
                </div>
              </div>
            </div>
            <div class="offer-details--equipment-optional" *ngIf="offer.equipment.optional && offer.equipment.optional.length > 0">
              <div class="offer-details-box" [ngClass]="{'open-box': openBox.equipmentOptional}">
                <div class="offer-details-box-button" (click)="openBox.equipmentOptional = !openBox.equipmentOptional"><span>Wyposażenie dodatkowe</span></div>
                <div class="offer-details-box-content">
                  <div class="offer-details-box-headline">
                    <strong>Wyposażenie dodatkowe</strong><br />
                    (wliczone w cenę netto/brutto)
                  </div>
                  <app-equipment-list
                    [data]="offer.equipment.optional"
                    [showPrices]="true"
                    [showGrossPrice]="brandSlug === 'fiat'"
                    [showDetailsList]="true"></app-equipment-list>
                </div>
              </div>
            </div>
            <div class="offer-details--desc" *ngIf="offer.description && offer.description.length > 0">
              <div class="offer-details-box" [ngClass]="{'open-box': openBox.dealerDescription}">
                <div class="offer-details-box-button-no-arrow"><span>Opis oferty</span></div>
                <div class="offer-details-box-content open-box">
                  <div class="offer-details-box-content--desc"
                       [innerHTML]="offer.description | offerDescription"
                       [ngClass]="{'less-desc': hiddenText.description}"></div>
                  <div class="link link--more" *ngIf="hiddenText.description"><span (click)="hiddenText.description=false">POKAŻ WIĘCEJ</span></div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-grid--financing" *ngIf="offerFinancialCalculators['b2b'] !== null">
            <div class="headline">
              <h2 class="headline__title">Finansowanie</h2>
            </div>
            <div class="financing row" *ngIf="offerFinancialCalculators['b2b'] !== null">
<!--              nowa struktura danych-->
              <ng-container *ngIf="offerFinancialCalculators['b2b'] !== undefined">
                <ng-container *ngIf="customerTab">
                  <div class="financing__customers-tab">
                    <div class="financing__box col-md-12">
                      <div class="headline--transparent customers-tab">
                      <span class="customer-tab" (click)="selectCustomer('b2b')" [ngClass]="{'active': activeCustomer === 'b2b'}">
                        <h3 class="headline__title">Klient biznesowy</h3>
                      </span>
                        <span class="customer-tab" (click)="selectCustomer('b2c')" [ngClass]="{'active': activeCustomer === 'b2c'}">
                        <h3 class="headline__title">Klient indywidualny</h3>
                      </span>
                      </div>
                    </div>
                  </div>
  <!--              Klient biznesowy-->
                  <div class="financing row" [ngClass]="{'customer-box': customerTab}" [hidden]="activeCustomer === 'b2c'" *ngIf="offerFinancialCalculators['b2b'] !== undefined">
                    <ng-container *ngFor="let item of offerFinancialCalculators['b2b']; let i = index">
                      <div class="financing__box col-md-6" [ngClass]="{'animate-frozen': activeFinancialCalculatorId.b2b !== item.name && isSol}">
                        <div *ngIf="activeFinancialCalculatorId.b2b !== item.name && isSol" class="financing__box-frozen" (click)="selectFinancialCalculator('b2b', i, item.name)">
                          <span>wybierz</span>
                        </div>
                        <div class="financing__box-wrap">
                          <app-financial-api-calculator
                            [name]="item.name"
                            [calculation]="item.calculation"
                            [offer]="offer"
                            [addToCartBtn]="isSol"
                            [customerType]="'b2b'"
                            (calculationData)="setValue($event)"
                            (addToCart)="goToCart()"
                            (setSelectedPriceType)="setSelectedPriceType($event)"
                            [selectedPriceType]="selectedPriceType"
                          ></app-financial-api-calculator>
                        </div>
                      </div>
                    </ng-container>
                  </div>
  <!--              Klient indywidualny-->
                  <div class="financing row" [ngClass]="{'customer-box': customerTab}" [hidden]="activeCustomer === 'b2b'" *ngIf="offerFinancialCalculators['b2c'] !== undefined">
                    <ng-container *ngFor="let item of offerFinancialCalculators['b2c']; let i = index">
                      <div class="financing__box col-md-6" [ngClass]="{'animate-frozen': activeFinancialCalculatorId.b2c !== item.name && isSol}">
                        <div *ngIf="activeFinancialCalculatorId.b2c !== item.name && isSol" class="financing__box-frozen" (click)="selectFinancialCalculator('b2c', i, item.name)">
                          <span>wybierz</span>
                        </div>
                        <div class="financing__box-wrap">
                          <app-financial-api-calculator
                            [name]="item.name"
                            [calculation]="item.calculation"
                            [offer]="offer"
                            [addToCartBtn]="isSol"
                            [customerType]="'b2c'"
                            (calculationData)="setValue($event)"
                            (addToCart)="goToCart()"
                            (setSelectedPriceType)="setSelectedPriceType($event)"
                            [selectedPriceType]="selectedPriceType"
                          ></app-financial-api-calculator>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="!customerTab">
                  <ng-container *ngFor="let customer of offerFinancialCalculators | keyvalue;">
                    <ng-container  *ngFor="let item of customer.value; let i = index">
                      <div class="financing__box col-md-6 pr-0" *ngIf="i === 0">
                        <div class="financing__box-wrap">
                          <app-financial-api-calculator
                            [name]="item.name"
                            [calculation]="item.calculation"
                            [offer]="offer"
                            [addToCartBtn]="false"
                            (calculationData)="setValue($event)"
                            (setSelectedPriceType)="setSelectedPriceType($event)"
                            [selectedPriceType]="selectedPriceType"
                          ></app-financial-api-calculator>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div class="financing row" *ngIf="isSol">
              <div class="financing--sol-info"
                   *ngIf="serviceFlex && activeCustomer === 'b2c' && activeFinancialCalculatorId.b2c === 'b2c'">
                <h4>Dodatkowe korzyści</h4>
                <div class="sol-info-table">
                  <div class="sol-info-item">
                    Ubezpieczenie AC/OC/NNW
                  </div>
                  <div class="sol-info-item">
                    Gwarantowany odkup
                  </div>
                  <div class="sol-info-item">
                    Elastyczny termin zakończenia umowy: FLEX <span class="icon-info cursor-pointer" (click)="showExtraServicesInfo()"></span>
                  </div>
                </div>
              </div>
              <div class="financing--sol-info">
                <h4>Dodatkowe opłaty brutto w&nbsp;zamówieniu <span>on-line:</span></h4>
                <div class="sol-info-table">
                  <ng-container>
                    <div class="sol-info-key">
                      Ubezpieczenie AC/OC/NNW:
                      <span class="info-tooltip icon-info" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                        <span class="tooltip-box">
                        Cena za rekomendowane ubezpieczenie pakietowe pojazdu, ze zniesionymi wszelkimi udziałami własnymi po stronie Klienta. Stawka podlega weryfikacji zgodnie z indywidualna historią ubezpieczeniową.
                        </span>
                      </span>
                    </div>
                    <div *ngIf="insuranceSol !== null" class="sol-info-value">{{ insuranceSol | formatMoney }} ZŁ</div>
                    <div *ngIf="insuranceSol === null" class="sol-info-value">Zapytaj dealera</div>
                  </ng-container>
                  <div class="sol-info-key">
                    Opłata za rejestrację:
                    <span class="info-tooltip icon-info" (mouseover)="showTooltip($event)" (mouseleave)="hideTooltip($event)">
                        <span class="tooltip-box">
                        Opłata jednorazowa doliczana do czynszu inicjalnego, tytułem czynności rejestracyjnych pojazdu
                        </span>
                      </span>
                  </div>
                  <div class="sol-info-value">480 zł</div>
                </div>
              </div>
            </div>
            <div class="financing row" *ngIf="brandConfig.eSellerPhone">
              <div class="financing--sol-info">
                <h4>Masz pytania lub potrzebujesz pomocy w zamówieniu online? Zadzwoń: <span>{{ brandConfig.eSellerPhone }}</span></h4>
              </div>
            </div>


            <div class="financing row" *ngIf="optionalInsuranceForBev">
              <div class="financing--sol-info optional-insurance">
                <div class="optional-insurance-headline">
                  <div class="optional-insurance-logo">
                    <img src="/assets/img/optional-insurance-logo-black.png" />
                  </div>
                  <div class="optional-insurance-title">
                    Ubezpieczenie dopasowane do Twojego elektryka zapewni ochronę&nbsp;m.in.:
                  </div>
                </div>
                <ul class="optional-insurance-list">
                  <li>na wypadek pożaru, wybuchu czy samozapłonu,</li>
                  <li>działania wilgoci lub mrozu na baterię trakcyjną,</li>
                  <li>przed zawirusowaniem lub włamaniem do systemów.</li>
                </ul>
                <small>O szczegóły zapytaj dealera</small>
                <div class="pt-3 text-right">
                  <a href="/assets/uploads/ERGO_EV_PAKIET_w_pigulce.pdf" target="_blank" class="link-more-info">
                    <span>Więcej informacji <i class="icon icon-right_arrow"></i></span>
                  </a>
                </div>
              </div>
            </div>



            <div class="info-km0" *ngIf="offer.is_km0">
              Samochód nowy, zarejestrowany, bez przebiegu.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--offer-section-3">
      <div class="container">
        <div class="row">
          <div class="col-md-11 col-lg-4" *ngIf="offer.dealer === null">
            <div class="headline headline--dealer">
              <h2 class="headline__title">Lokalizacja</h2>
            </div>
            <ng-container *ngIf="!isSol">
              <div class="headline__info">
                <small>Samochód dostępny na placu centralnym FCA Poland i&nbsp;dostępny u&nbsp;każdego dealera w&nbsp;Polsce</small>
              </div>
            </ng-container>
          </div>

          <div class="col-md-11 col-lg-4" *ngIf="offer.dealer !== null">
            <div class="headline headline--dealer">
              <h2 class="headline__title">Sprzedawca</h2>
            </div>
            <ng-container *ngIf="!isSol">
              <div class="headline__info" *ngIf="offer.dealer.subdealer !== true">
                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon--show-map">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                    </svg>
                    Pokaż na mapie
                  </a>
                </p>

                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                  </svg>
                  +48 ... <small>POKAŻ NUMER</small>
                </div>
                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                    </svg>
                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>
                </div>
                <div class="headline__communicators"
                     *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                  <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                  <div *ngFor="let item of offer.dealer.communicators">
                    <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                    <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                  </div>
                </div>
              </div>
              <div class="headline__info d-block d-lg-none" *ngIf="offer.dealer.subdealer === true">
                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon--show-map">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                    </svg>
                    Pokaż na mapie
                  </a>
                </p>

                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                  </svg>
                  +48 ... <small>POKAŻ NUMER</small>
                </div>
                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                    </svg>
                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>
                </div>
                <div class="headline__communicators"
                     *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                  <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                  <div *ngFor="let item of offer.dealer.communicators">
                    <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                    <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                  </div>
                </div>
              </div>
              <div class="dealer-all-offer">
                <a [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
                  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
                  </svg>
                  <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                </a>
              </div>
            </ng-container>
            <div class="dealer-all-offer d-none d-lg-block" *ngIf="isSol && offer.dealer.subdealer">
              <a [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
                </svg>
                <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
              </a>
            </div>
          </div>

          <div class="col-md-11 col-lg-8 px-0 px-sm-4">
            <ng-container *ngIf="isSol">
              <ng-container *ngIf="offer.dealer === null">
                <div class="headline__info w-100 mt-lg-0">
                  <small>Samochód dostępny na placu centralnym FCA Poland i&nbsp;dostępny u&nbsp;każdego dealera w&nbsp;Polsce</small>
                </div>
              </ng-container>
              <ng-container *ngIf="offer.dealer !== null">
                <div class="headline__info w-100 mt-lg-0" *ngIf="offer.dealer.subdealer !== true">
                  <p class="headline__info--dealer-name">
                    {{offer.dealer.name}}
                  </p>
                  <p><em>{{offer.dealer.street}}<br />
                    {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                    <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                       target="_blank"
                       class="btn-icon btn-icon--show-map">
                      <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                      </svg>
                      Pokaż na mapie
                    </a>
                  </p>

                  <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                    </svg>
                    +48 ... <small>POKAŻ NUMER</small>
                  </div>
                  <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                    <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                      <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                      </svg>
                      <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>
                  </div>
                  <div class="headline__communicators"
                       *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                    <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                    <div *ngFor="let item of offer.dealer.communicators">
                      <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                      <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                    </div>
                  </div>
                </div>
                <div class="headline__info d-block d-lg-none" *ngIf="offer.dealer.subdealer === true">
                  <p class="headline__info--dealer-name w-100">
                    {{offer.dealer.name}}
                  </p>
                  <p><em>{{offer.dealer.street}}<br />
                    {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                    <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                       target="_blank"
                       class="btn-icon btn-icon--show-map">
                      <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                      </svg>
                      Pokaż na mapie
                    </a>
                  </p>

                  <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                    </svg>
                    +48 ... <small>POKAŻ NUMER</small>
                  </div>
                  <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                    <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                      <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                      </svg>
                      <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>
                  </div>
                  <div class="headline__communicators"
                       *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                    <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                    <div *ngFor="let item of offer.dealer.communicators">
                      <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                      <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                    </div>
                  </div>
                </div>
                <div class="dealer-all-offer d-block d-lg-none" *ngIf="isSol && offer.dealer.subdealer">
                  <a [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
                    </svg>
                    <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                  </a>
                </div>
                <div class="dealer-all-offer w-100" *ngIf="isSol && !offer.dealer.subdealer">
                  <a [routerLink]="'/dealer/' + offer.dealer.code | addUrlPrefix" *ngIf="appComponentReference.isDealerStock === false">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#1B1C1B"/>
                    </svg>
                    <span>ZOBACZ WSZYSTKIE OFERTY TEGO&nbsp;DEALERA</span>
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <div class="section-offer--form" *ngIf="!isSol">
              <app-form [cta]="'getaquote'" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                        [dealer]="offer.dealer.code + '.' + offer.dealer.sitecode"
                        [model_id]="offer.model.commercialCode"
                        [vin]="offer.vin"
                        [adobeThankyouPageData]="adobeThankyouPageData"
                        [brand_slug]="offer.brand.slug"
                        [showAgreementDescription]="true"
              ></app-form>
              <app-form [cta]="'getaquote_invenduto'" *ngIf="offer.dealer === null && !appComponentReference.isDealerStock"
                        [dealer]="null"
                        [model_id]="offer.model.commercialCode"
                        [vin]="offer.vin"
                        [adobeThankyouPageData]="adobeThankyouPageData"
                        [brand_slug]="offer.brand.slug"
                        [showAgreementDescription]="true"
              ></app-form>
            </div>

            <div class="modal-form" *ngIf="modalFormIsOpen && isSol">
              <div class="modal-form-dialog">
                <div class="modal-header border-0">
                  <span aria-label="Zamknij" (click)="openOrCloseForm(false)">
                    <span class="icon-x"></span>
                  </span>
                </div>
                <div class="section-offer--form">
                  <app-form [cta]="'getaquote'" *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                            [dealer]="offer.dealer.code + '.' + offer.dealer.sitecode"
                            [model_id]="offer.model.commercialCode"
                            [vin]="offer.vin"
                            [adobeThankyouPageData]="adobeThankyouPageData"
                            [brand_slug]="offer.brand.slug"
                            [showAgreementDescription]="false"
                  ></app-form>
                  <app-form [cta]="'getaquote_invenduto'" *ngIf="offer.dealer === null && !appComponentReference.isDealerStock"
                            [dealer]="null"
                            [model_id]="offer.model.commercialCode"
                            [vin]="offer.vin"
                            [adobeThankyouPageData]="adobeThankyouPageData"
                            [brand_slug]="offer.brand.slug"
                            [showAgreementDescription]="false"
                  ></app-form>
                </div>
              </div>
            </div>

            <app-flex-modal
              *ngIf="serviceFlex && offer && offer.price"
              [(showModal)]="showModalFlex"
              [price]="offer.price"
            ></app-flex-modal>

            <div *ngIf="offer.dealer !== null && offer.dealer.subdealer === true" class="subdealer-box-info">

              <div class="headline__info d-none d-lg-block" style="border-bottom: 0; flex: 0 0 70%">

                <p class="headline__info--dealer-name">
                  {{offer.dealer.name}}
                </p>
                <p><em>{{offer.dealer.street}}<br />
                  {{offer.dealer.post_code}} {{offer.dealer.city}}<ng-container *ngIf="offer.dealer.region">, WOJ. {{offer.dealer.region}}</ng-container></em><br />
                  <a href="https://www.google.com/maps/dir/?api=1&destination={{offer.dealer.coordinates.latitude}},{{offer.dealer.coordinates.longitude}}"
                     target="_blank"
                     class="btn-icon btn-icon--show-map">
                    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.5026 7.50033C6.89219 7.50033 7.22581 7.36149 7.50348 7.08383C7.78067 6.80663 7.91927 6.47324 7.91927 6.08366C7.91927 5.69408 7.78067 5.36045 7.50348 5.08278C7.22581 4.80559 6.89219 4.66699 6.5026 4.66699C6.11302 4.66699 5.77963 4.80559 5.50244 5.08278C5.22477 5.36045 5.08594 5.69408 5.08594 6.08366C5.08594 6.47324 5.22477 6.80663 5.50244 7.08383C5.77963 7.36149 6.11302 7.50033 6.5026 7.50033ZM6.5026 14.5837C4.60191 12.9663 3.18241 11.4639 2.2441 10.0765C1.30533 8.68962 0.835938 7.40588 0.835938 6.22533C0.835938 4.45449 1.40567 3.04373 2.54515 1.99303C3.68415 0.942339 5.0033 0.416992 6.5026 0.416992C8.00191 0.416992 9.32106 0.942339 10.4601 1.99303C11.5995 3.04373 12.1693 4.45449 12.1693 6.22533C12.1693 7.40588 11.7001 8.68962 10.7618 10.0765C9.82304 11.4639 8.4033 12.9663 6.5026 14.5837Z" fill="#1B1C1B"/>
                    </svg>
                    Pokaż na mapie
                  </a>
                </p>

                <div class="headline__phone" *ngIf="!freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0" (click)="showPhone()">
                  <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                  </svg>
                  +48 ... <small>POKAŻ NUMER</small>
                </div>
                <div class="headline__phone" *ngIf="freespeeDealerPhoneNumber && offer.dealer.phones && offer.dealer.phones.length > 0">
                  <a data-adobe="content:stocklocator:call" href="tel:{{freespeeDealerPhoneNumber}}">
                    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V19.5C0 20.163 0.263392 20.7989 0.732233 21.2678C1.20107 21.7366 1.83696 22 2.5 22H10.5C11.163 22 11.7989 21.7366 12.2678 21.2678C12.7366 20.7989 13 20.163 13 19.5V2.5C13 1.83696 12.7366 1.20107 12.2678 0.732233C11.7989 0.263392 11.163 0 10.5 0ZM6.5 21C5.67 21 5 20.33 5 19.5C5 18.67 5.67 18 6.5 18C7.33 18 8 18.67 8 19.5C8 20.33 7.33 21 6.5 21ZM11 17H2V3H11V17Z" fill="#1B1C1B"/>
                    </svg>
                    <span [innerHTML]="formatNumber(freespeeDealerPhoneNumber)"></span></a>
                </div>
                <div class="headline__communicators"
                     *ngIf="offer.dealer !== null && offer.dealer.communicators !== null && offer.dealer.communicators.length > 0 && showComunicators()">
                  <p class="headline__communicators-title">Porozmawiaj na czacie:</p>
                  <div *ngFor="let item of offer.dealer.communicators">
                    <div class="pb-2" *ngIf="item.name === 'Messenger'"><a href="https://m.me/{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'Messenger')" target="_blank"><img src="assets/img/icon/messenger-dark.png"> {{item.name}}</a></div>
                    <div class="pb-2" *ngIf="item.name === 'WhatsApp'"><a href="https://wa.me/48{{item.id}}" (click)="sendGA('send', 'event', 'link', 'click', 'WhatsApp')" target="_blank"><img src="assets/img/icon/whatsapp-dark.png"> {{item.name}}</a></div>
                  </div>
                </div>
              </div>
              <div class="subdealer-map-wrap">
                <div class="subdealer-map" id="map"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section--sol-banner" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <h3 *ngIf="brandSlug === 'fiat'">Zamów Fiata online</h3>
          <h3 *ngIf="brandSlug === 'fiat-professional'">Zamów Fiata Professional online</h3>

          <div class="sol-banner-items">
            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_1.png?v=2" />
              <span>Szeroki wybór samochodów dostępnych od ręki</span>
            </div>

            <div class="sol-banner-item" *ngIf="insuranceSolPercent && brandSlug !== 'fiat-professional'">
              <img src="/assets/img/icon/sol_2.png?v=2" />
              <span>Pakiet ubezpieczenia OC/AC za {{ insuranceSolPercent }} wartości auta</span>
            </div>

            <div class="sol-banner-item" *ngIf="packageService">
              <img src="/assets/img/icon/sol_2.png?v=2" />
              <span>Pakiet serwisowy (3&nbsp;lata/180&nbsp;000&nbsp;km) za 1&nbsp;zł przy zamówieniu online</span>
            </div>

            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_3.png?v=2" />
              <span>Bezpieczna transakcja</span>
            </div>

            <div class="sol-banner-item">
              <img src="/assets/img/icon/sol_4.png?v=2" />
              <span>Wsparcie sprzedawcy</span>
              <span class="p-0" *ngIf="brandConfig.eSellerPhone">Zadzwoń: <span class="p-0">{{ brandConfig.eSellerPhone }}</span></span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--offer-section-4" *ngIf="similarOffers && similarOffers.length > 2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="headline">
              <h2 class="headline__title">Podobne oferty</h2>
            </div>
          </div>
          <div class="col-12">
            <div class="row models-wrap">
              <app-offer-box class="col-6 col-lg-4" *ngFor="let item of similarOffers; let last = last" [item]="item" [ngClass]="(last)?'d-none d-lg-block':null"></app-offer-box>
            </div>
          </div>
          <div class="section__bg"></div>
        </div>
      </div>
    </section>
    <section class="section section--sol-documents" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="sol-accordion-wrap">
              <div class="sol-accordion-box sol-field-faq">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-faq')">
                  <h3 class="text-left px-2 mb-2">FAQ</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description pl-3">
                  <div class="row">
                    <div class="col-12 pl-4">
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-1">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-1')">
                          <h4 class="text-left">JAK ODNALEŹĆ SAMOCHODY DEALERA W MOJEJ OKOLICY?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              W polu wyszukiwarki możesz wybrać swoją lokalizacje, strona internetowa wyświetli auta z koncesji najbliżej Ciebie.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-2">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-2')">
                          <h4 class="text-left">JAK KORZYSTAĆ Z FILTRÓW PRZY WYBORZE SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Jeśli masz sprecyzowane oczekiwania wobec swojego nowego auta, ale nie wiesz jeszcze, który
                              <ng-container *ngIf="brandSlug === 'fiat'">model Fiata</ng-container>
                              <ng-container *ngIf="brandSlug === 'fiat-professional'">model Fiata Professional</ng-container>
                              interesuje Cię najbardziej, skorzystaj z filtrów wyszukiwania.
                              <br />
                              <br />
                              Na liście ofert możesz przeszukiwać bazę aut na podstawie preferowanej ceny, modelu rodzaju, rodzaju paliwa, roku produkcyjnego, lokalizacji lub wielu innych możliwości parametryzowania.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-3">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-3')">
                          <h4 class="text-left">JAK ZMIENIAĆ PARAMETRY FINANSOWE OFERTY?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Parametry finansowe możesz zmienić na karcie produktu w sekcji "FINANSOWANIE". Możesz dostosować wysokość wpłaty własnej, czas trwania umowy oraz roczny limit kilometrów.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-4">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-4')">
                          <h4 class="text-left">O JAKICH KOSZTACH PAMIĘTAĆ PRZY ZAMÓWIENIU SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Na karcie konkretnego pojazdu jest pełna informacja o kosztach związanych z zawarciem umowy finansowej na konkretne auto. Przede wszystkim należy zwrócić uwagę na wysokość raty miesięcznej, czas trwania kontraktu oraz na wysokość opłaty wstępnej.
                              <br /><br />
                              Dodatkowo, zgodnie z polskimi przepisami, aby zapewnić Ci możliwość wyjechania z salonu samochodem auto musi być ubezpieczone. Dlatego w tej samej sekcji strony znajduje się informacja o wysokości kosztu za polisę ubezpieczenia OC/AC/NW.
                              <br /><br />
                              Ostatnią opłatą o jakiej należy pamiętać to koszt rejestracji samochodu – aktualna cena znajduje się na karcie samochodu. Faktura za opłatę rejestracyjną zostanie przesłana do Klienta po uruchomieniu umowy finansowania.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-5">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-5')">
                          <h4 class="text-left">ILE TO WSZYSTKO TRWA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Zakładamy, że od momentu złożenia zamówienia do odbioru samochodu z salonu nie minie więcej niż 14 dni. Na długość procesu ma wpływ przede wszystkim czas potrzebny na wydanie decyzji (od 15 minut do maksymalnie 48h – do końca następnego po złożeniu wniosku dnia roboczego), czas rejestracji samochodu w wydziale komunikacji starostwa powiatowego lub miasta oraz czas jaki będziesz potrzebował na dostarczenie wymaganych dokumentów aby złożyć wniosek o finansowanie.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-6">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-6')">
                          <h4 class="text-left">JAK MOGĘ OPŁACIĆ UBEZPIECZENIE?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Ubezpieczenie opłacić możesz w racie lub jednorazowym przelewem w zależności od wyboru produktu finansowego (pożyczka, leasing). Informacje o wysokości opłaty znajdziesz na karcie produktu, a szczegółowy zakres ubezpieczenia tutaj.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-7">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-7')">
                          <h4 class="text-left">CZY MOGĘ DOKUPIĆ DODATKOWE USŁUGI DO ZAMÓWIENIA SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Tak, możesz dokupić pakiet pogwarancyjny, który zapewni Ci bezproblemowe serwisowanie swojego samochodu przez 4 lata lub do osiągnięcia określonego limitu kilometrów.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-10">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-10')">
                          <h4 class="text-left">CZYM JEST OPŁATA WSTĘPNA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Wysokość opłaty wstępnej na naszej platformie jest wyrażona w kwocie brutto. Opłata wstępna, zwana również wpłatą początkową, lub czynszem inicjalnym jest opłatą ponoszoną przez Klienta na początku kontraktu finansowego. O wysokości opłaty wstępnej decyduje Klient wybierając z dostępnego zakresu.
                              <br /><br />
                              Od wysokości opłaty wstępnej zależy wysokość miesięcznych rat, w taki sposób, że im wyższa opłata wstępna, tym niższe miesięczne raty płacone przez Klienta.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-11">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-11')">
                          <h4 class="text-left">ILE MAM CZASU NA DOKONANIE OPŁATY WSTĘPNEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Na opłacenie opłaty wstępnej masz 7 dni, po tym terminie Twoje zamówienie będzie anulowane.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-12">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-12')">
                          <h4 class="text-left">JAK MOGĘ WYBRAĆ PREFEROWANY DZIEŃ ODBIORU SAMOCHODU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Datę i godzinę odbioru ustali z Tobą sprzedawca.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-13">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-13')">
                          <h4 class="text-left">CZY MOGĘ ZREZYGNOWAĆ Z ZAMÓWIENIA?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Tak, na każdym etapie procesu przed podpisaniem umowy o finansowanie możesz zrezygnować z zamówienia. Skontaktuj się w tym celu z dealerem u którego zamówiłeś auto.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-14">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-14')">
                          <h4 class="text-left">CO TO JEST LEASING?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              To forma finansowania auta, w której, w przeciwieństwie do pożyczki leasingobiorca użytkując pojazd, nie posiada go w rozumieniu prawnym, przez co jest zwolniony z wielu formalności wynikających z posiadania samochodu.
                              <br /><br />
                              SimplyDrive to szeroki wachlarz korzyści dla Twojej firmy:
                              <ul>
                                <li>Wyjątkowo niska miesięczna rata</li>
                                <li>Elastyczność zakończenia kontraktu – możliwość zwrotu auta:
                                  <ul>
                                    <li>Brak kłopotów z odsprzedażą (gwarantowana wartość odkupu samochodu, określona w momencie podpisania umowy leasingowej)</li>
                                    <li>Łatwość wymiany na nowe auto w ramach kolejnej umowy leasingowej</li>
                                  </ul>
                                </li>
                                <li>Możliwość jeżdżenia ciągle nowym samochodem – okres leasingu już od dwóch lat</li>
                                <li>Kompleksowy pakiet ubezpieczeń komunikacyjnych OC, AC, NNW, Assistance w atrakcyjnej cenie</li>
                                <li>Pakiet przeglądów serwisowych na 4 lata lub 60 000 km bez dodatkowych opłat</li>
                                <li>Możliwość odliczenia VAT przez firmy</li>
                              </ul>
                              <br />
                              Od teraz to wszystko dostępne także w wersji online. W ramach oferty łączymy prostotę i mobilność - podstawowe zalety
                              <br /><br />
                              SimplyDrive z jeszcze większą niż dotychczas wygodą. Cały proces począwszy od wybrania modelu samochodu, a kończąc na uzyskaniu zgody na leasing odbywa się online. Twoja pierwsza wizyta w salonie
                              <ng-container *ngIf="brandSlug === 'fiat'">Fiata</ng-container>
                              <ng-container *ngIf="brandSlug === 'fiat-professional'">Fiata Professional</ng-container>
                              może nastąpić dopiero przy odbiorze samochodu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-15">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-15')">
                          <h4 class="text-left">ILE TRWA AKCEPTACJA WNIOSKU?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Wniosek będzie analizowany od 15 minut do 48 godzin, czyli do końca kolejnego dnia roboczego. W przypadku dni wolnych od pracy czas ten może ulec wydłużeniu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-16">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-16')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O BRAKU LUB BŁĘDNYCH DOKUMENTACH LEASINGOWYCH, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Nic się nie stało! Przygotuj brakujące dokumenty, dołącz je do formularza i raz jeszcze wyślij swój wniosek leasingowy.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-17">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-17')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O NEGATYWNEJ DECYZJI, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              W tej sytuacji skontaktuje się z Tobą dealer aby przedstawić Ci alternatywne propozycje.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-18">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-18')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O WARUNKOWEJ DECYZJI, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Skontaktuje się z Tobą Dealer i przedstawi Ci możliwe rozwiązania. Jeżeli je zaakceptujesz, będziesz mógł kontynuować proces zakupowy.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-19">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-19')">
                          <h4 class="text-left">DOSTAŁEM INFORMACJĘ O ZAAKCEPTOWANYM WNIOSKU, CO DALEJ?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Gratulujemy! Teraz możesz dokonać opłaty wstępnej i wybrać datę odbioru Twojego samochodu.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sol-accordion-box sol-field-faq sol-field-faq-20">
                        <div class="sol-accordion-box--title box--title-faq" (click)="openSolFaqDescription('sol-field-faq-20')">
                          <h4 class="text-left">PO JAKIM CZASIE OD AKCEPTACJI WNIOSKU BĘDĘ MÓGŁ ODEBRAĆ SAMOCHÓD?</h4>
                          <div class="sol-accordion-box-faq-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            </svg>
                          </div>
                        </div>
                        <div class="sol-accordion-box--description-faq">
                          <div class="row">
                            <div class="col-12">
                              Proces rejestracji i ubezpieczenia auta oraz przygotowania go do wydania będzie trwał ok. 3-4 dni po dokonaniu opłaty wstępnej. Preferowaną datę odbioru możesz ustalić zaraz po dokonaniu opłaty wstępnej. O możliwym terminie odbioru samochodu poinformuje Cię dealer.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sol-accordion-box sol-field-get-terms" *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-get-terms')">
                  <h3 class="text-left px-2 mb-2" *ngIf="brandSlug === 'fiat'">REGULAMIN SKLEPU FIAT</h3>
                  <h3 class="text-left px-2 mb-2" *ngIf="brandSlug === 'fiat-professional'">REGULAMIN SKLEPU FIAT PROFESSIONAL</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description">
                  <div class="row">
                    <div class="col-12">
                      <ul>
                        <li>
                          <a [routerLink]="'/regulamin-uslugi' | addUrlPrefix">
                            <span>REGULAMIN USŁUGI</span>
                          </a>
                        </li>
                        <li class="mb-3">
                          <a [routerLink]="'/regulamin-platformy' | addUrlPrefix">
                            <span>REGULAMIN PLATFORMY</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sol-accordion-box sol-field-get-documents">
                <div class="sol-accordion-box--title" (click)="openSolDescription('sol-field-get-documents')">
                  <h3 class="text-left px-2">DOKUMENTY DO POBRANIA</h3>
                  <div class="sol-accordion-box-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </div>
                </div>
                <div class="sol-accordion-box--description">
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <ul>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Umowa_leasingu_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa leasingu Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Umowa_leasingu_konsumenckiego_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa leasingu konsumenckiego Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Wniosek_leasingu_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek leasingu Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Wniosek_leasingu_konsumenckiego_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek leasingu konsumenckiego Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2B/Ogolne_Warunki_Umowy_Leasing.pdf" target="_blank">
                            <span class="sol-doc">Ogólne Warunki Umowy - Leasing</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Leasing_B2C/Ogolne_Warunki_Umowy_Leasing_Konsumencki.pdf" target="_blank">
                            <span class="sol-doc">Ogólne Warunki Umowy - Leasing Konsumencki</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <ul>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Umowa_pozyczki_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa pożyczki Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Umowa_pozyczki_dla_osob_fizycznych_Pozyczka_Konsumencka.pdf" target="_blank">
                            <span class="sol-doc-signature">Umowa pożyczki dla osób fizycznych - Pożyczka Konsumencka Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Wniosek_pozyczki_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek pożyczki Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Wniosek_o_udzielenie_Pozyczki_Konsumenckiej_Stellantis_Consumer_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Wniosek o udzielenie Pożyczki Konsumenckiej Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2B/Regulamin_udzielania_pozyczek_na_zakup_pojazdow_Stellantis_Financial_Services_Polska_Sp._z_o.o..pdf" target="_blank">
                            <span class="sol-doc">Regulamin udzielania pożyczek na zakup pojazdów Stellantis Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o.</span>
                          </a>
                        </li>
                        <li>
                          <a href="/assets/uploads/documents/Pozyczka_B2C/Regulamin_udzielania_pozyczek_na_zakup_pojazdow_Stellantis_Consumer_Financial_Services_Polska_Sp._z_o.o._dla_Pozyczki_Konsumenckiej.pdf" target="_blank">
                            <span class="sol-doc">Regulamin udzielania pożyczek na zakup pojazdów Stellantis Consumer Financial Services Polska&nbsp;Sp.&nbsp;z&nbsp;o.o. dla Pożyczki Konsumenckiej</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section--banner-bottom">
      <img src="assets/img/brands/{{brandSlug}}/offer-banner-bottom.jpg" class="d-none d-lg-inline" />
      <img src="assets/img/brands/{{brandSlug}}/offer-banner-bottom-mobile.jpg" class="d-inline d-lg-none" />
      <div class="banner-bottom-headline">
        <span>wybierzemy dla ciebie najlepsze oferty</span><br />
        <button class="btn banner-btn" (click)="newsletterService.clickShowNL()"> SPRAWDŹ
          <svg fill="none" height="17" viewBox="0 0 20 17" width="20" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1489 0.189729L19.8085 8.04195C19.869 8.10103 19.9172 8.17209 19.9501 8.25083C19.983 8.32957 20 8.41434 20 8.5C20 8.58566 19.983 8.67043 19.9501 8.74917C19.9172 8.82791 19.869 8.89897 19.8085 8.95805L12.1489 16.8103C12.0304 16.9318 11.8697 17 11.7021 17C11.5345 17 11.3738 16.9318 11.2553 16.8103C11.1368 16.6888 11.0702 16.524 11.0702 16.3522C11.0702 16.1804 11.1368 16.0157 11.2553 15.8942L17.8191 9.15435L0.638298 9.15435C0.46901 9.15435 0.306657 9.08541 0.186953 8.9627C0.0672486 8.83998 -3.63961e-07 8.67354 -3.71547e-07 8.5C-3.79133e-07 8.32646 0.0672486 8.16002 0.186953 8.0373C0.306657 7.91459 0.46901 7.84565 0.638298 7.84565L17.8191 7.84565L11.2553 1.10582C11.1368 0.98434 11.0702 0.819576 11.0702 0.647775C11.0702 0.475974 11.1368 0.311211 11.2553 0.189729C11.3738 0.0682477 11.5345 -5.00804e-07 11.7021 -5.11516e-07C11.8697 -5.22228e-07 12.0304 0.0682477 12.1489 0.189729Z" fill="#FEFFFF"></path>
          </svg>
        </button>
      </div>
    </section>
    <section class="section section--note">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 ml-lg-auto">
            <p class="mb-2 text-uppercase">Ogłoszenie wyświetlono {{offer.visitCount}} razy</p>
          </div>
        </div>
        <div class="row" *ngIf="isSol && insuranceSolPercent && brandSlug === 'fiat'">
          <div class="col-lg-9 ml-lg-auto">
            <p  class="mb-2">
              Pakiet ubezpieczeń OC/AC za 0,7% przy zamówieniu online
              <br />
              Ubezpieczenie OC/AC/NNW/HCA Premium dla pojazdów w pierwszym roku eksploatacji. Stała suma ubezpieczenia fabrycznie nowego pojazdu przez okres 12 miesięcy, brak konsumpcji sumy ubezpieczenia oraz udziału własnego w przypadku wystąpienia szkody, zniesiona zwyżka w przypadku pojazdów będących własnością (lub współwłasnością) osób fizycznych w wieku do 29 lat, naprawa samochodu w Autoryzowanym Serwisie Obsługi Fiat, z zastosowaniem części oryginalnych. Pakiet ubezpieczeń dostępny za 0,7% w przypadku skorzystania przez Klienta z oferty zakupu online. Istnieje możliwość rozszerzenia ochrony o opcje dodatkowe - zwyżki z tytułu użytkowania oraz rozszerzenia ochrony dopłaca Klient zgodnie z tabelą zwyżek i zniżek. Oferta dotyczy tylko samochodów nowych. Szczegóły dotyczące promocji dostępne w salonach Fiat.
            </p>
          </div>
        </div>
      </div>
    </section>
    <div class="sol-beam" *ngIf="isSol">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="headline-beam" *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]">
              <ng-container *ngIf="brandSlug === 'fiat' && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment">
                <span>{{ beamHeadline }}</span><br />
                <ng-container *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].calculation.priceType === selectedPriceType">
                  <strong>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment | formatMoney }} zł</strong>
                  <small>
                    {{availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].priceType}} x {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].periodLabel }}
                  </small>
                </ng-container>
                <ng-container *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].calculation.priceType !== selectedPriceType">
                  <ng-container *ngIf="selectedPriceType === 'NET'">
                    <strong>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment / 1.23 | formatMoney }} zł</strong>
                    <small>
                      netto x {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].periodLabel }}
                    </small>
                  </ng-container>
                  <ng-container *ngIf="selectedPriceType === 'GROSS'">
                    <strong>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment * 1.23 | formatMoney }} zł</strong>
                    <small>
                      brutto x {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].periodLabel }}
                    </small>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="brandSlug !== 'fiat' && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment">
                <span>{{ beamHeadline }}</span><br />
                <strong>{{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].installment | formatMoney }} zł</strong>
                <small> {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].priceType }} x {{ availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].periodLabel }}</small>
              </ng-container>
            </div>
          </div>
          <div class="col-12 col-md-7 mt-4 mt-md-0 px-md-0 text-center text-md-right">
            <button *ngIf="offer.dealer !== null && offer.dealer.subdealer !== true"
                    class="btn btn-sol"
                    (click)="openOrCloseForm(true)">Poproś o kontakt</button>
            <button *ngIf="availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]
             && availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment"
                    class="btn btn-sol"
                    (click)="goToCart()">Dodaj do koszyka</button>
            <button *ngIf="!availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]]
             || !availableFinancialCalculators[activeCustomer][activeFinancialCalculatorId[activeCustomer]].correctInstallment"
                    class="btn btn-sol btn-sol-disabled">Dodaj do koszyka</button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- / NEW LAYOUT (FIAT)-->
</div>
<app-not-found *ngIf="offerNotExist"></app-not-found>
<loader [visible]="!loaded"></loader>

