import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {ISearchFormElement} from '../../interface/i-search-form-element';

declare var $: any;

@Component({
  selector: 'app-model-length-select',
  templateUrl: './model-length-select.component.html'
})
export class ModelLengthSelectComponent implements OnInit, ISearchFormElement {

  @Input() name: string;
  @Input() class: string;
  @Input() icon: string;
  @Input() value: string;
  @Input() version: string;
  @Input() availableValues: Array<any>;
  @Input() nullValue: string;
  isOptionListOpened = false;

  @Output() update = new EventEmitter<any>();
  @Output() maxLength = new EventEmitter<any>();

  maxHeightArray: Array<any>;
  size: string;
  @Output() maxHeight = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef) {
    this.value = null;
  }

  ngOnInit() {
    for (const av of this.availableValues) {
      if (av.value === this.value) {
        this.size = av.size;
      }
    }

    if (this.value !== null) {
      for (const av of this.availableValues) {
        if (av.value === this.value) {
          if (av.maxHeight.length > 0) {
            this.maxHeightArray = av.maxHeight;
          } else {
            this.maxHeightArray = null;
          }
        }
      }
    } else {
      this.maxHeightArray = null;
    }

    this.maxHeight.emit(this.maxHeightArray);
    this.maxLength.emit(this.value);

  }
  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setIcon(icon): void {
    this.icon = icon;
  }

  public getIcon(key): string {

    if (this.version === 'Transport Towarów') {
      switch (key) {
        case 'L1':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L1.jpg';
        case 'L2':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L2.jpg';
        case 'L3':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L3.jpg';
        case 'L4':
          return 'assets/img/brands/fiat-professional/form/transport_towarow/form-L4.jpg';
      }
    } else {
      switch (key) {
        case 'L2':
          return 'assets/img/brands/fiat-professional/form/do_transformacji/form-L2.jpg';
        case 'L2+':
          return 'assets/img/brands/fiat-professional/form/do_transformacji/form-L2+.jpg';
        case 'L3':
          return 'assets/img/brands/fiat-professional/form/do_transformacji/form-L3.jpg';
        case 'L4':
          return 'assets/img/brands/fiat-professional/form/do_transformacji/form-L4.jpg';
        case 'L5':
          return 'assets/img/brands/fiat-professional/form/do_transformacji/form-L5.jpg';
      }
    }

  }

  public setValue(value: string): void {
    let newVal: string = null;
    if (value !== null) {
      for (const item of value.split(':;')) {
        for (const av of this.availableValues) {
          if (av.key === item) {
              newVal = item;
              this.size = av.size;
          }
        }
      }
    }
    this.value = newVal;
  }

  public getValue(): string {
    if (this.value !== null) {
      return 'Długość: ' + this.value + ' ' + this.size + ' mm';
    } else {
      return this.nullValue;
    }
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public setAvailableValues(availableValues: Array<object>): void {
    this.availableValues = availableValues;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = null;
      this.emitUpdateEvent();
    } else {
      this.addNewValueOrDeleteWhenIsSelected(value);
      this.emitUpdateEvent();
    }
  }

  private emitUpdateEvent() {
    const updateObject = {
      name: this.name,
      value: this.value
    };

    if (this.value !== null) {
      for (const av of this.availableValues) {
        if (av.value === this.value) {
          if (av.maxHeight.length > 0) {
            this.maxHeightArray = av.maxHeight;
          } else {
            this.maxHeightArray = null;
          }
        }
      }
    } else {
      this.maxHeightArray = null;
    }

    this.maxHeight.emit(this.maxHeightArray);
    this.maxLength.emit(this.value);
    this.update.emit(updateObject);
  }

  private addNewValueOrDeleteWhenIsSelected(value: string): void {
    if (this.isSelected(value)) {
      const array: Array<string> = this.value.split(':;');
      array.splice(array.indexOf(value, 0), 1);
      if (array.length === 0) {
        this.value = null;
        this.maxHeightArray = null;
      } else {
        this.value = array.join(':;');
      }
    } else {
        this.value = value;
        for (const av of this.availableValues) {
          if (av.value === this.value) {
            this.size = av.size;
          }
        }
    }
  }

  public openOrCloseOptionList($event = null) {
    const isTargetHasIconClas = $($event.target).hasClass('icon-x');
    const isTargetHasRemoveClass = $($event.target).hasClass('remove');
    const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
    if ($event === null || !clickOnRemoveButton) {
      this.isOptionListOpened = !this.isOptionListOpened;
    }
  }


  closeOptionList() {
    this.isOptionListOpened = false;
  }

  public isSelected(value: any): boolean {
    if (this.value === null) {
      return false;
    }

    if (value === this.value) {
      return true;
    }
    return false;
  }
}
