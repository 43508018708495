import { Component, OnInit } from '@angular/core';
import {StockApiService} from '../../services/stock-api.service';
import {AppComponent} from '../../app.component';
import {AdobeService} from '../../services/adobe.service';
import {BlueKaiService} from '../../services/blue-kai.service';
import {TitleService} from '../../services/title.service';
import {BrandService} from '../../services/brand.service';

@Component({
  selector: 'app-dealer-list',
  templateUrl: './dealer-list.component.html',
})
export class DealerListComponent implements OnInit {

  dealers: Dealer[] = [];
  brandSlug: string;

  constructor(private stockApiService: StockApiService,
              private adobeService: AdobeService,
              private blueKaiService: BlueKaiService,
              private titleService: TitleService,
              ) {
    this.titleService.setTitleDealerList();

    stockApiService.getDealerList().then(value => {
      this.dealers = value;
    });
  }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page';
      this.adobeService.pageLoadDealerList();
      this.blueKaiService.changeRoute();
    });

    this.brandSlug = BrandService.getCurrentBrand().slug;
  }
}

interface Coordinates {
  longitude: number;
  latitude: number;
}

interface Dealer {
  code: string;
  name: string;
  street: string;
  postCode: string;
  city: string;
  region: string;
  coordinates: Coordinates;
  website: string;
  email: string;
  phones: string[];
  offer_count: number;
}


