import {Component, OnInit, Input, EventEmitter} from '@angular/core';
import {Brand} from '../../models/Brands';
import {BannerNoteComponent} from './banner-note.component';
import {AppComponent} from '../../app.component';

declare let window: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html'
})

export class BannerComponent implements OnInit {

  static onSelectBanner: EventEmitter<any> = new EventEmitter();

  @Input() bodyClass: string;
  @Input() brand: Brand;
  @Input() bannerPosition: string;

  selectedBanner: Object;
  bannerHash: string = null;
  bannerTarget: string = null;
  bannerLink: string = null;

  ngOnInit(): void {
    BannerComponent.onSelectBanner.subscribe((selectedBanner) => {
      BannerNoteComponent.onSelectBanner.emit(selectedBanner);
      this.selectedBanner = selectedBanner;
      if (typeof this.selectedBanner === 'object') {
        this.bannerHash = this.selectedBanner['hash'];
        this.bannerLink = this.selectedBanner['redirect_url'];
        this.bannerTarget = this.selectedBanner['redirect_target'];
      } else {
        this.bannerHash = null;
        this.bannerLink = null;
        this.bannerTarget = null;
      }
    });
  }

  isBannerSelected() {
    return this.selectedBanner !== undefined;
  }

  getCdnBannerUrlForSize(size: string): string {
    if (this.selectedBanner !== undefined) {
      return (AppComponent.getCDNBaseUrl() +
        '/uploads/banners/' +
        this.bannerHash + '/' +
        size +
        '.jpg');
    }
    return '';
  }
}
