import { Component, OnInit } from '@angular/core';
import {StockApiService} from '../../services/stock-api.service';
import {AppComponent} from '../../app.component';
import {BasicFilter} from '../../models/search/basic-filter';
import {BrandService} from '../../services/brand.service';
import {ErrorHandler} from '../../tools/error-handler';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {

  constructor(
    private stockApiService: StockApiService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      AppComponent.bodyClass = 'page page-error';
    });
    if (typeof BrandService.getCurrentBrand().slug !== 'undefined') {
      this.stockApiService.getBasicSearchData().then((value: BasicFilter) => {
        const documentPath = document.location.pathname;
        const path = documentPath.split('/')[documentPath.split('/').length - 1];


        if (documentPath === '/' + path || documentPath === '/' + BrandService.getCurrentBrand().slug + '/' + path) {
          for (const model of value.model) {
            if (model.key === path) {
              document.location.href = '/szukaj?models=' + model.key;
            }
          }
        }
      }).catch(reason => {
        ErrorHandler.handle(reason);
      });
    }
  }

}
