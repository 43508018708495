import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-pre-reservation',
  templateUrl: './pre-reservation-component.html'
})
export class PreReservationComponent implements OnInit {

  @Input() name: string;
  @Input() label: string;
  @Input() value: any = null;
  @Input() nullValue: string;

  @Output() update = new EventEmitter<any>();

  constructor() {
    this.value = null;
  }

  ngOnInit() {
  }

  public setName(name): void {
    this.name = name;
  }

  public getName(): string {
    return this.name;
  }

  public setValue(value: string): void {
    this.value = value;
  }

  public getValue(): string {
    return this.value;
  }

  public setNullValue(nullValue: string): void {
    this.nullValue = nullValue;
  }

  public onUpdate(value: string): void {
    if (value === null) {
      this.value = "1";
      this.emitUpdateEvent();
    } else {
      this.value = null;
      this.emitUpdateEvent();
    }
  }

  private emitUpdateEvent() {
    this.update.emit(this.value);
  }

  public isSelected(value: any): boolean {
    return this.value !== null;
  }
}
