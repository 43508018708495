import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {BrandService} from './brand.service';
import {TrackingService} from './tracking.service';
import {TrackingDebuggerComponent} from '../app-components/tracking-debugger/tracking-debugger.component';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class BlueKaiService {

  pushAttempsNumber = 0;

  constructor() {
  }

  formFillingOutStart(formType: string) {
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
      if (typeof window.bk_addPageCtx !== 'undefined') {
        window.bk_addPageCtx('form type', formType);
        window.bk_addPageCtx('form step', 'start');
        this.push();
        TrackingService.debug({
          type: TrackingDebuggerComponent.TYPE_SUCCESS,
          action: 'formFillingOutStart',
          service: 'BlueKaiService',
          data: {'form type': formType, 'form step': 'start'}
        });
      } else {
        if (this.pushAttempsNumber < 10) {
          this.nextAttemp('formFillingOutStart');
          this.pushAttempsNumber++;
          setTimeout(() => {
            this.formFillingOutStart(formType);
          }, 500);
        } else {
          this.trackerNotLoaded('formFillingOutStart');
        }
      }
    }
  }

  click2call() {
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
      if (typeof window.bk_addPageCtx !== 'undefined') {
        window.bk_addPageCtx('form type', 'click2call');
        window.bk_addPageCtx('form step', 'start');
        this.push();
        TrackingService.debug({
          type: TrackingDebuggerComponent.TYPE_SUCCESS,
          action: 'click2call',
          service: 'BlueKaiService',
          data: {'form type': 'click2call', 'form step': 'start'}
        });
      } else {
        if (this.pushAttempsNumber < 10) {
          this.nextAttemp('click2call');
          this.pushAttempsNumber++;
          setTimeout(() => {
            this.click2call();
          }, 500);
        } else {
          this.trackerNotLoaded('click2call');
        }
      }
    }
  }

  changeRoute(model = null, dealer = null) {
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
      if (typeof window.bk_addPageCtx !== 'undefined') {
        window.bk_addPageCtx('site section', window.location.href);
        window.bk_addPageCtx('brand', BrandService.getCurrentBrand().name);
        if (model !== null) {
          window.bk_addPageCtx('model', model);
        }
        if (dealer !== null) {
          window.bk_addPageCtx('dealer', dealer);
        }
        this.push();
        TrackingService.debug({
          type: TrackingDebuggerComponent.TYPE_SUCCESS,
          action: 'changeRoute',
          service: 'BlueKaiService',
          data: {'site section': window.location.href, 'brand': BrandService.getCurrentBrand().name, model, dealer}
        });
      } else {
        if (this.pushAttempsNumber < 10) {
          this.nextAttemp('changeRoute');
          this.pushAttempsNumber++;
          setTimeout(() => {
            this.changeRoute(model, dealer);
          }, 500);
        } else {
          this.trackerNotLoaded('changeRoute');
        }
      }
    }
  }

  thankYouPage(formType: string) {
    if (environment.version !== 'dev' && TrackingService.isEnabled(TrackingService.BLUEKAI)) {
      if (typeof window.bk_addPageCtx !== 'undefined') {
        window.bk_addPageCtx('form type', formType);
        window.bk_addPageCtx('form step', 'thankyou');
        this.push();
        TrackingService.debug({
          type: TrackingDebuggerComponent.TYPE_SUCCESS,
          action: 'thankYouPage',
          service: 'BlueKaiService',
          data: {'form type': formType, 'form step': 'thankyou'}
        });
      } else {
        if (this.pushAttempsNumber < 10) {
          this.nextAttemp('thankYouPage');
          this.pushAttempsNumber++;
          setTimeout(() => {
            this.thankYouPage(formType);
          }, 500);
        } else {
          this.trackerNotLoaded('thankYouPage');
        }
      }
    }
  }

  push() {
    if (TrackingService.isEnabled(TrackingService.BLUEKAI)) {
      if (typeof window.blueKaiKey !== 'undefined' && window.blueKaiKey !== null) {
        window.bk_doJSTag(window.blueKaiKey, 5);
        this.pushAttempsNumber = 0;
      } else {
        if (this.pushAttempsNumber < 10) {
          this.nextAttemp('push');
          this.pushAttempsNumber++;
          setTimeout(() => {
            this.push();
          }, 500);
        } else {
          this.trackerNotLoaded('push');
        }
      }
    }
  }

  nextAttemp(methodName) {
    TrackingService.debug(
      {
        type: TrackingDebuggerComponent.TYPE_WARNING,
        action: methodName,
        service: 'BlueKaiService',
        data: {message: 'Tracker not loaded, next attempt after 500ms'}
      });
  }

  trackerNotLoaded(methodName) {
    TrackingService.debug(
      {
        type: TrackingDebuggerComponent.TYPE_ERROR,
        action: methodName,
        service: 'BlueKaiService',
        data: {message: 'Tracker not loaded, number of attempts exceeded'}
      });
  }
}
