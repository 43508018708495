import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  static format(value) {
    return Object.keys(value);
  }

  transform(value: any, args?: any): any {
    return KeysPipe.format(value);
  }
}
