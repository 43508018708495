/// <reference types="@types/googlemaps" /> // Don't remove this line!!! It isn't normal comment!

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-dealer-select',
  templateUrl: './dealer-select.component.html',
})
export class DealerSelectComponent implements OnInit {
  @Input() changeZipCode: Observable<any> = null;
  @Input() zipCode: any = null;
  @Input() dealers: Array<any>;
  @Input() nullValue: string;

  isValid = false;
  loadingDealerList = true;
  showAll = false;
  availableValues: any[] = [];
  firstAvailableValues: any[] = [];
  value = null;
  isOptionListOpened = false;

  @Output() update = new EventEmitter<any>();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target) && !$(event.target).hasClass('show_all')) {
      this.closeOptionList();
    }
  }

  constructor(private eRef: ElementRef,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.changeZipCode.subscribe(value => {
      if (/^\d{2}-\d{3}$/.test(value)) {
        this.isValid = true;
        this.loadingDealerList = true;
        $(document.activeElement).trigger('blur');
        $(this.eRef.nativeElement).find('.select-selection').trigger('focus');
         this.isOptionListOpened = true;
        this.setDealerList(value);
      } else {
        this.isValid = false;
      }
    });
  }

  getLabelByValue(value) {
    for (const item of this.availableValues) {
      if (item.value === value) {
        return item.label;
      }
    }

    return 'Wybierz dealera';
  }

  setDealerList(zipCode) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({address: zipCode, region: 'pl'}, (results: any) => {
      if (results.length > 0) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();

        this.setAvailableValues(lat, lng);
      } else {
        this.isValid = false;
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  setAvailableValues(lat: number, lng: number) {
    this.availableValues = [];

    const dealerList = [];

    for (const key of Object.keys(this.dealers)) {
      const dealer = this.dealers[key];

      const distance = this.calcDistance(lat, lng, dealer.coordinates.latitude, dealer.coordinates.longitude);
      const label = dealer.name + ' (~' + distance + ' km)';
      const address = dealer.address.city + ', ' + dealer.address.street;
      dealerList.push({value: dealer.sincom, label: label, distance: distance, address: address});
    }

    this.availableValues = dealerList.sort((a, b) => {
      return a.distance - b.distance;
    });
    this.firstAvailableValues = this.availableValues.slice(0, 5);
    this.loadingDealerList = false;
    this.changeDetectorRef.detectChanges();
  }

  calcDistance(startLat: number, startLng: number, endLat: number, endLng: number): number {
    const distance = Math.sqrt(Math.pow(Math.abs(startLat - endLat), 2) + Math.pow(Math.abs(startLng - endLng), 2)) * 73;
    return Math.ceil(distance / 5) * 5;
  }

  public onUpdate(value: any): void {
    this.value = value;
    this.update.emit(value);
    this.closeOptionList();
  }

  public openOrCloseOptionList($event = null) {
      const isTargetHasIconClas = $($event.target).hasClass('icon-x');
      const isTargetHasRemoveClass = $($event.target).hasClass('remove');
      const clickOnRemoveButton = isTargetHasIconClas || isTargetHasRemoveClass;
      if ($event === null || !clickOnRemoveButton) {
        this.isOptionListOpened = !this.isOptionListOpened;
      }
  }

  closeOptionList() {
    this.isOptionListOpened = false;
    this.showAll = false;
  }
  setShowAll() {
    this.showAll = true;
  }
}
