<div *ngIf="loaded">
  <div class="financing__head row align-items-end beam-top-name-logo">
    <div class="financing__head-left col-6 pt-4 pt-xs-0">
<!--      <h4 class="financing__head-name-top text-uppercase">promocyjny produkt finansowy</h4>-->
      <h4 *ngIf="data !== null && (data.id === 'SMART_RENTING_AR' || data.id === 'SMART_RENTING_JEEP')" class="financing__head-name text-uppercase"><a href="https://www.leasys.com/pl/polish" target="_blank">{{ data.name }}</a></h4>
      <h4 *ngIf="data !== null && data.id !== 'SMART_RENTING_AR' && data.id !== 'SMART_RENTING_JEEP'" class="financing__head-name text-uppercase">{{ data.name }}</h4>
      <h4 *ngIf="data === null && brandSlug === 'jeep'" class="financing__head-name text-uppercase">Wynajem</h4>
      <h4 *ngIf="data === null && brandSlug !== 'jeep'" class="financing__head-name text-uppercase">Abonament</h4>
    </div>
    <div class="financing__head-right col-6 pt-4 pt-xs-0 text-right">
      <a href="https://www.leasys.com/pl/polish" target="_blank" *ngIf="data !== null && (data.id === 'SMART_RENTING_AR' || data.id === 'SMART_RENTING_JEEP')">
        <img src="assets/img/logo_leasys_white.png" alt="LEASYS"/>
      </a>
      <ng-container *ngIf="brandSlug !== 'fiat' && brandSlug !== 'fiat-professional'">
        <img *ngIf="data !== null && data.type === 'abonament' && data.id !== 'SMART_RENTING_AR' && data.id !== 'SMART_RENTING_JEEP'" src="assets/img/logo_leasys_white.png" alt="LEASYS"/>
        <img *ngIf="data !== null && (data.type === 'credit' || data.type === 'leasing')" src="assets/img/logo_stellantis_financial_white.svg" alt="Stellantis Financial Services"/>
        <img *ngIf="data === null" src="assets/img/logo_leasys_white.png" alt="LEASYS"/>
      </ng-container>
      <ng-container *ngIf="brandSlug === 'fiat' || brandSlug === 'fiat-professional'">
        <img *ngIf="data !== null && data.type === 'abonament' && data.id !== 'SMART_RENTING_AR' && data.id !== 'SMART_RENTING_JEEP'" src="assets/img/logo_leasys-black.png" alt="LEASYS"/>
        <img *ngIf="data !== null  && (data.type === 'credit' || data.type === 'leasing')" src="assets/img/logo_stellantis_financial_blue.svg" alt="Stellantis Financial Services"/>
        <img *ngIf="data === null" src="assets/img/logo-leasys-black.png" alt="LEASYS"/>
      </ng-container>
    </div>
  </div>
  <div *ngIf="data !== null" class="financing__summary row">
    <div class="financing__summary-label-top col-11 col-sm-8 col-md-6 pl-5 text-center text-uppercase">
      <span>od <strong>{{data.min_installment | formatMoney}}</strong> ZŁ {{getPriceType()}}/MIESIĄC</span><br />{{ data.label }}
    </div>
  </div>
  <div *ngIf="data !== null && data.description" class="financing__description"><p [innerHTML]="data.description"></p></div>
  <div *ngIf="data === null" class="financing__description">
    <ul class="pt-0">
      <li><b>Bez wpłaty własnej</b></li>
      <li>Samochody <b>dostępne od ręki</b> u dealera</li>
      <li>Rejestracja <b>w cenie</b></li>
      <li><b>Ubezpieczenie OC</b> w cenie raty</li>
      <li><b>Limit przebiegu</b> 10&nbsp;000 km rocznie</li>
      <li>Umowa na <b>36 miesięcy</b></li>
      <li>Oferta skierowana <b>do firm</b></li>
    </ul>
  </div>
  <div *ngIf="data !== null" class="financing__note-bottom"><p [innerHTML]="data.disclaimer"></p></div>
  <ng-container *ngIf="data === null">
    <div *ngIf="brandSlug === 'jeep' || brandSlug === 'alfa-romeo'" class="financing__note-bottom pb-3"><span class="financing__note-button" (click)="scrollToForm()">ZAPYTAJ O SZCZEGÓŁY <i class="icon icon-right_arrow"></i></span></div>
    <div *ngIf="brandSlug !== 'jeep' && brandSlug !== 'alfa-romeo'" class="financing__note-bottom pb-3"><span class="financing__note-button" (click)="scrollToForm()">POPROŚ O OFERTĘ <i class="icon icon-right_arrow"></i></span></div>
  </ng-container>
</div>
