<div class="price-field">
  <div class="price-field__label-input">
    <div class="price-field__label">Cena</div>
    <input class="price-field__input"
           type="text" (focus)="onClickInput($event, 'from')" (blur)="onBlurInput($event, 'from')"
           value="{{ nullValueFrom }} {{ (valueFrom !== null ? formattedValueFrom + ' zł': setInputMinValue())}}">
    <div class="price-field__separate"> - </div>
    <input class="price-field__input"
           type="text" (focus)="onClickInput($event, 'to')" (blur)="onBlurInput($event, 'to')"
           value="{{ nullValueTo }} {{ (valueTo !== null ? formattedValueTo + ' zł': setInputMaxValue())}}">
  </div>
  <div class="range_container">
    <div class="sliders_control">
      <input id="fromSlider" type="range" step="100" value="{{ (valueFrom !== null ? getFormSliderValue() : setFromSlider('min')) }}" min="{{priceMin}}" max="{{priceMax}}"/>
      <input id="toSlider" type="range" step="100" value="{{ (valueTo !== null ? getToSliderValue() : setToSlider('max')) }}" min="{{priceMin}}" max="{{priceMax}}"/>
    </div>
  </div>
</div>
