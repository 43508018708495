import { Pipe, PipeTransform } from '@angular/core';
import {BrandService} from '../services/brand.service';

@Pipe({
  name: 'addUrlPrefix'
})
export class UrlPrefixPipe implements PipeTransform {

  static getUrlPrefix() {
    const stockUrl = BrandService.getCurrentBrand().stockUrl;
    const prefix = stockUrl.split('/').slice(3).join('/');
    if (prefix.length > 0) {
      return '/' + prefix;
    } else {
      return '';
    }
  }

  transform(value: any, args?: any): any {
    return UrlPrefixPipe.getUrlPrefix() + value;
  }

}
