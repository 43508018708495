<form [formGroup]="formGroup">
  <ngx-recaptcha2 #captchaFormElem
                  [siteKey]="siteKey"
                  (success)="setCaptchaResponse($event)"
                  [hl]="'pl'"
                  [useGlobalDomain]="false"
                  (expire)="handleExpire()"
                  formControlName="recaptcha">
  </ngx-recaptcha2>
</form>
