import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent implements OnInit {
  @Input() public name: string;
  @Input() public placeholder: string;
  @Input() value: Array<any>;
  @Output() update = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }
}
