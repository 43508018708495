<div class="modal modal--select-brand right" id="selectBrand" tabindex="-1" role="dialog" aria-labelledby="selectBrandLabel" aria-hidden="true">
  <div class="modal-dialog ie-modal-dialog" role="document">
    <div class="modal-content ie-modal-content">
      <div class="modal-header">
        <span class="close" data-dismiss="modal" aria-label="Zamknij">
            <span class="icon-x"></span>
        </span>
        <h5 class="modal-title" id="selectBrandLabel">SPRAWDŹ OFERTY INNYCH MAREK</h5>
      </div>
      <div class="modal-body">
        <ul class="nav-brands">
          <li class="nav-brands__item" *ngFor="let brand of brandList">
            <a [href]="brand.stockUrl" (click)="changeBrand(brand.slug, $event)" class="nav-brands__link" [attr.data-adobe]="'top-nav:stocklocator:' + brand.slug">
              <span class="nav-brands__logo">
                <img *ngIf="brand.slug !== 'jeep' && brand.slug !== 'fiat-professional' &&  brand.slug !== 'alfa-romeo'" [lazyLoad]="'assets/img/brands/' + brand.slug + '/logo.png'" [customObservable]="loading$" [alt]="brand.name" />
                <img *ngIf="brand.slug === 'jeep' ||  brand.slug === 'fiat-professional' ||  brand.slug === 'alfa-romeo'" [lazyLoad]="'assets/img/brands/' + brand.slug + '/logo_white.png'" [customObservable]="loading$" [alt]="brand.name" />
              </span>
              <span class="nav-brands__text">
                {{brand.offerCount}} {{brand.offerCount | offerWord}}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
