import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'seo'
})
export class SeoPipe implements PipeTransform {

  public static slugify(string) {
    const a = 'ąśężźćńóàáäâãåèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    const b = 'asezzcnoaaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with ‘and’
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple — with single -
      .replace(/^-+/, '') // Trim — from start of Text
      .replace(/-+$/, ''); // Trim — from end of text
  }

  transform(value: any, args?: any): any {
    return SeoPipe.slugify(value);
  }
}
